import React from 'react';
import IntroOverlay from '../../components/intro-overlay/intro-overlay.component'
import './homepage.styles.scss';

const HomePage = () => (
    <div className="home-page">
        <IntroOverlay />
    </div>
)

export default HomePage;
import React from 'react'
import Header from '../../components/header/header.component'
import SavedBuildItem from '../../components/saved-build-item/saved-build-item.compent'
import DropZone from '../../components/drop-zone/drop-zone.component'
import Sidebar from '../../components/sidebar/sidebar.component'
import EditParkWidthHeight from '../../components/edit-width-height/edit-width-height.component'
import './preBuildPage.styles.scss'

import preBuiltParks from '../../preBuiltData.js'

class PreBuilt extends React.Component {
    
    constructor(props) {
        super()
        this.state = {
            cloudSaved: preBuiltParks,
            setupReady: false,
            relatedIds: [],
            gridState: {}
        }
    }

    componentDidMount() {
        document.body.classList.remove('freezeUi')
    }

    getSelectedParkData = (e, info, items) => {
        if (e.target.id === 'jsCopyID') {
            const parkID = e.target.children[0].innerText
            
            if (parkID !== 'local save') {
                var text = parkID
                navigator.clipboard.writeText(text).then(function() {
                    alert('You have copied this park ID: ' + parkID);
                });
            }
        }
        
        const gridState = {
            'parkDepth': Number(info.parkDepth),
            'parkHeight': info.height,
            'parkWidth': info.width,
            'parkName': info.name,
            'parkSizeText': info.text,
            'savedItems': items,
            'currency': info.currency,
            'location': info.location,
        }

        this.setState({'gridState': gridState})
        this.setState({setupReady: true})

        window.localStorage.setItem('grid', JSON.stringify(items))
        window.localStorage.setItem('parkName', info.name)
        window.localStorage.setItem('parkDepth', info.parkDepth)

        // this will gets a more standardized string, no caps & no white space
        const userCountry = gridState.location.toLowerCase().replace(/\s/g, "");

        const date = new Date()
        // returns MM-DD for simple comparison 
        const dateThisYear = date.toISOString().substring(5,10)

        // this MM-DD string is: March 1st (the last day of annual pre-price sale)
        const lastDayOfSale = '03-01'

        let currencyString = 'EUR';

        document.body.classList.remove('gbpprePrice', 'gbpPrice', 'ieprePrice', 'iePrice', 'usdPrice', 'denmark', 'france', 'poland')

        // by default the currency is set to IE Euros
        switch(userCountry.length > 0 && dateThisYear.length > 0) {
            case userCountry === 'unitedkingdom' && dateThisYear < lastDayOfSale :
                // this.setState({ regionSelected: 'gbpprePrice' })
                document.body.classList.add('gbpprePrice')
                currencyString = 'GBP'
                break;
            case userCountry === 'unitedkingdom' :
                // this.setState({ regionSelected: 'gbpPrice' })
                document.body.classList.add('gbpPrice')
                currencyString = 'GBP'
                break;
            case userCountry === 'ireland' && dateThisYear < lastDayOfSale :
                // this.setState({ regionSelected: 'ieprePrice' })
                document.body.classList.add('ieprePrice' )
                break;
            case userCountry === 'unitedstatesofamerica' :
                // this.setState({ regionSelected: 'usdPrice' })
                document.body.classList.add('usdPrice')
                currencyString = 'USD'
                break;
            case userCountry === 'poland':
                // this.setState({ regionSelected: 'poland' })
                document.body.classList.add('poland')
                currencyString = 'PLN'
                break;
            case userCountry === 'denmark':
                // this.setState({ regionSelected: 'denmark' })
                document.body.classList.add('denmark')
                currencyString = 'DKK'
                break;
            case userCountry === 'france':
                // this.setState({ regionSelected: 'france' })
                document.body.classList.add('france')
                break;
            case dateThisYear < lastDayOfSale :
                // this.setState({ regionSelected: 'ieprePrice' })
                document.body.classList.add('ieprePrice')
                break;
            default:
                // this.setState({ regionSelected: 'iePrice' })
                document.body.classList.add('iePrice')
        }
        console.log('this park was built in ' + gridState.location)
        window.localStorage.setItem('location', gridState.location)
        window.localStorage.setItem('currency', gridState.currency)
    }

    setRelatedProducts = (relatedProductIds) => {
        this.setState({relatedIds: relatedProductIds})
    }

    backToSaveList = () => {
        // close any possibly opened sidebar product groups
        const productSideBarGroups = document.querySelectorAll('.products__group')        
        productSideBarGroups.forEach(item => {
            item.classList.remove('products__group--open')
        })
        // reset the state of the page so user goes back to saved list
        this.setState({'setupReady': false})
    }

    setNewParkName = (newName) => {
        this.setState({
            gridState: {
                  ...this.state.gridState,
                  parkName: newName
            }
        })
    }

    updateParkWidthHeightState = (e) => {
        e.preventDefault()

        // hide the popup
        document.querySelector('#jsEditPopup').classList.remove('edit-width-height--open')

        // get the updated values
        let newWidth = Number(document.querySelector('#jsEdit-width').value)
        let newHeight = Number(document.querySelector('#jsEdit-height').value)

        // if nothing is entered then take the measurement from the state
        newWidth === 0 ? newWidth = this.state.parkWidth : newWidth = newWidth  * 14
        newHeight === 0 ? newHeight = this.state.parkHeight : newHeight = newHeight * 14
        
        // set the state
        this.setState({
            gridState: {
                  ...this.state.gridState,
                  parkWidth: newWidth,
                  parkHeight: newHeight
            }
        })
    }

    openEditWidthHeightPopup = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.add('edit-width-height--open')
        }
    }

    closeEditWidthHeightPopup = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.remove('edit-width-height--open')
        }
    }

    toggleNav = (e) => {
        document.body.classList.toggle('navOpen')
    }
    
    render() {
        if(!this.state.setupReady) {
            return (
                <div className='pre-build-list build-page page-flex'>
                    <main className='main'>
                        <button 
                            onClick={this.toggleNav}
                            className='menuButton wayOut' 
                            id='jsMenu'
                            alt="open and close navigation menu">
                            Menu
                            <img src="/img/icons/burger.svg" alt="menu icon" />
                        </button>
                        <div className="saved-builds">
                            <h1 className='offlineTitle'>Sign into to get prebuilt parks</h1>
                            <h1 className='onlineTitle'>Prebuilt Aqua Parks</h1>

                            <ol>
                                {
                                    this.state.cloudSaved && this.state.cloudSaved.length > 0 && this.state.cloudSaved.sort((a, b) => (a.parkInfo.name.trim() > b.parkInfo.name.trim()) ? 1 : -1).map((park, i) => (
                                        <SavedBuildItem getSelectedParkData={this.getSelectedParkData} key={i} info={park.parkInfo} items={park.parkItems} />
                                    ))
                                }
                            </ol>
                        </div>
                    </main>
                    <Sidebar disabled={true} />
                </div>
            )
        } else {
            return (
                <div className='build-page page-flex'>
                    <main className='main'>
                        <Header currentUser={this.props.currentUser} title={this.state.gridState.parkName} setNewParkName={this.setNewParkName}/>
                        <button onClick={this.backToSaveList} className='button button--grey button--savedBuilds'>&lt; Back to saved park list</button>
                        
                        <DropZone gridState={this.state.gridState} setRelatedProducts={this.setRelatedProducts} />
                        <p onClick={this.openEditWidthHeightPopup} id="jsParkSize" className="park-size">Park Size: { Number(this.state.gridState.parkWidth) / 14 }m x { Number(this.state.gridState.parkHeight) / 14}m</p>
                        <EditParkWidthHeight 
                            update={this.updateParkWidthHeightState} 
                            close={this.closeEditWidthHeightPopup} 
                            width={this.state.gridState.parkWidth}
                            height={this.state.gridState.parkHeight}
                        />
                    </main>
                    <Sidebar disabled={false} parkDepth={this.state.gridState.parkDepth} relatedProductIds={this.state.relatedIds} />
                </div>
            )
        }
    }
}

export default PreBuilt;
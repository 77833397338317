import React from 'react';

const ParkSettings = () => {

    const toggleEditWidthHeight = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.toggle('edit-width-height--open')
        }
    }

    return(
        <button className='menuButton' onClick={toggleEditWidthHeight}>
            Park Settings
            <img src="/img/icons/cog.svg" alt="open and edit park settings" />
        </button>
    )
}

export default ParkSettings;
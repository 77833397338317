import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase/firebase.utils'
import { signInWithGoogle } from '../../firebase/firebase.utils'
import './nav.styles.scss';

const Nav = ({ currentUser, displayName }) => {

    const toggleNav = (e) => {
        document.body.classList.toggle('navOpen')
        if (e.target.id === 'open-help') {
            document.body.classList.add('help--open')
        } else if(e.target.id === 'jsMenu') {
            document.body.classList.remove('help--open')
        }
    }

    const closeNav = () => {
        document.body.classList.remove('navOpen')
    }

    return(
        <section className="navAndButton">
            <button onClick={closeNav} className="button">
                <img src="/img/icons/close.svg" alt="close menu overlay" />
            </button>
            <nav className='nav'>
                <ul>
                    <li onClick={toggleNav}>
                        <Link to='/new-build'>New Build</Link>
                    </li>
                    <li onClick={toggleNav}>
                        <Link to='/auto-build'>Auto Build</Link>
                    </li>
                    <li onClick={toggleNav} className="ifLoggedIn">
                        <Link to='/saved-builds'>My Saved Parks</Link>
                    </li>
                    <li onClick={toggleNav} className="ifLoggedIn">
                        <Link to='/shared-builds'>Load Shared Park</Link>
                    </li>
                    <li onClick={toggleNav}>
                        <Link to='/pre-built'>Pre-Built Parks</Link>
                    </li>
                    <li id="open-help" className='help-link' onClick={toggleNav}>
                        Help
                    </li>

                    <li>
                        {
                        currentUser ?
                            <div 
                                id='jsGoogleSignOut'
                                onClick={() => auth.signOut()} 
                                className='googleAuth loggedIn'>
                                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" className="svg-inline--fa fa-google fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
                                <p>Sign out {displayName}?</p>
                            </div>
                        :
                            <div 
                                id='jsGoogleSignIn'
                                onClick={signInWithGoogle}
                                className='googleAuth loggedOut'>
                                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" className="svg-inline--fa fa-google fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
                                <p>Sign into your <strong>Google Account</strong></p>
                            </div>
                        }
                    </li>
                </ul>
            </nav>
        </section>
    )
}

export default Nav;
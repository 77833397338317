import React from 'react'
import './summary-product.styles.scss'

const SummaryProduct = ({ data, amount }) => {
    return (
        <div className="product">
            <div className="product__img">
                <img src={`/img/products-new/${data.imgNew ? data.imgNew : data.img}`} alt={`Aqua park attraction called ${data.title}`}></img>
            </div>
            <div className="product__details">
                <h3>{data.title}{amount ? ` x${amount}` : ``}</h3>
                <ul>
                    <li className="icon icon--size">{ (Number(data.width) / 14).toFixed(2) }m x { (Number(data.height) / 14).toFixed(2) }m</li>
                    <li className="icon icon--person">{data.brocap} person(s)</li>
                    <li className="icon icon--money">{Number(data.price).toFixed(2)}</li>
                    <li className="bold">TOTAL: { (Number(data.price) * Number(amount)).toFixed(2) }</li>
                </ul>
            </div>
        </div>
    )
}
export default SummaryProduct
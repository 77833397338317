import React from 'react';
import './new-form-build4.styles.scss';

const NewBuildForm4 = (props) => (
    <div id='new-build-section--four' className='new-build-section'>
        <h2>New Build</h2>
        <label htmlFor='your-email'>Would you like to be contacted by our team?
            <input type='email' placeholder='Your email' id='your-email' onKeyUp={props.getUserEmail} />
        </label>
        <p>4 of 5</p>
        <button className='button nextBuildForm' id='five'>Next</button>
    </div>
)

export default NewBuildForm4;
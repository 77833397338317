import React from 'react';
import './toggle-switch.styles.scss'

const ToggleSwitch = ({ text, toggleClass }) => {

    const toggleClassOnBody = () => {
        document.body.classList.toggle(`${toggleClass}`)
    }

    return(
        <div className={`toggle-switch ${toggleClass}`}>
            <span className="text">{ text }</span>
            <label className="switch" onChange={toggleClassOnBody}>
                <input type="checkbox" />
                <span className="slider round"></span>
            </label>
        </div>
    )
}

export default ToggleSwitch;
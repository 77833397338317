import React from 'react'
import './edit-park-item.styles.scss'

const EditParkItem = (props) => {

    // all this JS helps work out a dynamically sized UI for edit pieces
    let biggestSize = ''
    let isWidthLarger = false
    let sizeDifference = 0
    if (Number(props.height) >= Number(props.width)) {
        biggestSize = props.height
        isWidthLarger = false
        sizeDifference = Number(props.height) - Number(props.width)
    } else {
       biggestSize = props.width
       isWidthLarger = true
       sizeDifference = Number(props.width) - Number(props.height)
    }
    const halfTheDiff = Number(sizeDifference / 2).toFixed(2)
    const offsetOfPosition = 0 - Number(halfTheDiff) - 25

    return (
        <div 
            className="edit-park-item" 
            style={{ 
                height: `calc(${biggestSize}px + 50px)`, 
                width: `calc(${biggestSize}px + 50px)`,
                left: isWidthLarger ? `-25px` : `${offsetOfPosition}px`,
                top: isWidthLarger ? `${offsetOfPosition}px` : `-25px`
            }}
        >
            <div className="tool-button-wrap">
                <button id='jsTurnLeft' className='button tool-button rotate-item' data-array-pos={props.arrayPos} onClick={props.rotateClicked}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo" className="svg-inline--fa fa-undo fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"></path></svg>
                </button>

                <button id='jsTurnRight' className='button tool-button rotate-item rotate-item--right' data-array-pos={props.arrayPos} onClick={props.rotateClicked}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo" className="svg-inline--fa fa-undo fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"></path></svg>
                </button>

                <button className='button tool-button trash-item' data-array-pos={props.arrayPos} onClick={props.deleteClicked}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                </button>

                <button className='button tool-button duplicate-item' data-array-pos={props.arrayPos} onClick={props.duplicate}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="copy" className="svg-inline--fa fa-copy fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path></svg>
                </button>
            </div>
        </div>
    )
}

export default EditParkItem
import React from 'react';
import Sidebar from '../../components/sidebar/sidebar.component'
import './help-page.styles.scss';

const HelpPage = () => (
    <div className='help-page page-flex'>
        <main className='main'>
            <h1>Help</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium lorem in eros semper, at egestas neque fringilla.</p>
            <p>Donec consequat mi justo, eget tempor dui lacinia et. Mauris mattis at ante in molestie. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
            <p>Fusce ut urna quis arcu cursus varius at quis sapien. Proin ullamcorper urna vel venenatis tincidunt. Pellentesque non elementum massa. Maecenas a tincidunt felis. In hac habitasse platea dictumst.</p>
            <p>Duis pretium est velit. Phasellus laoreet justo eu nibh tincidunt accumsan. Sed lobortis ultricies eleifend. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
        </main>
        <Sidebar disabled={true} />
    </div>
)

export default HelpPage;
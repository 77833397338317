import React from 'react'
import Nav from './components/nav/nav.component'
import Help from './components/help/help.component'
import ImagePreviewOverlay from './components/image-preview-overlay/image-preview-overlay.component'
import HomePage from './pages/home/homepage.component'
import AutoBuildPage from './pages/auto-build/autoBuild.component'
import NewBuildPage from './pages/new-build/newBuild.component'
import SavedBuildPage from './pages/saved-build/savedBuild.component'
import PreBuiltPage from './pages/pre-built/preBuildPage.component'
import LoadBuild from './pages/load-build/loadBuild.component'
import HelpPage from './pages/help/help-page.component'
import SideTools from './components/side-tools/side-tools.component'
import { Route } from 'react-router-dom'
import { auth, createUserProfileDocument } from './firebase/firebase.utils'
import './App.scss';

class App extends React.Component {

  constructor() {
    super()

    this.state = {
      currentUser: null,
    }
  }

  // easy flick between hosted URL and sub URL
  localDev = true
  euroMode = false

  unsubscribeFromAuth = null

  componentDidMount() {
    document.body.classList.add(this.euroMode ? 'euroMode' : 'ukMode')
    // firebase auth when state changed set userAuth to this user
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {

      if (userAuth) {
        // createUserProfileDocument is a custom function that uses userAuth
        const userRef = await createUserProfileDocument(userAuth)

        // onSnapShot is a firestore function that can return user info and data
        userRef.onSnapshot(snapShot => {
          // set sate for current user from id then spread in the other data
          this.setState({
            currentUser: snapShot.id,
            ...snapShot.data()
          })
        })
      } else {
        // if this user logs out reset the current user to null
        this.setState({ currentUser: userAuth })
      }
    })
  } 

  SavedBuildPageWithProps = (props) => {
    return (
      <SavedBuildPage 
        currentUser={this.state.currentUser}
        {...props}
      />
    );
  }

  preBuiltPageWithProps = (props) => {
    return (
      <PreBuiltPage 
        currentUser={this.state.currentUser}
        {...props}
      />
    );
  }

  newBuiltPageWithProps = (props) => {
    return (
      <NewBuildPage 
        currentUser={this.state.currentUser}
        {...props}
      />
    );
  }

  autoBuiltPageWithProps = (props) => {
    return (
      <AutoBuildPage 
        currentUser={this.state.currentUser}
        {...props}
      />
    );
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth()
  }

  render() {
    return (
      <section className={`page ${this.state.currentUser ? 'loggedIn' : 'loggedOut'} ${this.euroMode ? 'euroMode' : 'ukMode'}`} data-user={this.state.currentUser ? `${this.state.currentUser}` : 'none' }>
        <Nav currentUser={this.state.currentUser} displayName={this.state.displayName} />
        <Help />
        <Route exact path={this.localDev ? '/' : '/aqua-park-builder'} component={HomePage} />
        <Route exact path='/new-build' render={this.newBuiltPageWithProps} />
        <Route exact path='/auto-build' render={this.autoBuiltPageWithProps} />
        <Route exact path='/saved-builds' render={this.SavedBuildPageWithProps} />
        <Route exact path='/shared-builds' component={LoadBuild} />
        <Route exact path='/pre-built' render={this.preBuiltPageWithProps} />
        <Route exact path='/help' component={HelpPage} />
        <SideTools currentUser={this.state.currentUser} displayName={this.state.displayName} />
        <ImagePreviewOverlay />
      </section>
    )
  }
}

export default App;

import React from 'react'
import Header from '../../components/header/header.component'
import DropZone from '../../components/drop-zone/drop-zone.component'
import EditParkWidthHeight from '../../components/edit-width-height/edit-width-height.component'
import Sidebar from '../../components/sidebar/sidebar.component'
import { getSavedPark } from '../../firebase/firebase.utils'
import './loadBuild.styles.scss'

class SavedBuildPage extends React.Component {
    
    constructor() {
        super();

        this.state = {
            setupReady: false,
            relatedIds: [],
            'gridState': {}
        }
    }

    checkDataJSON = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    checkData = (e) => {
        e.preventDefault()
        if(this.checkDataJSON(document.querySelector('#jsDataBox').value)) {
            const loadedData = JSON.parse(document.querySelector('#jsDataBox').value)
            this.getSelectedParkData(loadedData.parkInfo, loadedData.parkItems)
        } else {
            alert('Oops, try that copy and paste again')
        }
    }

    getSelectedParkData = (info, items) => {        
        const gridState = {
            'parkDepth' : Number(info.parkDepth),
            'parkHeight' : info.height,
            'parkWidth' : info.width,
            'parkName' : info.name,
            'parkSizeText' : info.text,
            'savedItems' : items,
            'currency': info.currency,
            'location': info.location,
        }

        this.setState({'gridState': gridState})
        this.setState({setupReady: true})

        // document.querySelector('#jsSidebar').setAttribute('data-park-depth', Number(info.parkDepth))

        window.localStorage.setItem('grid', JSON.stringify(items))
        window.localStorage.setItem('parkName', info.name)
        window.localStorage.setItem('parkDepth', info.parkDepth)

        if (info.location) {
            // this will gets a more standardized string, no caps & no white space
            const userCountry = info.location.toLowerCase().replace(/\s/g, "");

            const date = new Date()
            // returns MM-DD for simple comparison 
            const dateThisYear = date.toISOString().substring(5,10)

            // this MM-DD string is: March 1st (the last day of annual pre-price sale)
            const lastDayOfSale = '03-01'

            let currencyString = 'EUR';

            document.body.classList.remove('gbpprePrice', 'gbpPrice', 'ieprePrice', 'iePrice', 'usdPrice', 'denmark', 'france', 'poland')
            
            // by default the currency is set to IE Euros
            switch(userCountry.length > 0 && dateThisYear.length > 0) {
                case userCountry === 'unitedkingdom' && dateThisYear < lastDayOfSale :
                    // this.setState({ regionSelected: 'gbpprePrice' })
                    document.body.classList.add('gbpprePrice')
                    currencyString = 'GBP'
                    break;
                case userCountry === 'unitedkingdom' :
                    // this.setState({ regionSelected: 'gbpPrice' })
                    document.body.classList.add('gbpPrice')
                    currencyString = 'GBP'
                    break;
                case userCountry === 'ireland' && dateThisYear < lastDayOfSale :
                    // this.setState({ regionSelected: 'ieprePrice' })
                    document.body.classList.add('ieprePrice' )
                    break;
                case userCountry === 'unitedstatesofamerica' :
                    // this.setState({ regionSelected: 'usdPrice' })
                    document.body.classList.add('usdPrice')
                    currencyString = 'USD'
                    break;
                case userCountry === 'poland':
                    // this.setState({ regionSelected: 'poland' })
                    document.body.classList.add('poland')
                    currencyString = 'PLN'
                    break;
                case userCountry === 'denmark':
                    // this.setState({ regionSelected: 'denmark' })
                    document.body.classList.add('denmark')
                    currencyString = 'DKK'
                    break;
                case userCountry === 'france':
                    // this.setState({ regionSelected: 'france' })
                    document.body.classList.add('france')
                    break;
                case dateThisYear < lastDayOfSale :
                    // this.setState({ regionSelected: 'ieprePrice' })
                    document.body.classList.add('ieprePrice')
                    break;
                default:
                    // this.setState({ regionSelected: 'iePrice' })
                    document.body.classList.add('iePrice')
            }
            console.log('this park was built in ' + info.location)
            window.localStorage.setItem('location', info.location)
            window.localStorage.setItem('currency', info.currency)
        }
    }

    setRelatedProducts = (relatedProductIds) => {
        this.setState({relatedIds: relatedProductIds})
    }

    setNewParkName = (newName) => {
        this.setState({
            gridState: {
                  ...this.state.gridState,
                  parkName: newName
            }
        })
    }

    updateParkWidthHeightState = (e) => {
        e.preventDefault()

        // hide the popup
        document.querySelector('#jsEditPopup').classList.remove('edit-width-height--open')

        // get the updated values
        let newWidth = Number(document.querySelector('#jsEdit-width').value)
        let newHeight = Number(document.querySelector('#jsEdit-height').value)

        // if nothing is entered then take the measurement from the state
        newWidth === 0 ? newWidth = this.state.parkWidth : newWidth = newWidth  * 14
        newHeight === 0 ? newHeight = this.state.parkHeight : newHeight = newHeight * 14
        
        // set the state
        this.setState({
            gridState: {
                  ...this.state.gridState,
                  parkWidth: newWidth,
                  parkHeight: newHeight
            }
        })
    }

    openEditWidthHeightPopup = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.add('edit-width-height--open')
        }
    }

    closeEditWidthHeightPopup = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.remove('edit-width-height--open')
        }
    }

    getCloudSavedPark = async (e) => {
        e.preventDefault()
        const currentUserID = document.querySelector('.page').getAttribute('data-user')
        const requestParkID = document.querySelector('#jsDataInput').value
        const parkData = null

        // checking the user is logged in and has an ID in the input
        if (currentUserID.length <= 0) {
            alert('Sorry you might need to retry logging in')
        } else if (requestParkID.length <= 0) {
            alert('Oops you forgot to enter the parks ID')
        } else {
            const savedParkData = await getSavedPark(currentUserID, requestParkID, parkData)
            if (!savedParkData) { 
                return 
            } else {
                const savedParkJSON = JSON.parse(savedParkData)
                this.getSelectedParkData(savedParkJSON.parkInfo, savedParkJSON.parkItems)
            }
        }
    }

    backToSaveList = () => {
        // close any possibly opened sidebar product groups
        const productSideBarGroups = document.querySelectorAll('.products__group')        
        productSideBarGroups.forEach(item => {
            item.classList.remove('products__group--open')
        })
        // reset the state of the page so user goes back to saved list
        this.setState({'setupReady': false})
    }

    toggleNav = (e) => {
        document.body.classList.toggle('navOpen')
    }

    render() {
        if(!this.state.setupReady) {
            return (
                <div className='load-build-form build-page page-flex'>
                    <main className='main'>
                        <button 
                            onClick={this.toggleNav}
                            className='menuButton wayOut' 
                            id='jsMenu'
                            alt="open and close navigation menu">
                            Menu
                            <img src="/img/icons/burger.svg" alt="menu icon" />
                        </button>

                        <div className="load-builds">

                            <h1 className='offlineTitle'>Load a park from data</h1>
                            <form className='offlineLoader'>
                                <textarea id='jsDataBox' placeholder='paste the park data here'></textarea>
                                <button className='button' onClick={this.checkData}>Load Park</button>
                                <p className='small-print'>You can load cloud saved parks if you sign in</p>
                            </form>

                            <h1 className='onlineTitle'>Load a park from ID</h1>
                            <form className='onlineLoader'>
                                <input id='jsDataInput' placeholder='paste the park ID here'></input>
                                <button className='button' onClick={this.getCloudSavedPark}>Load Park</button>
                                <p className='small-print'>You can load parks offline from data strings if you log out</p>
                            </form>
                        </div>
                    </main>
                    <Sidebar disabled={true} />
                </div>
            )
        } else {
            return (
                <div className='build-page page-flex'>
                    <main className='main'>

                        <Header currentUser={this.props.currentUser} title={this.state.gridState.parkName} setNewParkName={this.setNewParkName}/>
                        <button onClick={this.backToSaveList} className='button button--grey button--savedBuilds'>&lt; Back to saved park list</button>
                        
                        <DropZone gridState={this.state.gridState} setRelatedProducts={this.setRelatedProducts} />
                        <p onClick={this.openEditWidthHeightPopup} id="jsParkSize" className="park-size">Park Size: { Number(this.state.gridState.parkWidth) / 14 }m x { Number(this.state.gridState.parkHeight) / 14}m</p>
                        <EditParkWidthHeight 
                            update={this.updateParkWidthHeightState} 
                            close={this.closeEditWidthHeightPopup} 
                            width={this.state.gridState.parkWidth}
                            height={this.state.gridState.parkHeight}
                        />
                    </main>
                    <Sidebar disabled={false} parkDepth={this.state.gridState.parkDepth} relatedProductIds={this.state.relatedIds} />
                </div>
            )
        }
    }
}

export default SavedBuildPage;
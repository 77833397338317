import html2canvas from 'html2canvas';
const buildPdf = () => {
    // const button = document.querySelector('.button--pdf')
    // getting variables needed for HTML
    const parkName = localStorage.getItem('parkName')
    // const shareID = localStorage.getItem('shareID')
    const location = localStorage.getItem('location')
    const currency = localStorage.getItem('currency')
    const parkSizeString = localStorage.getItem('parkSize')
    const parkSize = JSON.parse(parkSizeString)
    const parkDepth = localStorage.getItem('parkDepth')
    const sentDataString = localStorage.getItem('grid')
    const sentData = JSON.parse(sentDataString)
    // const features = sentData.length
    const vestCost = location === 'France' ? 0 : 23
    const wetsuitCost = location === 'France' ? 0 : 40
    let anchors = 0
    let capacity = 0
    let subTotalPrice = 0
    // let accessoriesTotalPrice = 0

    sentData.forEach(item => {
        anchors = anchors + Number(item.anchors)
        capacity = capacity + Number(item.brocap)
        subTotalPrice = subTotalPrice + Number(item.price)
    })

    // getting the park items data and grouping for use on HTML
    let itemArray = []
    let itemArrayIDs = []

    // All images in HTML
    let allImages = []
    allImages['shadow'] = `${window.location.origin}/img/pdf-pics/downShadow.png`;
    allImages['pump'] = `${window.location.origin}/img/pump.png`;
    allImages['buoy'] = `${window.location.origin}/img/buoy.png`;
    allImages['logo'] = `${window.location.origin}/img/icons/logo.png`;
    allImages['symbol'] = `${window.location.origin}/img/icons/symbol.png`;
    allImages['overview'] = `${window.location.origin}/img/overview.png`;

    // Pump object
    let pump = { quantity: 0, total: 0 };

    // Buoy object
    let buoy = { quantity: 0, total: 0 };

    // Pump quantity logic
    const pumpQty = Math.floor(capacity/150);

    if(pumpQty === 0){
        pump.quantity = 1
        pump.total = 180
    }else if(pumpQty > 0){
        pump.quantity = pumpQty
        pump.total = pumpQty * 180
    }

    // accessoriesTotalPrice += pump.total

    sentData && sentData.forEach(item => {

        // product_121 buoys, a park item that is on page 3 
        // not with other park items on page 2
        if (item.id === "product_121") {
            buoy.quantity ++
            buoy.total = Number(item.price) * buoy.quantity
            return
        }

        // this is all the other park items
        if (itemArrayIDs.includes(item.id)) {
            // if the park item exists then update its values
            const arrayIndex = itemArray.findIndex(x => x.itemId === item.id)
            itemArray[arrayIndex].itemAmount ++
            const newItemSubTotal = itemArray[arrayIndex].itemSubTotal *= itemArray[arrayIndex].itemAmount
            itemArray[arrayIndex].itemSubTotal = newItemSubTotal.toFixed(2)
        } else {
            // if not make that park item
            let itemObj = {
                'itemAmount': 1,
                'itemCapacity': item.brocap,
                'itemId': item.id,
                'itemPrice': item.price,
                'itemPrice-per': item.price,
                'itemSubTotal': Number(item.price),
                'itemTitle': item.title,
                'itemImage': item.imgNew,
                'itemAnchors': item.anchors
            }

            allImages[item.imgNew] = `${window.location.origin}/img/products-new/${item.imgNew}`
            itemArray.push(itemObj)
            itemArrayIDs.push(item.id)
        }
    })

    let vestTotalCost = 0, vestRecommendedQty = 0;

    let vestsData = [
        { size: 'XXS', img: `${window.location.origin}/img/pdf-pics2/VestXXS.png`, qty: 0.36 },
        { size: 'XS', img: `${window.location.origin}/img/pdf-pics2/VestXS.png`, qty: 0.36 },
        { size: 'S', img: `${window.location.origin}/img/pdf-pics2/VestS.png`, qty: 0.48 },
        { size: 'M', img: `${window.location.origin}/img/pdf-pics2/VestM.png`, qty: 0.54 },
        { size: 'L', img: `${window.location.origin}/img/pdf-pics2/VestL.png`, qty: 0.36 },
        { size: 'XL', img: `${window.location.origin}/img/pdf-pics2/VestXL.png`, qty: 0.24 },
        { size: 'XXL', img: `${window.location.origin}/img/pdf-pics2/VestXXL.png`, qty: 0.06 }
    ];

    let wetsuitTotalCost = 0, wetsuitRecommendedQty = 0;

    let wetsuitArr = [
        { size: 'Jnr S/128', img: `${window.location.origin}/img/pdf-pics/FullsuitJunior128.png`, qty: 0.208 },
        { size: 'Jnr M/140', img: `${window.location.origin}/img/pdf-pics/FullsuitJunior140.png`, qty: 0.26 },
        { size: 'Jnr L/146', img: `${window.location.origin}/img/pdf-pics/FullsuitJunior146.png`, qty: 0.26 },
        { size: 'Jnr XL/152', img: `${window.location.origin}/img/pdf-pics/FullsuitJunior152.png`, qty: 0.26 },
        { size: 'Adult XXS', img: `${window.location.origin}/img/pdf-pics/FullsuitXXS.png`, qty: 0.26 },
        { size: 'Adult XS', img: `${window.location.origin}/img/pdf-pics/FullsuitXS.png`, qty: 0.26 },
        { size: 'Adult S', img: `${window.location.origin}/img/pdf-pics/FullsuitS.png`, qty: 0.325 },
        { size: 'Adult M', img: `${window.location.origin}/img/pdf-pics/FullsuitM.png`, qty: 0.325 },
        { size: 'Adult L', img: `${window.location.origin}/img/pdf-pics/FullsuitL.png`, qty: 0.26 },
        { size: 'Adult XL', img: `${window.location.origin}/img/pdf-pics/FullsuitXL.png`, qty: 0.13 },
        { size: 'Adult XXL', img: `${window.location.origin}/img/pdf-pics/FullsuitXXL.png`, qty: 0.052 },
    ];

    wetsuitArr.forEach(item => {
        item.qtyTotal = Math.floor(capacity * item.qty);
        item.priceTotal = Math.floor(item.qtyTotal * wetsuitCost);
        wetsuitRecommendedQty += item.qtyTotal;
        wetsuitTotalCost += item.priceTotal;
    })

    vestsData.forEach(item => {
        item.qtyTotal = Math.floor(capacity * item.qty)
        item.priceTotal = Math.floor(item.qtyTotal * vestCost)
        vestRecommendedQty += item.qtyTotal
        vestTotalCost += item.priceTotal;
    })

    const dropZoneWrap = document.getElementById('jsPdfImage');
    const dropZone = document.getElementById('jsDropZone');
    let bestSizedZone = dropZoneWrap

    if (dropZoneWrap.clientWidth > dropZone.clientWidth) {
        bestSizedZone = dropZone
    }

    html2canvas(bestSizedZone).then(function(canvas) {
        let canvasImgSrc = canvas.toDataURL();

        let html = `
        <!DOCTYPE html>
        <html>
        <head>
            <meta charset="utf-8" />
            <title>Aquaglide - Commercial Aquaparks</title>
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="preconnect" href="https://fonts.gstatic.com">
            <link href="https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap" rel="stylesheet">
            <style>
                @font-face {
                    font-family: 'Avenir';
                    src: url('assets/fonts/Avenir-Roman.woff2') format('woff2'),
                    url('assets/fonts/Avenir-Roman.woff') format('woff');
                    font-weight: normal;
                    font-style: normal;
                    font-display: swap;
                }
                @font-face {
                    font-family: 'Avenir';
                    src: url('assets/fonts/Avenir-Medium.woff2') format('woff2'),
                    url('assets/fonts/Avenir-Medium.woff') format('woff');
                    font-weight: 500;
                    font-style: normal;
                    font-display: swap;
                }
                @font-face {
                    font-family: 'Avenir';
                    src: url('assets/fonts/Avenir-Heavy.woff2') format('woff2'),
                    url('assets/fonts/Avenir-Heavy.woff') format('woff');
                    font-weight: 700;
                    font-style: normal;
                    font-display: swap;
                }
                html{
                    font-size: 10px;
                }
                body{
                    background: #fff;
                    font-size: 1.4rem;
                    font-family: 'Avenir', sans-serif;
                    font-weight: 500;
                    color: #001721;
                    line-height: 1.42;
                    letter-spacing: 0;
                    margin: 0;
                    min-width: 320px;
                    padding: 2rem 3rem;
                    position: relative;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-text-size-adjust: none;
                }
                *{
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }
                img{
                    display: inline-block;
                    height: auto;
                    max-width: 100%;
                    vertical-align: middle;
                }
                a{
                    color: #002456;
                    transition: color 0.2s linear, opacity 0.2s linear;
                    text-decoration: none;
                }
                a:hover{
                    color: #2e8ece;
                }
                a:focus{
                    outline: none;
                }
                .panel{
                    box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
                    background: #d9d9d9;
                    margin: 3rem auto;
                    min-height: 74.9rem;
                    padding-right: 3.5rem;
                    position: relative;
                    width: 106rem;
                }
                .web-link-bar{
                    background: #001721;
                    color: #fff;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 3.5rem;
                }
                .web-link-bar a{
                    color: #fff;
                    display: inline-block;
                    font-size: 1.4rem;
                    line-height: 1.7rem;
                    letter-spacing: 0.4em;
                    transform: rotate(90deg);
                    transform-origin: 0 50%;
                    left: 1.8rem;
                    position: absolute;
                    top: 0.8rem;
                }
                .web-link-bar a:hover{
                    color: #ccc;
                }
                .panel-header{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    padding: 3rem 0 0;
                }
                .panel-title-wrap{
                    background: #001721;
                    color: #fff;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                }
                .panel-header .panel-title-wrap{
                    min-width: 35.5rem;
                    padding: 2rem 1rem 2rem 7rem;
                }
                .panel-symbol{
                    padding-right: 3rem;
                    position: relative;
                }
                .panel-symbol:after{
                    background: #fff;
                    content: "";
                    display: block;
                    height: 80%;
                    max-height: 3rem;
                    position: absolute;
                    right: 1.3rem;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 2px;
                }
                .panel-symbol img{
                    display: inline-block;
                    height: auto;
                    vertical-align: middle;
                    width: 6.7rem;
                }
                .panel-title{
                    font-family: 'Passion One', sans-serif;
                    font-size: 3.2rem;
                    line-height: 1.1;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                }
                .panel-project-title{
                    text-align: right;
                    padding-right: 4.2rem;
                }
                .panel-project-heading{
                    font-size: 1.3rem;
                    line-height: 1.1;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                }
                .panel-project-name{
                    color: #458ed4;
                    font-family: 'Passion One', sans-serif;
                    font-size: 2rem;
                    line-height: 1.1;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                }
                .a-panel-row{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                }
                .a-panel-row-header{
                    padding-bottom: 0.3rem;
                }
                .ft-prod-row{
                    -ms-flex-pack: end;
                    justify-content: flex-end;
                    padding-right: 4.2rem;
                    padding-top: 1.7rem;
                }
                .ft-prod{
                    margin-left: 11.5rem;
                    width: 21rem;
                }
                .ft-prod:first-child{
                    margin-left: 0;
                }
                .ft-prod h3{
                    font-family: 'Passion One', sans-serif;
                    font-weight: bold;
                    font-size: 1.9rem;
                    letter-spacing: 0.01em;
                    line-height: 1;
                    margin: 0;
                }
                .ft-prod h3 sub{
                    font-family: 'Avenir', sans-serif;
                    font-size: 1rem;
                    font-weight: 700;
                    display: inline-block;
                    line-height: 1.2;
                    letter-spacing: 0;
                    vertical-align: bottom;
                }
                .ft-prod p{
                    font-size: 1rem;
                    font-weight: 700;
                    line-height: 1.2;
                    margin: 0;
                }
                .ft-prod-image{
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-size: contain;
                    display: block;
                    margin-top: 0.3rem;
                    height: 120px;
                }
                .a-panel-row-title{
                    display: inline-block;
                    font-family: 'Passion One', sans-serif;
                    font-weight: bold;
                    font-size: 1.9rem;
                    letter-spacing: 0.01em;
                    line-height: 1;
                    margin: 0;
                }
                .a-panel-row-price{
                    font-size: 1rem;
                    font-weight: 700;
                    display: inline-block;
                    line-height: 1.2;
                    letter-spacing: 0;
                    vertical-align: bottom;
                }
                .ws-block{
                    padding: 1.6rem 3.6rem 0;
                }
                .ws-row{
                    margin-left: -1.5rem;
                    margin-right: -1.5rem;
                }
                .ws-prod-item-wrap{
                    padding-bottom: 0.9rem;
                    width: 8.8rem;
                }
                .ws-prod-item{
                    font-size: 1rem;
                    line-height: 1.2;
                    margin: 0 auto;
                    text-align: center;
                    width: 7rem;
                }
                .ws-prod-image{
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    height: 16.1rem;
                    margin-bottom: 0.5em;
                }
                .ws-prod-item p{
                    margin: 0 0 0.5em;
                }
                .ws-prod-item p:last-child{
                    margin-bottom: 0;
                }
                .ws-prod-qty{
                    font-family: 'Passion One', sans-serif;
                    font-size: 1.2em;
                    font-weight: bold;
                }
                .a-panel-bot{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: end;
                    align-items: flex-end;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                }
                .tp-block{
                    -ms-flex-item-align: end;
                    align-self: flex-end;
                    text-align: center;
                    text-transform: uppercase;
                    width: 19rem;
                }
                .tp-descr{
                    background: #001721;
                    border-bottom: 3px solid #fff;
                    color: #fff;
                    padding: 1.6rem 1rem 0.5rem;
                }
                .tp-item{
                    padding-bottom: 1.2rem;
                }
                .tp-item:last-child{
                    padding-bottom: 0;
                }
                .tp-item-name{
                    font-family: 'Passion One', sans-serif;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 1;
                    letter-spacing: 0.01em;
                }
                .tp-item-value{
                    color: #458ed4;
                    font-size: 2.2rem;
                    font-weight: 700;
                    line-height: 1.1;
                    padding-top: 0.3rem;
                }
                .tp-total{
                    background: #001721;
                    color: #fff;
                    padding: 0.5rem;
                }
                .tp-total-name{
                    display: inline-block;
                    font-family: 'Passion One', sans-serif;
                    font-weight: bold;
                    font-size: 1.88rem;
                    line-height: 1.1;
                    letter-spacing: 0.05em;
                    vertical-align: middle;
                }
                .tp-total-value{
                    color: #458ed4;
                    display: inline-block;
                    font-weight: 700;
                    font-size: 1.76rem;
                    line-height: 1.1;
                    letter-spacing: 0.01em;
                    margin-left: 0.1em;
                    vertical-align: middle;
                }
                .vs-block{
                    width: 83.5rem;
                }
                .vs-block .a-panel-row-header{
                    text-align: center;
                }
                .vs-row{
                    margin: 0 auto;
                    width: 79.8rem;
                }
                .vs-prod-item-wrap{
                    width: 11.4rem;
                }
                .vs-prod-item{
                    font-size: 1rem;
                    line-height: 1.2;
                    margin: 0 auto;
                    text-align: center;
                    width: 6.8rem;
                }
                .vs-prod-image{
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    height: 10.9rem;
                    margin-bottom: 0.5em;
                }
                .vs-prod-item p{
                    margin: 0 0 0.5em;
                }
                .vs-prod-item p:last-child{
                    margin-bottom: 0;
                }
                .vs-prod-qty{
                    font-family: 'Passion One', sans-serif;
                    font-size: 1.2em;
                    font-weight: bold;
                }
                .a-panel-footer{
                    font-size: 1rem;
                    font-weight: 700;
                    line-height: 1.2;
                    padding: 0.8rem 0;
                    text-align: center;
                }
                .a-panel-footer p{
                    margin: 0;
                }
                .f-panel-body{
                    padding: 1rem 4rem 2rem;
                }
                .features-row{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    margin-left: -0.5rem;
                    margin-right: -0.5rem;
                }
                .feature-item-wrap{
                    padding: 1rem 0.5rem 0;
                    width: 33.33%;
                }
                .feature-item{
                    background: #f3f3f3;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    position: relative;
                    padding: 0.9rem 1.4rem 0.7rem;
                }
                .feature-info-col{
                    width: 55%;
                }
                .feature-image-col{
                    padding-top: 1.8rem;
                    width: 45%;
                }
                .feature-title{
                    font-family: 'Passion One', sans-serif;
                    font-weight: bold;
                    font-size: 1.6rem;
                    line-height: 1;
                    letter-spacing: 0.1em;
                    min-height: 3.2rem;
                    padding-bottom: 0.4rem;
                    text-transform: uppercase;
                    height: 34px;
                    overflow: hidden;
                }
                .feature-descr-row{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    font-size: 1.1rem;
                    font-weight: 700;
                    line-height: 1.2;
                    letter-spacing: 0.02em;
                    margin-top: 0.3rem;
                    text-transform: uppercase;
                }
                .feature-descr-icon{
                    padding-right: 0.6rem;
                }
                .feature-descr-icon img{
                    display: block;
                    height: 1.5rem;
                    width: 1.5rem;
                }
                .feature-descr-name{
                    padding-top: 0.2rem;
                }
                .feature-descr-value{
                    color: #458ed4;
                    padding-left: 0.2em;
                    padding-top: 0.2rem;
                }
                .feature-image{
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    height: 7.2rem;
                    width: 11.6rem;
                }
                .feature-qty{
                    background: #449ce6;
                    border-radius: 50%;
                    color: #fff;
                    font-family: 'Passion One', sans-serif;
                    font-weight: bold;
                    font-size: 1.5rem;
                    line-height: 3rem;
                    position: absolute;
                    right: 1.1rem;
                    top: 1.1rem;
                    text-align: center;
                    width: 3rem;
                }
                .o-panel{
                    min-height: 22rem;
                    padding-left: 3.5rem;
                }
                .o-panel-inner{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    min-height: 74.9rem;
                }
                .o-panel-sidebar{
                    background: #001721;
                    color: #fff;
                    padding: 5rem 3.5rem 11rem;
                    position: relative;
                    width: 31.8rem;
                }
                .overview-wrap{
                    padding-top: 5.6rem;
                }
                .overview-item{
                    padding-bottom: 1rem;
                }
                .overview-item:last-child{
                    padding-bottom: 0;
                }
                .overview-name{
                    font-size: 1.6rem;
                    font-weight: 700;
                    line-height: 1.2;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                }
                .overview-value{
                    color: #458ed4;
                    font-family: 'Passion One', sans-serif;
                    font-size: 2.6rem;
                    line-height: 1.1;
                    letter-spacing: 0.1em;
                }
                .overview-value.upper{
                    text-transform: uppercase;
                }
                .share-wrap{
                    bottom: 4.5rem;
                    position: absolute;
                }
                .share-link{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    font-size: 1.2rem;
                    font-weight: 700;
                    line-height: 1.2;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                }
                .share-link img{
                    display: block;
                    height: auto;
                    margin-right: 2.8rem;
                    width: 4rem;
                }
                .o-panel-main{
                    display: flex;
                    justify-content: center;
                    width: 67rem;
                }
                .o-panel-image{
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    height: 100%;
                    width: 100%;
                }
                .c-panel{
                    background: #001721;
                    color: #fff;
                    min-height: 74.9rem;
                    padding: 0;
                    text-align: center;
                }
                .c-panel a{
                    color: #fff;
                    text-decoration: none;
                }
                .c-panel a:hover{
                    color: #ccc;
                }
                .c-panel-logo a{
                    display: inline-block;
                    vertical-align: middle;
                }
                .c-panel-logo{
                    padding: 14.9rem 0 9rem;
                }
                .c-panel-logo img{
                    height: auto;
                    display: inline-block;
                    vertical-align: middle;
                    width: 26rem;
                }
                .c-panel-contacts{
                    border: 1px solid #fff;
                    font-size: 1.5rem;
                    max-width: 44.4rem;
                    margin: 0 auto 13.8rem;
                    padding: 2.4rem 2rem 2.6rem;
                    text-align: center;
                    text-transform: uppercase;
                }
                .c-panel-contacts h3{
                    font-family: 'Passion One', sans-serif;
                    font-size: 2.8rem;
                    font-weight: bold;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    margin: 0 0 0.33em;
                }
                .c-panel-contacts p{
                    line-height: 1.73;
                    letter-spacing: 0.25em;
                    margin: 0;
                }
                .c-panel-footer{
                    border-top: 3px solid #fff;
                    font-size: 1.8rem;
                    letter-spacing: 0.25em;
                    margin: 0 auto;
                    max-width: 65rem;
                    padding: 1.6rem 0 5.7rem;
                    text-transform: uppercase;
                }
                .i-panel{
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    background: #001721;
                    color: #fff;
                    min-height: 74.9rem;
                    padding: 4rem 0;
                    text-align: center;
                }
                .i-panel-logo{
                    text-align: center;
                    padding: 0 2rem;
                    width: 39.6%;
                }
                .i-panel-logo a{
                    display: inline-block;
                    vertical-align: middle;
                }
                .i-panel-logo img{
                    display: inline-block;
                    height: auto;
                    vertical-align: middle;
                    width: 26rem;
                }
                .i-panel-info{
                    width: 60.4%;
                    padding: 0 2rem;
                }
                .i-panel-title{
                    display: inline-block;
                    font-family: 'Passion One', sans-serif;
                    font-size: 4.2rem;
                    letter-spacing: 0.2em;
                    font-weight: bold;
                    line-height: 1;
                    padding: 0.3em 0;
                    position: relative;
                    text-transform: uppercase;
                }
                .i-panel-title:before,
                .i-panel-title:after{
                    background: #fff;
                    content: "";
                    display: block;
                    height: 2px;
                    left: 50%;
                    max-width: 39rem;
                    position: absolute;
                    transform: translateX(-50%);
                    width: 90%;
                }
                .i-panel-title:before{
                    top: 0;
                }
                .i-panel-title:after{
                    bottom: 0;
                }
                .i-panel-subtitle{
                    font-size: 1.7rem;
                    line-height: 1.5;
                    letter-spacing: 0.8em;
                    padding-top: 0.85em;
                    text-transform: uppercase;
                }
            </style>
        </head>
        <body>
            <div class="wrapper">
                <div class="panel i-panel">
                    <div class="i-panel-logo">
                        <a href="http://aquaglide.com/" target="_blank"><img src="${allImages['logo']}" alt="Aquaglide"></a>
                    </div>
                    <div class="i-panel-info">
                        <div class="i-panel-title">${parkName}</div>
                        <div class="i-panel-subtitle">Design Concept</div>
                    </div>
                </div>
                <div class="panel o-panel">
                    <div class="o-panel-inner">
                        <div class="o-panel-sidebar">
                            <div class="panel-title-wrap">
                                <div class="panel-symbol"><img src="${allImages['symbol']}" alt=""></div>
                                <div class="panel-title">Overview</div>
                            </div>
                            <div class="overview-wrap">
                                <div class="overview-item">
                                    <div class="overview-name">Project Name:</div>
                                    <div class="overview-value upper">${parkName}</div>
                                </div>
                                <div class="overview-item">
                                    <div class="overview-name">Location:</div>
                                    <div class="overview-value upper">${location}</div>
                                </div>
                                <div class="overview-item">
                                    <div class="overview-name">Certified Capacity:</div>
                                    <div class="overview-value">${capacity}</div>
                                </div>
                                <div class="overview-item">
                                    <div class="overview-name">Park Area:</div>
                                    <div class="overview-value">${parkSize.width} x ${parkSize.height}</div>
                                </div>
                                <div class="overview-item">
                                    <div class="overview-name">Minimum Water Depth:</div>
                                    <div class="overview-value">${parkDepth}</div>
                                </div>
                                <div class="overview-item">
                                    <div class="overview-name">Anchor Points:</div>
                                    <div class="overview-value">${anchors}</div>
                                </div>
                                <div class="overview-item">
                                    <div class="overview-name">Park Total:</div>
                                    <div class="overview-value">${subTotalPrice.toFixed(2)} ${currency}</div>
                                </div>
                            </div>
                            <div class="share-wrap">
                                <div class="share-link"><img src="assets/img/icons/share-icon.png" alt=""> <span>Share This Park</span></div>
                            </div>
                        </div>
                        <div class="o-panel-main" style="background-color: #bfddf5">
                            <div class="o-panel-image" style="max-width:${dropZoneWrap.clientWidth > dropZone.clientWidth ? `${dropZone.clientWidth}px` : `unset`}; background-image: url(${canvasImgSrc})"></div>
                        </div>
                    </div>
                    <div class="web-link-bar">
                        <a href="http://aquaglide.com/" target="_blank">AQUAGLIDE.COM</a>
                    </div>
                </div>
                <div class="panel f-panel">
                    <div class="panel-header">
                        <div class="panel-title-wrap">
                            <div class="panel-symbol"><img src="${allImages['symbol']}" alt=""></div>
                            <div class="panel-title">Features</div>
                        </div>
                        <div class="panel-project-title">
                            <div class="panel-project-heading">Project name</div>
                            <div class="panel-project-name">${parkName}</div>
                        </div>
                    </div>
                    <div class="f-panel-body">
                        <div class="features-row">`
                        itemArray.forEach(item => {
                            html += `
                                <div class="feature-item-wrap">
                                    <div class="feature-item">
                                        <div class="feature-info-col">
                                            <div class="feature-title">${item.itemTitle}</div>
                                            <div class="feature-descr">
                                                <div class="feature-descr-row">
                                                    <div class="feature-descr-icon"><img src="assets/img/icons/capacity-icon.png" alt=""></div>
                                                    <div class="feature-descr-name">Capacity:</div>
                                                    <div class="feature-descr-value">${item.itemCapacity}</div>
                                                </div>
                                                <div class="feature-descr-row">
                                                    <div class="feature-descr-icon"><img src="assets/img/icons/anchors-icon.png" alt=""></div>
                                                    <div class="feature-descr-name">Anchors:</div>
                                                    <div class="feature-descr-value">${item.itemAnchors}</div>
                                                </div>
                                                <div class="feature-descr-row">
                                                    <div class="feature-descr-icon"><img src="assets/img/icons/price-icon.png" alt=""></div>
                                                    <div class="feature-descr-name">Unit Price:</div>
                                                    <div class="feature-descr-value">${location === 'France' ? 0 : item.itemPrice}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="feature-image-col">
                                            <div class="feature-image" style="background-image: url(${window.location.origin}/img/products-real/${item.itemImage});"></div>
                                        </div>
                                        <div class="feature-qty">x${item.itemAmount}</div>
                                    </div>
                                </div>
                            `
                        })
                        html += `</div>
                    </div>
                    <div class="web-link-bar">
                        <a href="http://aquaglide.com/" target="_blank">AQUAGLIDE.COM</a>
                    </div>
                </div>
                <div class="panel a-panel">
                    <div class="panel-header">
                        <div class="panel-title-wrap">
                            <div class="panel-symbol"><img src="${allImages['symbol']}" alt=""></div>
                            <div class="panel-title">ACCESSORIES</div>
                        </div>
                        <div class="panel-project-title">
                            <div class="panel-project-heading">Project name</div>
                            <div class="panel-project-name">${parkName}</div>
                        </div>
                    </div>
                    <div class="a-panel-body">
                        <div class="ft-prod-block">
                            <div class="a-panel-row ft-prod-row">
                                <div class="ft-prod">
                                    <h3 class="ft-prod-name">PARK PUMP</h3>
                                    <p class="ft-prod-price">UNIT PRICE: ${location === 'France' ? 0 : pump.total} ${currency}</p>
                                    <p class="ft-prod-qty">QTY: ${pump.quantity}</p>
                                    <div class="ft-prod-image" style="background-image: url(${allImages['pump']});"></div>
                                </div>
                                <div class="ft-prod">
                                    <h3 class="ft-prod-name">SWIM BUOY <sub>PACK OF 3</sub></h3>
                                    <p class="ft-prod-price">UNIT PRICE: ${location === 'France' ? 0 : buoy.total} ${currency}</p>
                                    <p class="ft-prod-qty">QTY: ${buoy.quantity}</p>
                                    <div class="ft-prod-image" style="background-image: url(${allImages['buoy']});"></div>
                                </div>
                            </div>
                        </div>
                        <div class="ws-block">
                            <div class="a-panel-row-header">
                                <div class="a-panel-row-title">WETSUITS</div>
                                <div class="a-panel-row-price">UNIT PRICE ${location === 'France' ? 0 : wetsuitCost} ${currency}</div>
                            </div>
                            <div class="a-panel-row ws-row">`
                                wetsuitArr.forEach(item => {
                                    html += `<div class="ws-prod-item-wrap">
                                        <div class="ws-prod-item">
                                            <div class="ws-prod-image" style="background-image: url(${item.img});"></div>
                                            <p class="ws-prod-title">${item.size.split(" ")[0]} <br>${item.size.split(" ")[1]}</p>
                                            <p class="ws-prod-qty">QTY: ${item.qtyTotal}</p>
                                        </div>
                                    </div>`
                                })
                            html += `</div>
                        </div>
                        <div class="a-panel-bot">
                            <div class="tp-block">
                                <div class="tp-descr">
                                    <div class="tp-item">
                                        <div class="tp-item-name">Recommended wetsuit <br>quantity</div>
                                        <div class="tp-item-value">${wetsuitRecommendedQty}</div>
                                    </div>
                                    <div class="tp-item">
                                        <div class="tp-item-name">Recommended vest <br>quantity</div>
                                        <div class="tp-item-value">${vestRecommendedQty}</div>
                                    </div>
                                </div>
                                <div class="tp-total">
                                    <div class="tp-total-name">TOTAL:</div>
                                    <div class="tp-total-value">${wetsuitTotalCost + vestTotalCost} ${currency}</div>
                                </div>
                            </div>
                            <div class="vs-block">
                                <div class="a-panel-row-header">
                                    <div class="a-panel-row-title">VESTS</div>
                                    <div class="a-panel-row-price">UNIT PRICE ${vestCost} ${currency}</div>
                                </div>
                                <div class="a-panel-row vs-row">`;
                                    vestsData.forEach(item => {
                                        html += `<div class="vs-prod-item-wrap">
                                            <div class="vs-prod-item">
                                                <div class="vs-prod-image" style="background-image: url(${item.img});"></div>
                                                <p class="vs-prod-title">${item.size}</p>
                                                <p class="vs-prod-qty">QTY: ${item.qtyTotal}</p>
                                            </div>
                                        </div>`
                                    })
                                html += `</div>
                            </div>
                        </div>
                    </div>
                    <div class="a-panel-footer">
                        <p>All pricing given in this document are estimates and are subject to change without warning. Please get in touch with your local Aquaglide Distributor to discuss a quotation.</p>
                    </div>
                    <div class="web-link-bar">
                        <a href="http://aquaglide.com/" target="_blank">AQUAGLIDE.COM</a>
                    </div>
                </div>
                <div class="panel c-panel">
                    <div class="c-panel-inner">
                        <div class="c-panel-logo">
                            <a href="http://aquaglide.com/" target="_blank"><img src="${allImages['logo']}" alt="Aquaglide"></a>
                        </div>
                        <div class="c-panel-contacts">
                            <h3>ANY QUESTIONS?</h3>
                            <p>Contact <br><a href="mailto:sales@aquaglide.com">sales@aquaglide.com</a></p>
                        </div>
                        <div class="c-panel-footer">
                            <a href="https://www.aquaglide.com/" target="_blank">aquaglide.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </body>
        </html>
        `;

        var new_page = window.open();
        new_page.document.write(html);
    })
}

export default buildPdf
import React from 'react';
import { cloudShare } from '../../firebase/firebase.utils'

const ShareBuild = ({ currentUser })  => {

    const sharePark = (e) => {
        // TD: refactor to a utility, this is commonly used now

        if (!currentUser) {
            e.target.id === 'jsSend' ? 
            alert('Contact a park representative and share your park ID with them to take a look, but first you need to sign into your Google account')
            :
            alert('You can upload your build to a shared space and send the ID code to a friend, colleague or park representative, but first you need to sign into your Google account')
            return
        }

        // check if there is anything currently to save
        let currentBuild = localStorage.getItem('grid')
        
        if (currentBuild && currentBuild.length > 0) {
            currentBuild = JSON.parse(currentBuild)

            // get the park info
            const parkInfo = {
                'name': localStorage.getItem('parkName'),
                'text': document.querySelector('#jsParkSize').innerHTML,
                'height': document.querySelector('#jsDropZone').offsetHeight,
                'width': document.querySelector('#jsDropZone').offsetWidth,
                'parkDepth': document.querySelector('#jsSidebar').dataset.parkDepth,
                'location': window.localStorage.getItem('location'),
                'currency': window.localStorage.getItem('currency'),
            }

            // save the build info with the park info
            const savedParkItem = {
                'parkInfo': parkInfo,
                'parkItems': currentBuild
            }

            cloudShare(currentUser, savedParkItem.parkInfo.name, JSON.stringify(savedParkItem), e.target.id)
            
        } else {
            alert('Add some items to your park first')
        }
    }

    return(
        <button className='menuButton share' onClick={sharePark}>
            <img src="/img/icons/share-dark.svg" alt="share your 3D park" />
            Share
        </button>
    )
}

export default ShareBuild;
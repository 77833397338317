// PLEASE NOTE
// there is a bug in G Sheets Export to JSON button
// its fucks up around line 470 on a URL
// this needs manually fixed each time you paste here

const productData = [
    {
        "id": 9,
        "originalRank": 1,
        "rankId": 103,
        "product": "Everest",
        "categoryOldBackup": "XLarge",
        "category": "Slides",
        "image": "Everest.png",
        "imageNew": "everest.png",
        "imageWithAnchors": "Everestwithanchors.png",
        "folder": "everest",
        "model": "Everest",
        "upc": "0 7 90628 04211 6",
        "agarticle": 585219625,
        "oldwebsitelink": "https://www.aquaglide.co.uk/project/everest/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/everest/",
        "brochureCapacity": 18,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 4,
        "anchorPoints": 8,
        "lengthM": 11.9,
        "widthM": 4.96,
        "symetricalYn": "N",
        "usdPrice": 23999.99,
        "gbpPrice": 19503.647,
        "iePrice": 21351.0849,
        "france": 23030,
        "euukPrice": 13945.1,
        "eunlPrice": 13170.37,
        "nlPrice": 14140.23,
        "euroPrice": 21847.9,
        "poland": 105686.99,
        "denmark": 175448,
        "canada": 43999.98,
        "switzerland": 21540,
        "base": 21540,
        "preOrderDiscount": 16926.01,
        "stRelatedId": 0,
        "ndRelatedId": 10,
        "rdRelatedId": 21,
        "connectorPieceOrAlone": 20,
        "previewImages": "Piece"
    },
    {
        "id": 10,
        "originalRank": 2,
        "rankId": 201,
        "product": "Sky Rocket",
        "categoryOldBackup": "XLarge",
        "category": "Swing & Climb",
        "image": "Skyrocket.png",
        "imageNew": "skyrocket.png",
        "imageWithAnchors": "SkyRocketwithanchors.png",
        "folder": "skyrocket",
        "model": "Sky_Rocket",
        "upc": "0 7 90628 04222 2",
        "agarticle": 585219664,
        "oldwebsitelink": "https://www.aquaglide.co.uk/project/skyrocket/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/skyrocket/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 3.66,
        "anchorPoints": 6,
        "lengthM": 8,
        "widthM": 8,
        "symetricalYn": "Y",
        "usdPrice": 8999.99,
        "gbpPrice": 7010,
        "iePrice": 8194.52,
        "france": 8960,
        "euukPrice": 5221.52,
        "eunlPrice": 4931.43,
        "nlPrice": 5294.58,
        "euroPrice": 7478.15,
        "poland": 36174.8,
        "denmark": 58337,
        "canada": 15999.98,
        "switzerland": 8070,
        "base": 8070,
        "preOrderDiscount": 6347.25,
        "stRelatedId": 0,
        "ndRelatedId": 37,
        "rdRelatedId": 9,
        "connectorPieceOrAlone": 20,
        "previewImages": "Piece",
        "undefined": "Skyrocket-1.jpg, Skyrocket-2.jpg, Skyrocket-3.png"
    },
    {
        "id": 11,
        "originalRank": 5,
        "rankId": 306,
        "product": "Blockade 20",
        "categoryOldBackup": "Medium",
        "category": "Core 20ft",
        "image": "blockade.png",
        "imageNew": "Blockade20.png",
        "imageWithAnchors": "Blockade20withanchors.png",
        "folder": "blockade",
        "model": "Blockade_20",
        "upc": "0 7 90628 04116 4",
        "agarticle": 585219662,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/blockade/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/blockade-20/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.66,
        "anchorPoints": 4,
        "lengthM": 1.98,
        "widthM": 6.09,
        "symetricalYn": "N",
        "usdPrice": 3699.99,
        "gbpPrice": 2750,
        "iePrice": 3205.1,
        "france": 3510,
        "euukPrice": 2179.95,
        "eunlPrice": 2058.84,
        "nlPrice": 2210.45,
        "euroPrice": 3360.5,
        "poland": 16256.1,
        "denmark": 26216,
        "canada": 6399.98,
        "switzerland": 3180,
        "base": 3180,
        "preOrderDiscount": 2640.45,
        "stRelatedId": 0,
        "ndRelatedId": 46,
        "rdRelatedId": 51,
        "connectorPieceOrAlone": 69,
        "previewImages": "Piece",
        "undefined": "Blockade-1.jpg, Blockade-2.png"
    },
    {
        "id": 12,
        "originalRank": 9,
        "rankId": 404,
        "product": "Barricade 10",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "barricade.png",
        "imageNew": "Barricade10.png",
        "imageWithAnchors": "Barricade10withanchors.png",
        "folder": "barricade",
        "model": "Barricade_10",
        "upc": "0 7 90628 04117 1",
        "agarticle": 585219103,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/barricade/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/barricade-10/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 1.98,
        "widthM": 3.04,
        "symetricalYn": "N",
        "usdPrice": 2099.99,
        "gbpPrice": 1710,
        "iePrice": 1994.55,
        "france": 2185,
        "euukPrice": 1295.24,
        "eunlPrice": 1223.28,
        "nlPrice": 1313.36507483045,
        "euroPrice": 1931.93,
        "poland": 9345.53,
        "denmark": 15071,
        "canada": 3799.98,
        "switzerland": 2000,
        "base": 2000,
        "preOrderDiscount": 1702.99,
        "stRelatedId": 0,
        "ndRelatedId": 11,
        "rdRelatedId": 47,
        "connectorPieceOrAlone": 44,
        "previewImages": "Piece",
        "undefined": "Barricade-1.jpg, Barricade-2.jpg, Barricade-3.png"
    },
    {
        "id": 17,
        "originalRank": 10,
        "rankId": 503,
        "product": "Bendback",
        "categoryOldBackup": "Medium",
        "category": "Corners",
        "image": "Bendback.png",
        "imageNew": "bendback.png",
        "imageWithAnchors": "Bendbackwithanchors.png",
        "folder": "bendback",
        "model": "Bendback",
        "upc": "0 7 90628 04129 4",
        "agarticle": 585219633,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/bendback/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/bendback/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 2.16,
        "widthM": 4.62,
        "symetricalYn": "N",
        "usdPrice": 2099.99,
        "gbpPrice": 1530,
        "iePrice": 1741.09,
        "france": 1905,
        "euukPrice": 1130.66,
        "eunlPrice": 1067.84,
        "nlPrice": 1146.48,
        "euroPrice": 2142.02,
        "poland": 10361.79,
        "denmark": 15973,
        "canada": 3799.98,
        "switzerland": 1750,
        "base": 1750,
        "preOrderDiscount": 1584.27,
        "stRelatedId": 0,
        "ndRelatedId": 18,
        "rdRelatedId": 63,
        "connectorPieceOrAlone": 34,
        "previewImages": "Piece",
        "undefined": "Bendback-1.jpg, Bendback-2.jpg, Bendback-3.png"
    },
    {
        "id": 18,
        "originalRank": 11,
        "rankId": 504,
        "product": "Kickback",
        "categoryOldBackup": "Medium",
        "category": "Corners",
        "image": "Kickback.png",
        "imageNew": "Kickback.png",
        "imageWithAnchors": "Kickbackwithanchors.png",
        "folder": "kickback",
        "model": "Kickback",
        "upc": "0 7 90628 04167 6",
        "agarticle": 585219634,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/kickback/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/kickback/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 2.16,
        "widthM": 4.62,
        "symetricalYn": "N",
        "usdPrice": 2099.99,
        "gbpPrice": 1640,
        "iePrice": 1872.65,
        "france": 2050,
        "euukPrice": 1216.08,
        "eunlPrice": 1148.52,
        "nlPrice": 1233.1,
        "euroPrice": 2142.02,
        "poland": 10361.79,
        "denmark": 15973,
        "canada": 3799.98,
        "switzerland": 1880,
        "base": 1880,
        "preOrderDiscount": 1584.27,
        "stRelatedId": 0,
        "ndRelatedId": 17,
        "rdRelatedId": 63,
        "connectorPieceOrAlone": 44,
        "previewImages": "Piece",
        "undefined": "Kickback-1.jpg, Kickback-2.jpg, Kickback-3.png"
    },
    {
        "id": 20,
        "originalRank": 12,
        "rankId": 104,
        "product": "Freefall Supreme",
        "categoryOldBackup": "XLarge",
        "category": "Slides",
        "image": "Freefall-sup.png",
        "imageNew": "freefallsupreme.png",
        "imageWithAnchors": "FreefallSupremewithanchors.png",
        "folder": "14FreefallSupreme",
        "model": "Freefall_Sup",
        "upc": "0 7 90628 04212 3",
        "agarticle": 585219629,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/freefall-supreme/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/freefall-supreme/",
        "brochureCapacity": 12,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.8,
        "anchorPoints": 6,
        "lengthM": 7.8,
        "widthM": 3.8,
        "symetricalYn": "N",
        "usdPrice": 14699.99,
        "gbpPrice": 11560,
        "iePrice": 13508.79,
        "france": 14770,
        "euukPrice": 8613.92,
        "eunlPrice": 8135.37,
        "nlPrice": 8734.45,
        "euroPrice": 12352.1,
        "poland": 59752.03,
        "denmark": 96359,
        "canada": 29399.98,
        "switzerland": 13300,
        "base": 13300,
        "preOrderDiscount": 10578.76,
        "stRelatedId": 0,
        "ndRelatedId": 19,
        "rdRelatedId": 50,
        "connectorPieceOrAlone": 7,
        "previewImages": "Piece",
        "undefined": "Freefallsupreme-1.jpg, Freefallsupreme-3.jpg"
    },
    {
        "id": 21,
        "originalRank": 13,
        "rankId": 105,
        "product": "Summit Express",
        "categoryOldBackup": "XLarge",
        "category": "Slides",
        "image": "Summit.png",
        "imageNew": "summit.png",
        "imageWithAnchors": "Summitwithanchors.png",
        "folder": "08Summit",
        "model": "SummitExp",
        "upc": "0 7 90628 04213 0",
        "agarticle": 585219110,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/summit-express/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/summit-express-cx3/",
        "brochureCapacity": 12,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.78,
        "anchorPoints": 6,
        "lengthM": 9.09,
        "widthM": 2.74,
        "symetricalYn": "N",
        "usdPrice": 11899.99,
        "gbpPrice": 9360,
        "iePrice": 10935.69,
        "france": 11850,
        "euukPrice": 6821.03,
        "eunlPrice": 6442.09,
        "nlPrice": 6916.48,
        "euroPrice": 11024.37,
        "poland": 53329.27,
        "denmark": 86001,
        "canada": 20999.98,
        "switzerland": 10540,
        "base": 10540,
        "preOrderDiscount": 8463,
        "stRelatedId": 0,
        "ndRelatedId": 23,
        "rdRelatedId": 20,
        "connectorPieceOrAlone": 25,
        "previewImages": "Piece",
        "undefined": "Summit-1.jpg, Summit-2.jpg, Summit-3.jpg"
    },
    {
        "id": 22,
        "originalRank": 14,
        "rankId": 106,
        "product": "Escalade Summit Climbing Wall",
        "categoryOldBackup": "XLarge",
        "category": "Slides",
        "image": "Summit-esc.png",
        "imageNew": "Summit-esc.png",
        "folder": "37EscaladeSummit",
        "model": "Esc_Sum",
        "upc": "0 7 90628 03434 0",
        "agarticle": 585215113,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/escalade-summit/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/escalade-summit-climbing-wall/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.74,
        "anchorPoints": 0,
        "lengthM": 1.4,
        "widthM": 2.1,
        "symetricalYn": "N",
        "usdPrice": 3599.99,
        "gbpPrice": 2920,
        "iePrice": 3343.11,
        "france": 3660,
        "euukPrice": 2192.26,
        "eunlPrice": 2070.47,
        "nlPrice": 2222.94,
        "euroPrice": 3948.74,
        "poland": 17194.31,
        "denmark": 30804,
        "canada": 6599.98,
        "switzerland": 3390,
        "base": 3390,
        "preOrderDiscount": 2792.79,
        "stRelatedId": 0,
        "ndRelatedId": 21,
        "previewImages": "Alone",
        "undefined": "Escaladesummit-1.jpg, Escaladesummit-2.jpg, Escaladesummit-3.jpg, Escaladesummit-4.jpg"
    },
    {
        "id": 23,
        "originalRank": 3,
        "rankId": 109,
        "product": "Freefall Extreme",
        "categoryOldBackup": "Large",
        "category": "Slides",
        "image": "Freefall-extreme.png",
        "imageNew": "freefallextreme.png",
        "imageWithAnchors": "Freefallextremewithanchors.png",
        "folder": "19FreefallExtreme",
        "model": "Freefall_Ext",
        "upc": "0 7 90628 04214 7",
        "agarticle": 585219105,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/freefall-extreme/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/freefall-extreme/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 6.35,
        "widthM": 2.74,
        "symetricalYn": "N",
        "usdPrice": 5799.99,
        "gbpPrice": 4710,
        "iePrice": 5499.84,
        "france": 5995,
        "euukPrice": 3440.48,
        "eunlPrice": 3249.35,
        "nlPrice": 3488.63,
        "euroPrice": 5461.34,
        "poland": 26418.7,
        "denmark": 40725,
        "canada": 9999.98,
        "switzerland": 5310,
        "base": 5310,
        "preOrderDiscount": 4231.5,
        "stRelatedId": 0,
        "ndRelatedId": 21,
        "rdRelatedId": 26,
        "connectorPieceOrAlone": 29,
        "previewImages": "Piece",
        "undefined": "Freefallextreme-1.jpg, Freefallextreme-2.jpg, Freefallextreme-3.jpg"
    },
    {
        "id": 24,
        "originalRank": 4,
        "rankId": 110,
        "product": "Freefall 6'",
        "categoryOldBackup": "Large",
        "category": "Slides",
        "image": "Freefall-6.png",
        "imageNew": "freefall6.png",
        "imageWithAnchors": "Freefall6withanchors.png",
        "folder": "18Freefall6",
        "model": "Freefall6",
        "upc": "0 7 90628 04237 6",
        "agarticle": 585219680,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/freefall-6/",
        "brochureCapacity": 3,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.82,
        "anchorPoints": 4,
        "lengthM": 4.06,
        "widthM": 2.44,
        "symetricalYn": "N",
        "usdPrice": 2899.99,
        "gbpPrice": 2230,
        "iePrice": 2604.03,
        "france": 2850,
        "euukPrice": 1691.65,
        "eunlPrice": 1597.67,
        "nlPrice": 1715.32,
        "euroPrice": 2520.17,
        "poland": 12154.47,
        "denmark": 19660,
        "canada": 5199.98,
        "switzerland": 2610,
        "base": 2610,
        "preOrderDiscount": 2115.74,
        "stRelatedId": 0,
        "ndRelatedId": 22,
        "rdRelatedId": 26,
        "connectorPieceOrAlone": 68,
        "previewImages": "Piece",
        "undefined": "Freefall6-1.jpg, Freefall6-2.jpg, Freefall6-3.jpg"
    },
    {
        "id": 25,
        "originalRank": 15,
        "rankId": 107,
        "product": "Jungle Joe 2",
        "categoryOldBackup": "Large",
        "category": "Slides",
        "image": "JungleJoe2.png",
        "imageNew": "junglejoe.png",
        "imageWithAnchors": "JungleJoewithanchors.png",
        "folder": "02JungleJoe",
        "model": "JJoe2",
        "upc": "0 7 90628 04215 4",
        "agarticle": 585219679,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/jungle-joe/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/jungle-joe/",
        "brochureCapacity": 10,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.78,
        "anchorPoints": 4,
        "lengthM": 6.58,
        "widthM": 5.47,
        "symetricalYn": "N",
        "usdPrice": 9999.99,
        "gbpPrice": 6750,
        "iePrice": 7500,
        "france": 9750,
        "euukPrice": 5786.2,
        "eunlPrice": 5464.74,
        "nlPrice": 5867.17,
        "euroPrice": 9427.73,
        "poland": 45605.69,
        "denmark": 73546,
        "canada": 17999.98,
        "switzerland": 8940,
        "base": 8940,
        "preOrderDiscount": 7.8010295161189385,
        "stRelatedId": 0,
        "ndRelatedId": 33,
        "rdRelatedId": 31,
        "connectorPieceOrAlone": 21,
        "previewImages": "Piece",
        "undefined": "Junglejoe-1.jpg, Junglejoe-2.jpg, Junglejoe-3.jpg"
    },
    {
        "id": 26,
        "originalRank": 16,
        "rankId": 207,
        "product": "Jungle Jim",
        "categoryOldBackup": "Medium",
        "category": "Swing & Climb",
        "image": "JungleGym102020.png",
        "imageNew": "JungleGym102020.png",
        "imageWithAnchors": "JungleGym102020withanchors.png",
        "folder": "01JungleGym",
        "model": "Jungle_Jim",
        "upc": "0 7 90628 04216 1",
        "agarticle": 585219107,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/jungle-jim/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/jungle-jim/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 1.93,
        "anchorPoints": 4,
        "lengthM": 3,
        "widthM": 2.7,
        "symetricalYn": "N",
        "usdPrice": 3699.99,
        "gbpPrice": 2850,
        "iePrice": 3331.44,
        "france": 3650,
        "euukPrice": 2084.01,
        "eunlPrice": 1968.23,
        "nlPrice": 2113.17,
        "euroPrice": 3528.57,
        "poland": 17078.2788,
        "denmark": 27522.846,
        "canada": 6399.98,
        "switzerland": 3220,
        "base": 3220,
        "stRelatedId": 0,
        "ndRelatedId": 34,
        "rdRelatedId": 25,
        "connectorPieceOrAlone": 53,
        "previewImages": "Piece",
        "undefined": "Junglejim-1.jpg, Junglejim-2.jpg, Junglejim-3.jpg, Junglejim-4.jpg"
    },
    {
        "id": 27,
        "originalRank": 17,
        "rankId": 112,
        "product": "Blast Bag",
        "categoryOldBackup": "Medium",
        "category": "Slides",
        "image": "Blast-Bag.png",
        "imageNew": "blastbag.png",
        "imageWithAnchors": "BlastBagwithanchors.png",
        "folder": "24BastBag",
        "model": "Blastbag",
        "upc": "0 7 90628 03108 0",
        "agarticle": 585219114,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/blast-bag/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/blast-bag/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 12,
        "minDepthM": 2.44,
        "anchorPoints": 2,
        "lengthM": 3.66,
        "widthM": 1.65,
        "symetricalYn": "N",
        "usdPrice": 699.99,
        "gbpPrice": 660,
        "iePrice": 612.7,
        "france": 670,
        "euukPrice": 409.97,
        "eunlPrice": 387.19,
        "nlPrice": 415.7,
        "euroPrice": 756.26,
        "poland": 3658.33,
        "denmark": 5640,
        "canada": 1299.98,
        "switzerland": 630,
        "base": 630,
        "preOrderDiscount": 7.457752624758681,
        "stRelatedId": 0,
        "ndRelatedId": 89,
        "rdRelatedId": 25,
        "connectorPieceOrAlone": 82,
        "previewImages": "Piece",
        "undefined": "Blastbag-1.jpg, Blastbag-2.jpg, Blastbag-3.jpg"
    },
    {
        "id": 28,
        "originalRank": 18,
        "rankId": 108,
        "product": "King of the Mountain (set)",
        "categoryOldBackup": "Large",
        "category": "Slides",
        "image": "KOM.png",
        "imageNew": "kom.png",
        "imageWithAnchors": "KOMwithanchors.png",
        "folder": "51KingoftheMountian",
        "model": "KingOTM",
        "upc": "0 7 90628 04206 2",
        "agarticle": 585219620,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/king-of-the-mountain/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/king-of-the-mountain-body-only/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 4,
        "widthM": 2.7,
        "symetricalYn": "N",
        "usdPrice": 5699.99,
        "gbpPrice": 4300,
        "iePrice": 5021.18,
        "france": 5490,
        "euukPrice": 3292.07,
        "eunlPrice": 3109.18,
        "nlPrice": 3338.13,
        "euroPrice": 5041.18,
        "poland": 24386.18,
        "denmark": 37592,
        "canada": 10379.98,
        "switzerland": 5080,
        "base": 5080,
        "preOrderDiscount": 7.456984277490587,
        "stRelatedId": 0,
        "ndRelatedId": 26,
        "rdRelatedId": 25,
        "connectorPieceOrAlone": 34,
        "previewImages": "Piece",
        "undefined": "Kingofthemountain-1.jpg, Kingofthemountain-2.jpg, Kingofthemountain-4.jpg, Kingofthemountain-3.jpg"
    },
    {
        "id": 31,
        "originalRank": 19,
        "rankId": 203,
        "product": "Monkey Dome",
        "categoryOldBackup": "Large",
        "category": "Swing & Climb",
        "image": "monkey-dome.png",
        "imageNew": "MonkeyDome.png",
        "imageWithAnchors": "MonkeyDome20withanchors.png",
        "folder": "monkeyDome",
        "model": "Monkey_Dome",
        "upc": "0 7 90628 04220 8",
        "agarticle": 585219627,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/water-obstacles/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/monkey-dome/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 1.52,
        "anchorPoints": 4,
        "lengthM": 3.04,
        "widthM": 6.1,
        "symetricalYn": "N",
        "usdPrice": 7199.99,
        "gbpPrice": 4710,
        "iePrice": 6639.85,
        "france": 7260,
        "euukPrice": 4354.12,
        "eunlPrice": 4112.22,
        "nlPrice": 4415.05,
        "euroPrice": 6637.82,
        "poland": 32109.76,
        "denmark": 51782,
        "canada": 13199.98,
        "switzerland": 6730,
        "base": 6730,
        "preOrderDiscount": 7.801055165701992,
        "stRelatedId": 0,
        "ndRelatedId": 49,
        "rdRelatedId": 51,
        "connectorPieceOrAlone": 62,
        "previewImages": "Piece"
    },
    {
        "id": 32,
        "originalRank": 20,
        "rankId": 205,
        "product": "Kaos",
        "categoryOldBackup": "XLarge",
        "category": "Swing & Climb",
        "image": "Kaos.png",
        "imageNew": "kaos.png",
        "imageWithAnchors": "Kaoswithanchors.png",
        "folder": "61Kaos",
        "model": "Kaos",
        "upc": "0 7 90628 04218 5",
        "agarticle": 585219660,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/kaos/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/kaos/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 1.52,
        "anchorPoints": 4,
        "lengthM": 7.62,
        "widthM": 7.62,
        "symetricalYn": "Y",
        "usdPrice": 5799.99,
        "gbpPrice": 4420,
        "iePrice": 5164.67,
        "france": 5650,
        "euukPrice": 3230.81,
        "eunlPrice": 3051.33,
        "nlPrice": 3276.02,
        "euroPrice": 5629.41,
        "poland": 27231.71,
        "denmark": 43915,
        "canada": 9999.98,
        "switzerland": 4990,
        "base": 4990,
        "preOrderDiscount": 7.800995130928463,
        "stRelatedId": 0,
        "ndRelatedId": 31,
        "rdRelatedId": 82,
        "connectorPieceOrAlone": 33,
        "previewImages": "Piece",
        "undefined": "Kaos-1.jpg, Kaos-2.jpg, Kaos-3.png"
    },
    {
        "id": 33,
        "originalRank": 21,
        "rankId": 204,
        "product": "Thunderdome",
        "categoryOldBackup": "Large",
        "category": "Swing & Climb",
        "image": "Thunderdome.png",
        "imageNew": "thunderdome.png",
        "imageWithAnchors": "Thunderdomewithanchors.png",
        "folder": "thunderdome",
        "model": "Thunderdome",
        "upc": "0 7 90628 04219 2",
        "agarticle": 585219661,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/thunderdome/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/thunderdome/",
        "brochureCapacity": 12,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 4.5,
        "widthM": 4.5,
        "symetricalYn": "Y",
        "usdPrice": 6999.99,
        "gbpPrice": 5340,
        "iePrice": 6241.81,
        "france": 6830,
        "euukPrice": 3867.12,
        "eunlPrice": 3652.28,
        "nlPrice": 3921.24,
        "euroPrice": 6721.85,
        "poland": 29264.23,
        "denmark": 52437,
        "canada": 11999.98,
        "switzerland": 5970,
        "base": 5970,
        "preOrderDiscount": 5077.8,
        "stRelatedId": 0,
        "ndRelatedId": 35,
        "rdRelatedId": 34,
        "connectorPieceOrAlone": 31,
        "previewImages": "Piece",
        "undefined": "Thunderdome-1.jpg, Thunderdome-2.jpg, Thunderdome-3.jpg, Thunderdome-4.png"
    },
    {
        "id": 34,
        "originalRank": 22,
        "rankId": 551,
        "product": "Universal Thunderdome",
        "categoryOldBackup": "Medium",
        "category": "Universal",
        "image": "universal-thunderdome.png",
        "imageNew": "UniversalThunderdome.png",
        "imageWithAnchors": "UniversalThunderdomewithanchors.png",
        "folder": "universal-thunder-dome",
        "model": "Uni_TDome",
        "upc": "0 7 90628 04232 1",
        "agarticle": 585219675,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/universal-thunderdome/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/universal-thunderdome/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.6,
        "anchorPoints": 4,
        "lengthM": 3.85,
        "widthM": 3.85,
        "symetricalYn": "Y",
        "usdPrice": 3599.99,
        "gbpPrice": 2820,
        "iePrice": 3229.02,
        "france": 3530,
        "euukPrice": 2117.45,
        "eunlPrice": 1999.81,
        "nlPrice": 2147.08,
        "euroPrice": 3108.4,
        "poland": 15036.59,
        "denmark": 24249,
        "canada": 6599.98,
        "switzerland": 3270,
        "base": 3270,
        "preOrderDiscount": 2623.52,
        "stRelatedId": 0,
        "ndRelatedId": 35,
        "rdRelatedId": 57,
        "connectorPieceOrAlone": 26,
        "previewImages": "Piece",
        "undefined": "Universal-Thunderdome-2.jpg, Universal-Thunderdome-1.jpg"
    },
    {
        "id": 35,
        "originalRank": 23,
        "rankId": 550,
        "product": "Universal Connection",
        "categoryOldBackup": "Medium",
        "category": "Universal",
        "image": "Universal.png",
        "imageNew": "Universal.png",
        "imageWithAnchors": "Universalwithanchors.png",
        "folder": "UniversalConnection",
        "model": "Uni_Connection",
        "upc": "0 7 90628 04233 8",
        "agarticle": 585219676,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/universal/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/universal/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 3.85,
        "widthM": 3.85,
        "symetricalYn": "Y",
        "usdPrice": 2499.99,
        "gbpPrice": 1890,
        "iePrice": 2160.86,
        "france": 2370,
        "euukPrice": 1377.38,
        "eunlPrice": 1300.86,
        "nlPrice": 1396.65,
        "euroPrice": 2100,
        "poland": 10158.54,
        "denmark": 16383,
        "canada": 4399.98,
        "switzerland": 2130,
        "base": 2130,
        "preOrderDiscount": 1692.59,
        "stRelatedId": 0,
        "ndRelatedId": 34,
        "rdRelatedId": 92,
        "connectorPieceOrAlone": 33,
        "previewImages": "Piece",
        "undefined": "Universal-1.jpg, Universal-2.jpg, Universal-3.jpg"
    },
    {
        "id": 37,
        "originalRank": 24,
        "rankId": 202,
        "product": "Catapult",
        "categoryOldBackup": "Large",
        "category": "Swing & Climb",
        "image": "Catapult.png",
        "imageNew": "catapult.png",
        "imageWithAnchors": "Catapultwithanchors.png",
        "folder": "57Catapult",
        "model": "Catapult",
        "upc": "0 7 90628 04223 9",
        "agarticle": 585219665,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/catapult/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/catapult/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 4.8,
        "widthM": 4,
        "symetricalYn": "N",
        "usdPrice": 3899.99,
        "gbpPrice": 2830,
        "iePrice": 3650.73,
        "france": 3990,
        "euukPrice": 2179.95,
        "eunlPrice": 2058.84,
        "nlPrice": 2210.45,
        "euroPrice": 3780.67,
        "poland": 18288.62,
        "denmark": 29493,
        "canada": 6399.98,
        "switzerland": 3370,
        "base": 3370,
        "preOrderDiscount": 2708.16,
        "stRelatedId": 0,
        "ndRelatedId": 10,
        "rdRelatedId": 33,
        "connectorPieceOrAlone": 28,
        "previewImages": "Piece",
        "undefined": "Catapult-1.jpg, Catapult-2.jpg, Catapult-3.jpg"
    },
    {
        "id": 38,
        "originalRank": 7,
        "rankId": 602,
        "product": "Rockit",
        "categoryOldBackup": "Stand Alone",
        "category": "Stand Alone",
        "image": "Rockit.png",
        "imageNew": "rocket.png",
        "imageWithAnchors": "Rockitwithanchors.png",
        "folder": "33Rockit",
        "model": "Rockit",
        "upc": "0 7 90628 03157 8",
        "agarticle": 585219668,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rockit/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/rockit/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 1,
        "lengthM": 3.96,
        "widthM": 3.96,
        "symetricalYn": "Y",
        "usdPrice": 3199.99,
        "gbpPrice": 2560,
        "iePrice": 2923.48,
        "france": 3200,
        "euukPrice": 1917.09,
        "eunlPrice": 1810.58,
        "nlPrice": 1943.91,
        "euroPrice": 2940.34,
        "poland": 13780.4878048781,
        "denmark": 22938,
        "canada": 6399.98,
        "switzerland": 2960,
        "base": 2960,
        "preOrderDiscount": 2464.42,
        "stRelatedId": 0,
        "ndRelatedId": 39,
        "rdRelatedId": 40,
        "connectorPieceOrAlone": 41,
        "previewImages": "Alone",
        "undefined": "Rockit-1.jpg, Rockit-2.jpg, Rockit-3.jpg"
    },
    {
        "id": 39,
        "originalRank": 25,
        "rankId": 601,
        "product": "Rockit Jr",
        "categoryOldBackup": "Stand Alone",
        "category": "Stand Alone",
        "image": "Rockit-Jnr.png",
        "imageNew": "rocketjnr.png",
        "imageWithAnchors": "RockitJnrwithanchors.png",
        "folder": "44RockitJr",
        "model": "Rockit_Jr",
        "upc": "0 7 90628 03397 8",
        "agarticle": 585219626,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rockit-junior/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/rockit-jr/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.83,
        "anchorPoints": 1,
        "lengthM": 2.9,
        "widthM": 2.9,
        "symetricalYn": "Y",
        "usdPrice": 1999.99,
        "gbpPrice": 1470,
        "iePrice": 1673.05,
        "france": 1830,
        "euukPrice": 1086.47,
        "eunlPrice": 1026.11,
        "nlPrice": 1101.67,
        "euroPrice": 1679.83,
        "poland": 7804.87804878049,
        "denmark": 13105,
        "canada": 3999.98,
        "switzerland": 1680,
        "base": 1680,
        "preOrderDiscount": 1584.27,
        "stRelatedId": 0,
        "ndRelatedId": 38,
        "rdRelatedId": 40,
        "connectorPieceOrAlone": 41,
        "previewImages": "Alone",
        "undefined": "Rockitjr-1.jpg, Rockitjr-2.jpg, Rockitjr-3.jpg"
    },
    {
        "id": 40,
        "originalRank": 26,
        "rankId": 603,
        "product": "Axis Rocker",
        "categoryOldBackup": "Stand Alone",
        "category": "Stand Alone",
        "image": "Axis.png",
        "imageNew": "axis.png",
        "imageWithAnchors": "Axiswithanchors.png",
        "folder": "axis",
        "model": "Axis_Rocker",
        "upc": "0 7 90628 03398 5",
        "agarticle": 585219638,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/axis/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/axis-rocker/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.83,
        "anchorPoints": 1,
        "lengthM": 3.7,
        "widthM": 2.35,
        "symetricalYn": "N",
        "usdPrice": 1099.99,
        "gbpPrice": 870,
        "iePrice": 990.32,
        "france": 1080,
        "euukPrice": 591.35,
        "eunlPrice": 558.5,
        "nlPrice": 599.62,
        "euroPrice": 982.35,
        "poland": 4280.49,
        "denmark": 7664,
        "canada": 2199.98,
        "switzerland": 910,
        "base": 910,
        "preOrderDiscount": 792.13,
        "stRelatedId": 0,
        "ndRelatedId": 38,
        "rdRelatedId": 39,
        "connectorPieceOrAlone": 26,
        "previewImages": "Alone",
        "undefined": "Axis-1.jpg, Axis-2.jpg"
    },
    {
        "id": 41,
        "originalRank": 27,
        "rankId": 604,
        "product": "Cyclone Wheel Only",
        "categoryOldBackup": "Stand Alone",
        "category": "Stand Alone",
        "image": "cyclone-wheel.png",
        "imageNew": "cyclonewheel.png",
        "imageWithAnchors": "CycloneWheelwithanchors.png",
        "folder": "50CycloneWheel",
        "model": "Cyclone_Wheel",
        "upc": "0 7 90628 04217 8",
        "agarticle": 585219631,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/cyclone-wheel/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/cyclone-wheel/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.5,
        "anchorPoints": 1,
        "lengthM": 2.4,
        "widthM": 2.4,
        "symetricalYn": "Y",
        "usdPrice": 2699.99,
        "gbpPrice": 2160,
        "iePrice": 2520.16,
        "france": 2760,
        "euukPrice": 1562.99,
        "eunlPrice": 1476.16,
        "nlPrice": 1584.86,
        "euroPrice": 2100.8,
        "poland": 13817.07,
        "denmark": 22282,
        "canada": 5399.98,
        "switzerland": 2410,
        "base": 2410,
        "preOrderDiscount": 2112.36,
        "stRelatedId": 0,
        "ndRelatedId": 40,
        "rdRelatedId": 38,
        "connectorPieceOrAlone": 39,
        "previewImages": "Alone",
        "undefined": "Cyclone-1.jpg, Cyclone-3.jpg, Cyclone-2.jpg"
    },
    {
        "id": 44,
        "originalRank": 28,
        "rankId": 402,
        "product": "Overpass 10",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "Over-pass.png",
        "imageNew": "Overpass10.png",
        "imageWithAnchors": "Overpass10withanchors.png",
        "folder": "overpass",
        "model": "Overpass",
        "upc": "0 7 90628 04121 8",
        "agarticle": 585219106,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/overpass/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/overpass-10/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 1.83,
        "widthM": 3.04,
        "symetricalYn": "N",
        "usdPrice": 1899.99,
        "gbpPrice": 1470,
        "iePrice": 1710.94,
        "france": 1870,
        "euukPrice": 1100.59,
        "eunlPrice": 1039.45,
        "nlPrice": 1115.99,
        "euroPrice": 1679.83,
        "poland": 8126.02,
        "denmark": 13105,
        "canada": 3399.98,
        "switzerland": 1700,
        "base": 1700,
        "preOrderDiscount": 1408.24,
        "stRelatedId": 0,
        "ndRelatedId": 47,
        "rdRelatedId": 45,
        "connectorPieceOrAlone": 52,
        "previewImages": "Piece",
        "undefined": "Overpass-1.jpg, Overpass-2.jpg"
    },
    {
        "id": 45,
        "originalRank": 29,
        "rankId": 401,
        "product": "Subway 10",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "Subway.png",
        "imageNew": "Subway10.png",
        "imageWithAnchors": "Subway10withanchors.png",
        "folder": "Subway10",
        "model": "Subway10",
        "upc": "0 7 90628 04118 8",
        "agarticle": 585219630,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/subway/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/subway-10/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.66,
        "anchorPoints": 4,
        "lengthM": 2.44,
        "widthM": 3.04,
        "symetricalYn": "N",
        "usdPrice": 2999.99,
        "gbpPrice": 2280,
        "iePrice": 2655.58,
        "france": 2910,
        "euukPrice": 1725.14,
        "eunlPrice": 1629.29,
        "nlPrice": 1749.28,
        "euroPrice": 2688.24,
        "poland": 13004.07,
        "denmark": 20971,
        "canada": 5399.98,
        "switzerland": 2660,
        "base": 2660,
        "preOrderDiscount": 2200.38,
        "stRelatedId": 0,
        "ndRelatedId": 44,
        "rdRelatedId": 12,
        "connectorPieceOrAlone": 54,
        "previewImages": "Piece",
        "undefined": "Subway-1.jpg, Subway-1.png"
    },
    {
        "id": 46,
        "originalRank": 30,
        "rankId": 305,
        "product": "Parkway 20",
        "categoryOldBackup": "Medium",
        "category": "Core 20ft",
        "image": "Parkway-20.png",
        "imageNew": "Parkway20.png",
        "imageWithAnchors": "Parkway20withanchors.png",
        "folder": "parkway20",
        "model": "PWay_20",
        "upc": "0 7 90628 04120 1",
        "agarticle": 585219663,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/parkway-20/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/parkway-20/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.66,
        "anchorPoints": 4,
        "lengthM": 1.98,
        "widthM": 6.09,
        "symetricalYn": "N",
        "usdPrice": 3099.99,
        "gbpPrice": 2520,
        "iePrice": 2935.87,
        "france": 3210,
        "euukPrice": 1853.24,
        "eunlPrice": 1750.28,
        "nlPrice": 1879.17498463594,
        "euroPrice": 2856.3,
        "poland": 13333.33,
        "denmark": 22282,
        "canada": 5399.98,
        "switzerland": 2860,
        "base": 2860,
        "preOrderDiscount": 2376.4,
        "stRelatedId": 0,
        "ndRelatedId": 11,
        "rdRelatedId": 51,
        "connectorPieceOrAlone": 31,
        "previewImages": "Piece",
        "undefined": "Parkway20-1.jpg, Parkway20-2.jpg, Parkway20-3.png"
    },
    {
        "id": 47,
        "originalRank": 31,
        "rankId": 405,
        "product": "Parkway 10",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "Parkway10.png",
        "imageNew": "Parkway10.png",
        "imageWithAnchors": "Parkway1010withanchors.png",
        "folder": "parkway10",
        "model": "PWay_10",
        "upc": "0 7 90628 04119 5",
        "agarticle": 585219104,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/parkway-10/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/parkway-10/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 1.98,
        "widthM": 3.04,
        "symetricalYn": "N",
        "usdPrice": 1699.99,
        "gbpPrice": 1290,
        "iePrice": 1501.02,
        "france": 1640,
        "euukPrice": 956.44,
        "eunlPrice": 903.3,
        "nlPrice": 969.823970845394,
        "euroPrice": 1511.76,
        "poland": 7313.01,
        "denmark": 11273,
        "canada": 2999.98,
        "switzerland": 1480,
        "base": 1480,
        "preOrderDiscount": 1232.21,
        "stRelatedId": 0,
        "ndRelatedId": 44,
        "rdRelatedId": 45,
        "connectorPieceOrAlone": 12,
        "previewImages": "Piece",
        "undefined": "Parkway10-1.jpg, Parkway10-2.jpg, Parkway10-3.png"
    },
    {
        "id": 48,
        "originalRank": 32,
        "rankId": 111,
        "product": "Zulu",
        "categoryOldBackup": "Medium",
        "category": "Slides",
        "image": "Zulu.png",
        "imageNew": "zulu.png",
        "imageWithAnchors": "Zuluwithanchors.png",
        "folder": "39Zulu",
        "model": "Zulu",
        "upc": "0 7 90628 03252 0",
        "agarticle": 585213160,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/zulu/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/zulu/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.73,
        "anchorPoints": 4,
        "lengthM": 3.28,
        "widthM": 1.98,
        "symetricalYn": "N",
        "usdPrice": 2099.99,
        "gbpPrice": 1550,
        "iePrice": 1803.51,
        "france": 1970,
        "euukPrice": 1171.18,
        "eunlPrice": 1106.12,
        "nlPrice": 1187.57,
        "euroPrice": 2184.03,
        "poland": 10565.04,
        "denmark": 16286,
        "canada": 3799.98,
        "switzerland": 1810,
        "base": 1810,
        "preOrderDiscount": 1584.27,
        "stRelatedId": 0,
        "ndRelatedId": 26,
        "rdRelatedId": 24,
        "connectorPieceOrAlone": 28,
        "previewImages": "Piece"
    },
    {
        "id": 49,
        "originalRank": 33,
        "rankId": 303,
        "product": "Neptune",
        "categoryOldBackup": "Large",
        "category": "Core 20ft",
        "image": "neptune.png",
        "imageNew": "Neptune20.png",
        "imageWithAnchors": "Neptune20withanchors.png",
        "folder": "neptune",
        "model": "Neptune",
        "upc": "0 7 90628 04221 5",
        "agarticle": 585213140,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/neptune/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/neptune-cx3/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 2.5,
        "widthM": 6.1,
        "symetricalYn": "N",
        "usdPrice": 3199.99,
        "gbpPrice": 2400,
        "iePrice": 2802.33,
        "france": 3070,
        "euukPrice": 1720.7,
        "eunlPrice": 1625.11,
        "nlPrice": 1744.78,
        "euroPrice": 2772.27,
        "poland": 13410.57,
        "denmark": 21627,
        "canada": 5399.98,
        "switzerland": 2660,
        "base": 2660,
        "preOrderDiscount": 2200.38,
        "stRelatedId": 0,
        "ndRelatedId": 31,
        "rdRelatedId": 51,
        "connectorPieceOrAlone": 11,
        "previewImages": "Piece",
        "undefined": "Neptune-1.jpg, Neptune-2.jpg"
    },
    {
        "id": 50,
        "originalRank": 34,
        "rankId": 308,
        "product": "Triad",
        "categoryOldBackup": "Large",
        "category": "Core 20ft",
        "image": "Triad.png",
        "imageNew": "triad.png",
        "imageWithAnchors": "Triadwithanchors.png",
        "folder": "triad",
        "model": "Triad",
        "upc": "0 7 90628 04226 0",
        "agarticle": 585219681,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/triad-2/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/triad/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 9.15,
        "widthM": 9.15,
        "symetricalYn": "Y",
        "usdPrice": 3199.99,
        "gbpPrice": 1738.05466,
        "iePrice": 2720.40702,
        "france": 3060,
        "euukPrice": 1716.85,
        "eunlPrice": 1621.47,
        "nlPrice": 1740.87,
        "euroPrice": 2520.17,
        "poland": 12191.06,
        "denmark": 19660,
        "canada": 5399.98,
        "switzerland": 2650,
        "base": 2650,
        "preOrderDiscount": 2200.37,
        "stRelatedId": 0,
        "ndRelatedId": 51,
        "rdRelatedId": 49,
        "connectorPieceOrAlone": 32,
        "previewImages": "Piece",
        "undefined": "Triad-1.jpg, Triad-2.jpg, Triad-3.jpg"
    },
    {
        "id": 51,
        "originalRank": 35,
        "rankId": 307,
        "product": "Foxtrot 20",
        "categoryOldBackup": "Medium",
        "category": "Core 20ft",
        "image": "Foxtrot.png",
        "imageNew": "Foxtrot20.png",
        "imageWithAnchors": "Foxtrot20withanchors.png",
        "folder": "21Foxrot",
        "model": "Foxtrot",
        "upc": "0 7 90628 04227 7",
        "agarticle": 585219637,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/foxtrot/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/foxtrot-20/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 2,
        "lengthM": 6.1,
        "widthM": 2,
        "symetricalYn": "N",
        "usdPrice": 1599.99,
        "gbpPrice": 1170,
        "iePrice": 1359.27,
        "france": 1490,
        "euukPrice": 812.47,
        "eunlPrice": 767.33,
        "nlPrice": 823.84,
        "euroPrice": 1427.73,
        "poland": 6906.5,
        "denmark": 11138,
        "canada": 2599.98,
        "switzerland": 1250,
        "base": 1250,
        "preOrderDiscount": 1056.17,
        "stRelatedId": 0,
        "ndRelatedId": 46,
        "rdRelatedId": 45,
        "connectorPieceOrAlone": 11,
        "previewImages": "Piece",
        "undefined": "Foxtrot-3.jpg, Foxtrot-2.jpg, Foxtrot-1.jpg"
    },
    {
        "id": 52,
        "originalRank": 36,
        "rankId": 406,
        "product": "Vista 10",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "Vista.png",
        "imageNew": "Vista10.png",
        "imageWithAnchors": "Vista10withanchors.png",
        "folder": "31Vista",
        "model": "Vista_10",
        "upc": "0 7 90628 04228 4",
        "agarticle": 585219669,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/vista/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/vista-10-cx3/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.64,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 1.98,
        "symetricalYn": "N",
        "usdPrice": 3099.99,
        "gbpPrice": 2460,
        "iePrice": 2864.91,
        "france": 3140,
        "euukPrice": 1861.12,
        "eunlPrice": 1757.73,
        "nlPrice": 1887.17,
        "euroPrice": 2982.35,
        "poland": 14426.83,
        "denmark": 23266,
        "canada": 5599.98,
        "switzerland": 2870,
        "base": 2870,
        "preOrderDiscount": 2288.39,
        "stRelatedId": 0,
        "ndRelatedId": 53,
        "rdRelatedId": 45,
        "connectorPieceOrAlone": 44,
        "previewImages": "Piece",
        "undefined": "Vista-1.jpg, Vista-2.jpg, Vista-3.jpg"
    },
    {
        "id": 53,
        "originalRank": 37,
        "rankId": 407,
        "product": "Sierra 10",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "Sierra.png",
        "imageNew": "Sierra10.png",
        "imageWithAnchors": "Sierra10withanchors.png",
        "folder": "30Sierra",
        "model": "Sierra_10",
        "upc": "0 7 90628 04229 1",
        "agarticle": 585215118,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/sierra/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/sierra-10/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.46,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 2.44,
        "symetricalYn": "N",
        "usdPrice": 2099.99,
        "gbpPrice": 1640,
        "iePrice": 1911.78,
        "france": 2090,
        "euukPrice": 1241.49,
        "eunlPrice": 1172.52,
        "nlPrice": 1258.86,
        "euroPrice": 2184.03,
        "poland": 10565.04,
        "denmark": 17038,
        "canada": 3799.98,
        "switzerland": 1920,
        "base": 1920,
        "preOrderDiscount": 1584.27,
        "stRelatedId": 0,
        "ndRelatedId": 52,
        "rdRelatedId": 44,
        "connectorPieceOrAlone": 45,
        "previewImages": "Piece",
        "undefined": "Sierra-1.jpg, Sierra-2.jpg, Sierra-3.jpg"
    },
    {
        "id": 54,
        "originalRank": 38,
        "rankId": 408,
        "product": "Delta 10",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "Delta.png",
        "imageNew": "Delta10.png",
        "imageWithAnchors": "Delta10withanchors.png",
        "folder": "29Delta",
        "model": "Delta_10",
        "upc": "0 7 90628 04230 7",
        "agarticle": 585215116,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/delta/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/delta-10/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 2.05,
        "symetricalYn": "N",
        "usdPrice": 1199.99,
        "gbpPrice": 680,
        "iePrice": 791.6,
        "france": 870,
        "euukPrice": 481.44,
        "eunlPrice": 454.69,
        "nlPrice": 488.18,
        "euroPrice": 1217.65,
        "poland": 5890.24,
        "denmark": 9080,
        "canada": 1999.98,
        "switzerland": 740,
        "base": 740,
        "preOrderDiscount": 880.14,
        "stRelatedId": 0,
        "ndRelatedId": 44,
        "rdRelatedId": 47,
        "connectorPieceOrAlone": 45,
        "previewImages": "Piece",
        "undefined": "Delta-1.jpg, Delta-2.jpg, Delta-3.jpg"
    },
    {
        "id": 57,
        "originalRank": 39,
        "rankId": 409,
        "product": "Tango",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "Tango.png",
        "imageNew": "tango.png",
        "imageWithAnchors": "Tangowithanchors.png",
        "folder": "25Tango",
        "model": "Tango",
        "upc": "0 7 90628 03249 0",
        "agarticle": 585210011,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/tango/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/tango/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 2.01,
        "widthM": 2.01,
        "symetricalYn": "Y",
        "usdPrice": 999.99,
        "gbpPrice": 800,
        "iePrice": 909.76,
        "france": 995,
        "euukPrice": 210.05,
        "eunlPrice": 198.38,
        "nlPrice": 212.99,
        "euroPrice": 965.55,
        "poland": 4670.73,
        "denmark": 7533,
        "canada": 1599.98,
        "switzerland": 320,
        "base": 320,
        "preOrderDiscount": 704.11,
        "stRelatedId": 0,
        "ndRelatedId": 52,
        "rdRelatedId": 53,
        "connectorPieceOrAlone": 26,
        "previewImages": "Piece",
        "undefined": "Tango-1.jpg, Tango-2.jpg, Tango-3.jpg"
    },
    {
        "id": 58,
        "originalRank": 40,
        "rankId": 607,
        "product": "Arena 40",
        "categoryOldBackup": "XLarge",
        "category": "Stand Alone",
        "image": "arena-40.png",
        "imageNew": "arena40.png",
        "imageWithAnchors": "Arena40withanchors.png",
        "folder": "arena40",
        "model": "Arena_40",
        "upc": "0 7 90628 04224 6",
        "agarticle": 585219666,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/arena-40/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/arena-30/",
        "brochureCapacity": 10,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 6,
        "lengthM": 6.09,
        "widthM": 12.19,
        "symetricalYn": "N",
        "usdPrice": 18499.99,
        "gbpPrice": 13119.1941,
        "iePrice": 16954.9966,
        "france": 19050,
        "euukPrice": 10901.32,
        "eunlPrice": 10295.69,
        "nlPrice": 11053.86,
        "euroPrice": 15125.21,
        "poland": 73166.67,
        "denmark": 121462,
        "canada": 31999.98,
        "switzerland": 16840,
        "base": 16840,
        "preOrderDiscount": 14082.44,
        "stRelatedId": 0,
        "ndRelatedId": 59,
        "rdRelatedId": 79,
        "connectorPieceOrAlone": 25,
        "previewImages": "Piece",
        "undefined": "Arena30-1.jpg, Arena30-2.jpg, Arena30-3.jpg"
    },
    {
        "id": 59,
        "originalRank": 41,
        "rankId": 606,
        "product": "Arena 30",
        "categoryOldBackup": "XLarge",
        "category": "Stand Alone",
        "image": "Arena-30.png\r",
        "imageNew": "arena30.png",
        "imageWithAnchors": "Arena30withanchors.png",
        "folder": "arena30",
        "model": "Arena_30",
        "upc": "0 7 90628 04225 3",
        "agarticle": 585219667,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/arena-30/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/arena-30/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 6,
        "lengthM": 4.58,
        "widthM": 9.15,
        "symetricalYn": "N",
        "usdPrice": 14699.99,
        "gbpPrice": 12475.5057,
        "iePrice": 13657.2192,
        "france": 15350,
        "euukPrice": 8945.12,
        "eunlPrice": 8448.17,
        "nlPrice": 9070.29,
        "euroPrice": 12015.97,
        "poland": 58126.02,
        "denmark": 93737,
        "canada": 25999.98,
        "switzerland": 13820,
        "base": 13820,
        "preOrderDiscount": 11441.98,
        "stRelatedId": 0,
        "ndRelatedId": 58,
        "rdRelatedId": 41,
        "connectorPieceOrAlone": 28,
        "previewImages": "Piece",
        "undefined": "Arena30-1.jpg, Arena30-2.jpg, Arena30-3.jpg"
    },
    {
        "id": 60,
        "originalRank": 8,
        "rankId": 811,
        "product": "Landing Pad 4m x 4m",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "LandingPad.png",
        "imageNew": "landingpad.png",
        "imageWithAnchors": "LandingPad4mwithanchors.png",
        "folder": "LandingPad",
        "model": "Landing_Pad",
        "upc": "0 7 90628 03214 8",
        "agarticle": 585215002,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/landing-pad/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/landing-pad-4x4/",
        "brochureCapacity": 10,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 4,
        "widthM": 4,
        "symetricalYn": "Y",
        "usdPrice": 2799.99,
        "gbpPrice": 2210,
        "iePrice": 2522.61,
        "france": 2760,
        "euukPrice": 1623,
        "eunlPrice": 1532.84,
        "nlPrice": 1645.71407482759,
        "euroPrice": 2856.3,
        "poland": 12438.21,
        "denmark": 21299,
        "canada": 4999.98,
        "switzerland": 2510,
        "base": 2510,
        "preOrderDiscount": 2112.36,
        "stRelatedId": 0,
        "ndRelatedId": 61,
        "rdRelatedId": 65,
        "connectorPieceOrAlone": 64,
        "previewImages": "Connector",
        "undefined": "Landingpad-1.jpg, Landingpad-2.jpg, Landingpad-3.jpg"
    },
    {
        "id": 61,
        "originalRank": 42,
        "rankId": 608,
        "product": "Event Tent (Requires Landing Pad)",
        "categoryOldBackup": "Medium",
        "category": "Stand Alone",
        "image": "Event-Tent.png\r",
        "imageNew": "Event-Tent.png\r",
        "folder": "53ActivityTent",
        "model": "Event_Tent",
        "upc": "0 7 90628 03558 3",
        "agarticle": 585216630,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/event-tent/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/event-tent/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 3.6,
        "widthM": 3.6,
        "symetricalYn": "Y",
        "usdPrice": 1599.99,
        "gbpPrice": 1240,
        "iePrice": 1410.82,
        "france": 1545,
        "euukPrice": 890.57,
        "eunlPrice": 841.09,
        "nlPrice": 903.027126808333,
        "euroPrice": 1511.76,
        "poland": 7313.01,
        "denmark": 11273,
        "canada": 2799.98,
        "switzerland": 1380,
        "base": 1380,
        "preOrderDiscount": 1144.19,
        "stRelatedId": 0,
        "ndRelatedId": 60,
        "previewImages": "Alone"
    },
    {
        "id": 62,
        "originalRank": 43,
        "rankId": 801,
        "product": "Walk On Water 20",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "Wal-On-Water.png\r",
        "imageNew": "Wal-On-Water.png\r",
        "folder": "49WalkonWater",
        "model": "Walk_on_Water",
        "upc": "0 7 90628 04236 9",
        "agarticle": 585219670,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/walk-on-water/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/walk-on-water/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 2,
        "lengthM": 1.5,
        "widthM": 6,
        "symetricalYn": "N",
        "usdPrice": 2199.99,
        "gbpPrice": 1750,
        "iePrice": 1925.22,
        "france": 2170,
        "euukPrice": 1354.19,
        "eunlPrice": 1278.96,
        "nlPrice": 1373.14,
        "euroPrice": 2184.03,
        "poland": 10158.54,
        "denmark": 16383,
        "canada": 4199.98,
        "switzerland": 2090,
        "base": 2090,
        "preOrderDiscount": 1760.3,
        "stRelatedId": 0,
        "ndRelatedId": 66,
        "rdRelatedId": 63,
        "connectorPieceOrAlone": 64,
        "previewImages": "Connector",
        "undefined": "Walkonwater-1.jpg, Walkonwater-2.jpg, Walkonwater-3.jpg"
    },
    {
        "id": 63,
        "originalRank": 44,
        "rankId": 802,
        "product": "Walkback",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "walk-back.png",
        "imageNew": "walk-back.png",
        "folder": "walkback",
        "model": "Walkback",
        "upc": "0 7 90628 04122 5",
        "agarticle": 585219678,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/walkback/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/walkback/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 2,
        "lengthM": 2.16,
        "widthM": 4.62,
        "symetricalYn": "N",
        "usdPrice": 1999.99,
        "gbpPrice": 1197.35719,
        "iePrice": 1692.79986,
        "france": 1865,
        "euukPrice": 1106.8,
        "eunlPrice": 1045.31,
        "nlPrice": 1122.29,
        "euroPrice": 2015.97,
        "poland": 9752.03,
        "denmark": 15033,
        "canada": 3599.98,
        "switzerland": 1710,
        "base": 1710,
        "preOrderDiscount": 1496.25,
        "stRelatedId": 0,
        "ndRelatedId": 62,
        "rdRelatedId": 66,
        "connectorPieceOrAlone": 64,
        "previewImages": "Connector",
        "undefined": "Walkback-2.jpg, Walkback-1.png, Walkback-3.jpg"
    },
    {
        "id": 64,
        "originalRank": 45,
        "rankId": 803,
        "product": "Speedway 20",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "Speedway-20.png",
        "imageNew": "Speedway-20.png",
        "folder": "03Runway20ft",
        "model": "SWay_20",
        "upc": "0 7 90628 04179 9",
        "agarticle": 585219628,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/speedway-20/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/speedway-20/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 0,
        "lengthM": 6.04,
        "widthM": 1.57,
        "symetricalYn": "N",
        "usdPrice": 1999.99,
        "gbpPrice": 1430,
        "iePrice": 1639.93,
        "france": 1850,
        "euukPrice": 1153.51,
        "eunlPrice": 1089.43,
        "nlPrice": 1169.65,
        "euroPrice": 1679.83,
        "poland": 8126.02,
        "denmark": 13105,
        "canada": 3799.98,
        "switzerland": 1780,
        "base": 1780,
        "preOrderDiscount": 1478.65,
        "stRelatedId": 0,
        "ndRelatedId": 65,
        "rdRelatedId": 66,
        "connectorPieceOrAlone": 68,
        "previewImages": "Connector",
        "undefined": "Speedway20-2.jpg, Speedway20-1.jpg"
    },
    {
        "id": 65,
        "originalRank": 46,
        "rankId": 804,
        "product": "Speedway 10",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "Speedway-10.png",
        "imageNew": "Speedway-10.png",
        "folder": "04Runway10ft",
        "model": "SwWay_10",
        "upc": "0 7 90628 04180 5",
        "agarticle": 585209207,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/speedway-10/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/speedway-10/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 0,
        "lengthM": 3.05,
        "widthM": 1.57,
        "symetricalYn": "N",
        "usdPrice": 1399.99,
        "gbpPrice": 970,
        "iePrice": 1104.71,
        "france": 1250,
        "euukPrice": 762.51,
        "eunlPrice": 720.15,
        "nlPrice": 773.18,
        "euroPrice": 1133.61,
        "poland": 5483.74,
        "denmark": 8844,
        "canada": 2599.98,
        "switzerland": 1180,
        "base": 1180,
        "preOrderDiscount": 985.76,
        "stRelatedId": 0,
        "ndRelatedId": 64,
        "rdRelatedId": 66,
        "connectorPieceOrAlone": 68,
        "previewImages": "Connector",
        "undefined": "Speedway10-2.jpg, Speedway10-3.jpg, Speedway10-1.jpg"
    },
    {
        "id": 66,
        "originalRank": 47,
        "rankId": 805,
        "product": "Splashmat 18",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "Spalshmat.png",
        "imageNew": "Spalshmat.png",
        "folder": "07Splashmat",
        "model": "Splashmat",
        "upc": "0 7 90628 03195 0",
        "agarticle": 585219671,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/splashmat/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/splashmat/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 0,
        "lengthM": 5.49,
        "widthM": 1.5,
        "symetricalYn": "N",
        "usdPrice": 1699.99,
        "gbpPrice": 1290,
        "iePrice": 1474.61,
        "france": 1664,
        "euukPrice": 1027.45,
        "eunlPrice": 970.37,
        "nlPrice": 1041.82,
        "euroPrice": 1595.8,
        "poland": 7719.51,
        "denmark": 10790,
        "canada": 3199.98,
        "switzerland": 1590,
        "base": 1590,
        "preOrderDiscount": 1320.22,
        "stRelatedId": 0,
        "ndRelatedId": 65,
        "rdRelatedId": 64,
        "connectorPieceOrAlone": 67,
        "previewImages": "Connector",
        "undefined": "Splashmat-1.jpg, Splashmat-2.jpg, Splashmat-3.jpg"
    },
    {
        "id": 67,
        "originalRank": 48,
        "rankId": 806,
        "product": "Swimstep XL",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "SSXL.png\r",
        "imageNew": "SSXL.png\r",
        "folder": "22SwimstepXL",
        "model": "Swimstep_XL",
        "upc": "0 7 90628 03246 9",
        "agarticle": 585219112,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/swimstep-xl/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/swimstep-xl/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 0,
        "lengthM": 1.52,
        "widthM": 1.98,
        "symetricalYn": "N",
        "usdPrice": 849.99,
        "gbpPrice": 690,
        "iePrice": 735.53,
        "france": 830,
        "euukPrice": 543.23,
        "eunlPrice": 513.05,
        "nlPrice": 550.83,
        "euroPrice": 856.3,
        "poland": 4142.28,
        "denmark": 6680,
        "canada": 1699.98,
        "switzerland": 840,
        "base": 840,
        "preOrderDiscount": 657.17,
        "stRelatedId": 0,
        "ndRelatedId": 68,
        "rdRelatedId": 64,
        "connectorPieceOrAlone": 65,
        "previewImages": "Connector",
        "undefined": "SwimstepXL-2.jpg, SwimstepXL-3.jpg, SwimstepXL-1.jpg"
    },
    {
        "id": 68,
        "originalRank": 49,
        "rankId": 807,
        "product": "Swimstep 5x5",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "SS5x5.png\r",
        "imageNew": "SS5x5.png\r",
        "folder": "23Swimstep",
        "model": "Swimstep_5x5",
        "upc": "0 7 90628 04168 3",
        "agarticle": 585219677,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/swimstep-5x5/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/swimstep-5x5/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 0,
        "lengthM": 1.52,
        "widthM": 1.52,
        "symetricalYn": "Y",
        "usdPrice": 799.99,
        "gbpPrice": 640,
        "iePrice": 731.28,
        "france": 825,
        "euukPrice": 540.09,
        "eunlPrice": 510.09,
        "nlPrice": 547.65,
        "euroPrice": 839.5,
        "poland": 4060.98,
        "denmark": 6549,
        "canada": 1599.98,
        "switzerland": 830,
        "base": 830,
        "preOrderDiscount": 657.17,
        "stRelatedId": 0,
        "ndRelatedId": 67,
        "rdRelatedId": 65,
        "connectorPieceOrAlone": 64,
        "previewImages": "Connector",
        "undefined": "Swimstep-1.png, Swimstep-2.png"
    },
    {
        "id": 69,
        "originalRank": 50,
        "rankId": 808,
        "product": "Breezeway 20",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "Breezeway20.png\r",
        "imageNew": "Breezeway20.png\r",
        "folder": "Breezeway20",
        "model": "BWay_20",
        "upc": "0 7 90628 04234 5",
        "agarticle": 585219102,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/breezeway-20/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 0,
        "lengthM": 6.04,
        "widthM": 1.57,
        "symetricalYn": "N",
        "usdPrice": 1999.99,
        "gbpPrice": 1592.71841,
        "iePrice": 1743.58498,
        "france": 1980,
        "euukPrice": 1234.81,
        "eunlPrice": 1166.2,
        "nlPrice": 1252.08,
        "euroPrice": 1847.9,
        "poland": 7984.62,
        "denmark": 14416,
        "canada": 3799.98,
        "switzerland": 1910,
        "base": 1910,
        "preOrderDiscount": 1584.27,
        "stRelatedId": 0,
        "ndRelatedId": 70,
        "rdRelatedId": 65,
        "connectorPieceOrAlone": 64,
        "previewImages": "Connector",
        "undefined": "Breezeway20-1.jpg, Breezeway20-2.jpg, Breezeway20-3.jpg"
    },
    {
        "id": 70,
        "originalRank": 51,
        "rankId": 809,
        "product": "Breezeway 10",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "Breezeway10.png",
        "imageNew": "Breezeway10.png",
        "folder": "Breezeway10",
        "model": "BWay_10",
        "upc": "0 7 90628 04235 2",
        "agarticle": 585219101,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/breezeway-10/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 0,
        "lengthM": 3.05,
        "widthM": 1.57,
        "symetricalYn": "N",
        "usdPrice": 1399.99,
        "gbpPrice": 1058.53229,
        "iePrice": 1158.79932,
        "france": 1320,
        "euukPrice": 805.32,
        "eunlPrice": 760.58,
        "nlPrice": 816.59,
        "euroPrice": 1175.63,
        "poland": 5686.99,
        "denmark": 9172,
        "canada": 2599.98,
        "switzerland": 1240,
        "base": 1240,
        "preOrderDiscount": 1056.17,
        "stRelatedId": 0,
        "ndRelatedId": 69,
        "rdRelatedId": 65,
        "connectorPieceOrAlone": 64,
        "previewImages": "Connector",
        "undefined": "Breezeway10-1.jpg, Breezeway10-2.jpg, Breezeway10-3.jpg"
    },
    {
        "id": 71,
        "originalRank": 52,
        "rankId": 810,
        "product": "Swimstep Access Platform",
        "categoryOldBackup": "Connectors",
        "category": "Connectors",
        "image": "swim-step-access.png",
        "imageNew": "swim-step-access.png",
        "folder": "swimstep-access",
        "upc": "0 7 90628 03200 1",
        "agarticle": 585211016,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/swimstep/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/swimstep-access-platform/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 0,
        "lengthM": 1.42,
        "widthM": 1.07,
        "symetricalYn": "N",
        "usdPrice": 799.99,
        "gbpPrice": 175,
        "iePrice": 200,
        "france": 825,
        "euukPrice": 135.89,
        "eunlPrice": 128.34,
        "nlPrice": 137.79,
        "euroPrice": 235.25,
        "poland": 1031.71,
        "denmark": 1836,
        "canada": 499.98,
        "switzerland": 210,
        "base": 210,
        "preOrderDiscount": 176.02,
        "stRelatedId": 0,
        "ndRelatedId": 67,
        "rdRelatedId": 68,
        "connectorPieceOrAlone": 65,
        "previewImages": "Connector"
    },
    {
        "id": 72,
        "originalRank": 6,
        "rankId": 700,
        "product": "Supertramp 14 w/Swimstep",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Supertramp14.png",
        "imageNew": "supertramp14.png",
        "imageWithAnchors": "Supertramp14withanchors.png",
        "folder": "13Supertramp14",
        "model": "STramp_14",
        "upc": "0 7 90628 03132 5",
        "agarticle": 585209106,
        "oldwebsitelink": "https://www.aquaglide.com/aquaglide-products/supertramp-14",
        "brochureCapacity": 3,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 4.27,
        "widthM": 4.27,
        "symetricalYn": "Y",
        "gbpPrice": 1500,
        "iePrice": 1500,
        "euukPrice": 2061.72,
        "eunlPrice": 1947.18,
        "nlPrice": 2090.57308973286,
        "euroPrice": 2520.17,
        "poland": 12197.6228,
        "denmark": "#N/A",
        "canada": 6599.98,
        "switzerland": 1,
        "base": 1,
        "preOrderDiscount": 1936.33,
        "stRelatedId": 0,
        "ndRelatedId": 73,
        "rdRelatedId": 74,
        "connectorPieceOrAlone": 82,
        "previewImages": "Piece"
    },
    {
        "id": 73,
        "originalRank": 53,
        "rankId": 701,
        "product": "Supertramp 17 w/Swimstep",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Supertramp17.png",
        "imageNew": "supertramp17.png",
        "imageWithAnchors": "Supertramp17withanchors.png",
        "folder": "12Supertramp17",
        "model": "STramp_17",
        "upc": "0 7 90628 03100 4",
        "agarticle": 585209102,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/supertramp-17/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/supertramp-17-swimstep/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 5.18,
        "widthM": 5.18,
        "symetricalYn": "Y",
        "usdPrice": 3399.99,
        "gbpPrice": 2730,
        "iePrice": 3124.47,
        "france": 3420,
        "euukPrice": 1999.41,
        "eunlPrice": 1888.34,
        "euroPrice": 3276.47,
        "poland": 13689.33,
        "denmark": 24432,
        "canada": 8399.98,
        "switzerland": 3800,
        "base": 3800,
        "preOrderDiscount": 2376.4,
        "stRelatedId": 0,
        "ndRelatedId": 72,
        "rdRelatedId": 74,
        "connectorPieceOrAlone": 82,
        "previewImages": "Piece",
        "undefined": "Supertramp17-2.jpg, Supertramp17-1.jpg"
    },
    {
        "id": 74,
        "originalRank": 54,
        "rankId": 702,
        "product": "Supertramp 23 w/Swimstep",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Supertramp23.png",
        "imageNew": "Supertramp23.png",
        "imageWithAnchors": "Supertramp23withanchors.png",
        "folder": "11Supertramp23",
        "model": "STramp_23",
        "upc": "0 7 90628 03101 1",
        "agarticle": 585209103,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/supertramp-23/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/supertramp-23-swimstep/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 6.86,
        "widthM": 6.86,
        "symetricalYn": "Y",
        "usdPrice": 4999.99,
        "gbpPrice": 4020,
        "iePrice": 4594.82,
        "france": 5030,
        "euukPrice": 2749.39,
        "eunlPrice": 2596.64,
        "nlPrice": 2787.86,
        "euroPrice": 4789.08,
        "poland": 23166.67,
        "denmark": 37360,
        "canada": 8999.98,
        "switzerland": 4450,
        "base": 4450,
        "preOrderDiscount": 3520.6,
        "stRelatedId": 0,
        "ndRelatedId": 73,
        "rdRelatedId": 72,
        "connectorPieceOrAlone": 82,
        "previewImages": "Piece",
        "undefined": "Supertramp23-1.jpg, Supertramp23-2.jpg"
    },
    {
        "id": 78,
        "originalRank": 55,
        "rankId": 703,
        "product": "Supertramp 27",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Supertramp-27.png\r",
        "imageNew": "Supertramp27.png",
        "imageWithAnchors": "Supertramp27withanchors.png",
        "folder": "56Supertramp27",
        "model": "STramp_27",
        "upc": "0 7 90628 04199 7",
        "agarticle": 585219611,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/supertramp-27/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/supertramp-27/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 6,
        "widthM": 6,
        "symetricalYn": "Y",
        "usdPrice": 5599.99,
        "gbpPrice": 4402.93017,
        "iePrice": 4819.99,
        "france": 5310,
        "euukPrice": 3212.34,
        "eunlPrice": 3033.88,
        "nlPrice": 3257.29,
        "euroPrice": 5696.64,
        "poland": 27556.91,
        "denmark": 44440,
        "canada": 10399.98,
        "switzerland": 4960,
        "base": 4960,
        "preOrderDiscount": 4224.73,
        "stRelatedId": 0,
        "ndRelatedId": 72,
        "rdRelatedId": 73,
        "connectorPieceOrAlone": 74,
        "previewImages": "Piece",
        "undefined": "Supertramp27-1.jpg, Supertramp27-2.jpg, Supertramp27-3.jpg"
    },
    {
        "id": 79,
        "originalRank": 56,
        "rankId": 707,
        "product": "Supervolley 30",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Super-Volley-30.png\r",
        "imageNew": "supervolley30.png",
        "imageWithAnchors": "Supervolleywithanchors.png",
        "folder": "55SupervolleyNEW",
        "model": "Supervolley",
        "upc": "0 7 90628 04202 4",
        "agarticle": 585219616,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/supervolley-30/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/supervolley-30/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 6,
        "lengthM": 4.5,
        "widthM": 9,
        "symetricalYn": "N",
        "usdPrice": 9199.99,
        "gbpPrice": 7843.11188,
        "iePrice": 8586.03,
        "france": 9450,
        "euukPrice": 5777.82,
        "eunlPrice": 5456.83,
        "nlPrice": 5858.67,
        "euroPrice": 8738.66,
        "poland": 42272.36,
        "denmark": 68170,
        "canada": 16999.98,
        "switzerland": 8920,
        "base": 8920,
        "preOrderDiscount": 7305.26,
        "stRelatedId": 0,
        "ndRelatedId": 72,
        "rdRelatedId": 73,
        "connectorPieceOrAlone": 74,
        "previewImages": "Piece",
        "undefined": "Supervolley-1.jpg, Supervolley-2.jpg, Supervolley-3.jpg"
    },
    {
        "id": 80,
        "originalRank": 57,
        "rankId": 708,
        "product": "Rebound 12",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Rebound12.png\r",
        "imageNew": "rebound12.png",
        "imageWithAnchors": "rebound12withanchors.png",
        "folder": "17ReboundSize12",
        "model": "Rebound_12",
        "upc": "0 7 90628 03096 0",
        "agarticle": 585209100,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rebound-12/",
        "brochureCapacity": 3,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 2,
        "lengthM": 3.68,
        "widthM": 3.68,
        "symetricalYn": "Y",
        "usdPrice": 2399.99,
        "gbpPrice": 750,
        "iePrice": 750,
        "france": 2230,
        "euukPrice": 592,
        "eunlPrice": 559.11,
        "euroPrice": 1150.42,
        "poland": 3546.57,
        "canada": 4799.98,
        "switzerland": 1,
        "base": 1,
        "preOrderDiscount": 880.14,
        "stRelatedId": 0,
        "ndRelatedId": 81,
        "rdRelatedId": 82,
        "connectorPieceOrAlone": 34,
        "previewImages": "Piece",
        "undefined": "Rebound12-2.jpg, Rebound12-1.jpg, Rebound12-3.jpg"
    },
    {
        "id": 81,
        "originalRank": 58,
        "rankId": 709,
        "product": "Rebound 16",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Rebound16.png\r",
        "imageNew": "rebound16.png",
        "imageWithAnchors": "rebound16withanchors.png",
        "folder": "16ReboundSize16",
        "model": "Rebound_16",
        "upc": "0 7 90628 03098 4",
        "agarticle": 585209101,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rebound-16/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 4.88,
        "widthM": 4.88,
        "symetricalYn": "Y",
        "usdPrice": 2399.99,
        "gbpPrice": 1000,
        "iePrice": 1000,
        "france": 2230,
        "euukPrice": 827.2,
        "eunlPrice": 781.24,
        "euroPrice": 1595.8,
        "poland": 4955.62,
        "canada": 4799.98,
        "switzerland": 1,
        "base": 1,
        "preOrderDiscount": 1232.21,
        "stRelatedId": 0,
        "ndRelatedId": 82,
        "rdRelatedId": 34,
        "connectorPieceOrAlone": 74,
        "previewImages": "Piece",
        "undefined": "Rebound16-1.jpg, Rebound16-2.jpg, Rebound16-3.jpg"
    },
    {
        "id": 82,
        "originalRank": 59,
        "rankId": 710,
        "product": "Rebound 20",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Rebound20.png\r",
        "imageNew": "Rebound20.png",
        "imageWithAnchors": "Rebound20withanchors.png",
        "folder": "15ReboundSize20",
        "model": "Rebound_20",
        "upc": "0 7 90628 04209 3",
        "agarticle": 585219623,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rebound-20/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/rebound-20/",
        "brochureCapacity": 7,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 6,
        "widthM": 6,
        "symetricalYn": "Y",
        "usdPrice": 2399.99,
        "gbpPrice": 1780,
        "iePrice": 2036.74,
        "france": 2230,
        "euukPrice": 1350.2,
        "eunlPrice": 1275.19,
        "nlPrice": 1369.09,
        "euroPrice": 2226.05,
        "poland": 10768.29,
        "denmark": 17366,
        "canada": 4799.98,
        "switzerland": 2090,
        "base": 2090,
        "preOrderDiscount": 1760.3,
        "stRelatedId": 0,
        "ndRelatedId": 81,
        "rdRelatedId": 33,
        "connectorPieceOrAlone": 74,
        "previewImages": "Piece",
        "undefined": "Rebound20-2.jpg, Rebound20-1.jpg"
    },
    {
        "id": 86,
        "originalRank": 60,
        "rankId": 714,
        "product": "Plunge Slide",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Plunge Slide.png\r",
        "imageNew": "PlungeSlide.png\r",
        "folder": "28PlungeSlide",
        "model": "Plunge_Slide",
        "upc": "0 7 90628 03105 9",
        "agarticle": 585209204,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/plunge-slide/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/plunge-slide/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 0,
        "lengthM": 2.01,
        "widthM": 1.65,
        "symetricalYn": "N",
        "usdPrice": 849.99,
        "gbpPrice": 629.273815,
        "iePrice": 688.880323,
        "france": 760,
        "euroPrice": 756.26,
        "poland": 3292.68,
        "denmark": 5640,
        "canada": 1699.98,
        "switzerland": 640,
        "base": 640,
        "preOrderDiscount": 528.08,
        "stRelatedId": 0,
        "ndRelatedId": 87,
        "rdRelatedId": 88,
        "previewImages": "Alone",
        "undefined": "Plunge-1.jpg, Plunge-2.jpg, Plunge-3.jpg"
    },
    {
        "id": 86,
        "originalRank": 74,
        "rankId": 714,
        "product": "Plunge Slide",
        "categoryOldBackup": "Trampoline ",
        "category": "Trampoline & Bouncers",
        "image": "PlungeSlide.png",
        "imageNew": "PlungeSlide.png",
        "folder": "28PlungeSlide",
        "model": "Plunge_Slide",
        "upc": "0 7 90628 03105 9",
        "agarticle": 585209204,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/plunge-slide/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": "2.44m",
        "minDepthM": 0,
        "usdPrice": 849.99,
        "gbpPrice": 629.273815,
        "iePrice": 688.880323,
        "france": 760,
        "euukPrice": 536.15,
        "eunlPrice": 482.54,
        "nlPrice": 414.57,
        "euroPrice": 391.54,
        "poland": 3292.68,
        "denmark": 5640,
        "canada": 1699.98,
        "switzerland": 640,
        "base": 640,
        "preOrderDiscount": 420.37,
        "stRelatedId": 0
    },
    {
        "id": 87,
        "originalRank": 61,
        "rankId": 712,
        "product": "Rebound slide 12",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "RB SLIDE.png",
        "imageNew": "RBSLIDE.png",
        "upc": "0 7 90628 03113 4",
        "agarticle": 585209211,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rebound-slide-12/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 0,
        "lengthM": 2.1,
        "widthM": 0.6,
        "symetricalYn": "N",
        "usdPrice": "No US Pricing",
        "gbpPrice": 0,
        "france": 330,
        "euukPrice": 232,
        "eunlPrice": 219.11,
        "euroPrice": 302.48,
        "poland": 1389.87,
        "canada": "#VALUE!",
        "switzerland": 1,
        "base": 1,
        "preOrderDiscount": 220.03,
        "stRelatedId": 0,
        "ndRelatedId": 80,
        "previewImages": "Alone"
    },
    {
        "id": 87,
        "originalRank": 75,
        "rankId": 712,
        "product": "Rebound slide 12",
        "categoryOldBackup": "Trampoline ",
        "category": "Trampoline & Bouncers",
        "imageNew": "RBSlide.png",
        "upc": "0 7 90628 03113 4",
        "agarticle": 585209211,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rebound-slide-12/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": "2.44m",
        "minDepthM": 0,
        "gbpPrice": 0,
        "euroPrice": 327.69,
        "poland": 1389.87,
        "canada": 0,
        "switzerland": 1,
        "base": 1,
        "preOrderDiscount": 221.41,
        "stRelatedId": 0
    },
    {
        "id": 88,
        "originalRank": 62,
        "rankId": 713,
        "product": "Rebound slide 16",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "RB SLIDE.png\r",
        "imageNew": "RBSLIDE.png\r",
        "upc": "0 7 90628 03114 1",
        "agarticle": 585209212,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rebound-slide-16/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 0,
        "lengthM": 2.4,
        "widthM": 0.6,
        "symetricalYn": "N",
        "usdPrice": "No US Pricing",
        "gbpPrice": 0,
        "france": 415,
        "euukPrice": 251.2,
        "eunlPrice": 237.24,
        "euroPrice": 378.11,
        "poland": 1504.9,
        "canada": "#VALUE!",
        "switzerland": 1,
        "base": 1,
        "preOrderDiscount": 264.04,
        "stRelatedId": 0,
        "ndRelatedId": 81,
        "previewImages": "Alone"
    },
    {
        "id": 88,
        "originalRank": 76,
        "rankId": 713,
        "product": "Rebound slide 16",
        "categoryOldBackup": "Trampoline ",
        "category": "Trampoline & Bouncers",
        "imageNew": "RBSlide.png",
        "upc": "0 7 90628 03114 1",
        "agarticle": 585209212,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/rebound-slide-16/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": "2.44m",
        "minDepthM": 0,
        "gbpPrice": 0,
        "euroPrice": 378.11,
        "poland": 1504.9,
        "canada": 0,
        "switzerland": 1,
        "base": 1,
        "preOrderDiscount": 265.7,
        "stRelatedId": 0
    },
    {
        "id": 89,
        "originalRank": 63,
        "rankId": 711,
        "product": "I-Log",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "I-Log.png\r",
        "imageNew": "I-Log.png\r",
        "folder": "06I-log",
        "model": "I-Log",
        "upc": "0 7 90628 03115 8",
        "agarticle": 585209213,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/i-log/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 1,
        "lengthM": 1.48,
        "widthM": 0.48,
        "symetricalYn": "N",
        "usdPrice": 429.99,
        "gbpPrice": 175,
        "iePrice": 231.21,
        "france": 415,
        "euroPrice": 302.48,
        "poland": 1893.4,
        "canada": 859.98,
        "switzerland": 399,
        "base": 399,
        "preOrderDiscount": 352.05,
        "stRelatedId": 0,
        "ndRelatedId": 80,
        "rdRelatedId": 81,
        "connectorPieceOrAlone": 82,
        "previewImages": "Alone",
        "undefined": "i-Log-1.jpg, i-Log-2.jpg, i-Log-3.jpg"
    },
    {
        "id": 89,
        "originalRank": 77,
        "rankId": 711,
        "product": "I-Log",
        "categoryOldBackup": "Trampoline ",
        "category": "Trampoline & Bouncers",
        "image": "I-Log.png",
        "imageNew": "I-Log.png",
        "folder": "06I-log",
        "model": "I-Log",
        "upc": "0 7 90628 03115 8",
        "agarticle": 585209213,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/i-log/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": "1.21m",
        "minDepthM": 1,
        "usdPrice": 429.99,
        "gbpPrice": 175,
        "iePrice": 231.21,
        "france": 415,
        "euukPrice": 320,
        "eunlPrice": 302.22,
        "euroPrice": 302.48,
        "poland": 1893.4,
        "canada": 859.98,
        "switzerland": 399,
        "base": 399,
        "preOrderDiscount": 354.29,
        "stRelatedId": 0
    },
    {
        "id": 90,
        "originalRank": 64,
        "rankId": 705,
        "product": "Escalade Trampoline Climbing Wall 2mtr",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Esc2m.png",
        "imageNew": "Esc2m.png",
        "folder": "38Escalade2M",
        "model": "Esc_2m",
        "upc": "0 7 90628 03402 9",
        "agarticle": 585215100,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/escalade-2m/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/escalade-2m/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 1.48,
        "widthM": 1.55,
        "symetricalYn": "N",
        "usdPrice": 1599.99,
        "gbpPrice": 1244.33,
        "iePrice": 1362.19,
        "france": 1500,
        "euroPrice": 1343.7,
        "poland": 5894.31,
        "denmark": 17694,
        "canada": 3199.98,
        "switzerland": 1270,
        "base": 1270,
        "preOrderDiscount": 1056.17,
        "stRelatedId": 0,
        "ndRelatedId": 91,
        "rdRelatedId": 73,
        "connectorPieceOrAlone": 82,
        "previewImages": "Piece",
        "undefined": "Escalade2m-1.jpg, Escalade2m-2.jpg"
    },
    {
        "id": 90,
        "originalRank": 78,
        "rankId": 705,
        "product": "Escalade Trampoline Climbing Wall 2mtr",
        "categoryOldBackup": "Trampoline ",
        "category": "Trampoline & Bouncers",
        "image": "escalade2M.png",
        "imageNew": "escalade2M.png",
        "folder": "38Escalade2M",
        "model": "Esc_2m",
        "upc": "0 7 90628 03402 9",
        "agarticle": 585215100,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/escalade-3m/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": "2.44m",
        "minDepthM": 4,
        "usdPrice": 1599.99,
        "gbpPrice": 1244.33,
        "iePrice": 1362.19,
        "france": 1500,
        "euukPrice": 819.78,
        "eunlPrice": 774.24,
        "nlPrice": 831.25,
        "euroPrice": 1201.64,
        "poland": 5894.31,
        "denmark": 17694,
        "canada": 3199.98,
        "switzerland": 1270,
        "base": 1270,
        "preOrderDiscount": 1062.95,
        "stRelatedId": 0
    },
    {
        "id": 91,
        "originalRank": 65,
        "rankId": 706,
        "product": "Escalade Trampoline Climbing Wall 3mtr",
        "categoryOldBackup": "Trampoline & Bouncers",
        "category": "Trampoline & Bouncers",
        "image": "Esc3m.png",
        "imageNew": "Esc3m.png",
        "folder": "36Escalade3M",
        "model": "Esc_3m",
        "upc": "0 7 90628 03403 6",
        "agarticle": 585215105,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/escalade-3m/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/escalade-3m/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.44,
        "anchorPoints": 4,
        "lengthM": 1.55,
        "widthM": 1.55,
        "symetricalYn": "Y",
        "usdPrice": 2399.99,
        "gbpPrice": 2004.89,
        "iePrice": 2194.8,
        "france": 2420,
        "euroPrice": 2268.07,
        "poland": 9877.24,
        "denmark": 10483,
        "canada": 4799.98,
        "switzerland": 2080,
        "base": 2080,
        "preOrderDiscount": 1760.3,
        "stRelatedId": 0,
        "ndRelatedId": 90,
        "rdRelatedId": 73,
        "connectorPieceOrAlone": 82,
        "previewImages": "Piece",
        "undefined": "Escalade3m-1.jpg, Escalade3m-2.jpg"
    },
    {
        "id": 91,
        "originalRank": 79,
        "rankId": 706,
        "product": "Escalade Trampoline Climbing Wall 3mtr",
        "categoryOldBackup": "Trampoline ",
        "category": "Trampoline & Bouncers",
        "image": "escalade3M.png",
        "imageNew": "escalade3M.png",
        "folder": "36Escalade3M",
        "model": "Esc_3m",
        "upc": "0 7 90628 03403 6",
        "agarticle": 585215105,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/universal-monsoon/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": "2.44m",
        "minDepthM": 4,
        "usdPrice": 2399.99,
        "gbpPrice": 2004.89,
        "iePrice": 2194.8,
        "france": 2420,
        "euukPrice": 1346.25,
        "eunlPrice": 1271.46,
        "nlPrice": 1365.09,
        "euroPrice": 2210.04,
        "poland": 9877.24,
        "denmark": 10483,
        "canada": 4799.98,
        "switzerland": 2080,
        "base": 2080,
        "preOrderDiscount": 1771.61,
        "stRelatedId": 0
    },
    {
        "id": 92,
        "originalRank": 80,
        "rankId": 555,
        "product": "Universal Monsoon Spray station",
        "categoryOldBackup": "Station",
        "category": "Universal",
        "image": "monsoon.png",
        "imageNew": "UniversalMonsoon.png",
        "imageWithAnchors": "UniversalMonsoonwithanchors.png",
        "folder": "universal-monsoon-spray",
        "model": "Monsoon",
        "upc": "0 7 90628 04231 4",
        "agarticle": 585219674,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/universal-monsoon/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/universal-monsoon/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.6,
        "anchorPoints": 4,
        "lengthM": 3.85,
        "widthM": 3.85,
        "usdPrice": 3349.99,
        "gbpPrice": 6850,
        "iePrice": 7838.35,
        "france": 3240,
        "euukPrice": 2059.05,
        "eunlPrice": 1944.66,
        "nlPrice": 2087.87,
        "euroPrice": 3024.37,
        "poland": 14796.75,
        "denmark": 23593,
        "canada": 6699.98,
        "switzerland": 3180,
        "base": 3180,
        "preOrderDiscount": 2657.44,
        "stRelatedId": 0,
        "undefined": "monsoon-1.jpg, monsoon-2.jpg, monsoon-3.jpg"
    },
    {
        "id": 99,
        "originalRank": 81,
        "rankId": 901,
        "product": "Escalade Yacht Climbing wall",
        "categoryOldBackup": "Station",
        "category": "Yacht",
        "upc": "0 7 90628 03435 7",
        "agarticle": 585215115,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/escalade-5m-yacht-wall/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/escalade-5m-yacht-wall/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": "3.66m",
        "minDepthM": 10,
        "usdPrice": 8999.99,
        "gbpPrice": 2920,
        "iePrice": 3343.11,
        "france": 8580,
        "euukPrice": 5294.25,
        "eunlPrice": 5000.12,
        "nlPrice": 5368.33,
        "euroPrice": 9242.86,
        "poland": 44711.38,
        "denmark": 74224,
        "canada": 17999.98,
        "switzerland": 8180,
        "base": 8180,
        "preOrderDiscount": 7086.58,
        "stRelatedId": 0,
        "undefined": "Escalade5myachtwall-1.jpg, Escalade5myachtwall-2.jpg, Escalade5myachtwall-3.jpg"
    },
    {
        "id": 100,
        "originalRank": 82,
        "rankId": 902,
        "product": "Yacht Ocean Pool 6m x 5m",
        "categoryOldBackup": "Station",
        "category": "Yacht",
        "upc": "0 7 90628 03637 5",
        "agarticle": 585216625,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/ocean-pool-5x6/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/ocean-pool-6x5/",
        "brochureCapacity": 10,
        "safetyDistanceFromLandM": "1.21m",
        "minDepthM": 4,
        "usdPrice": 3899.99,
        "gbpPrice": 3000,
        "iePrice": 3430.3,
        "france": 3750,
        "euukPrice": 1692.37,
        "eunlPrice": 1598.35,
        "nlPrice": 1716.05,
        "euroPrice": 5041.18,
        "poland": 24386.1788617886,
        "denmark": 37592,
        "canada": 7799.98,
        "switzerland": 3510,
        "base": 3510,
        "preOrderDiscount": 2657.44,
        "stRelatedId": 0
    },
    {
        "id": 101,
        "originalRank": 83,
        "rankId": 903,
        "product": "Yacht Ocean Pool 4m x 4m",
        "categoryOldBackup": "Station",
        "category": "Yacht",
        "image": "Ocean pool.png",
        "upc": "0 7 90628 03560 6",
        "agarticle": 585215001,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/ocean-pool-4x4/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/ocean-pool-4x4/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": "1.21m",
        "minDepthM": 4,
        "usdPrice": 2899.99,
        "gbpPrice": 2210,
        "iePrice": 2529.27,
        "france": 2770,
        "euukPrice": 2274.02,
        "eunlPrice": 2147.69,
        "nlPrice": 2305.84,
        "euroPrice": 3192.44,
        "poland": 15443.0894308943,
        "denmark": 23806,
        "canada": 5799.98,
        "switzerland": 2610,
        "base": 2610,
        "preOrderDiscount": 2125.94,
        "stRelatedId": 0
    },
    {
        "id": 102,
        "originalRank": 84,
        "rankId": 904,
        "product": "Docking Station 4,5x3xm",
        "categoryOldBackup": "Station",
        "category": "Yacht",
        "image": "Docking Station.png",
        "upc": "0 7 90628 03558 3",
        "agarticle": 585215003,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/docking-station/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/docking-station-3-1m-x-4-5m/",
        "brochureCapacity": 3,
        "safetyDistanceFromLandM": "1.21m",
        "minDepthM": 2,
        "usdPrice": 2299.99,
        "gbpPrice": 1780,
        "iePrice": 2027.53,
        "france": 2220,
        "euukPrice": 1331.76,
        "eunlPrice": 1257.77,
        "nlPrice": 1350.4,
        "euroPrice": 2772.27,
        "poland": 13410.57,
        "denmark": 20673,
        "canada": 4599.98,
        "switzerland": 2060,
        "base": 2060,
        "preOrderDiscount": 1771.61,
        "stRelatedId": 0
    },
    {
        "id": 121,
        "originalRank": 85,
        "rankId": 1001,
        "product": "Safety buoy 9\" x 15' x 3",
        "categoryOldBackup": "Anchor",
        "category": "Stand Alone",
        "image": "Swimbuoy.png",
        "imageNew": "Swimbuoy.png",
        "folder": "34SwimBuoy",
        "model": "Swim_Buoyx3",
        "upc": "0 7 90628 03158 5",
        "agarticle": 585210012,
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/swim-buoys/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/safety-buoy/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 0,
        "minDepthM": 0,
        "anchorPoints": 4,
        "lengthM": 15,
        "widthM": 0.3,
        "symetricalYn": "n",
        "usdPrice": 469.99,
        "gbpPrice": 320,
        "iePrice": 383.85,
        "france": 420,
        "euukPrice": 274.82,
        "eunlPrice": 259.56,
        "nlPrice": 278.67,
        "canada": 939.98,
        "switzerland": 460,
        "base": 460,
        "preOrderDiscount": 354.29,
        "stRelatedId": 0,
        "undefined": "Swim-Buoy-1.jpg, Swim-Buoy-2.jpg, Swim-Buoy-3.jpg"
    },
    {
        "gbpPrice": 0
    },
    {
        "gbpPrice": 0
    },
    {
        "gbpPrice": 0
    },
    {
        "gbpPrice": 0
    },
    {
        "gbpPrice": 0
    },
    {
        "gbpPrice": 0
    },
    {
        "gbpPrice": 0
    },
    {
        "id": 174,
        "originalRank": 95,
        "rankId": 102,
        "product": "Loki Corner",
        "categoryOldBackup": "XLarge",
        "category": "Slides",
        "image": "LokiCorner.png",
        "imageNew": "lokicorner.png",
        "imageWithAnchors": "LokiCornerwithanchors.png",
        "folder": "LokiCorner",
        "model": "Loki_Corner",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/1228/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/loki-corner/",
        "brochureCapacity": 10,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 2.5,
        "anchorPoints": 8,
        "lengthM": 8.38,
        "widthM": 4.62,
        "symetricalYn": "N",
        "usdPrice": 13199.99,
        "gbpPrice": 10200,
        "iePrice": 11920.87,
        "france": 13050,
        "euukPrice": 7815.75,
        "eunlPrice": 7532.18,
        "nlPrice": 7925.11,
        "poland": 56219.51,
        "denmark": 95101,
        "canada": 26399.98,
        "switzerland": 12070,
        "base": 12070,
        "previewImages": "Piece"
    },
    {
        "id": 175,
        "originalRank": 96,
        "rankId": 501,
        "product": "Lugeback",
        "categoryOldBackup": "Medium",
        "category": "Corners",
        "image": "lugeback.png",
        "imageNew": "Lugeback.png",
        "imageWithAnchors": "Lugebackwithanchors.png",
        "folder": "Lugeback",
        "model": "Lugeback",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/lugeback/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/lugeback/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.54,
        "anchorPoints": 2,
        "lengthM": 2.16,
        "widthM": 4.62,
        "symetricalYn": "N",
        "usdPrice": 2999.99,
        "gbpPrice": 2280,
        "iePrice": 2601.94,
        "france": 2847,
        "euukPrice": 1690.29,
        "eunlPrice": 1596.38,
        "nlPrice": 1713.94,
        "poland": 12154.47,
        "denmark": 20567,
        "canada": 5999.98,
        "switzerland": 2610,
        "base": 2610,
        "previewImages": "Piece"
    },
    {
        "id": 176,
        "originalRank": 97,
        "rankId": 502,
        "product": "Quarterback",
        "categoryOldBackup": "Medium",
        "category": "Corners",
        "image": "quarterback.png",
        "imageNew": "QuarterBack.png",
        "imageWithAnchors": "Quarterbackwithanchors.png",
        "folder": "Quarterback",
        "model": "Q_Back",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/quarterback/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/quarterback/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.62,
        "anchorPoints": 2,
        "lengthM": 2.74,
        "widthM": 4.62,
        "symetricalYn": "N",
        "usdPrice": 4499.99,
        "gbpPrice": 2810,
        "iePrice": 3903.17,
        "france": 4270,
        "euukPrice": 2511.23,
        "eunlPrice": 2371.72,
        "nlPrice": 2546.37,
        "poland": 18048.78,
        "denmark": 30556,
        "canada": 8999.98,
        "switzerland": 3880,
        "base": 3880,
        "previewImages": "Piece"
    },
    {
        "id": 177,
        "originalRank": 98,
        "rankId": 403,
        "product": "Lugeway 10",
        "categoryOldBackup": "Medium",
        "category": "Core 10ft",
        "image": "lugeway10.png",
        "imageNew": "Lugeway10.png",
        "imageWithAnchors": "Lugeway10withanchors.png",
        "folder": "Lugeway10-2",
        "model": "LWay_10",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/lugeway-10/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/lugeway-10/",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.43,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 1.58,
        "symetricalYn": "N",
        "usdPrice": 2299.99,
        "gbpPrice": 1320,
        "iePrice": 1929.74,
        "france": 2110,
        "euukPrice": 1218.78,
        "eunlPrice": 1151.07,
        "nlPrice": 1235.83,
        "poland": 8780.49,
        "denmark": 14830,
        "canada": 4599.98,
        "switzerland": 1880,
        "base": 1880,
        "previewImages": "Piece"
    },
    {
        "id": 178,
        "originalRank": 99,
        "rankId": 304,
        "product": "Lugeway 20",
        "categoryOldBackup": "Medium",
        "category": "Core 20ft",
        "image": "lugeway20.png",
        "imageNew": "Lugeway20.png",
        "imageWithAnchors": "Lugeway20withanchors.png",
        "folder": "Lugeway20-2",
        "model": "LWay_20",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/lugeway-20/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/lugeway-20/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.43,
        "anchorPoints": 4,
        "lengthM": 6.1,
        "widthM": 1.85,
        "symetricalYn": "N",
        "usdPrice": 3499.99,
        "gbpPrice": 2710,
        "iePrice": 3162.58,
        "france": 3460,
        "euukPrice": 1959.73,
        "eunlPrice": 1850.85,
        "nlPrice": 1987.15,
        "poland": 14105.69,
        "denmark": 25833,
        "canada": 6999.98,
        "switzerland": 3030,
        "base": 3030,
        "previewImages": "Piece"
    },
    {
        "id": 179,
        "originalRank": 100,
        "rankId": 552,
        "product": "Universal Archway",
        "categoryOldBackup": "Medium",
        "category": "Universal",
        "image": "Universal Archway.png",
        "imageNew": "universalarchway.png",
        "imageWithAnchors": "UniversalArchwaywithanchors.png",
        "folder": "UniversalArchway",
        "model": "Uni_Archway",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/universal-archway/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/universal-archway/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 2.17,
        "anchorPoints": 4,
        "lengthM": 3.85,
        "widthM": 3.85,
        "symetricalYn": "Y",
        "usdPrice": 4999.99,
        "gbpPrice": 3510,
        "iePrice": 4353.91,
        "france": 4790,
        "euukPrice": 2938.54,
        "eunlPrice": 2775.28,
        "nlPrice": 2979.66,
        "poland": 21138.21,
        "denmark": 35756,
        "canada": 9999.98,
        "switzerland": 4540,
        "base": 4540,
        "previewImages": "Piece"
    },
    {
        "id": 180,
        "originalRank": 101,
        "rankId": 554,
        "product": "Universal Pavilion",
        "categoryOldBackup": "Medium",
        "category": "Universal",
        "image": "Universal Pavilion.png",
        "imageNew": "universalpavilion.png",
        "imageWithAnchors": "UniversalPavilionwithanchors.png",
        "folder": "UniversalPavilion",
        "model": "Uni_Pavilion",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/universal-pavilion/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/universal-pavilion/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.06,
        "anchorPoints": 4,
        "lengthM": 3.85,
        "widthM": 3.85,
        "symetricalYn": "Y",
        "usdPrice": 3299.99,
        "gbpPrice": 2530,
        "iePrice": 2888.99,
        "france": 3160,
        "euukPrice": 1894.47,
        "eunlPrice": 1789.22,
        "nlPrice": 1920.98,
        "poland": 13617.89,
        "denmark": 23052,
        "canada": 6599.98,
        "switzerland": 2930,
        "base": 2930,
        "previewImages": "Piece"
    },
    {
        "id": 181,
        "originalRank": 102,
        "rankId": 800,
        "product": "OG Lounge",
        "categoryOldBackup": "Medium",
        "category": "Lounges",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/og-lounge/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/og-lounge/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3.3,
        "minDepthM": 0.98,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 3.05,
        "usdPrice": 3199.99,
        "gbpPrice": 2230,
        "iePrice": 3032.35,
        "france": 3410,
        "euukPrice": 2048.13,
        "eunlPrice": 1934.35,
        "nlPrice": 2076.79,
        "canada": 6399.98,
        "switzerland": 3017,
        "base": 3017
    },
    {
        "id": 182,
        "originalRank": 103,
        "rankId": 800,
        "product": "Ohana Lounge",
        "categoryOldBackup": "Medium",
        "category": "Lounges",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/ohana-lounge/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/ohana-lounge/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3.3,
        "minDepthM": 0.98,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 3.05,
        "usdPrice": 3199.99,
        "gbpPrice": 2300,
        "iePrice": 3123.02,
        "france": 3510,
        "euukPrice": 2109.37,
        "eunlPrice": 1992.19,
        "nlPrice": 2138.89,
        "canada": 6399.98,
        "switzerland": 3017,
        "base": 3017
    },
    {
        "id": 183,
        "originalRank": 104,
        "rankId": 800,
        "product": "Revel Lounge",
        "categoryOldBackup": "Medium",
        "category": "Lounges",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/revel-lounge/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/revel-lounge/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 0.98,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 3.05,
        "usdPrice": 3199.99,
        "gbpPrice": 1850,
        "iePrice": 2511.9,
        "france": 2830,
        "euukPrice": 1696.6,
        "eunlPrice": 1602.35,
        "nlPrice": 1720.35,
        "canada": 6399.98,
        "switzerland": 3017,
        "base": 3017
    },
    {
        "id": 184,
        "originalRank": 105,
        "rankId": 800,
        "product": "Solarium",
        "categoryOldBackup": "Medium",
        "category": "Lounges",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/solarium/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/solarium/",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 0.98,
        "anchorPoints": 1,
        "lengthM": 3.05,
        "widthM": 2,
        "usdPrice": 1099.99,
        "gbpPrice": 690,
        "iePrice": 938.98,
        "france": 1060,
        "canada": 2199.98,
        "switzerland": 1090,
        "base": 1090
    },
    {
        "gbpPrice": 0
    },
    {
        "id": 186,
        "originalRank": 94,
        "rankId": 101,
        "product": "Loki",
        "categoryOldBackup": "XLarge",
        "category": "Slides",
        "image": "Loki.png",
        "imageNew": "loki.png",
        "imageWithAnchors": "Lokiwithanchors.png",
        "folder": "LokiCentre",
        "model": "Loki",
        "oldwebsitelink": "https://www.aquaparks.co.uk/project/loki/",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/loki/",
        "brochureCapacity": 10,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 2.5,
        "anchorPoints": 8,
        "lengthM": 8.38,
        "widthM": 4.62,
        "symetricalYn": "N",
        "usdPrice": 13199.99,
        "gbpPrice": 10080,
        "iePrice": 11779.61,
        "france": 12880,
        "euukPrice": 7723.13,
        "eunlPrice": 7442.93,
        "nlPrice": 7831.19,
        "poland": 55569.11,
        "denmark": 93974,
        "canada": 26399.98,
        "switzerland": 11930,
        "base": 11930,
        "ndRelatedId": 165,
        "rdRelatedId": 4,
        "connectorPieceOrAlone": 14,
        "previewImages": "Piece"
    },
    {
        "id": 201,
        "originalRank": 67,
        "rankId": 605,
        "product": "Cyclone Enclosure",
        "categoryOldBackup": "Medium",
        "category": "Stand Alone",
        "image": "Cyclone-Enclosure.png\r",
        "imageNew": "Enclousure.png",
        "imageWithAnchors": "Enclousurewithanchors.png",
        "folder": "cyclone-enclosure",
        "model": "Cyclone_Enclosure",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/cyclone-enclosure/",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 1.5,
        "anchorPoints": 4,
        "lengthM": 6,
        "widthM": 3,
        "symetricalYn": "N",
        "usdPrice": 2699.99,
        "gbpPrice": 2130,
        "iePrice": 2487.98,
        "france": 2720,
        "euukPrice": 1600.71,
        "eunlPrice": 1511.79,
        "nlPrice": 1623.11,
        "euroPrice": 2940.34,
        "poland": 14223.58,
        "denmark": 22938,
        "canada": 5399.98,
        "switzerland": 2470,
        "base": 2470,
        "preOrderDiscount": 2200.37,
        "stRelatedId": 0,
        "ndRelatedId": 41,
        "rdRelatedId": 11,
        "connectorPieceOrAlone": 31,
        "previewImages": "Piece"
    },
    {
        "id": 202,
        "originalRank": 68,
        "rankId": 302,
        "product": "I-Beam 20",
        "categoryOldBackup": "Medium",
        "category": "Core 20ft",
        "image": "I-Beam.png\r",
        "imageNew": "I-BEAM20.png",
        "imageWithAnchors": "I-Beam20withanchors.png",
        "folder": "i-beam",
        "model": "I-Beam_20",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/i-beam-20/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 6.1,
        "symetricalYn": "N",
        "usdPrice": 2799.99,
        "gbpPrice": 1180,
        "iePrice": 1317.41,
        "france": 1450,
        "euukPrice": 1055.28,
        "eunlPrice": 996.65,
        "nlPrice": 1070.04468317727,
        "euroPrice": 1763.87,
        "poland": 8532.52,
        "denmark": 14416,
        "canada": 5599.98,
        "switzerland": 1630,
        "base": 1630,
        "preOrderDiscount": 1269.44,
        "stRelatedId": 0,
        "ndRelatedId": 51,
        "rdRelatedId": 49,
        "connectorPieceOrAlone": 31,
        "previewImages": "Piece"
    },
    {
        "id": 203,
        "originalRank": 69,
        "rankId": 301,
        "product": "I-Hop 20",
        "categoryOldBackup": "Medium",
        "category": "Core 20ft",
        "image": "I-Hop.png\r",
        "imageNew": "I-HOP20.png",
        "imageWithAnchors": "I-Hop20withanchors.png",
        "folder": "i-hop",
        "model": "I-Hop_20",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/i-hop-20/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 6.1,
        "symetricalYn": "N",
        "usdPrice": 3499.99,
        "gbpPrice": 2750,
        "iePrice": 3214.53,
        "france": 3520,
        "euukPrice": 2192.81,
        "eunlPrice": 2070.98,
        "nlPrice": 2223.49132166958,
        "euroPrice": 3948.74,
        "poland": 19101.63,
        "denmark": 30804,
        "canada": 6999.98,
        "switzerland": 3390,
        "base": 3390,
        "preOrderDiscount": 2792.79,
        "stRelatedId": 0,
        "ndRelatedId": 51,
        "rdRelatedId": 49,
        "connectorPieceOrAlone": 31,
        "previewImages": "Piece"
    },
    {
        "id": 204,
        "originalRank": 70,
        "rankId": 206,
        "product": "Parkway 30",
        "categoryOldBackup": "XLarge",
        "category": "Swing & Climb",
        "image": "parkway-30.png",
        "imageNew": "Parkway30.png",
        "imageWithAnchors": "Parkway30withanchors.png",
        "folder": "parkway30",
        "model": "PWay_30",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/parkway-30-cx3/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.66,
        "anchorPoints": 6,
        "lengthM": 1.98,
        "widthM": 9.14,
        "symetricalYn": "N",
        "usdPrice": 4199.99,
        "gbpPrice": 3320,
        "iePrice": 3873.48,
        "france": 4240,
        "euukPrice": 2401.07,
        "eunlPrice": 2267.68,
        "nlPrice": 2434.67011990781,
        "euroPrice": 3696.64,
        "poland": 17882.11,
        "denmark": 28838,
        "canada": 8399.98,
        "switzerland": 3710,
        "base": 3710,
        "preOrderDiscount": 2962.05,
        "ndRelatedId": 46,
        "rdRelatedId": 47,
        "connectorPieceOrAlone": 11,
        "previewImages": "Piece"
    },
    {
        "id": 205,
        "originalRank": 71,
        "rankId": 553,
        "product": "Universal Lifeguard Station",
        "categoryOldBackup": "Medium",
        "category": "Universal",
        "image": "Uni-Lifeguard.png",
        "imageNew": "universallifeguard.png",
        "imageWithAnchors": "UniversalLifeguardwithanchors.png",
        "folder": "universal-lifeguard",
        "model": "Uni_LGaurd",
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/universal-lifeguard-station/",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 4,
        "lengthM": 3.85,
        "widthM": 3.85,
        "symetricalYn": "Y",
        "usdPrice": 4099.99,
        "gbpPrice": 3170,
        "iePrice": 3624.85,
        "france": 3970,
        "euukPrice": 2544.34,
        "eunlPrice": 2402.99,
        "nlPrice": 2579.95,
        "euroPrice": 4385.71,
        "poland": 21215.45,
        "denmark": 34213,
        "canada": 8199.98,
        "switzerland": 3930,
        "base": 3930,
        "preOrderDiscount": 3131.31,
        "stRelatedId": 0,
        "ndRelatedId": 34,
        "rdRelatedId": 35,
        "connectorPieceOrAlone": 33,
        "previewImages": "Piece"
    },
    {
        "id": 206,
        "product": "Thor",
        "category": "Jump",
        "image": "thor.png",
        "imageNew": "thor.png",
        "imageWithAnchors": "Thor-Anchor.png",
        "folder": "Thor",
        "model": "Thor",
        "upc": "0 7 90628 04599 5",
        "agarticle": 585222150,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/thor/",
        "brochureCapacity": 12,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 2.84,
        "anchorPoints": 8,
        "lengthM": 4.5,
        "widthM": 9,
        "symetricalYn": "N",
        "usdPrice": 15999.99,
        "gbpPrice": 13020,
        "iePrice": 14611.55,
        "france": 15995
    },
    {
        "id": 207,
        "product": "Anvil",
        "category": "Jump",
        "image": "anvil.png",
        "imageNew": "anvil.png",
        "imageWithAnchors": "Anvil-Anchor.png",
        "folder": "Anvil",
        "model": "Anvil",
        "upc": "0 7 90628 04598 8",
        "agarticle": 585222101,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/anvil/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 2.43,
        "anchorPoints": 6,
        "lengthM": 4.5,
        "widthM": 4.5,
        "symetricalYn": "Y",
        "usdPrice": 10499.99,
        "gbpPrice": 8260,
        "iePrice": 9649.13,
        "france": 10550
    },
    {
        "id": 208,
        "product": "Hammer",
        "category": "Jump",
        "image": "hammer.png",
        "imageNew": "hammer.png",
        "imageWithAnchors": "Hammer-Anchor.png",
        "folder": "Hammer",
        "model": "Hammer",
        "upc": "0 7 90628 04600 8",
        "agarticle": 585222152,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/hammer/",
        "brochureCapacity": 6,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 2.12,
        "anchorPoints": 4,
        "lengthM": 3.6,
        "widthM": 4.5,
        "symetricalYn": "Y",
        "usdPrice": 7499.99,
        "gbpPrice": 5840,
        "iePrice": 6818.71,
        "france": 7460
    },
    {
        "id": 209,
        "product": "Universal Icecap",
        "category": "Universal",
        "image": "universal_icecap.png",
        "imageNew": "universal_icecap.png",
        "imageWithAnchors": "Uni-Ice-Anchor.png",
        "folder": "Universal_icecap",
        "model": "Universal_icecap",
        "upc": "0 7 90628 04603 9",
        "agarticle": 585222155,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/universal-icecap/",
        "brochureCapacity": 3,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.38,
        "anchorPoints": 4,
        "lengthM": 3.8,
        "widthM": 3.8,
        "symetricalYn": "Y",
        "usdPrice": 2499.99,
        "gbpPrice": 2010,
        "iePrice": 2293.73,
        "france": 2510
    },
    {
        "id": 210,
        "product": "Universal Keyhole",
        "category": "Universal",
        "image": "universal_keyhole.png",
        "imageNew": "universal_keyhole.png",
        "imageWithAnchors": "Uni-Key-Anchor.png",
        "folder": "Universal_keyhole",
        "model": "Universal_keyhole",
        "upc": "0 7 90628 04604 6",
        "agarticle": 585222156,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/universal-keyhole/",
        "brochureCapacity": 3,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.76,
        "anchorPoints": 4,
        "lengthM": 3.8,
        "widthM": 3.8,
        "symetricalYn": "Y",
        "usdPrice": 2699.99,
        "gbpPrice": 2170,
        "iePrice": 2484.88,
        "france": 2720
    },
    {
        "id": 211,
        "product": "Double Icecap 20",
        "category": "Core 20ft",
        "image": "double_icecap.png",
        "imageNew": "double_icecap.png",
        "imageWithAnchors": "Double-Ice-Anchor.png",
        "folder": "Double_icecap",
        "model": "Double_icecap",
        "upc": "0 7 90628 04601 5",
        "agarticle": 585222153,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/double-icecap/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.44,
        "anchorPoints": 4,
        "lengthM": 3.2,
        "widthM": 6,
        "symetricalYn": "Y",
        "usdPrice": 6199.99,
        "gbpPrice": 4860,
        "iePrice": 5679.19,
        "france": 6210
    },
    {
        "id": 212,
        "product": "Figure 8 20",
        "category": "Core 20ft",
        "image": "figure_8.png",
        "imageNew": "figure_8.png",
        "imageWithAnchors": "Fig8-Anchor.png",
        "folder": "Figure_8",
        "model": "Figure_8",
        "upc": "0 7 90628 04602 2",
        "agarticle": 585222154,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/figure-8/",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.14,
        "anchorPoints": 4,
        "lengthM": 3.2,
        "widthM": 6,
        "symetricalYn": "Y",
        "usdPrice": 2699.99,
        "gbpPrice": 2110,
        "iePrice": 2458.22,
        "france": 2690
    },
    {
        "id": 213,
        "product": "C Lounge",
        "category": "Lounges",
        "image": "c_lounge.png",
        "imageNew": "c_lounge.png",
        "imageWithAnchors": "C-Deck-Anchor.png",
        "folder": "C_lounge",
        "model": "C_lounge",
        "upc": "0 7 90628 04605 3",
        "agarticle": 585222157,
        "newWebsiteLinkAgcom": "https://www.aquaglide.com/product/c-lounge/",
        "brochureCapacity": 8,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 0.98,
        "anchorPoints": 4,
        "lengthM": 3,
        "widthM": 3,
        "symetricalYn": "Y",
        "usdPrice": 2299.99,
        "gbpPrice": 1560,
        "iePrice": 2113.61,
        "france": 2390
    },
    {
        "id": 214,
        "product": "Arete 20",
        "category": "Core 20ft",
        "imageNew": "Arete20.png",
        "imageWithAnchors": "Arete20-Anchor.png",
        "folder": "arete",
        "model": "Arete_20",
        "newWebsiteLinkAgcom": "https://issuu.com/watersportsworld/docs/2023_aquaglide_aquapark_catalog/12",
        "brochureCapacity": 5,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.82,
        "anchorPoints": 6,
        "lengthM": 6.1,
        "widthM": 2.5,
        "symetricalYn": "N",
        "usdPrice": 5999.99,
        "gbpPrice": 4720,
        "iePrice": 5513.78,
        "france": 6035
    },
    {
        "id": 215,
        "product": "Arete 10",
        "category": "Core 10ft",
        "imageNew": "Arete10.png",
        "imageWithAnchors": "Arete10-Anchor.png",
        "folder": "arete",
        "model": "Arete_10",
        "newWebsiteLinkAgcom": "https://issuu.com/watersportsworld/docs/2023_aquaglide_aquapark_catalog/12",
        "brochureCapacity": 3,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.82,
        "anchorPoints": 4,
        "lengthM": 3,
        "widthM": 2.5,
        "symetricalYn": "N",
        "usdPrice": 3299.99,
        "gbpPrice": 2600,
        "iePrice": 3032.58,
        "france": 3320
    },
    {
        "id": 216,
        "product": "Mini Monsoon",
        "category": "Stand Alone",
        "imageNew": "Mini-Mon.png",
        "imageWithAnchors": "Mini-Mon-Anchor.png",
        "folder": "arete",
        "model": "mini_monsoon",
        "newWebsiteLinkAgcom": "https://issuu.com/watersportsworld/docs/2023_aquaglide_aquapark_catalog/16",
        "brochureCapacity": 0,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.21,
        "anchorPoints": 1,
        "lengthM": 1.2,
        "widthM": 1.2,
        "symetricalYn": "Y",
        "usdPrice": 1499.99,
        "gbpPrice": 1130,
        "iePrice": 1378.44,
        "france": 1510
    },
    {
        "id": 241,
        "product": "Hatchway 10",
        "category": "Core 10ft",
        "imageNew": "Hatchway_10.png",
        "folder": "Hatchway",
        "model": "Hatchway_10",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.7,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 1.98,
        "symetricalYn": "N",
        "usdPrice": 1999.99,
        "gbpPrice": 1480,
        "iePrice": 1729.8,
        "france": 1999
    },
    {
        "id": 242,
        "product": "Hatchway 20",
        "category": "Core 20ft",
        "imageNew": "Hatchway_20.png",
        "folder": "Hatchway",
        "model": "Hatchway_20",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.75,
        "anchorPoints": 4,
        "lengthM": 6.1,
        "widthM": 1.98,
        "symetricalYn": "N",
        "usdPrice": 2999.99,
        "gbpPrice": 2220,
        "iePrice": 2594.71,
        "france": 2999
    },
    {
        "id": 243,
        "product": "El Jefe",
        "category": "Slides",
        "imageNew": "El_Jefe.png",
        "folder": "el_jefe",
        "model": "El_Jefe",
        "brochureCapacity": 12,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 3.27,
        "anchorPoints": 8,
        "lengthM": 15.36,
        "widthM": 6.1,
        "symetricalYn": "N",
        "usdPrice": 19999.99,
        "gbpPrice": 15720,
        "iePrice": 18754.39,
        "france": 19999
    },
    {
        "id": 244,
        "product": "El Jefe Climbing Wall",
        "category": "Slides",
        "imageNew": "El_Jefe_Climb_Wall.png",
        "folder": "el_jefe",
        "model": "El_Jefe_climbing_wall",
        "brochureCapacity": 1,
        "safetyDistanceFromLandM": 4.5,
        "minDepthM": 2.9,
        "anchorPoints": 0,
        "lengthM": 2.00,
        "widthM": 4.2,
        "symetricalYn": "N",
        "usdPrice": 1999.99,
        "gbpPrice": 1520,
        "iePrice": 1729.8,
        "france": 1999
    },
    {
        "id": 245,
        "product": "Ridgeway 10",
        "category": "Core 10ft",
        "imageNew": "Ridgeway_10.png",
        "folder": "ridgeway",
        "model": "ridgeway_10",
        "brochureCapacity": 2,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.65,
        "anchorPoints": 4,
        "lengthM": 3.05,
        "widthM": 2.44,
        "symetricalYn": "N",
        "usdPrice": 2999.99,
        "gbpPrice": 2220,
        "iePrice": 2594.71,
        "france": 2999
    },
    {
        "id": 246,
        "product": "Ridgeway 20",
        "category": "Core 20ft",
        "imageNew": "Ridgeway_20.png",
        "folder": "ridgeway",
        "model": "ridgeway_20",
        "brochureCapacity": 4,
        "safetyDistanceFromLandM": 3,
        "minDepthM": 1.82,
        "anchorPoints": 4,
        "lengthM": 6.1,
        "widthM": 2.44,
        "symetricalYn": "M",
        "usdPrice": 5499.99,
        "gbpPrice": 4070,
        "iePrice": 4756.99,
        "france": 5499
    }
]


export default productData;
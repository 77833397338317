const preBuiltParks = [
    {
        "parkInfo": {
            "location": "United Kingdom",
            "currency": "GBP",
            "name": "Challenge Track M",
            "parkID": "8wAUjW2akagUjZlgIqIZQCaoEM43ChallengeTrackM",
            "text": "Park Size: 100m x 100m",
            "height": 700,
            "width": 700,
            "parkDepth": 5,
            "timeStamp": "last updated: Wednesday, February 17, 2021, 3:48 PM",
            "parkIcon": "universal_keyhole.png"
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Slides",
                "depth": "2.44",
                "folder": "51KingoftheMountian",
                "height": "56.00",
                "id": "product_28",
                "img": "kom.png",
                "imgNew": "kom.png",
                "imgAnchor": "KOMwithanchors.png",
                "model": "KingOTM",
                "price": "3934.95",
                "relProducts": "26,25,34",
                "rotation": 0,
                "title": "King of the Mountain (set)",
                "width": "37.80",
                "xPos": 111.1,
                "xPos3D": 130,
                "yPos": "142.00",
                "yPos3D": "170.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Swing & Climb",
                "depth": "1.93",
                "folder": "01JungleGym",
                "height": "42.00",
                "id": "product_26",
                "img": "JungleGym102020.png",
                "imgNew": "JungleGym102020.png",
                "imgAnchor": "JungleGym102020withanchors.png",
                "model": "Jungle_Jim",
                "price": "2610.74",
                "relProducts": "34,25,53",
                "rotation": 0,
                "title": "Jungle Jim",
                "width": "37.80",
                "xPos": 171.1,
                "xPos3D": 190,
                "yPos": "138.00",
                "yPos3D": "159.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.76",
                "folder": "Universal_keyhole",
                "height": "53.20",
                "id": "product_210",
                "img": "universal_keyhole.png",
                "imgNew": "universal_keyhole.png",
                "imgAnchor": "undefined",
                "model": "Universal_keyhole",
                "price": "1966.04",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Keyhole",
                "width": "53.20",
                "xPos": 230.4,
                "xPos3D": 257,
                "yPos": 132.4,
                "yPos3D": 159,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "overpass",
                "height": "25.62",
                "id": "product_44",
                "img": "Overpass10.png",
                "imgNew": "Overpass10.png",
                "imgAnchor": "Overpass10withanchors.png",
                "model": "Overpass",
                "price": "1340.82",
                "relProducts": "47,45,52",
                "rotation": 0,
                "title": "Overpass 10",
                "width": "42.56",
                "xPos": 304.72,
                "xPos3D": 326,
                "yPos": 146.19,
                "yPos3D": 159,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "348.36",
                "xPos3D": "359.00",
                "yPos": "148.36",
                "yPos3D": "159.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "209.36",
                "xPos3D": "220.00",
                "yPos": "149.36",
                "yPos3D": "160.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 149.36,
                "xPos3D": 160,
                "yPos": "148.36",
                "yPos3D": "159.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "283.36",
                "xPos3D": "294.00",
                "yPos": "148.36",
                "yPos3D": "159.00",
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "location": "United Kingdom",
            "currency": "GBP",
            "name": "Serpentine Monsoon XXL 200",
            "parkID": "8wAUjW2akagUjZlgIqIZQCaoEM43SerpentineMonsoonXXL200",
            "text": "Park Size: 100m x 100m",
            "height": 1400,
            "width": 1400,
            "parkDepth": 5,
            "timeStamp": "last updated: Wednesday, February 17, 2021, 3:48 PM",
            "parkIcon": "thor.png"
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "8",
                "brocap": "12",
                "category": "Jump",
                "depth": "2.84",
                "folder": "Thor",
                "height": "63.00",
                "id": "product_206",
                "img": "thor.png",
                "imgNew": "thor.png",
                "imgAnchor": "undefined",
                "model": "Thor",
                "price": "11515.32",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Thor",
                "width": "126.00",
                "xPos": 330,
                "xPos3D": 393,
                "yPos": 126.5,
                "yPos3D": 158,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Jump",
                "depth": "2.43",
                "folder": "Anvil",
                "height": "63.00",
                "id": "product_207",
                "img": "anvil.png",
                "imgNew": "anvil.png",
                "imgAnchor": "undefined",
                "model": "Anvil",
                "price": "7676.88",
                "relProducts": ",,",
                "rotation": 135,
                "title": "Anvil",
                "width": "63.00",
                "xPos": "868.50",
                "xPos3D": "900.00",
                "yPos": "454.50",
                "yPos3D": "486.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Jump",
                "depth": "2.12",
                "folder": "Hammer",
                "height": "50.40",
                "id": "product_208",
                "img": "hammer.png",
                "imgNew": "hammer.png",
                "imgAnchor": "undefined",
                "model": "Hammer",
                "price": "6141.5",
                "relProducts": ",,",
                "rotation": 225,
                "title": "Hammer",
                "width": "63.00",
                "xPos": "77.50",
                "xPos3D": "109.00",
                "yPos": "459.80",
                "yPos3D": "485.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "18",
                "category": "Slides",
                "depth": "4",
                "folder": "everest",
                "height": "166.60",
                "id": "product_9",
                "img": "everest.png",
                "imgNew": "everest.png",
                "imgAnchor": "Everestwithanchors.png",
                "model": "Everest",
                "price": "16668.31",
                "relProducts": "10,21,20",
                "rotation": 225,
                "title": "Everest",
                "width": "69.44",
                "xPos": "666.28",
                "xPos3D": "701.00",
                "yPos": "184.70",
                "yPos3D": "268.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "3.34",
                "folder": "14FreefallSupreme",
                "height": "109.20",
                "id": "product_20",
                "img": "freefallsupreme.png",
                "imgNew": "freefallsupreme.png",
                "imgAnchor": "FreefallSupremewithanchors.png",
                "model": "Freefall_Sup",
                "price": "10296.05",
                "relProducts": "19,50,7",
                "rotation": 315,
                "title": "Freefall Supreme",
                "width": "53.20",
                "xPos": "391.40",
                "xPos3D": "418.00",
                "yPos": "505.40",
                "yPos3D": "560.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "3.34",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8231.44",
                "relProducts": "23,20,25",
                "rotation": 315,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": "162.82",
                "xPos3D": "182.00",
                "yPos": "268.37",
                "yPos3D": "332.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Slides",
                "depth": "2.44",
                "folder": "19FreefallExtreme",
                "height": "88.90",
                "id": "product_23",
                "img": "freefallextreme.png",
                "imgNew": "freefallextreme.png",
                "imgAnchor": "Freefallextremewithanchors.png",
                "model": "Freefall_Ext",
                "price": "4310.05",
                "relProducts": "21,26,29",
                "rotation": 225,
                "title": "Freefall Extreme",
                "width": "38.36",
                "xPos": "569.82",
                "xPos3D": "589.00",
                "yPos": "523.55",
                "yPos3D": "568.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.78",
                "folder": "02JungleJoe",
                "height": "92.12",
                "id": "product_25",
                "img": "junglejoe.png",
                "imgNew": "junglejoe.png",
                "imgAnchor": "JungleJoewithanchors.png",
                "model": "JJoe2",
                "price": "6982.64",
                "relProducts": "33,31,21",
                "rotation": 45,
                "title": "Jungle Joe 2",
                "width": "76.58",
                "xPos": "785.71",
                "xPos3D": "824.00",
                "yPos": "286.94",
                "yPos3D": "333.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Slides",
                "depth": "2.44",
                "folder": "51KingoftheMountian",
                "height": "56.00",
                "id": "product_28",
                "img": "kom.png",
                "imgNew": "kom.png",
                "imgAnchor": "KOMwithanchors.png",
                "model": "KingOTM",
                "price": "3934.95",
                "relProducts": "26,25,34",
                "rotation": 90,
                "title": "King of the Mountain (set)",
                "width": "37.80",
                "xPos": 747.1,
                "xPos3D": 766,
                "yPos": 543,
                "yPos3D": 571,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "3.66",
                "folder": "skyrocket",
                "height": "112.00",
                "id": "product_10",
                "img": "skyrocket.png",
                "imgNew": "skyrocket.png",
                "imgAnchor": "SkyRocketwithanchors.png",
                "model": "Sky_Rocket",
                "price": "6361.21",
                "relProducts": "37,9,20",
                "rotation": 315,
                "title": "Sky Rocket",
                "width": "112.00",
                "xPos": 237,
                "xPos3D": 293,
                "yPos": 189,
                "yPos3D": 245,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Swing & Climb",
                "depth": "1.93",
                "folder": "01JungleGym",
                "height": "42.00",
                "id": "product_26",
                "img": "JungleGym102020.png",
                "imgNew": "JungleGym102020.png",
                "imgAnchor": "JungleGym102020withanchors.png",
                "model": "Jungle_Jim",
                "price": "2610.74",
                "relProducts": "34,25,53",
                "rotation": 270,
                "title": "Jungle Jim",
                "width": "37.80",
                "xPos": 680.1,
                "xPos3D": 699,
                "yPos": "431.00",
                "yPos3D": "452.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "1",
                "category": "Swing & Climb",
                "depth": "2.44",
                "folder": "57Catapult",
                "height": "67.20",
                "id": "product_37",
                "img": "catapult.png",
                "imgNew": "catapult.png",
                "imgAnchor": "Catapultwithanchors.png",
                "model": "Catapult",
                "price": "2730.92",
                "relProducts": "10,33,28",
                "rotation": 270,
                "title": "Catapult",
                "width": "56.00",
                "xPos": "217.00",
                "xPos3D": "245.00",
                "yPos": "530.40",
                "yPos3D": "564.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "61Kaos",
                "height": "106.68",
                "id": "product_32",
                "img": "kaos.png",
                "imgNew": "kaos.png",
                "imgAnchor": "Kaoswithanchors.png",
                "model": "Kaos",
                "price": "4047.39",
                "relProducts": "31,82,33",
                "rotation": 0,
                "title": "Kaos",
                "width": "106.68",
                "xPos": 456.66,
                "xPos3D": 510,
                "yPos": 283.66,
                "yPos3D": 337,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.66",
                "folder": "parkway30",
                "height": "27.72",
                "id": "product_204",
                "img": "Parkway30.png",
                "imgNew": "Parkway30.png",
                "imgAnchor": "Parkway30withanchors.png",
                "model": "PWay_30",
                "price": "3035.35",
                "relProducts": "46,47,11",
                "rotation": 0,
                "title": "Parkway 30",
                "width": "127.96",
                "xPos": "621.02",
                "xPos3D": "685.00",
                "yPos": "632.14",
                "yPos3D": "646.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.44",
                "folder": "Double_icecap",
                "height": "44.80",
                "id": "product_211",
                "img": "double_icecap.png",
                "imgNew": "double_icecap.png",
                "imgAnchor": "undefined",
                "model": "Double_icecap",
                "price": "4537.03",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Double Icecap 20",
                "width": "84.00",
                "xPos": 563,
                "xPos3D": 605,
                "yPos": 137.6,
                "yPos3D": 160,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "blockade",
                "height": "27.72",
                "id": "product_11",
                "img": "Blockade20.png",
                "imgNew": "Blockade20.png",
                "imgAnchor": "Blockade20withanchors.png",
                "model": "Blockade_20",
                "price": "2511.73",
                "relProducts": "46,51,69",
                "rotation": 0,
                "title": "Blockade 20",
                "width": "85.26",
                "xPos": "367.37",
                "xPos3D": "410.00",
                "yPos": "633.14",
                "yPos3D": "647.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.14",
                "folder": "Figure_8",
                "height": "44.80",
                "id": "product_212",
                "img": "figure_8.png",
                "imgNew": "figure_8.png",
                "imgAnchor": "undefined",
                "model": "Figure_8",
                "price": "1890.42",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Figure 8 20",
                "width": "84.00",
                "xPos": "574.00",
                "xPos3D": "616.00",
                "yPos": "431.60",
                "yPos3D": "454.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2495.36",
                "relProducts": "51,49,31",
                "rotation": 90,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": 467.3,
                "xPos3D": 510,
                "yPos": 513.65,
                "yPos3D": 535,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Core 20ft",
                "depth": "1.43",
                "folder": "Lugeway20-2",
                "height": "85.40",
                "id": "product_178",
                "img": "Lugeway20.png",
                "imgNew": "Lugeway20.png",
                "imgAnchor": "Lugeway20withanchors.png",
                "model": "LWay_20",
                "price": "2455.04",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Lugeway 20",
                "width": "25.90",
                "xPos": 698.05,
                "xPos3D": 711,
                "yPos": "117.30",
                "yPos3D": "160.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "parkway20",
                "height": "27.72",
                "id": "product_46",
                "img": "Parkway20.png",
                "imgNew": "Parkway20.png",
                "imgAnchor": "Parkway20withanchors.png",
                "model": "PWay_20",
                "price": "2279.04",
                "relProducts": "11,51,31",
                "rotation": 0,
                "title": "Parkway 20",
                "width": "85.26",
                "xPos": "258.37",
                "xPos3D": "301.00",
                "yPos": "634.14",
                "yPos3D": "648.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "49WalkonWater",
                "height": "21.00",
                "id": "product_62",
                "img": "Wal-On-Water.png\r",
                "imgNew": "Wal-On-Water.png\r",
                "imgAnchor": "undefined",
                "model": "Walk_on_Water",
                "price": "1634.21",
                "relProducts": "66,63,64",
                "rotation": 90,
                "title": "Walk On Water 20",
                "width": "84.00",
                "xPos": 861,
                "xPos3D": 903,
                "yPos": "318.50",
                "yPos3D": "329.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2121.5",
                "relProducts": "44,12,54",
                "rotation": 0,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": "487.72",
                "xPos3D": "509.00",
                "yPos": "435.92",
                "yPos3D": "453.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "1",
                "category": "Core 10ft",
                "depth": "1.43",
                "folder": "Lugeway10-2",
                "height": "42.70",
                "id": "product_177",
                "img": "Lugeway10.png",
                "imgNew": "Lugeway10.png",
                "imgAnchor": "Lugeway10withanchors.png",
                "model": "LWay_10",
                "price": "1526.82",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Lugeway 10",
                "width": "22.12",
                "xPos": "364.94",
                "xPos3D": "376.00",
                "yPos": "434.65",
                "yPos3D": "456.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.46",
                "folder": "30Sierra",
                "height": "42.70",
                "id": "product_53",
                "img": "Sierra10.png",
                "imgNew": "Sierra10.png",
                "imgAnchor": "Sierra10withanchors.png",
                "model": "Sierra_10",
                "price": "1498.2",
                "relProducts": "52,44,45",
                "rotation": 90,
                "title": "Sierra 10",
                "width": "34.16",
                "xPos": "423.92",
                "xPos3D": "441.00",
                "yPos": "432.65",
                "yPos3D": "454.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "barricade",
                "height": "27.72",
                "id": "product_12",
                "img": "Barricade10.png",
                "imgNew": "Barricade10.png",
                "imgAnchor": "Barricade10withanchors.png",
                "model": "Barricade_10",
                "price": "1563.06",
                "relProducts": "11,47,44",
                "rotation": 0,
                "title": "Barricade 10",
                "width": "42.56",
                "xPos": "291.72",
                "xPos3D": "313.00",
                "yPos": "440.14",
                "yPos3D": "454.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "overpass",
                "height": "25.62",
                "id": "product_44",
                "img": "Overpass10.png",
                "imgNew": "Overpass10.png",
                "imgAnchor": "Overpass10withanchors.png",
                "model": "Overpass",
                "price": "1340.82",
                "relProducts": "47,45,52",
                "rotation": 0,
                "title": "Overpass 10",
                "width": "42.56",
                "xPos": 263.72,
                "xPos3D": 285,
                "yPos": 146.19,
                "yPos3D": 159,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "parkway10",
                "height": "27.72",
                "id": "product_47",
                "img": "Parkway10.png",
                "imgNew": "Parkway10.png",
                "imgAnchor": "Parkway1010withanchors.png",
                "model": "PWay_10",
                "price": "1165.2",
                "relProducts": "44,45,12",
                "rotation": 0,
                "title": "Parkway 10",
                "width": "42.56",
                "xPos": 563.72,
                "xPos3D": 585,
                "yPos": 634.14,
                "yPos3D": 648,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3088.21",
                "relProducts": ",,",
                "rotation": 135,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": "155.66",
                "xPos3D": "188.00",
                "yPos": "214.82",
                "yPos3D": "234.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2078.65",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 785.66,
                "xPos3D": 818,
                "yPos": 486.88,
                "yPos3D": 502,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 45,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 766.66,
                "xPos3D": 799,
                "yPos": 156.88,
                "yPos3D": 172,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 45,
                "title": "Bendback",
                "width": "64.68",
                "xPos": "858.66",
                "xPos3D": "891.00",
                "yPos": "247.88",
                "yPos3D": "263.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 315,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 184.66,
                "xPos3D": 217,
                "yPos": 155.88,
                "yPos3D": 171,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 45,
                "title": "Bendback",
                "width": "64.68",
                "xPos": "156.66",
                "xPos3D": "189.00",
                "yPos": "485.88",
                "yPos3D": "501.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 135,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 761.66,
                "xPos3D": 794,
                "yPos": 618.88,
                "yPos3D": 634,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1467.54",
                "relProducts": "17,63,44",
                "rotation": 225,
                "title": "Kickback",
                "width": "64.68",
                "xPos": "181.66",
                "xPos3D": "214.00",
                "yPos": "618.88",
                "yPos3D": "634.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1467.54",
                "relProducts": "17,63,44",
                "rotation": 225,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 792.66,
                "xPos3D": 825,
                "yPos": 224.88,
                "yPos3D": 240,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1467.54",
                "relProducts": "17,63,44",
                "rotation": 315,
                "title": "Kickback",
                "width": "64.68",
                "xPos": "82.66",
                "xPos3D": "115.00",
                "yPos": "248.88",
                "yPos3D": "264.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "2924.24",
                "relProducts": "34,35,33",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 875.05,
                "xPos3D": 902,
                "yPos": 371.05,
                "yPos3D": 398,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "2924.24",
                "relProducts": "34,35,33",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": "75.05",
                "xPos3D": "102.00",
                "yPos": "373.05",
                "yPos3D": "400.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "2924.24",
                "relProducts": "34,35,33",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": "482.05",
                "xPos3D": "509.00",
                "yPos": "58.05",
                "yPos3D": "85.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2366.49",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 742.05,
                "xPos3D": 769,
                "yPos": 426.05,
                "yPos3D": 453,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2366.49",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 482.05,
                "xPos3D": 509,
                "yPos": 622.05,
                "yPos3D": 649,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2366.49",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 216.05,
                "xPos3D": 243,
                "yPos": 426.05,
                "yPos3D": 453,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2366.49",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 483.05,
                "xPos3D": 510,
                "yPos": 209.05,
                "yPos3D": 236,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.76",
                "folder": "Universal_keyhole",
                "height": "53.20",
                "id": "product_210",
                "img": "universal_keyhole.png",
                "imgNew": "universal_keyhole.png",
                "imgAnchor": "undefined",
                "model": "Universal_keyhole",
                "price": "1966.04",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Keyhole",
                "width": "53.20",
                "xPos": 483.4,
                "xPos3D": 510,
                "yPos": 134.4,
                "yPos3D": 161,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1299.23",
                "relProducts": "65,66,68",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 795.01,
                "xPos3D": 806,
                "yPos": 525.72,
                "yPos3D": 568,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1299.23",
                "relProducts": "65,66,68",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": "190.01",
                "xPos3D": "201.00",
                "yPos": "523.72",
                "yPos3D": "566.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1299.23",
                "relProducts": "65,66,68",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 92.01,
                "xPos3D": 103,
                "yPos": 287.72,
                "yPos3D": 330,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "534.01",
                "xPos3D": "545.00",
                "yPos": "593.65",
                "yPos3D": "615.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "542.01",
                "xPos3D": "553.00",
                "yPos": "433.65",
                "yPos3D": "455.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "672.01",
                "xPos3D": "683.00",
                "yPos": "658.65",
                "yPos3D": "680.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 315,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "808.01",
                "xPos3D": "819.00",
                "yPos": "638.65",
                "yPos3D": "660.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "173.01",
                "xPos3D": "184.00",
                "yPos": "638.65",
                "yPos3D": "660.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 315,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "461.01",
                "xPos3D": "472.00",
                "yPos": "593.65",
                "yPos3D": "615.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "766.01",
                "xPos3D": "777.00",
                "yPos": "171.65",
                "yPos3D": "193.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "499.01",
                "xPos3D": "510.00",
                "yPos": "578.65",
                "yPos3D": "600.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "861.01",
                "xPos3D": "872.00",
                "yPos": "262.65",
                "yPos3D": "284.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 497.01,
                "xPos3D": 508,
                "yPos": 676.65,
                "yPos3D": 698,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 499.01,
                "xPos3D": 510,
                "yPos": "391.65",
                "yPos3D": "413.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "287.01",
                "xPos3D": "298.00",
                "yPos": "662.65",
                "yPos3D": "684.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 315,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 225.01,
                "xPos3D": 236,
                "yPos": 169.65,
                "yPos3D": 191,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 45,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "115.14",
                "xPos3D": "129.00",
                "yPos": "267.36",
                "yPos3D": "278.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "535.14",
                "xPos3D": "549.00",
                "yPos": "149.36",
                "yPos3D": "160.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 45,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 783.14,
                "xPos3D": 797,
                "yPos": 470.36,
                "yPos3D": 481,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "89.14",
                "xPos3D": "103.00",
                "yPos": "429.36",
                "yPos3D": "440.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 455.14,
                "xPos3D": 469,
                "yPos": "148.36",
                "yPos3D": "159.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "888.14",
                "xPos3D": "902.00",
                "yPos": "427.36",
                "yPos3D": "438.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 495.14,
                "xPos3D": 509,
                "yPos": 261.36,
                "yPos3D": 272,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "453.14",
                "xPos3D": "467.00",
                "yPos": "636.36",
                "yPos3D": "647.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 536.14,
                "xPos3D": 550,
                "yPos": 637.36,
                "yPos3D": 648,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "139.14",
                "xPos3D": "153.00",
                "yPos": "239.36",
                "yPos3D": "250.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "139.14",
                "xPos3D": "153.00",
                "yPos": "478.36",
                "yPos3D": "489.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 315,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "196.14",
                "xPos3D": "210.00",
                "yPos": "469.36",
                "yPos3D": "480.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "624.34",
                "relProducts": "68,64,65",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 840.14,
                "xPos3D": 854,
                "yPos": 477.36,
                "yPos3D": 488,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 241.36,
                "xPos3D": 252,
                "yPos": 147.36,
                "yPos3D": 158,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 307.36,
                "xPos3D": 318,
                "yPos": 148.36,
                "yPos3D": 159,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "192.36",
                "xPos3D": "203.00",
                "yPos": "192.36",
                "yPos3D": "203.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 499.36,
                "xPos3D": 510,
                "yPos": "188.36",
                "yPos3D": "199.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 647.36,
                "xPos3D": 658,
                "yPos": 149.36,
                "yPos3D": 160,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "464.36",
                "xPos3D": "475.00",
                "yPos": "441.36",
                "yPos3D": "452.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "499.36",
                "xPos3D": "510.00",
                "yPos": "113.36",
                "yPos3D": "124.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 656.36,
                "xPos3D": 667,
                "yPos": "441.36",
                "yPos3D": "452.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "732.36",
                "xPos3D": "743.00",
                "yPos": "269.36",
                "yPos3D": "280.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "498.36",
                "xPos3D": "509.00",
                "yPos": 470.36,
                "yPos3D": 481,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 145.36,
                "xPos3D": 156,
                "yPos": 337.36,
                "yPos3D": 348,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "269.36",
                "xPos3D": "280.00",
                "yPos": "443.36",
                "yPos3D": "454.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "800.36",
                "xPos3D": "811.00",
                "yPos": "195.36",
                "yPos3D": "206.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "753.36",
                "xPos3D": "764.00",
                "yPos": 148.36,
                "yPos3D": 159,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "236.36",
                "xPos3D": "247.00",
                "yPos": "636.36",
                "yPos3D": "647.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "333.36",
                "xPos3D": "344.00",
                "yPos": "444.36",
                "yPos3D": "455.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "602.36",
                "xPos3D": "613.00",
                "yPos": "635.36",
                "yPos3D": "646.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "398.36",
                "xPos3D": "409.00",
                "yPos": "443.36",
                "yPos3D": "454.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 749.36,
                "xPos3D": 760,
                "yPos": 636.36,
                "yPos3D": 647,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 720.36,
                "xPos3D": 731,
                "yPos": 441.36,
                "yPos3D": 452,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "677.36",
                "xPos3D": "688.00",
                "yPos": "214.36",
                "yPos3D": "225.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "344.36",
                "xPos3D": "355.00",
                "yPos": "636.36",
                "yPos3D": "647.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "847.36",
                "xPos3D": "858.00",
                "yPos": "241.36",
                "yPos3D": "252.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "397.36",
                "xPos3D": "408.00",
                "yPos": "583.36",
                "yPos3D": "594.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "1",
                "category": "Slides",
                "depth": "2.74",
                "folder": "37EscaladeSummit",
                "height": "19.60",
                "id": "product_22",
                "img": "Summit-esc.png",
                "imgNew": "Summit-esc.png",
                "imgAnchor": "undefined",
                "model": "Esc_Sum",
                "price": "2670.76",
                "relProducts": "21,,",
                "rotation": 225,
                "title": "Escalade Summit Climbing Wall",
                "width": "29.40",
                "xPos": 184.3,
                "xPos3D": 199,
                "yPos": 298.2,
                "yPos3D": 308,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "791.36",
                "xPos3D": "802.00",
                "yPos": "274.36",
                "yPos3D": "285.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "583.36",
                "xPos3D": "594.00",
                "yPos": "587.36",
                "yPos3D": "598.00",
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "location": "United Kingdom",
            "currency": "GBP",
            "name": "Circuit Monsoon M 100",
            "parkID": "8wAUjW2akagUjZlgIqIZQCaoEM43CircuitMonsoonM100",
            "text": "Park Size: 100m x 100m",
            "height": 1400,
            "width": 1400,
            "parkDepth": 5,
            "timeStamp": "last updated: Wednesday, February 17, 2021, 3:48 PM",
            "parkIcon": "anvil.png"
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Slides",
                "depth": "2.44",
                "folder": "51KingoftheMountian",
                "height": "56.00",
                "id": "product_28",
                "img": "kom.png",
                "imgNew": "kom.png",
                "imgAnchor": "KOMwithanchors.png",
                "model": "KingOTM",
                "price": "3934.95",
                "relProducts": "26,25,34",
                "rotation": 90,
                "title": "King of the Mountain (set)",
                "width": "37.80",
                "xPos": 70.1,
                "xPos3D": 89,
                "yPos": "320.00",
                "yPos3D": "348.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 141.01,
                "xPos3D": 152,
                "yPos": 371.65,
                "yPos3D": 393,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 143.01,
                "xPos3D": 154,
                "yPos": 285.65,
                "yPos3D": 307,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 613.01,
                "xPos3D": 624,
                "yPos": 285.65,
                "yPos3D": 307,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 614.01,
                "xPos3D": 625,
                "yPos": 372.65,
                "yPos3D": 394,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 227.01,
                "xPos3D": 238,
                "yPos": 479.65,
                "yPos3D": 501,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 443.01,
                "xPos3D": 454,
                "yPos": 372.65,
                "yPos3D": 394,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "443.01",
                "xPos3D": "454.00",
                "yPos": "287.65",
                "yPos3D": "309.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 310.01,
                "xPos3D": 321,
                "yPos": 287.65,
                "yPos3D": 309,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 530.01,
                "xPos3D": 541,
                "yPos": 477.65,
                "yPos3D": 499,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 311.01,
                "xPos3D": 322,
                "yPos": 373.65,
                "yPos3D": 395,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "barricade",
                "height": "27.72",
                "id": "product_12",
                "img": "Barricade10.png",
                "imgNew": "Barricade10.png",
                "imgAnchor": "Barricade10withanchors.png",
                "model": "Barricade_10",
                "price": "1563.06",
                "relProducts": "11,47,44",
                "rotation": 90,
                "title": "Barricade 10",
                "width": "42.56",
                "xPos": 131.72,
                "xPos3D": 153,
                "yPos": 336.14,
                "yPos3D": 350,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.14",
                "folder": "Figure_8",
                "height": "44.80",
                "id": "product_212",
                "img": "figure_8.png",
                "imgNew": "figure_8.png",
                "imgAnchor": "undefined",
                "model": "Figure_8",
                "price": "1890.42",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Figure 8 20",
                "width": "84.00",
                "xPos": 345,
                "xPos3D": 387,
                "yPos": 239.6,
                "yPos3D": 262,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2495.36",
                "relProducts": "51,49,31",
                "rotation": 90,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": "345.30",
                "xPos3D": "388.00",
                "yPos": 420.65,
                "yPos3D": 442,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "2924.24",
                "relProducts": "34,35,33",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": "359.05",
                "xPos3D": "386.00",
                "yPos": 144.05,
                "yPos3D": 171,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "2.17",
                "folder": "UniversalArchway",
                "height": "53.90",
                "id": "product_179",
                "img": "universalarchway.png",
                "imgNew": "universalarchway.png",
                "imgAnchor": "UniversalArchwaywithanchors.png",
                "model": "Uni_Archway",
                "price": "3512.38",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Universal Archway",
                "width": "53.90",
                "xPos": 360.05,
                "xPos3D": 387,
                "yPos": 325.05,
                "yPos3D": 352,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.38",
                "folder": "Universal_icecap",
                "height": "53.20",
                "id": "product_209",
                "img": "universal_icecap.png",
                "imgNew": "universal_icecap.png",
                "imgAnchor": "undefined",
                "model": "Universal_icecap",
                "price": "1814.81",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Icecap",
                "width": "53.20",
                "xPos": 513.4,
                "xPos3D": 540,
                "yPos": 424.4,
                "yPos3D": 451,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2366.49",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 210.05,
                "xPos3D": 237,
                "yPos": 224.05,
                "yPos3D": 251,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.06",
                "folder": "UniversalPavilion",
                "height": "53.90",
                "id": "product_180",
                "img": "universalpavilion.png",
                "imgNew": "universalpavilion.png",
                "imgAnchor": "UniversalPavilionwithanchors.png",
                "model": "Uni_Pavilion",
                "price": "2307.97",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Pavilion",
                "width": "53.90",
                "xPos": 210.05,
                "xPos3D": 237,
                "yPos": "425.05",
                "yPos3D": "452.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "3.34",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8231.44",
                "relProducts": "23,20,25",
                "rotation": 180,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": "519.82",
                "xPos3D": "539.00",
                "yPos": 76.37,
                "yPos3D": 140,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2078.65",
                "relProducts": ",,",
                "rotation": 45,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 275.66,
                "xPos3D": 308,
                "yPos": 248.88,
                "yPos3D": 264,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 225,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 132.66,
                "xPos3D": 165,
                "yPos": 422.88,
                "yPos3D": 438,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1467.54",
                "relProducts": "17,63,44",
                "rotation": 135,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 276.66,
                "xPos3D": 309,
                "yPos": "424.88",
                "yPos3D": "440.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 225,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 435.66,
                "xPos3D": 468,
                "yPos": 422.88,
                "yPos3D": 438,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1467.54",
                "relProducts": "17,63,44",
                "rotation": 135,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 579.66,
                "xPos3D": 612,
                "yPos": 423.88,
                "yPos3D": 439,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Jump",
                "depth": "2.43",
                "folder": "Anvil",
                "height": "63.00",
                "id": "product_207",
                "img": "anvil.png",
                "imgNew": "anvil.png",
                "imgAnchor": "undefined",
                "model": "Anvil",
                "price": "7676.88",
                "relProducts": ",,",
                "rotation": 45,
                "title": "Anvil",
                "width": "63.00",
                "xPos": 589.5,
                "xPos3D": 621,
                "yPos": 220.5,
                "yPos3D": 252,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Jump",
                "depth": "2.12",
                "folder": "Hammer",
                "height": "50.40",
                "id": "product_208",
                "img": "hammer.png",
                "imgNew": "hammer.png",
                "imgAnchor": "undefined",
                "model": "Hammer",
                "price": "6141.5",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Hammer",
                "width": "63.00",
                "xPos": 127.5,
                "xPos3D": 159,
                "yPos": 230.8,
                "yPos3D": 256,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 490.36,
                "xPos3D": 501,
                "yPos": 240.36,
                "yPos3D": 251,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 491.36,
                "xPos3D": 502,
                "yPos": 440.36,
                "yPos3D": 451,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 566.36,
                "xPos3D": 577,
                "yPos": 440.36,
                "yPos3D": 451,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 117.36,
                "xPos3D": 128,
                "yPos": "338.36",
                "yPos3D": "349.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 376.36,
                "xPos3D": 387,
                "yPos": 378.36,
                "yPos3D": 389,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 376.36,
                "xPos3D": 387,
                "yPos": 303.36,
                "yPos3D": 314,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 638.36,
                "xPos3D": 649,
                "yPos": 340.36,
                "yPos3D": 351,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 528.36,
                "xPos3D": 539,
                "yPos": 203.36,
                "yPos3D": 214,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 414.36,
                "xPos3D": 425,
                "yPos": 341.36,
                "yPos3D": 352,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 264.36,
                "xPos3D": 275,
                "yPos": "441.36",
                "yPos3D": "452.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 189.36,
                "xPos3D": 200,
                "yPos": 240.36,
                "yPos3D": 251,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 263.36,
                "xPos3D": 274,
                "yPos": "241.36",
                "yPos3D": "252.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 227.36,
                "xPos3D": 238,
                "yPos": "203.36",
                "yPos3D": "214.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 188.36,
                "xPos3D": 199,
                "yPos": 440.36,
                "yPos3D": 451,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 338.36,
                "xPos3D": 349,
                "yPos": 342.36,
                "yPos3D": 353,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 376.36,
                "xPos3D": 387,
                "yPos": 198.36,
                "yPos3D": 209,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "376.36",
                "xPos3D": "387.00",
                "yPos": 484.36,
                "yPos3D": 495,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 558.36,
                "xPos3D": 569,
                "yPos": 136.36,
                "yPos3D": 147,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 565.36,
                "xPos3D": 576,
                "yPos": 239.36,
                "yPos3D": 250,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2366.49",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": "512.05",
                "xPos3D": "539.00",
                "yPos": 224.05,
                "yPos3D": 251,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3088.21",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": 436.66,
                "xPos3D": 469,
                "yPos": 246.82,
                "yPos3D": 266,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "parkway10",
                "height": "27.72",
                "id": "product_47",
                "img": "Parkway10.png",
                "imgNew": "Parkway10.png",
                "imgAnchor": "Parkway1010withanchors.png",
                "model": "PWay_10",
                "price": "1165.2",
                "relProducts": "44,45,12",
                "rotation": 90,
                "title": "Parkway 10",
                "width": "42.56",
                "xPos": 602.72,
                "xPos3D": 624,
                "yPos": 337.14,
                "yPos3D": 351,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.46",
                "folder": "30Sierra",
                "height": "42.70",
                "id": "product_53",
                "img": "Sierra10.png",
                "imgNew": "Sierra10.png",
                "imgAnchor": "Sierra10withanchors.png",
                "model": "Sierra_10",
                "price": "1498.2",
                "relProducts": "52,44,45",
                "rotation": 0,
                "title": "Sierra 10",
                "width": "34.16",
                "xPos": 435.92,
                "xPos3D": 453,
                "yPos": 329.65,
                "yPos3D": 351,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2121.5",
                "relProducts": "44,12,54",
                "rotation": 270,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 299.72,
                "xPos3D": 321,
                "yPos": 334.92,
                "yPos3D": 352,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Swing & Climb",
                "depth": "1.93",
                "folder": "01JungleGym",
                "height": "42.00",
                "id": "product_26",
                "img": "JungleGym102020.png",
                "imgNew": "JungleGym102020.png",
                "imgAnchor": "JungleGym102020withanchors.png",
                "model": "Jungle_Jim",
                "price": "2610.74",
                "relProducts": "34,25,53",
                "rotation": 0,
                "title": "Jungle Jim",
                "width": "37.80",
                "xPos": 660.1,
                "xPos3D": 679,
                "yPos": 328,
                "yPos3D": 349,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Slides",
                "depth": "2.44",
                "folder": "24BastBag",
                "height": "51.24",
                "id": "product_27",
                "img": "blastbag.png",
                "imgNew": "blastbag.png",
                "imgAnchor": "BlastBagwithanchors.png",
                "model": "Blastbag",
                "price": "504.16",
                "relProducts": "89,25,82",
                "rotation": 0,
                "title": "Blast Bag",
                "width": "23.10",
                "xPos": 89.45,
                "xPos3D": 101,
                "yPos": 366.38,
                "yPos3D": 392,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.5",
                "folder": "LokiCentre",
                "height": "117.32",
                "id": "product_186",
                "img": "loki.png",
                "imgNew": "loki.png",
                "imgAnchor": "Lokiwithanchors.png",
                "model": "Loki",
                "price": "9231.31",
                "relProducts": "165,4,14",
                "rotation": 180,
                "title": "Loki",
                "width": "64.68",
                "xPos": "206.66",
                "xPos3D": "239.00",
                "yPos": 85.34,
                "yPos3D": 144,
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "Ring Monsoon M 100",
            "parkID": "8wAUjW2akagUjZlgIqIZQCaoEM43RingMonsoonM100",
            "text": "Park Size: 100m x 100m",
            "height": 1400,
            "width": 1400,
            "parkDepth": 5,
            "timeStamp": "last updated: Thursday, February 18, 2021, 11:13 PM",
            "parkIcon": "anvil.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Slides",
                "depth": "2.44",
                "folder": "51KingoftheMountian",
                "height": "56.00",
                "id": "product_28",
                "img": "kom.png",
                "imgNew": "kom.png",
                "imgAnchor": "KOMwithanchors.png",
                "model": "KingOTM",
                "price": "3934.95",
                "relProducts": "26,25,34",
                "rotation": 90,
                "title": "King of the Mountain (set)",
                "width": "37.80",
                "xPos": 70.1,
                "xPos3D": 89,
                "yPos": "320.00",
                "yPos3D": "348.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 141.01,
                "xPos3D": 152,
                "yPos": 371.65,
                "yPos3D": 393,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 143.01,
                "xPos3D": 154,
                "yPos": 285.65,
                "yPos3D": 307,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 613.01,
                "xPos3D": 624,
                "yPos": 285.65,
                "yPos3D": 307,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 614.01,
                "xPos3D": 625,
                "yPos": 372.65,
                "yPos3D": 394,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 227.01,
                "xPos3D": 238,
                "yPos": 479.65,
                "yPos3D": 501,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 443.01,
                "xPos3D": 454,
                "yPos": 372.65,
                "yPos3D": 394,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "443.01",
                "xPos3D": "454.00",
                "yPos": "287.65",
                "yPos3D": "309.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 310.01,
                "xPos3D": 321,
                "yPos": 287.65,
                "yPos3D": 309,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 530.01,
                "xPos3D": 541,
                "yPos": 477.65,
                "yPos3D": 499,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 311.01,
                "xPos3D": 322,
                "yPos": 373.65,
                "yPos3D": 395,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "barricade",
                "height": "27.72",
                "id": "product_12",
                "img": "Barricade10.png",
                "imgNew": "Barricade10.png",
                "imgAnchor": "Barricade10withanchors.png",
                "model": "Barricade_10",
                "price": "1563.06",
                "relProducts": "11,47,44",
                "rotation": 90,
                "title": "Barricade 10",
                "width": "42.56",
                "xPos": 131.72,
                "xPos3D": 153,
                "yPos": 336.14,
                "yPos3D": 350,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.14",
                "folder": "Figure_8",
                "height": "44.80",
                "id": "product_212",
                "img": "figure_8.png",
                "imgNew": "figure_8.png",
                "imgAnchor": "undefined",
                "model": "Figure_8",
                "price": "1890.42",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Figure 8 20",
                "width": "84.00",
                "xPos": 345,
                "xPos3D": 387,
                "yPos": 239.6,
                "yPos3D": 262,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2495.36",
                "relProducts": "51,49,31",
                "rotation": 90,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": "345.30",
                "xPos3D": "388.00",
                "yPos": 420.65,
                "yPos3D": 442,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "2924.24",
                "relProducts": "34,35,33",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": "359.05",
                "xPos3D": "386.00",
                "yPos": 144.05,
                "yPos3D": 171,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "2.17",
                "folder": "UniversalArchway",
                "height": "53.90",
                "id": "product_179",
                "img": "universalarchway.png",
                "imgNew": "universalarchway.png",
                "imgAnchor": "UniversalArchwaywithanchors.png",
                "model": "Uni_Archway",
                "price": "3512.38",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Universal Archway",
                "width": "53.90",
                "xPos": 360.05,
                "xPos3D": 387,
                "yPos": 325.05,
                "yPos3D": 352,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.38",
                "folder": "Universal_icecap",
                "height": "53.20",
                "id": "product_209",
                "img": "universal_icecap.png",
                "imgNew": "universal_icecap.png",
                "imgAnchor": "undefined",
                "model": "Universal_icecap",
                "price": "1814.81",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Icecap",
                "width": "53.20",
                "xPos": 513.4,
                "xPos3D": 540,
                "yPos": 424.4,
                "yPos3D": 451,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2366.49",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 210.05,
                "xPos3D": 237,
                "yPos": 224.05,
                "yPos3D": 251,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.06",
                "folder": "UniversalPavilion",
                "height": "53.90",
                "id": "product_180",
                "img": "universalpavilion.png",
                "imgNew": "universalpavilion.png",
                "imgAnchor": "UniversalPavilionwithanchors.png",
                "model": "Uni_Pavilion",
                "price": "2307.97",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Pavilion",
                "width": "53.90",
                "xPos": 210.05,
                "xPos3D": 237,
                "yPos": "425.05",
                "yPos3D": "452.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "3.34",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8231.44",
                "relProducts": "23,20,25",
                "rotation": 180,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": "519.82",
                "xPos3D": "539.00",
                "yPos": 76.37,
                "yPos3D": 140,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2078.65",
                "relProducts": ",,",
                "rotation": 45,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 275.66,
                "xPos3D": 308,
                "yPos": 248.88,
                "yPos3D": 264,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 225,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 132.66,
                "xPos3D": 165,
                "yPos": 422.88,
                "yPos3D": 438,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1467.54",
                "relProducts": "17,63,44",
                "rotation": 135,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 276.66,
                "xPos3D": 309,
                "yPos": "424.88",
                "yPos3D": "440.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1364.45",
                "relProducts": "18,63,34",
                "rotation": 225,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 435.66,
                "xPos3D": 468,
                "yPos": 422.88,
                "yPos3D": 438,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1467.54",
                "relProducts": "17,63,44",
                "rotation": 135,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 579.66,
                "xPos3D": 612,
                "yPos": 423.88,
                "yPos3D": 439,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Jump",
                "depth": "2.43",
                "folder": "Anvil",
                "height": "63.00",
                "id": "product_207",
                "img": "anvil.png",
                "imgNew": "anvil.png",
                "imgAnchor": "undefined",
                "model": "Anvil",
                "price": "7676.88",
                "relProducts": ",,",
                "rotation": 45,
                "title": "Anvil",
                "width": "63.00",
                "xPos": 589.5,
                "xPos3D": 621,
                "yPos": 220.5,
                "yPos3D": 252,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Jump",
                "depth": "2.12",
                "folder": "Hammer",
                "height": "50.40",
                "id": "product_208",
                "img": "hammer.png",
                "imgNew": "hammer.png",
                "imgAnchor": "undefined",
                "model": "Hammer",
                "price": "6141.5",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Hammer",
                "width": "63.00",
                "xPos": 127.5,
                "xPos3D": 159,
                "yPos": 230.8,
                "yPos3D": 256,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 490.36,
                "xPos3D": 501,
                "yPos": 240.36,
                "yPos3D": 251,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 491.36,
                "xPos3D": 502,
                "yPos": 440.36,
                "yPos3D": 451,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 566.36,
                "xPos3D": 577,
                "yPos": 440.36,
                "yPos3D": 451,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 117.36,
                "xPos3D": 128,
                "yPos": "338.36",
                "yPos3D": "349.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 376.36,
                "xPos3D": 387,
                "yPos": 378.36,
                "yPos3D": 389,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 376.36,
                "xPos3D": 387,
                "yPos": 303.36,
                "yPos3D": 314,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 638.36,
                "xPos3D": 649,
                "yPos": 340.36,
                "yPos3D": 351,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 528.36,
                "xPos3D": 539,
                "yPos": 203.36,
                "yPos3D": 214,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 414.36,
                "xPos3D": 425,
                "yPos": 341.36,
                "yPos3D": 352,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 264.36,
                "xPos3D": 275,
                "yPos": "441.36",
                "yPos3D": "452.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 189.36,
                "xPos3D": 200,
                "yPos": 240.36,
                "yPos3D": 251,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 263.36,
                "xPos3D": 274,
                "yPos": "241.36",
                "yPos3D": "252.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 227.36,
                "xPos3D": 238,
                "yPos": "203.36",
                "yPos3D": "214.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 188.36,
                "xPos3D": 199,
                "yPos": 440.36,
                "yPos3D": 451,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 338.36,
                "xPos3D": 349,
                "yPos": 342.36,
                "yPos3D": 353,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 376.36,
                "xPos3D": 387,
                "yPos": 198.36,
                "yPos3D": 209,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "376.36",
                "xPos3D": "387.00",
                "yPos": 484.36,
                "yPos3D": 495,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 558.36,
                "xPos3D": 569,
                "yPos": 136.36,
                "yPos3D": 147,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 565.36,
                "xPos3D": 576,
                "yPos": 239.36,
                "yPos3D": 250,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2366.49",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": "512.05",
                "xPos3D": "539.00",
                "yPos": 224.05,
                "yPos3D": 251,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3088.21",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": 436.66,
                "xPos3D": 469,
                "yPos": 246.82,
                "yPos3D": 266,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "parkway10",
                "height": "27.72",
                "id": "product_47",
                "img": "Parkway10.png",
                "imgNew": "Parkway10.png",
                "imgAnchor": "Parkway1010withanchors.png",
                "model": "PWay_10",
                "price": "1165.2",
                "relProducts": "44,45,12",
                "rotation": 90,
                "title": "Parkway 10",
                "width": "42.56",
                "xPos": 602.72,
                "xPos3D": 624,
                "yPos": 337.14,
                "yPos3D": 351,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.46",
                "folder": "30Sierra",
                "height": "42.70",
                "id": "product_53",
                "img": "Sierra10.png",
                "imgNew": "Sierra10.png",
                "imgAnchor": "Sierra10withanchors.png",
                "model": "Sierra_10",
                "price": "1498.2",
                "relProducts": "52,44,45",
                "rotation": 0,
                "title": "Sierra 10",
                "width": "34.16",
                "xPos": 435.92,
                "xPos3D": 453,
                "yPos": 329.65,
                "yPos3D": 351,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2121.5",
                "relProducts": "44,12,54",
                "rotation": 270,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 299.72,
                "xPos3D": 321,
                "yPos": 334.92,
                "yPos3D": 352,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Swing & Climb",
                "depth": "1.93",
                "folder": "01JungleGym",
                "height": "42.00",
                "id": "product_26",
                "img": "JungleGym102020.png",
                "imgNew": "JungleGym102020.png",
                "imgAnchor": "JungleGym102020withanchors.png",
                "model": "Jungle_Jim",
                "price": "2610.74",
                "relProducts": "34,25,53",
                "rotation": 0,
                "title": "Jungle Jim",
                "width": "37.80",
                "xPos": 660.1,
                "xPos3D": 679,
                "yPos": 328,
                "yPos3D": 349,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Slides",
                "depth": "2.44",
                "folder": "24BastBag",
                "height": "51.24",
                "id": "product_27",
                "img": "blastbag.png",
                "imgNew": "blastbag.png",
                "imgAnchor": "BlastBagwithanchors.png",
                "model": "Blastbag",
                "price": "504.16",
                "relProducts": "89,25,82",
                "rotation": 0,
                "title": "Blast Bag",
                "width": "23.10",
                "xPos": 89.45,
                "xPos3D": 101,
                "yPos": 366.38,
                "yPos3D": 392,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.5",
                "folder": "LokiCentre",
                "height": "117.32",
                "id": "product_186",
                "img": "loki.png",
                "imgNew": "loki.png",
                "imgAnchor": "Lokiwithanchors.png",
                "model": "Loki",
                "price": "9231.31",
                "relProducts": "165,4,14",
                "rotation": 180,
                "title": "Loki",
                "width": "64.68",
                "xPos": "206.66",
                "xPos3D": "239.00",
                "yPos": 85.34,
                "yPos3D": 144,
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "Micro M 20",
            "parkID": "8wAUjW2akagUjZlgIqIZQCaoEM43MicroM20",
            "text": "Park Size: 100m x 100m",
            "height": 1400,
            "width": 1400,
            "parkDepth": 5,
            "timeStamp": "last updated: Thursday, February 18, 2021, 11:13 PM",
            "parkIcon": "kom.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-thunder-dome",
                "height": "53.90",
                "id": "product_34",
                "img": "UniversalThunderdome.png",
                "imgNew": "UniversalThunderdome.png",
                "imgAnchor": "UniversalThunderdomewithanchors.png",
                "model": "Uni_TDome",
                "price": "2579.61",
                "relProducts": "35,57,26",
                "rotation": 0,
                "title": "Universal Thunderdome",
                "width": "53.90",
                "xPos": 109.05,
                "xPos3D": 136,
                "yPos": 188.05,
                "yPos3D": 215,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Slides",
                "depth": "2.44",
                "folder": "51KingoftheMountian",
                "height": "56.00",
                "id": "product_28",
                "img": "kom.png",
                "imgNew": "kom.png",
                "imgAnchor": "KOMwithanchors.png",
                "model": "KingOTM",
                "price": "3934.95",
                "relProducts": "26,25,34",
                "rotation": 270,
                "title": "King of the Mountain (set)",
                "width": "37.80",
                "xPos": "291.10",
                "xPos3D": "310.00",
                "yPos": "188.00",
                "yPos3D": "216.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2121.5",
                "relProducts": "44,12,54",
                "rotation": 90,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 202.72,
                "xPos3D": 224,
                "yPos": "197.92",
                "yPos3D": "215.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "parkway10",
                "height": "27.72",
                "id": "product_47",
                "img": "Parkway10.png",
                "imgNew": "Parkway10.png",
                "imgAnchor": "Parkway1010withanchors.png",
                "model": "PWay_10",
                "price": "1165.2",
                "relProducts": "44,45,12",
                "rotation": 270,
                "title": "Parkway 10",
                "width": "42.56",
                "xPos": "201.72",
                "xPos3D": "223.00",
                "yPos": 137.14,
                "yPos3D": 151,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "212.36",
                "xPos3D": "223.00",
                "yPos": "107.36",
                "yPos3D": "118.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "579.35",
                "relProducts": "67,65,64",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 212.36,
                "xPos3D": 223,
                "yPos": 172.36,
                "yPos3D": 183,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "251.01",
                "xPos3D": "262.00",
                "yPos": "192.65",
                "yPos3D": "214.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "174.01",
                "xPos3D": "185.00",
                "yPos": "192.65",
                "yPos3D": "214.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "875.2",
                "relProducts": "64,66,68",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "212.01",
                "xPos3D": "223.00",
                "yPos": "236.65",
                "yPos3D": "258.00",
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Challenge Track 40",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023ChallengeTrack40",
            "text": "Park Size: 100m x 100m",
            "height": 2343.2,
            "width": 1959,
            "parkDepth": 5,
            "parkIcon": "I-HOP20.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "8",
                "brocap": "12",
                "category": "Jump",
                "depth": "2.84",
                "folder": "Thor",
                "height": "63.00",
                "id": "product_206",
                "img": "thor.png",
                "imgNew": "thor.png",
                "imgAnchor": "Thor-Anchor.png",
                "model": "Thor",
                "price": "13527.2328",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Thor",
                "width": "126.00",
                "xPos": "1236.60",
                "xPos3D": "1299.60",
                "yPos": 947.5,
                "yPos3D": 979,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "2.8",
                "folder": "14FreefallSupreme",
                "height": "109.20",
                "id": "product_20",
                "img": "freefallsupreme.png",
                "imgNew": "freefallsupreme.png",
                "imgAnchor": "FreefallSupremewithanchors.png",
                "model": "Freefall_Sup",
                "price": "10200",
                "relProducts": "0,19,50",
                "rotation": 90,
                "title": "Freefall Supreme",
                "width": "53.20",
                "xPos": 1418,
                "xPos3D": 1444.6,
                "yPos": 925.4,
                "yPos3D": 980,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Swing & Climb",
                "depth": "1.93",
                "folder": "01JungleGym",
                "height": "42.00",
                "id": "product_26",
                "img": "JungleGym102020.png",
                "imgNew": "JungleGym102020.png",
                "imgAnchor": "JungleGym102020withanchors.png",
                "model": "Jungle_Jim",
                "price": "2960.84023",
                "relProducts": "0,34,25",
                "rotation": 90,
                "title": "Jungle Jim",
                "width": "37.80",
                "xPos": 1168.7,
                "xPos3D": 1187.6,
                "yPos": 959,
                "yPos3D": 980,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2856.94482",
                "relProducts": "0,51,49",
                "rotation": 180,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": 1052.9,
                "xPos3D": 1095.6,
                "yPos": 957.65,
                "yPos3D": 979,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.64",
                "folder": "31Vista",
                "height": "42.70",
                "id": "product_52",
                "img": "Vista10.png",
                "imgNew": "Vista10.png",
                "imgAnchor": "Vista10withanchors.png",
                "model": "Vista_10",
                "price": "2546.21483",
                "relProducts": "0,53,45",
                "rotation": 90,
                "title": "Vista 10",
                "width": "27.72",
                "xPos": 919.74,
                "xPos3D": 933.6,
                "yPos": "956.65",
                "yPos3D": "978.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 0,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 982.32,
                "xPos3D": 1003.6,
                "yPos": 960.92,
                "yPos3D": 978,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "884.74",
                "xPos3D": "898.60",
                "yPos": "967.36",
                "yPos3D": "978.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 954.74,
                "xPos3D": 968.6,
                "yPos": 967.36,
                "yPos3D": 978,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1024.74,
                "xPos3D": 1038.6,
                "yPos": 967.36,
                "yPos3D": 978,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1362.74,
                "xPos3D": 1376.6,
                "yPos": 969.36,
                "yPos3D": 980,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "1208.74",
                "xPos3D": "1222.60",
                "yPos": 970.36,
                "yPos3D": 981,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1138.74,
                "xPos3D": 1152.6,
                "yPos": 969.36,
                "yPos3D": 980,
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Micro Park 40",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023MicroPark20",
            "text": "Park Size: 100m x 100m",
            "height": 700,
            "width": 700,
            "parkDepth": 5,
            "parkIcon": "thunderdome.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Slides",
                "depth": "2.44",
                "folder": "51KingoftheMountian",
                "height": "56.00",
                "id": "product_28",
                "img": "kom.png",
                "imgNew": "kom.png",
                "imgAnchor": "KOMwithanchors.png",
                "model": "KingOTM",
                "price": "4462.62417",
                "relProducts": "0,26,25",
                "rotation": 270,
                "title": "King of the Mountain (set)",
                "width": "37.80",
                "xPos": 259.1,
                "xPos3D": 278,
                "yPos": 159,
                "yPos3D": 187,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-thunder-dome",
                "height": "53.90",
                "id": "product_34",
                "img": "UniversalThunderdome.png",
                "imgNew": "UniversalThunderdome.png",
                "imgAnchor": "UniversalThunderdomewithanchors.png",
                "model": "Uni_TDome",
                "price": "2929.60523",
                "relProducts": "0,35,57",
                "rotation": 0,
                "title": "Universal Thunderdome",
                "width": "53.90",
                "xPos": 67.05,
                "xPos3D": 94,
                "yPos": 159.05,
                "yPos3D": 186,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 0,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": "163.72",
                "xPos3D": "185.00",
                "yPos": "169.92",
                "yPos3D": "187.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "parkway10",
                "height": "27.72",
                "id": "product_47",
                "img": "Parkway10.png",
                "imgNew": "Parkway10.png",
                "imgAnchor": "Parkway1010withanchors.png",
                "model": "PWay_10",
                "price": "1200.63527",
                "relProducts": "0,44,45",
                "rotation": 90,
                "title": "Parkway 10",
                "width": "42.56",
                "xPos": 163.72,
                "xPos3D": 185,
                "yPos": 114.14,
                "yPos3D": 128,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 131.01,
                "xPos3D": 142,
                "yPos": "164.65",
                "yPos3D": "186.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 180,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 174.01,
                "xPos3D": 185,
                "yPos": 203.65,
                "yPos3D": 225,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "217.01",
                "xPos3D": "228.00",
                "yPos": 165.65,
                "yPos3D": 187,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 174.36,
                "xPos3D": 185,
                "yPos": 149.36,
                "yPos3D": 160,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 174.36,
                "xPos3D": 185,
                "yPos": 85.36,
                "yPos3D": 96,
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Serpentine Monsoon Park 100 ",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023SerpentineMonsoonPark100",
            "text": "Park Size: 100m x 100m",
            "height": 2575,
            "width": 2070,
            "parkDepth": 5,
            "parkIcon": "UniversalMonsoon.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Jump",
                "depth": "2.43",
                "folder": "Anvil",
                "height": "63.00",
                "id": "product_207",
                "img": "anvil.png",
                "imgNew": "anvil.png",
                "imgAnchor": "Anvil-Anchor.png",
                "model": "Anvil",
                "price": "8575.75229",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Anvil",
                "width": "63.00",
                "xPos": 1176.3,
                "xPos3D": 1207.8,
                "yPos": 875.5,
                "yPos3D": 907,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "2.8",
                "folder": "14FreefallSupreme",
                "height": "109.20",
                "id": "product_20",
                "img": "freefallsupreme.png",
                "imgNew": "freefallsupreme.png",
                "imgAnchor": "FreefallSupremewithanchors.png",
                "model": "Freefall_Sup",
                "price": "10200",
                "relProducts": "0,19,50",
                "rotation": 90,
                "title": "Freefall Supreme",
                "width": "53.20",
                "xPos": "1483.00",
                "xPos3D": "1509.60",
                "yPos": 928.4,
                "yPos3D": 983,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "2.78",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8200",
                "relProducts": "0,23,20",
                "rotation": 90,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": 1024.8200000000002,
                "xPos3D": 1044,
                "yPos": 922.37,
                "yPos3D": 986,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Slides",
                "depth": "1.82",
                "folder": "18Freefall6",
                "height": "56.84",
                "id": "product_24",
                "img": "freefall6.png",
                "imgNew": "freefall6.png",
                "imgAnchor": "Freefall6withanchors.png",
                "model": "Freefall6",
                "price": "2314.35839",
                "relProducts": "0,22,26",
                "rotation": 315,
                "title": "Freefall 6'",
                "width": "34.16",
                "xPos": "1408.92",
                "xPos3D": "1426.00",
                "yPos": "1149.58",
                "yPos3D": "1178.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Swing & Climb",
                "depth": "1.93",
                "folder": "01JungleGym",
                "height": "42.00",
                "id": "product_26",
                "img": "JungleGym102020.png",
                "imgNew": "JungleGym102020.png",
                "imgAnchor": "JungleGym102020withanchors.png",
                "model": "Jungle_Jim",
                "price": "2960.84023",
                "relProducts": "0,34,25",
                "rotation": 45,
                "title": "Jungle Jim",
                "width": "37.80",
                "xPos": 1129.7,
                "xPos3D": 1148.6,
                "yPos": 1156,
                "yPos3D": 1177,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "3.66",
                "folder": "skyrocket",
                "height": "112.00",
                "id": "product_10",
                "img": "skyrocket.png",
                "imgNew": "skyrocket.png",
                "imgAnchor": "SkyRocketwithanchors.png",
                "model": "Sky_Rocket",
                "price": "7282.95537",
                "relProducts": "0,37,9",
                "rotation": 180,
                "title": "Sky Rocket",
                "width": "112.00",
                "xPos": "1228.20",
                "xPos3D": "1284.20",
                "yPos": 755,
                "yPos3D": 811,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "61Kaos",
                "height": "106.68",
                "id": "product_32",
                "img": "kaos.png",
                "imgNew": "kaos.png",
                "imgAnchor": "Kaoswithanchors.png",
                "model": "Kaos",
                "price": "4590.14862",
                "relProducts": "0,31,82",
                "rotation": 0,
                "title": "Kaos",
                "width": "106.68",
                "xPos": "1459.06",
                "xPos3D": "1512.40",
                "yPos": 1057.66,
                "yPos3D": 1111,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "monkeyDome",
                "height": "42.56",
                "id": "product_31",
                "img": "MonkeyDome.png",
                "imgNew": "MonkeyDome.png",
                "imgAnchor": "MonkeyDome20withanchors.png",
                "model": "Monkey_Dome",
                "price": "4706.71398",
                "relProducts": "0,49,51",
                "rotation": 0,
                "title": "Monkey Dome",
                "width": "85.40",
                "xPos": 1027.1,
                "xPos3D": 1069.8,
                "yPos": 1091.72,
                "yPos3D": 1113,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.14",
                "folder": "Figure_8",
                "height": "44.80",
                "id": "product_212",
                "img": "figure_8.png",
                "imgNew": "figure_8.png",
                "imgAnchor": "Fig8-Anchor.png",
                "model": "Figure_8",
                "price": "1966.29159",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Figure 8 20",
                "width": "84.00",
                "xPos": 1241.6,
                "xPos3D": 1283.6,
                "yPos": 1184.6,
                "yPos3D": 1207,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 270,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 1263.12,
                "xPos3D": 1284.4,
                "yPos": 1029.92,
                "yPos3D": 1047,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3541.25",
                "relProducts": ",,",
                "rotation": 270,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": 1116.46,
                "xPos3D": 1148.8,
                "yPos": "966.82",
                "yPos3D": "986.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3541.25",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": "1386.06",
                "xPos3D": "1418.40",
                "yPos": 1090.82,
                "yPos3D": 1110,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2360.67",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 1385.26,
                "xPos3D": 1417.6,
                "yPos": 967.88,
                "yPos3D": 983,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 180,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 1326.26,
                "xPos3D": 1358.6,
                "yPos": "1158.88",
                "yPos3D": "1174.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 270,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 1359.06,
                "xPos3D": 1391.4,
                "yPos": 1027.88,
                "yPos3D": 1043,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 90,
                "title": "Kickback",
                "width": "64.68",
                "xPos": "1143.46",
                "xPos3D": "1175.80",
                "yPos": "1036.88",
                "yPos3D": "1052.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 180,
                "title": "Kickback",
                "width": "64.68",
                "xPos": "1177.26",
                "xPos3D": "1209.60",
                "yPos": 1156.88,
                "yPos3D": 1172,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": "1199.41",
                "xPos3D": "1210.40",
                "yPos": "1187.72",
                "yPos3D": "1230.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": "1272.21",
                "xPos3D": "1283.20",
                "yPos": 865.72,
                "yPos3D": 908,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 1346.61,
                "xPos3D": 1357.6,
                "yPos": 1188.72,
                "yPos3D": 1231,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1156.81",
                "xPos3D": "1167.80",
                "yPos": 922.65,
                "yPos3D": 944,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1381.61",
                "xPos3D": "1392.60",
                "yPos": "921.65",
                "yPos3D": "943.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1387.61",
                "xPos3D": "1398.60",
                "yPos": 1129.65,
                "yPos3D": 1151,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1305.61",
                "xPos3D": "1316.60",
                "yPos": 1130.65,
                "yPos3D": 1152,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1158.81,
                "xPos3D": 1169.8,
                "yPos": 1132.65,
                "yPos3D": 1154,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1235.81",
                "xPos3D": "1246.80",
                "yPos": 922.65,
                "yPos3D": 944,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1305.41,
                "xPos3D": 1316.4,
                "yPos": 924.65,
                "yPos3D": 946,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1240.61,
                "xPos3D": 1251.6,
                "yPos": 1129.65,
                "yPos3D": 1151,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 270,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": 1219.3,
                "xPos3D": 1229.8,
                "yPos": 1009.57,
                "yPos3D": 1048,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 270,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": 1328.9,
                "xPos3D": 1339.4,
                "yPos": 1004.57,
                "yPos3D": 1043,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1108.36,
                "xPos3D": 1119,
                "yPos": 975.36,
                "yPos3D": 986,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "1438.16",
                "xPos3D": "1448.80",
                "yPos": "1099.36",
                "yPos3D": "1110.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1273.76,
                "xPos3D": 1284.4,
                "yPos": 1068.36,
                "yPos3D": 1079,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1395.76,
                "xPos3D": 1406.4,
                "yPos": 1065.36,
                "yPos3D": 1076,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1039.36,
                "xPos3D": 1050,
                "yPos": 1005.36,
                "yPos3D": 1016,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1150.16,
                "xPos3D": 1160.8,
                "yPos": 1008.36,
                "yPos3D": 1019,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1273.76,
                "xPos3D": 1284.4,
                "yPos": 1004.36,
                "yPos3D": 1015,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1272.96,
                "xPos3D": 1283.6,
                "yPos": 1143.36,
                "yPos3D": 1154,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1396.96,
                "xPos3D": 1407.6,
                "yPos": 999.36,
                "yPos3D": 1010,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1112.16,
                "xPos3D": 1122.8,
                "yPos": "1102.36",
                "yPos3D": "1113.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1432.96,
                "xPos3D": 1443.6,
                "yPos": 972.36,
                "yPos3D": 983,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1271.96,
                "xPos3D": 1282.6,
                "yPos": "1248.36",
                "yPos3D": "1259.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1005.36,
                "xPos3D": 1016,
                "yPos": 1101.36,
                "yPos3D": 1112,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1150.16,
                "xPos3D": 1160.8,
                "yPos": 1074.36,
                "yPos3D": 1085,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "1",
                "category": "Slides",
                "depth": "2.74",
                "folder": "37EscaladeSummit",
                "height": "19.60",
                "id": "product_22",
                "img": "Summit-esc.png",
                "imgNew": "Summit-esc.png",
                "imgAnchor": "undefined",
                "model": "Esc_Sum",
                "price": "3033.12047",
                "relProducts": "0,21,",
                "rotation": 180,
                "title": "Escalade Summit Climbing Wall",
                "width": "29.40",
                "xPos": 1037.3,
                "xPos3D": 1052,
                "yPos": 947.2,
                "yPos3D": 957,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2687.56218",
                "relProducts": "0,,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 1255.45,
                "xPos3D": 1282.4,
                "yPos": 950.05,
                "yPos3D": 977,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2687.56218",
                "relProducts": "0,,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": "930.65",
                "xPos3D": "957.60",
                "yPos": "1030.05",
                "yPos3D": "1057.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2687.56218",
                "relProducts": "0,,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": "1590.65",
                "xPos3D": "1617.60",
                "yPos": "1015.05",
                "yPos3D": "1042.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2687.56218",
                "relProducts": "0,,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 1257.65,
                "xPos3D": 1284.6,
                "yPos": 1089.05,
                "yPos3D": 1116,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Jump",
                "depth": "2.12",
                "folder": "Hammer",
                "height": "50.40",
                "id": "product_208",
                "img": "hammer.png",
                "imgNew": "hammer.png",
                "imgAnchor": "Hammer-Anchor.png",
                "model": "Hammer",
                "price": "6060.18868",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Hammer",
                "width": "63.00",
                "xPos": "1322.10",
                "xPos3D": "1353.60",
                "yPos": "886.80",
                "yPos3D": "912.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2360.67",
                "relProducts": ",,",
                "rotation": 270,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": "1116.46",
                "xPos3D": "1148.80",
                "yPos": "1099.88",
                "yPos3D": "1115.00",
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Ring Park 200",
            "parkID": "nqrNCySoPNQz0OsLc8QpTENWKrF3RingMonsoonXXL250",
            "text": "Park Size: 100m x 100m",
            "height": 2086,
            "width": 1836,
            "parkDepth": 5,
            "parkIcon": "everest.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 45,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": "834.01",
                "xPos3D": "845.00",
                "yPos": "1126.72",
                "yPos3D": "1169.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 225,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 852.66,
                "xPos3D": 885,
                "yPos": 1112.88,
                "yPos3D": 1128,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-thunder-dome",
                "height": "53.90",
                "id": "product_34",
                "img": "UniversalThunderdome.png",
                "imgNew": "UniversalThunderdome.png",
                "imgAnchor": "UniversalThunderdomewithanchors.png",
                "model": "Uni_TDome",
                "price": "2929.60523",
                "relProducts": "0,35,57",
                "rotation": 0,
                "title": "Universal Thunderdome",
                "width": "53.90",
                "xPos": 846.62,
                "xPos3D": 873.57,
                "yPos": 1008.48,
                "yPos3D": 1035.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 862.01,
                "xPos3D": 873,
                "yPos": 1062.65,
                "yPos3D": 1084,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 45,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 813.58,
                "xPos3D": 824.57,
                "yPos": 1042.15,
                "yPos3D": 1084.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 825.93,
                "xPos3D": 836.57,
                "yPos": "1024.79",
                "yPos3D": "1035.43",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 719.93,
                "xPos3D": 730.57,
                "yPos": 1024.79,
                "yPos3D": 1035.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.76",
                "folder": "Universal_keyhole",
                "height": "53.20",
                "id": "product_210",
                "img": "universal_keyhole.png",
                "imgNew": "universal_keyhole.png",
                "imgAnchor": "Uni-Key-Anchor.png",
                "model": "Universal_keyhole",
                "price": "2254.46358",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Keyhole",
                "width": "53.20",
                "xPos": 666.97,
                "xPos3D": 693.57,
                "yPos": 1008.83,
                "yPos3D": 1035.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 645.93,
                "xPos3D": 656.57,
                "yPos": 1024.79,
                "yPos3D": 1035.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 225,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 590.23,
                "xPos3D": 622.57,
                "yPos": "1008.31",
                "yPos3D": "1023.43",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 585.87,
                "xPos3D": 596.86,
                "yPos": 1027.79,
                "yPos3D": 1049.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "3288.74109",
                "relProducts": "0,34,35",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 534.91,
                "xPos3D": 561.86,
                "yPos": 1055.19,
                "yPos3D": 1082.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 600.22,
                "xPos3D": 610.86,
                "yPos": 979.5,
                "yPos3D": 990.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 600.22,
                "xPos3D": 610.86,
                "yPos": 915.5,
                "yPos3D": 926.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "barricade",
                "height": "27.72",
                "id": "product_12",
                "img": "Barricade10.png",
                "imgNew": "Barricade10.png",
                "imgAnchor": "Barricade10withanchors.png",
                "model": "Barricade_10",
                "price": "1772.67044",
                "relProducts": "0,11,47",
                "rotation": 270,
                "title": "Barricade 10",
                "width": "42.56",
                "xPos": 589.58,
                "xPos3D": 610.86,
                "yPos": 944.28,
                "yPos3D": 958.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2360.67",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 591.52,
                "xPos3D": 623.86,
                "yPos": 877.02,
                "yPos3D": 892.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 647.22,
                "xPos3D": 657.86,
                "yPos": 869.5,
                "yPos3D": 880.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 595.22,
                "xPos3D": 605.86,
                "yPos": 862.5,
                "yPos3D": 873.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.5",
                "folder": "LokiCentre",
                "height": "117.32",
                "id": "product_186",
                "img": "loki.png",
                "imgNew": "loki.png",
                "imgAnchor": "Lokiwithanchors.png",
                "model": "Loki",
                "price": "10469.23",
                "relProducts": ",165,4",
                "rotation": 135,
                "title": "Loki",
                "width": "64.68",
                "xPos": 525.23,
                "xPos3D": 557.57,
                "yPos": 766.05,
                "yPos3D": 824.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Slides",
                "depth": "2.44",
                "folder": "19FreefallExtreme",
                "height": "88.90",
                "id": "product_23",
                "img": "freefallextreme.png",
                "imgNew": "freefallextreme.png",
                "imgAnchor": "Freefallextremewithanchors.png",
                "model": "Freefall_Ext",
                "price": "4888.03437",
                "relProducts": "0,21,26",
                "rotation": 90,
                "title": "Freefall Extreme",
                "width": "38.36",
                "xPos": 512.39,
                "xPos3D": 531.57,
                "yPos": 914.55,
                "yPos3D": 959,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 575.93,
                "xPos3D": 586.57,
                "yPos": 947.36,
                "yPos3D": 958,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 536.93,
                "xPos3D": 547.57,
                "yPos": 974.36,
                "yPos3D": 985,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2687.56218",
                "relProducts": "0,,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 668.62,
                "xPos3D": 695.57,
                "yPos": 853.05,
                "yPos3D": 880,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "3288.74109",
                "relProducts": "0,34,35",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 668.62,
                "xPos3D": 695.57,
                "yPos": 777.05,
                "yPos3D": 804,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 684.93,
                "xPos3D": 695.57,
                "yPos": 831.36,
                "yPos3D": 842,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 721.93,
                "xPos3D": 732.57,
                "yPos": 869.36,
                "yPos3D": 880,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 826.93,
                "xPos3D": 837.57,
                "yPos": 869.36,
                "yPos3D": 880,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 847.62,
                "xPos3D": 874.57,
                "yPos": 853.05,
                "yPos3D": 880,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.44",
                "folder": "Double_icecap",
                "height": "44.80",
                "id": "product_211",
                "img": "double_icecap.png",
                "imgNew": "double_icecap.png",
                "imgAnchor": "Double-Ice-Anchor.png",
                "model": "Double_icecap",
                "price": "4542.69547",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Double Icecap 20",
                "width": "84.00",
                "xPos": 743.57,
                "xPos3D": 785.57,
                "yPos": "857.60",
                "yPos3D": "880.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Slides",
                "depth": "2.44",
                "folder": "51KingoftheMountian",
                "height": "56.00",
                "id": "product_28",
                "img": "kom.png",
                "imgNew": "kom.png",
                "imgAnchor": "KOMwithanchors.png",
                "model": "KingOTM",
                "price": "4462.62417",
                "relProducts": "0,26,25",
                "rotation": 270,
                "title": "King of the Mountain (set)",
                "width": "37.80",
                "xPos": 676.67,
                "xPos3D": 695.57,
                "yPos": 931,
                "yPos3D": 959,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 635.58,
                "xPos3D": 646.57,
                "yPos": 937.65,
                "yPos3D": 959,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "901.93",
                "xPos3D": "912.57",
                "yPos": "869.36",
                "yPos3D": "880.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 947.93,
                "xPos3D": 958.57,
                "yPos": 915.36,
                "yPos3D": 926,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2360.67",
                "relProducts": ",,",
                "rotation": 45,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 914.23,
                "xPos3D": 946.57,
                "yPos": 876.88,
                "yPos3D": 892,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.46",
                "folder": "30Sierra",
                "height": "42.70",
                "id": "product_53",
                "img": "Sierra10.png",
                "imgNew": "Sierra10.png",
                "imgAnchor": "Sierra10withanchors.png",
                "model": "Sierra_10",
                "price": "1699.10946",
                "relProducts": "0,52,44",
                "rotation": 0,
                "title": "Sierra 10",
                "width": "34.16",
                "xPos": 941.49,
                "xPos3D": 958.57,
                "yPos": 936.65,
                "yPos3D": 958,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 135,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 912.66,
                "xPos3D": 945,
                "yPos": 1008.88,
                "yPos3D": 1024,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 947.36,
                "xPos3D": 958,
                "yPos": "979.36",
                "yPos3D": "990.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 900.36,
                "xPos3D": 911,
                "yPos": 1025.36,
                "yPos3D": 1036,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 907.36,
                "xPos3D": 918,
                "yPos": "1129.79",
                "yPos3D": "1140.43",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 982.01,
                "xPos3D": 993,
                "yPos": 1119.08,
                "yPos3D": 1140.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "29Delta",
                "height": "42.70",
                "id": "product_54",
                "img": "Delta10.png",
                "imgNew": "Delta10.png",
                "imgAnchor": "Delta10withanchors.png",
                "model": "Delta_10",
                "price": "703.544495",
                "relProducts": "0,44,47",
                "rotation": 90,
                "title": "Delta 10",
                "width": "28.70",
                "xPos": 935.65,
                "xPos3D": 950,
                "yPos": 1119.08,
                "yPos3D": 1140.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "neptune",
                "height": "35.00",
                "id": "product_49",
                "img": "Neptune20.png",
                "imgNew": "Neptune20.png",
                "imgAnchor": "Neptune20withanchors.png",
                "model": "Neptune",
                "price": "2490.59674",
                "relProducts": "0,31,51",
                "rotation": 0,
                "title": "Neptune",
                "width": "85.40",
                "xPos": 740.44,
                "xPos3D": 783.14,
                "yPos": 1017.93,
                "yPos3D": 1035.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "LandingPad",
                "height": "56.00",
                "id": "product_60",
                "img": "landingpad.png",
                "imgNew": "landingpad.png",
                "imgAnchor": "LandingPad4mwithanchors.png",
                "model": "Landing_Pad",
                "price": "2288.70746",
                "relProducts": "0,61,65",
                "rotation": 90,
                "title": "Landing Pad 4m x 4m",
                "width": "56.00",
                "xPos": 1015.1400000000001,
                "xPos3D": 1043.14,
                "yPos": 1110.43,
                "yPos3D": 1138.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1032.5,
                "xPos3D": 1043.14,
                "yPos": 1088.79,
                "yPos3D": 1099.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1031.5,
                "xPos3D": 1042.14,
                "yPos": 979.5,
                "yPos3D": 990.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 987.15,
                "xPos3D": 998.14,
                "yPos": "936.79",
                "yPos3D": "958.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "monkeyDome",
                "height": "42.56",
                "id": "product_31",
                "img": "MonkeyDome.png",
                "imgNew": "MonkeyDome.png",
                "imgAnchor": "MonkeyDome20withanchors.png",
                "model": "Monkey_Dome",
                "price": "4706.71398",
                "relProducts": "0,49,51",
                "rotation": 270,
                "title": "Monkey Dome",
                "width": "85.40",
                "xPos": "999.44",
                "xPos3D": "1042.14",
                "yPos": 1023.15,
                "yPos3D": 1044.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Stand Alone",
                "depth": "1.21",
                "folder": "53ActivityTent",
                "height": "50.40",
                "id": "product_61",
                "img": "Event-Tent.png\r",
                "imgNew": "Event-Tent.png\r",
                "imgAnchor": "undefined",
                "model": "Event_Tent",
                "price": "1279.9978",
                "relProducts": "0,60,",
                "rotation": 0,
                "title": "Event Tent (Requires Landing Pad)",
                "width": "50.40",
                "xPos": "1016.94",
                "xPos3D": "1042.14",
                "yPos": "934.94",
                "yPos3D": "960.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 1032.15,
                "xPos3D": 1043.14,
                "yPos": 1166.43,
                "yPos3D": 1208.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "1029.65",
                "xPos3D": "1040.29",
                "yPos": 920.36,
                "yPos3D": 931,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1029.65,
                "xPos3D": 1040.29,
                "yPos": 812.36,
                "yPos3D": 823,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "LandingPad",
                "height": "56.00",
                "id": "product_60",
                "img": "landingpad.png",
                "imgNew": "landingpad.png",
                "imgAnchor": "LandingPad4mwithanchors.png",
                "model": "Landing_Pad",
                "price": "2288.70746",
                "relProducts": "0,61,65",
                "rotation": 0,
                "title": "Landing Pad 4m x 4m",
                "width": "56.00",
                "xPos": "1012.29",
                "xPos3D": "1040.29",
                "yPos": 756,
                "yPos3D": 784,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2856.94482",
                "relProducts": "0,51,49",
                "rotation": 90,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": 997.59,
                "xPos3D": 1040.29,
                "yPos": 855.65,
                "yPos3D": 877,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "61Kaos",
                "height": "106.68",
                "id": "product_32",
                "img": "kaos.png",
                "imgNew": "kaos.png",
                "imgAnchor": "Kaoswithanchors.png",
                "model": "Kaos",
                "price": "4590.14862",
                "relProducts": "0,31,82",
                "rotation": 0,
                "title": "Kaos",
                "width": "106.68",
                "xPos": 987.95,
                "xPos3D": 1041.29,
                "yPos": 607.23,
                "yPos3D": 660.57,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1030.30",
                "xPos3D": "1041.29",
                "yPos": 714.22,
                "yPos3D": 735.57,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 983.58,
                "xPos3D": 994.57,
                "yPos": 763.51,
                "yPos3D": 784.86,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "862.58",
                "xPos3D": "873.57",
                "yPos": 811.65,
                "yPos3D": 833,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 905.71,
                "xPos3D": 919.57,
                "yPos": "776.36",
                "yPos3D": "787.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 315,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 854.23,
                "xPos3D": 886.57,
                "yPos": 783.88,
                "yPos3D": 799,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "overpass",
                "height": "25.62",
                "id": "product_44",
                "img": "Overpass10.png",
                "imgNew": "Overpass10.png",
                "imgAnchor": "Overpass10withanchors.png",
                "model": "Overpass",
                "price": "1520.62193",
                "relProducts": "0,47,45",
                "rotation": 0,
                "title": "Overpass 10",
                "width": "42.56",
                "xPos": 932.29,
                "xPos3D": 953.57,
                "yPos": "773.19",
                "yPos3D": "786.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "18",
                "category": "Slides",
                "depth": "4",
                "folder": "everest",
                "height": "166.60",
                "id": "product_9",
                "img": "everest.png",
                "imgNew": "everest.png",
                "imgAnchor": "Everestwithanchors.png",
                "model": "Everest",
                "price": "19503.647",
                "relProducts": "0,10,21",
                "rotation": 315,
                "title": "Everest",
                "width": "69.44",
                "xPos": 722.85,
                "xPos3D": 757.57,
                "yPos": 586.41,
                "yPos3D": 669.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 135,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 834.58,
                "xPos3D": 845.57,
                "yPos": 716.43,
                "yPos3D": 758.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 790.93,
                "xPos3D": 801.57,
                "yPos": 648.79,
                "yPos3D": 659.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 735.93,
                "xPos3D": 746.57,
                "yPos": 702.79,
                "yPos3D": 713.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1148.57,
                "xPos3D": 1162.43,
                "yPos": 774.07,
                "yPos3D": 784.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1079.44,
                "xPos3D": 1090.43,
                "yPos": "763.36",
                "yPos3D": "784.71",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 45,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 1161.09,
                "xPos3D": 1193.43,
                "yPos": 780.59,
                "yPos3D": 795.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.64",
                "folder": "31Vista",
                "height": "42.70",
                "id": "product_52",
                "img": "Vista10.png",
                "imgNew": "Vista10.png",
                "imgAnchor": "Vista10withanchors.png",
                "model": "Vista_10",
                "price": "2546.21483",
                "relProducts": "0,53,45",
                "rotation": 90,
                "title": "Vista 10",
                "width": "27.72",
                "xPos": 1116.57,
                "xPos3D": 1130.43,
                "yPos": 763.36,
                "yPos3D": 784.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1200.79,
                "xPos3D": 1211.43,
                "yPos": 767.07,
                "yPos3D": 777.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1304.79,
                "xPos3D": 1315.43,
                "yPos": 663.07,
                "yPos3D": 673.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "12",
                "category": "Jump",
                "depth": "2.84",
                "folder": "Thor",
                "height": "63.00",
                "id": "product_206",
                "img": "thor.png",
                "imgNew": "thor.png",
                "imgAnchor": "Thor-Anchor.png",
                "model": "Thor",
                "price": "13527.2328",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Thor",
                "width": "126.00",
                "xPos": 1199.43,
                "xPos3D": 1262.43,
                "yPos": 694.21,
                "yPos3D": 725.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "3.66",
                "folder": "skyrocket",
                "height": "112.00",
                "id": "product_10",
                "img": "skyrocket.png",
                "imgNew": "skyrocket.png",
                "imgAnchor": "SkyRocketwithanchors.png",
                "model": "Sky_Rocket",
                "price": "7282.95537",
                "relProducts": "0,37,9",
                "rotation": 225,
                "title": "Sky Rocket",
                "width": "112.00",
                "xPos": 1306.43,
                "xPos3D": 1362.43,
                "yPos": 571.71,
                "yPos3D": 627.71,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "1",
                "category": "Swing & Climb",
                "depth": "2.44",
                "folder": "57Catapult",
                "height": "67.20",
                "id": "product_37",
                "img": "catapult.png",
                "imgNew": "catapult.png",
                "imgAnchor": "Catapultwithanchors.png",
                "model": "Catapult",
                "price": "3244.61911",
                "relProducts": "0,10,33",
                "rotation": 90,
                "title": "Catapult",
                "width": "56.00",
                "xPos": 837.57,
                "xPos3D": 865.57,
                "yPos": 924.54,
                "yPos3D": 958.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 909.58,
                "xPos3D": 920.57,
                "yPos": "936.79",
                "yPos3D": "958.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 180,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1193.15,
                "xPos3D": 1204.14,
                "yPos": 815.79,
                "yPos3D": 837.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2687.56218",
                "relProducts": "0,,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 1176.19,
                "xPos3D": 1203.14,
                "yPos": 858.19,
                "yPos3D": 885.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1154.5,
                "xPos3D": 1165.14,
                "yPos": 871.5,
                "yPos3D": 882.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3541.25",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": 1100.8,
                "xPos3D": 1133.14,
                "yPos": 877.82,
                "yPos3D": 897,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 90,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 1096.86,
                "xPos3D": 1118.14,
                "yPos": 943.06,
                "yPos3D": 960.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1069.15,
                "xPos3D": 1080.14,
                "yPos": 939.79,
                "yPos3D": 961.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1077.15,
                "xPos3D": 1088.14,
                "yPos": 1117.08,
                "yPos3D": 1138.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "1",
                "category": "Core 10ft",
                "depth": "1.43",
                "folder": "Lugeway10-2",
                "height": "42.70",
                "id": "product_177",
                "img": "Lugeway10.png",
                "imgNew": "Lugeway10.png",
                "imgAnchor": "Lugeway10withanchors.png",
                "model": "LWay_10",
                "price": "1318.50839",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Lugeway 10",
                "width": "22.12",
                "xPos": 1119.08,
                "xPos3D": 1130.14,
                "yPos": 1117.08,
                "yPos3D": 1138.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1150.28,
                "xPos3D": 1164.14,
                "yPos": 1127.79,
                "yPos3D": 1138.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 135,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 1167.8,
                "xPos3D": 1200.14,
                "yPos": 1111.31,
                "yPos3D": 1126.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.38",
                "folder": "Universal_icecap",
                "height": "53.20",
                "id": "product_209",
                "img": "universal_icecap.png",
                "imgNew": "universal_icecap.png",
                "imgAnchor": "Uni-Ice-Anchor.png",
                "model": "Universal_icecap",
                "price": "2081.04",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Icecap",
                "width": "53.20",
                "xPos": 1184.54,
                "xPos3D": 1211.14,
                "yPos": 1008.83,
                "yPos3D": 1035.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1200.15,
                "xPos3D": 1211.14,
                "yPos": 1061.08,
                "yPos3D": 1082.43,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1107.5,
                "xPos3D": 1118.14,
                "yPos": 918.36,
                "yPos3D": 929,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1108.5,
                "xPos3D": 1119.14,
                "yPos": 981.65,
                "yPos3D": 992.29,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 225,
                "title": "Kickback",
                "width": "64.68",
                "xPos": "1098.80",
                "xPos3D": "1131.14",
                "yPos": "1010.17",
                "yPos3D": "1025.29",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1155.28,
                "xPos3D": 1169.14,
                "yPos": 1027.65,
                "yPos3D": 1038.29,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1146.15,
                "xPos3D": 1157.14,
                "yPos": 938.79,
                "yPos3D": 960.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Slides",
                "depth": "1.82",
                "folder": "18Freefall6",
                "height": "56.84",
                "id": "product_24",
                "img": "freefall6.png",
                "imgNew": "freefall6.png",
                "imgAnchor": "Freefall6withanchors.png",
                "model": "Freefall6",
                "price": "2314.35839",
                "relProducts": "0,22,26",
                "rotation": 270,
                "title": "Freefall 6'",
                "width": "34.16",
                "xPos": 1189.06,
                "xPos3D": 1206.14,
                "yPos": 931.72,
                "yPos3D": 960.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 135,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": "1249.15",
                "xPos3D": "1260.14",
                "yPos": "1041.29",
                "yPos3D": "1083.57",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 135,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 1229.15,
                "xPos3D": 1240.14,
                "yPos": 1124.29,
                "yPos3D": 1166.57,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1237.5,
                "xPos3D": 1248.14,
                "yPos": 1024.9299999999998,
                "yPos3D": 1035.57,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1343.5,
                "xPos3D": 1354.14,
                "yPos": 1024.93,
                "yPos3D": 1035.57,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-beam",
                "height": "42.70",
                "id": "product_202",
                "img": "I-BEAM20.png",
                "imgNew": "I-BEAM20.png",
                "imgAnchor": "I-Beam20withanchors.png",
                "model": "I-Beam_20",
                "price": "1170.86303",
                "relProducts": "0,51,49",
                "rotation": 0,
                "title": "I-Beam 20",
                "width": "85.40",
                "xPos": 1258.44,
                "xPos3D": 1301.14,
                "yPos": 1014.22,
                "yPos3D": 1035.57,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "2.17",
                "folder": "UniversalArchway",
                "height": "53.90",
                "id": "product_179",
                "img": "universalarchway.png",
                "imgNew": "universalarchway.png",
                "imgAnchor": "UniversalArchwaywithanchors.png",
                "model": "Uni_Archway",
                "price": "3950.19",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Universal Archway",
                "width": "53.90",
                "xPos": 1364.34,
                "xPos3D": 1391.29,
                "yPos": 1008.19,
                "yPos3D": 1035.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1417.65,
                "xPos3D": 1428.29,
                "yPos": 1024.5,
                "yPos3D": 1035.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1463.65,
                "xPos3D": 1474.29,
                "yPos": 979.5,
                "yPos3D": 990.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 135,
                "title": "Kickback",
                "width": "64.68",
                "xPos": "1429.95",
                "xPos3D": "1462.29",
                "yPos": "1008.02",
                "yPos3D": "1023.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "parkway10",
                "height": "27.72",
                "id": "product_47",
                "img": "Parkway10.png",
                "imgNew": "Parkway10.png",
                "imgAnchor": "Parkway1010withanchors.png",
                "model": "PWay_10",
                "price": "1200.63527",
                "relProducts": "0,44,45",
                "rotation": 90,
                "title": "Parkway 10",
                "width": "42.56",
                "xPos": 1453.01,
                "xPos3D": 1474.29,
                "yPos": 945.28,
                "yPos3D": 959.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1428.3,
                "xPos3D": 1439.29,
                "yPos": 937.79,
                "yPos3D": 959.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Swing & Climb",
                "depth": "1.93",
                "folder": "01JungleGym",
                "height": "42.00",
                "id": "product_26",
                "img": "JungleGym102020.png",
                "imgNew": "JungleGym102020.png",
                "imgAnchor": "JungleGym102020withanchors.png",
                "model": "Jungle_Jim",
                "price": "2960.84023",
                "relProducts": "0,34,25",
                "rotation": 0,
                "title": "Jungle Jim",
                "width": "37.80",
                "xPos": 1380.39,
                "xPos3D": 1399.29,
                "yPos": "938.14",
                "yPos3D": "959.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.78",
                "folder": "02JungleJoe",
                "height": "92.12",
                "id": "product_25",
                "img": "junglejoe.png",
                "imgNew": "junglejoe.png",
                "imgAnchor": "JungleJoewithanchors.png",
                "model": "JJoe2",
                "price": "7738.31758",
                "relProducts": "0,33,31",
                "rotation": 270,
                "title": "Jungle Joe 2",
                "width": "76.58",
                "xPos": 1517,
                "xPos3D": 1555.29,
                "yPos": 912.08,
                "yPos3D": 958.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "1488.65",
                "xPos3D": "1499.29",
                "yPos": "948.50",
                "yPos3D": "959.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1524.65,
                "xPos3D": 1535.29,
                "yPos": "996.50",
                "yPos3D": "1007.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1477.30",
                "xPos3D": "1488.29",
                "yPos": "1027.79",
                "yPos3D": "1049.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "3288.74109",
                "relProducts": "0,34,35",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 1494.34,
                "xPos3D": 1521.29,
                "yPos": 1056.19,
                "yPos3D": 1083.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1463.65,
                "xPos3D": 1474.29,
                "yPos": "916.50",
                "yPos3D": "927.14",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1417.65,
                "xPos3D": 1428.29,
                "yPos": 870.5,
                "yPos3D": 881.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 45,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 1429.95,
                "xPos3D": 1462.29,
                "yPos": 878.02,
                "yPos3D": 893.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1469.65,
                "xPos3D": 1480.29,
                "yPos": 864.5,
                "yPos3D": 875.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1497.65,
                "xPos3D": 1508.29,
                "yPos": 796.5,
                "yPos3D": 807.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "2.78",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8200",
                "relProducts": "0,23,20",
                "rotation": 225,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": 1514.11,
                "xPos3D": 1533.29,
                "yPos": 759.51,
                "yPos3D": 823.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "1",
                "category": "Slides",
                "depth": "2.74",
                "folder": "37EscaladeSummit",
                "height": "19.60",
                "id": "product_22",
                "img": "Summit-esc.png",
                "imgNew": "Summit-esc.png",
                "imgAnchor": "undefined",
                "model": "Esc_Sum",
                "price": "3033.12047",
                "relProducts": "0,21,",
                "rotation": 315,
                "title": "Escalade Summit Climbing Wall",
                "width": "29.40",
                "xPos": 1533.59,
                "xPos3D": 1548.29,
                "yPos": 837.34,
                "yPos3D": 847.14,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "3288.74109",
                "relProducts": "0,34,35",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 1364.34,
                "xPos3D": 1391.29,
                "yPos": 781.19,
                "yPos3D": 808.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-monsoon-spray",
                "height": "53.90",
                "id": "product_92",
                "img": "UniversalMonsoon.png",
                "imgNew": "UniversalMonsoon.png",
                "imgAnchor": "UniversalMonsoonwithanchors.png",
                "model": "Monsoon",
                "price": "2687.56218",
                "relProducts": "0,,",
                "rotation": 0,
                "title": "Universal Monsoon Spray station",
                "width": "53.90",
                "xPos": 1362.34,
                "xPos3D": 1389.29,
                "yPos": 856.19,
                "yPos3D": 883.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "1380.65",
                "xPos3D": "1391.29",
                "yPos": 834.5,
                "yPos3D": 845.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1338.65,
                "xPos3D": 1349.29,
                "yPos": 875.5,
                "yPos3D": 886.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1230.65,
                "xPos3D": 1241.29,
                "yPos": 874.5,
                "yPos3D": 885.14,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.14",
                "folder": "Figure_8",
                "height": "44.80",
                "id": "product_212",
                "img": "figure_8.png",
                "imgNew": "figure_8.png",
                "imgAnchor": "Fig8-Anchor.png",
                "model": "Figure_8",
                "price": "1966.29159",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Figure 8 20",
                "width": "84.00",
                "xPos": 1253.29,
                "xPos3D": 1295.29,
                "yPos": "863.74",
                "yPos3D": "886.14",
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Custom Park Intense 120",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023CustomPark-Intense120",
            "text": "Park Size: 100m x 100m",
            "height": 2082,
            "width": 2096,
            "parkDepth": 5,
            "parkIcon": "kaos.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "859.01",
                "xPos3D": "870.00",
                "yPos": "1266.65",
                "yPos3D": "1288.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 844.05,
                "xPos3D": 871,
                "yPos": 1213.05,
                "yPos3D": 1240,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 859.81,
                "xPos3D": 870.8,
                "yPos": 1170.65,
                "yPos3D": 1192,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2856.94482",
                "relProducts": "0,51,49",
                "rotation": 90,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": 829.1,
                "xPos3D": 871.8,
                "yPos": 1106.65,
                "yPos3D": 1128,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.76",
                "folder": "Universal_keyhole",
                "height": "53.20",
                "id": "product_210",
                "img": "universal_keyhole.png",
                "imgNew": "universal_keyhole.png",
                "imgAnchor": "Uni-Key-Anchor.png",
                "model": "Universal_keyhole",
                "price": "2254.46358",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Keyhole",
                "width": "53.20",
                "xPos": 845.2,
                "xPos3D": 871.8,
                "yPos": 989.4,
                "yPos3D": 1016,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 860.81,
                "xPos3D": 871.8,
                "yPos": 1042.65,
                "yPos3D": 1064,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 857.94,
                "xPos3D": 871.8,
                "yPos": 965.36,
                "yPos3D": 976,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.14",
                "folder": "Figure_8",
                "height": "44.80",
                "id": "product_212",
                "img": "figure_8.png",
                "imgNew": "figure_8.png",
                "imgAnchor": "Fig8-Anchor.png",
                "model": "Figure_8",
                "price": "1966.29159",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Figure 8 20",
                "width": "84.00",
                "xPos": 830.8,
                "xPos3D": 872.8,
                "yPos": 897.6,
                "yPos3D": 920,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 859.94,
                "xPos3D": 873.8,
                "yPos": 853.36,
                "yPos3D": 864,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.78",
                "folder": "02JungleJoe",
                "height": "92.12",
                "id": "product_25",
                "img": "junglejoe.png",
                "imgNew": "junglejoe.png",
                "imgAnchor": "JungleJoewithanchors.png",
                "model": "JJoe2",
                "price": "7738.31758",
                "relProducts": "0,33,31",
                "rotation": 180,
                "title": "Jungle Joe 2",
                "width": "76.58",
                "xPos": "836.51",
                "xPos3D": "874.80",
                "yPos": 757.94,
                "yPos3D": 804,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 922.81,
                "xPos3D": 933.8,
                "yPos": 802.65,
                "yPos3D": 824,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "2.78",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8200",
                "relProducts": "0,23,20",
                "rotation": 180,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": 955.62,
                "xPos3D": 974.8,
                "yPos": 754.37,
                "yPos3D": 818,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 993.94,
                "xPos3D": 1007.8,
                "yPos": 814.36,
                "yPos3D": 825,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "12",
                "category": "Jump",
                "depth": "2.84",
                "folder": "Thor",
                "height": "63.00",
                "id": "product_206",
                "img": "thor.png",
                "imgNew": "thor.png",
                "imgAnchor": "Thor-Anchor.png",
                "model": "Thor",
                "price": "13527.2328",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Thor",
                "width": "126.00",
                "xPos": 1021.5,
                "xPos3D": 1084.5,
                "yPos": 792.5,
                "yPos3D": 824,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1147.64,
                "xPos3D": 1161.5,
                "yPos": 814.36,
                "yPos3D": 825,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3541.25",
                "relProducts": ",,",
                "rotation": 45,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": 1165.16,
                "xPos3D": 1197.5,
                "yPos": 820.82,
                "yPos3D": 840,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1200.51,
                "xPos3D": 1211.5,
                "yPos": 861.65,
                "yPos3D": 883,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 90,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": "1190.22",
                "xPos3D": "1211.50",
                "yPos": "908.92",
                "yPos3D": "926.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1199.96,
                "xPos3D": 1210.6,
                "yPos": 947.36,
                "yPos3D": 958,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "61Kaos",
                "height": "106.68",
                "id": "product_32",
                "img": "kaos.png",
                "imgNew": "kaos.png",
                "imgAnchor": "Kaoswithanchors.png",
                "model": "Kaos",
                "price": "4590.14862",
                "relProducts": "0,31,82",
                "rotation": 0,
                "title": "Kaos",
                "width": "106.68",
                "xPos": "1157.26",
                "xPos3D": "1210.60",
                "yPos": 968.66,
                "yPos3D": 1022,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "1196.74",
                "xPos3D": "1210.60",
                "yPos": 1075.36,
                "yPos3D": 1086,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "monkeyDome",
                "height": "42.56",
                "id": "product_31",
                "img": "MonkeyDome.png",
                "imgNew": "MonkeyDome.png",
                "imgAnchor": "MonkeyDome20withanchors.png",
                "model": "Monkey_Dome",
                "price": "4706.71398",
                "relProducts": "0,49,51",
                "rotation": 270,
                "title": "Monkey Dome",
                "width": "85.40",
                "xPos": 1168.9,
                "xPos3D": 1211.6,
                "yPos": "1116.72",
                "yPos3D": "1138.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 90,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "1197.74",
                "xPos3D": "1211.60",
                "yPos": "1183.36",
                "yPos3D": "1194.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2360.67",
                "relProducts": ",,",
                "rotation": 135,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 1167.26,
                "xPos3D": 1199.6,
                "yPos": 1216.88,
                "yPos3D": 1232,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1142.21,
                "xPos3D": 1153.2,
                "yPos": 1223.65,
                "yPos3D": 1245,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 908.21,
                "xPos3D": 919.2,
                "yPos": 1218.65,
                "yPos3D": 1240,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1094.21",
                "xPos3D": "1105.20",
                "yPos": "1269.65",
                "yPos3D": "1291.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-thunder-dome",
                "height": "53.90",
                "id": "product_34",
                "img": "UniversalThunderdome.png",
                "imgNew": "UniversalThunderdome.png",
                "imgAnchor": "UniversalThunderdomewithanchors.png",
                "model": "Uni_TDome",
                "price": "2929.60523",
                "relProducts": "0,35,57",
                "rotation": 0,
                "title": "Universal Thunderdome",
                "width": "53.90",
                "xPos": 1078.25,
                "xPos3D": 1105.2,
                "yPos": 1217.05,
                "yPos3D": 1244,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.38",
                "folder": "Universal_icecap",
                "height": "53.20",
                "id": "product_209",
                "img": "universal_icecap.png",
                "imgNew": "universal_icecap.png",
                "imgAnchor": "Uni-Ice-Anchor.png",
                "model": "Universal_icecap",
                "price": "2081.04",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Icecap",
                "width": "53.20",
                "xPos": 940.6,
                "xPos3D": 967.2,
                "yPos": 1216.4,
                "yPos3D": 1243,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 955.21,
                "xPos3D": 966.2,
                "yPos": 1269.65,
                "yPos3D": 1291,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "49WalkonWater",
                "height": "21.00",
                "id": "product_62",
                "img": "Wal-On-Water.png\r",
                "imgNew": "Wal-On-Water.png\r",
                "imgAnchor": "undefined",
                "model": "Walk_on_Water",
                "price": "1746.7119",
                "relProducts": "0,66,63",
                "rotation": 0,
                "title": "Walk On Water 20",
                "width": "84.00",
                "xPos": 994.2,
                "xPos3D": 1036.2,
                "yPos": 1232.5,
                "yPos3D": 1243,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 957.21,
                "xPos3D": 968.2,
                "yPos": 1172.65,
                "yPos3D": 1194,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "parkway20",
                "height": "27.72",
                "id": "product_46",
                "img": "Parkway20.png",
                "imgNew": "Parkway20.png",
                "imgAnchor": "Parkway20withanchors.png",
                "model": "PWay_20",
                "price": "2609.28073",
                "relProducts": "0,11,51",
                "rotation": 270,
                "title": "Parkway 20",
                "width": "85.26",
                "xPos": 925.57,
                "xPos3D": 968.2,
                "yPos": 1116.14,
                "yPos3D": 1130,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 958.21,
                "xPos3D": 969.2,
                "yPos": 1043.65,
                "yPos3D": 1065,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "2.17",
                "folder": "UniversalArchway",
                "height": "53.90",
                "id": "product_179",
                "img": "universalarchway.png",
                "imgNew": "universalarchway.png",
                "imgAnchor": "UniversalArchwaywithanchors.png",
                "model": "Uni_Archway",
                "price": "3950.19",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Universal Archway",
                "width": "53.90",
                "xPos": 942.25,
                "xPos3D": 969.2,
                "yPos": 989.05,
                "yPos3D": 1016,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 959.56,
                "xPos3D": 970.2,
                "yPos": 968.36,
                "yPos3D": 979,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "overpass",
                "height": "25.62",
                "id": "product_44",
                "img": "Overpass10.png",
                "imgNew": "Overpass10.png",
                "imgAnchor": "Overpass10withanchors.png",
                "model": "Overpass",
                "price": "1520.62193",
                "relProducts": "0,47,45",
                "rotation": 270,
                "title": "Overpass 10",
                "width": "42.56",
                "xPos": 950.92,
                "xPos3D": 972.2,
                "yPos": 934.19,
                "yPos3D": 947,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 963.21,
                "xPos3D": 974.2,
                "yPos": 881.65,
                "yPos3D": 903,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 909.01,
                "xPos3D": 920,
                "yPos": "993.45",
                "yPos3D": "1014.80",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 991.01,
                "xPos3D": 1002,
                "yPos": 1189.45,
                "yPos3D": 1210.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1058.01",
                "xPos3D": "1069.00",
                "yPos": "1189.45",
                "yPos3D": "1210.80",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "3288.74109",
                "relProducts": "0,34,35",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 1009.05,
                "xPos3D": 1036,
                "yPos": 1148.85,
                "yPos3D": 1175.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1022.14,
                "xPos3D": 1036,
                "yPos": 1125.16,
                "yPos3D": 1135.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "barricade",
                "height": "27.72",
                "id": "product_12",
                "img": "Barricade10.png",
                "imgNew": "Barricade10.png",
                "imgAnchor": "Barricade10withanchors.png",
                "model": "Barricade_10",
                "price": "1772.67044",
                "relProducts": "0,11,47",
                "rotation": 270,
                "title": "Barricade 10",
                "width": "42.56",
                "xPos": 1014.72,
                "xPos3D": 1036,
                "yPos": 1085.94,
                "yPos3D": 1099.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1026.01,
                "xPos3D": 1037,
                "yPos": 1036.45,
                "yPos3D": 1057.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.46",
                "folder": "30Sierra",
                "height": "42.70",
                "id": "product_53",
                "img": "Sierra10.png",
                "imgNew": "Sierra10.png",
                "imgAnchor": "Sierra10withanchors.png",
                "model": "Sierra_10",
                "price": "1699.10946",
                "relProducts": "0,52,44",
                "rotation": 90,
                "title": "Sierra 10",
                "width": "34.16",
                "xPos": "1019.92",
                "xPos3D": "1037.00",
                "yPos": "997.45",
                "yPos3D": "1018.80",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "994.36",
                "xPos3D": "1005.00",
                "yPos": "1006.16",
                "yPos3D": "1016.80",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1058.36,
                "xPos3D": 1069,
                "yPos": 1008.16,
                "yPos3D": 1018.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.06",
                "folder": "UniversalPavilion",
                "height": "53.90",
                "id": "product_180",
                "img": "universalpavilion.png",
                "imgNew": "universalpavilion.png",
                "imgAnchor": "UniversalPavilionwithanchors.png",
                "model": "Uni_Pavilion",
                "price": "2220.23",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Pavilion",
                "width": "53.90",
                "xPos": "1080.05",
                "xPos3D": "1107.00",
                "yPos": 991.85,
                "yPos3D": 1018.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 90,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1132.14,
                "xPos3D": 1146,
                "yPos": "1008.16",
                "yPos3D": "1018.80",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 993.01,
                "xPos3D": 1004,
                "yPos": 962.45,
                "yPos3D": 983.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1063.01",
                "xPos3D": "1074.00",
                "yPos": "964.45",
                "yPos3D": "985.80",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1029.3600000000001,
                "xPos3D": 1040,
                "yPos": 904.16,
                "yPos3D": 914.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1066.36,
                "xPos3D": 1077,
                "yPos": 941.16,
                "yPos3D": 951.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "3288.74109",
                "relProducts": "0,34,35",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 1013.05,
                "xPos3D": 1040,
                "yPos": 924.85,
                "yPos3D": 951.8,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-beam",
                "height": "42.70",
                "id": "product_202",
                "img": "I-BEAM20.png",
                "imgNew": "I-BEAM20.png",
                "imgAnchor": "I-Beam20withanchors.png",
                "model": "I-Beam_20",
                "price": "1170.86303",
                "relProducts": "0,51,49",
                "rotation": 90,
                "title": "I-Beam 20",
                "width": "85.40",
                "xPos": "1059.30",
                "xPos3D": "1102.00",
                "yPos": "1110.45",
                "yPos3D": "1131.80",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1092.01,
                "xPos3D": 1103,
                "yPos": 1174.45,
                "yPos3D": 1195.8,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "1093.01",
                "xPos3D": "1104.00",
                "yPos": 1046.45,
                "yPos3D": 1067.8,
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Custom Park Variety 120",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023CustomPark-Variety120",
            "text": "Park Size: 100m x 100m",
            "height": 2245,
            "width": 1666,
            "parkDepth": 5,
            "parkIcon": "hammer.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": "850.25",
                "xPos3D": "877.20",
                "yPos": "986.05",
                "yPos3D": "1013.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 822.34,
                "xPos3D": 836.2,
                "yPos": 1002.36,
                "yPos3D": 1013,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 45,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 834.34,
                "xPos3D": 848.2,
                "yPos": 973.36,
                "yPos3D": 984,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 90,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 867.56,
                "xPos3D": 878.2,
                "yPos": 964.36,
                "yPos3D": 975,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "blockade",
                "height": "27.72",
                "id": "product_11",
                "img": "Blockade20.png",
                "imgNew": "Blockade20.png",
                "imgAnchor": "Blockade20withanchors.png",
                "model": "Blockade_20",
                "price": "2848.55403",
                "relProducts": "0,46,51",
                "rotation": 270,
                "title": "Blockade 20",
                "width": "85.26",
                "xPos": "835.57",
                "xPos3D": "878.20",
                "yPos": 908.14,
                "yPos3D": 922,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 270,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 867.56,
                "xPos3D": 878.2,
                "yPos": 858.36,
                "yPos3D": 869,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 850.05,
                "xPos3D": 877,
                "yPos": 803.05,
                "yPos3D": 830,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 45,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 834.14,
                "xPos3D": 848,
                "yPos": 789.36,
                "yPos3D": 800,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 823.34,
                "xPos3D": 837.2,
                "yPos": 818.36,
                "yPos3D": 829,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 315,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 890.14,
                "xPos3D": 904,
                "yPos": 789.36,
                "yPos3D": 800,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 315,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 834.34,
                "xPos3D": 848.2,
                "yPos": 848.36,
                "yPos3D": 859,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.44",
                "folder": "Double_icecap",
                "height": "44.80",
                "id": "product_211",
                "img": "double_icecap.png",
                "imgNew": "double_icecap.png",
                "imgAnchor": "Double-Ice-Anchor.png",
                "model": "Double_icecap",
                "price": "4542.69547",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Double Icecap 20",
                "width": "84.00",
                "xPos": 902,
                "xPos3D": 944,
                "yPos": 737.6,
                "yPos3D": 760,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "49WalkonWater",
                "height": "21.00",
                "id": "product_62",
                "img": "Wal-On-Water.png\r",
                "imgNew": "Wal-On-Water.png\r",
                "imgAnchor": "undefined",
                "model": "Walk_on_Water",
                "price": "1746.7119",
                "relProducts": "0,66,63",
                "rotation": 315,
                "title": "Walk On Water 20",
                "width": "84.00",
                "xPos": 963,
                "xPos3D": 1005,
                "yPos": 689.5,
                "yPos3D": 700,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.76",
                "folder": "Universal_keyhole",
                "height": "53.20",
                "id": "product_210",
                "img": "universal_keyhole.png",
                "imgNew": "universal_keyhole.png",
                "imgAnchor": "Uni-Key-Anchor.png",
                "model": "Universal_keyhole",
                "price": "2254.46358",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Keyhole",
                "width": "53.20",
                "xPos": 1026.4,
                "xPos3D": 1053,
                "yPos": 621.4,
                "yPos3D": 648,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1090.01,
                "xPos3D": 1101,
                "yPos": 622.65,
                "yPos3D": 644,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.14",
                "folder": "Figure_8",
                "height": "44.80",
                "id": "product_212",
                "img": "figure_8.png",
                "imgNew": "figure_8.png",
                "imgAnchor": "Fig8-Anchor.png",
                "model": "Figure_8",
                "price": "1966.29159",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Figure 8 20",
                "width": "84.00",
                "xPos": 1122,
                "xPos3D": 1164,
                "yPos": "617.60",
                "yPos3D": "640.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1215.41,
                "xPos3D": 1226.4,
                "yPos": 618.65,
                "yPos3D": 640,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.38",
                "folder": "Universal_icecap",
                "height": "53.20",
                "id": "product_209",
                "img": "universal_icecap.png",
                "imgNew": "universal_icecap.png",
                "imgAnchor": "Uni-Ice-Anchor.png",
                "model": "Universal_icecap",
                "price": "2081.04",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Icecap",
                "width": "53.20",
                "xPos": 1247.4,
                "xPos3D": 1274,
                "yPos": 613.4,
                "yPos3D": 640,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1289.36,
                "xPos3D": 1300,
                "yPos": 656.36,
                "yPos3D": 667,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 45,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 1300.72,
                "xPos3D": 1322,
                "yPos": 673.92,
                "yPos3D": 691,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1341.01,
                "xPos3D": 1352,
                "yPos": 699.65,
                "yPos3D": 721,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.78",
                "folder": "02JungleJoe",
                "height": "92.12",
                "id": "product_25",
                "img": "junglejoe.png",
                "imgNew": "junglejoe.png",
                "imgAnchor": "JungleJoewithanchors.png",
                "model": "JJoe2",
                "price": "7738.31758",
                "relProducts": "0,33,31",
                "rotation": 225,
                "title": "Jungle Joe 2",
                "width": "76.58",
                "xPos": 1370.71,
                "xPos3D": 1409,
                "yPos": 702.94,
                "yPos3D": 749,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1350.01,
                "xPos3D": 1361,
                "yPos": 775.65,
                "yPos3D": 797,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "3288.74109",
                "relProducts": "0,34,35",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 1299.45,
                "xPos3D": 1326.4,
                "yPos": 803.05,
                "yPos3D": 830,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 45,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1342.76,
                "xPos3D": 1353.4,
                "yPos": 845.36,
                "yPos3D": 856,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3541.25",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": 1333.66,
                "xPos3D": 1366,
                "yPos": 870.82,
                "yPos3D": 890,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1342.76,
                "xPos3D": 1353.4,
                "yPos": 913.36,
                "yPos3D": 924,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2856.94482",
                "relProducts": "0,51,49",
                "rotation": 135,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": 1272.3,
                "xPos3D": 1315,
                "yPos": 938.65,
                "yPos3D": 960,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 315,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1265.76,
                "xPos3D": 1276.4,
                "yPos": 987.36,
                "yPos3D": 998,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Jump",
                "depth": "2.43",
                "folder": "Anvil",
                "height": "63.00",
                "id": "product_207",
                "img": "anvil.png",
                "imgNew": "anvil.png",
                "imgAnchor": "Anvil-Anchor.png",
                "model": "Anvil",
                "price": "8575.75229",
                "relProducts": ",,",
                "rotation": 180,
                "title": "Anvil",
                "width": "63.00",
                "xPos": 1212.9,
                "xPos3D": 1244.4,
                "yPos": 994.5,
                "yPos3D": 1026,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1194.41,
                "xPos3D": 1205.4,
                "yPos": 967.65,
                "yPos3D": 989,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "12",
                "category": "Jump",
                "depth": "2.84",
                "folder": "Thor",
                "height": "63.00",
                "id": "product_206",
                "img": "thor.png",
                "imgNew": "thor.png",
                "imgAnchor": "Thor-Anchor.png",
                "model": "Thor",
                "price": "13527.2328",
                "relProducts": ",,",
                "rotation": 45,
                "title": "Thor",
                "width": "126.00",
                "xPos": 1085.4,
                "xPos3D": 1148.4,
                "yPos": 895.5,
                "yPos3D": 927,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.38",
                "folder": "Universal_icecap",
                "height": "53.20",
                "id": "product_209",
                "img": "universal_icecap.png",
                "imgNew": "universal_icecap.png",
                "imgAnchor": "Uni-Ice-Anchor.png",
                "model": "Universal_icecap",
                "price": "2081.04",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Icecap",
                "width": "53.20",
                "xPos": 1029.8,
                "xPos3D": 1056.4,
                "yPos": 805.4,
                "yPos3D": 832,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1077.41,
                "xPos3D": 1088.4,
                "yPos": 846.65,
                "yPos3D": 868,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 45,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": 998.5,
                "xPos3D": 1009,
                "yPos": 840.57,
                "yPos3D": 879,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "2.78",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8200",
                "relProducts": "0,23,20",
                "rotation": 315,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": 953.22,
                "xPos3D": 972.4,
                "yPos": 859.37,
                "yPos3D": 923,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "901.01",
                "xPos3D": "912.00",
                "yPos": "842.65",
                "yPos3D": "864.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "49WalkonWater",
                "height": "21.00",
                "id": "product_62",
                "img": "Wal-On-Water.png\r",
                "imgNew": "Wal-On-Water.png\r",
                "imgAnchor": "undefined",
                "model": "Walk_on_Water",
                "price": "1746.7119",
                "relProducts": "0,66,63",
                "rotation": 315,
                "title": "Walk On Water 20",
                "width": "84.00",
                "xPos": "885.00",
                "xPos3D": "927.00",
                "yPos": "953.50",
                "yPos3D": "964.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 913.01,
                "xPos3D": 924,
                "yPos": 810.65,
                "yPos3D": 832,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 998.01,
                "xPos3D": 1009,
                "yPos": 810.65,
                "yPos3D": 832,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "parkway10",
                "height": "27.72",
                "id": "product_47",
                "img": "Parkway10.png",
                "imgNew": "Parkway10.png",
                "imgAnchor": "Parkway1010withanchors.png",
                "model": "PWay_10",
                "price": "1200.63527",
                "relProducts": "0,44,45",
                "rotation": 0,
                "title": "Parkway 10",
                "width": "42.56",
                "xPos": 945.72,
                "xPos3D": 967,
                "yPos": 818.14,
                "yPos3D": 832,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.38",
                "folder": "Universal_icecap",
                "height": "53.20",
                "id": "product_209",
                "img": "universal_icecap.png",
                "imgNew": "universal_icecap.png",
                "imgAnchor": "Uni-Ice-Anchor.png",
                "model": "Universal_icecap",
                "price": "2081.04",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Icecap",
                "width": "53.20",
                "xPos": 1118.4,
                "xPos3D": 1145,
                "yPos": 714.4,
                "yPos3D": 741,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 225,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": 1089.5,
                "xPos3D": 1100,
                "yPos": 749.57,
                "yPos3D": 788,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 315,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": 1086.5,
                "xPos3D": 1097,
                "yPos": 658.57,
                "yPos3D": 697,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1171.36,
                "xPos3D": 1182,
                "yPos": 730.36,
                "yPos3D": 741,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1042.36,
                "xPos3D": 1053,
                "yPos": 675.36,
                "yPos3D": 686,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1045.36,
                "xPos3D": 1056,
                "yPos": 783.36,
                "yPos3D": 794,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "monkeyDome",
                "height": "42.56",
                "id": "product_31",
                "img": "MonkeyDome.png",
                "imgNew": "MonkeyDome.png",
                "imgAnchor": "MonkeyDome20withanchors.png",
                "model": "Monkey_Dome",
                "price": "4706.71398",
                "relProducts": "0,49,51",
                "rotation": 270,
                "title": "Monkey Dome",
                "width": "85.40",
                "xPos": 1011.3,
                "xPos3D": 1054,
                "yPos": 718.72,
                "yPos3D": 740,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1083.36,
                "xPos3D": 1094,
                "yPos": "820.36",
                "yPos3D": "831.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "1",
                "category": "Core 10ft",
                "depth": "1.43",
                "folder": "Lugeway10-2",
                "height": "42.70",
                "id": "product_177",
                "img": "Lugeway10.png",
                "imgNew": "Lugeway10.png",
                "imgAnchor": "Lugeway10withanchors.png",
                "model": "LWay_10",
                "price": "1318.50839",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Lugeway 10",
                "width": "22.12",
                "xPos": 1114.94,
                "xPos3D": 1126,
                "yPos": 810.65,
                "yPos3D": 832,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1147.14,
                "xPos3D": 1161,
                "yPos": 822.36,
                "yPos3D": 833,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 45,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1283.14,
                "xPos3D": 1297,
                "yPos": 789.36,
                "yPos3D": 800,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1288.01,
                "xPos3D": 1299,
                "yPos": 699.65,
                "yPos3D": 721,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1268.01,
                "xPos3D": 1279,
                "yPos": "810.65",
                "yPos3D": "832.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "parkway20",
                "height": "27.72",
                "id": "product_46",
                "img": "Parkway20.png",
                "imgNew": "Parkway20.png",
                "imgAnchor": "Parkway20withanchors.png",
                "model": "PWay_20",
                "price": "2609.28073",
                "relProducts": "0,11,51",
                "rotation": 0,
                "title": "Parkway 20",
                "width": "85.26",
                "xPos": "1174.37",
                "xPos3D": "1217.00",
                "yPos": 818.14,
                "yPos3D": 832,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Jump",
                "depth": "2.12",
                "folder": "Hammer",
                "height": "50.40",
                "id": "product_208",
                "img": "hammer.png",
                "imgNew": "hammer.png",
                "imgAnchor": "Hammer-Anchor.png",
                "model": "Hammer",
                "price": "6060.18868",
                "relProducts": ",,",
                "rotation": 270,
                "title": "Hammer",
                "width": "63.00",
                "xPos": 1238.5,
                "xPos3D": 1270,
                "yPos": 738.8,
                "yPos3D": 764,
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Custom Park Double Loop 70",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023CustomPark-DoubleLoop70",
            "text": "Park Size: 100m x 100m",
            "height": 1980,
            "width": 1626,
            "parkDepth": 5,
            "parkIcon": "MonkeyDome.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 315,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 943.34,
                "xPos3D": 957.2,
                "yPos": 946.36,
                "yPos3D": 957,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 45,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1001.34,
                "xPos3D": 1015.2,
                "yPos": 947.36,
                "yPos3D": 958,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 933.34,
                "xPos3D": 947.2,
                "yPos": 918.36,
                "yPos3D": 929,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": "960.25",
                "xPos3D": "987.20",
                "yPos": "902.05",
                "yPos3D": "929.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Jump",
                "depth": "2.43",
                "folder": "Anvil",
                "height": "63.00",
                "id": "product_207",
                "img": "anvil.png",
                "imgNew": "anvil.png",
                "imgAnchor": "Anvil-Anchor.png",
                "model": "Anvil",
                "price": "8575.75229",
                "relProducts": ",,",
                "rotation": 225,
                "title": "Anvil",
                "width": "63.00",
                "xPos": 868.7,
                "xPos3D": 900.2,
                "yPos": 895.5,
                "yPos3D": 927,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 887.56,
                "xPos3D": 898.2,
                "yPos": 872.36,
                "yPos3D": 883,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2856.94482",
                "relProducts": "0,51,49",
                "rotation": 90,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": 855.5,
                "xPos3D": 898.2,
                "yPos": 808.65,
                "yPos3D": 830,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 887.56,
                "xPos3D": 898.2,
                "yPos": 765.36,
                "yPos3D": 776,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2360.67",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 877.86,
                "xPos3D": 910.2,
                "yPos": 726.88,
                "yPos3D": 742,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 45,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 874.34,
                "xPos3D": 888.2,
                "yPos": 711.36,
                "yPos3D": 722,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "2.78",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8200",
                "relProducts": "0,23,20",
                "rotation": 135,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": 814.02,
                "xPos3D": 833.2,
                "yPos": 604.37,
                "yPos3D": 668,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 933.56,
                "xPos3D": 944.2,
                "yPos": 718.36,
                "yPos3D": 729,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.78",
                "folder": "02JungleJoe",
                "height": "92.12",
                "id": "product_25",
                "img": "junglejoe.png",
                "imgNew": "junglejoe.png",
                "imgAnchor": "JungleJoewithanchors.png",
                "model": "JJoe2",
                "price": "7738.31758",
                "relProducts": "0,33,31",
                "rotation": 180,
                "title": "Jungle Joe 2",
                "width": "76.58",
                "xPos": 954.91,
                "xPos3D": 993.2,
                "yPos": 663.94,
                "yPos3D": 710,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 981.56,
                "xPos3D": 992.2,
                "yPos": 755.36,
                "yPos3D": 766,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "barricade",
                "height": "27.72",
                "id": "product_12",
                "img": "Barricade10.png",
                "imgNew": "Barricade10.png",
                "imgAnchor": "Barricade10withanchors.png",
                "model": "Barricade_10",
                "price": "1772.67044",
                "relProducts": "0,11,47",
                "rotation": 90,
                "title": "Barricade 10",
                "width": "42.56",
                "xPos": 970.92,
                "xPos3D": 992.2,
                "yPos": 784.14,
                "yPos3D": 798,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "49WalkonWater",
                "height": "21.00",
                "id": "product_62",
                "img": "Wal-On-Water.png\r",
                "imgNew": "Wal-On-Water.png\r",
                "imgAnchor": "undefined",
                "model": "Walk_on_Water",
                "price": "1746.7119",
                "relProducts": "0,66,63",
                "rotation": 90,
                "title": "Walk On Water 20",
                "width": "84.00",
                "xPos": "949.20",
                "xPos3D": "991.20",
                "yPos": "850.50",
                "yPos3D": "861.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1016.34,
                "xPos3D": 1030.2,
                "yPos": 918.36,
                "yPos3D": 929,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Jump",
                "depth": "2.12",
                "folder": "Hammer",
                "height": "50.40",
                "id": "product_208",
                "img": "hammer.png",
                "imgNew": "hammer.png",
                "imgAnchor": "Hammer-Anchor.png",
                "model": "Hammer",
                "price": "6060.18868",
                "relProducts": ",,",
                "rotation": 135,
                "title": "Hammer",
                "width": "63.00",
                "xPos": "1044.70",
                "xPos3D": "1076.20",
                "yPos": "896.80",
                "yPos3D": "922.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "1072.56",
                "xPos3D": "1083.20",
                "yPos": "871.36",
                "yPos3D": "882.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "monkeyDome",
                "height": "42.56",
                "id": "product_31",
                "img": "MonkeyDome.png",
                "imgNew": "MonkeyDome.png",
                "imgAnchor": "MonkeyDome20withanchors.png",
                "model": "Monkey_Dome",
                "price": "4706.71398",
                "relProducts": "0,49,51",
                "rotation": 90,
                "title": "Monkey Dome",
                "width": "85.40",
                "xPos": "1043.50",
                "xPos3D": "1086.20",
                "yPos": "807.72",
                "yPos3D": "829.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1076.56,
                "xPos3D": 1087.2,
                "yPos": 765.36,
                "yPos3D": 776,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 45,
                "title": "Bendback",
                "width": "64.68",
                "xPos": "1043.86",
                "xPos3D": "1076.20",
                "yPos": 726.88,
                "yPos3D": 742,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1031.56,
                "xPos3D": 1042.2,
                "yPos": "719.36",
                "yPos3D": "730.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 315,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1084.34,
                "xPos3D": 1098.2,
                "yPos": 712.36,
                "yPos3D": 723,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "3.66",
                "folder": "skyrocket",
                "height": "112.00",
                "id": "product_10",
                "img": "skyrocket.png",
                "imgNew": "skyrocket.png",
                "imgAnchor": "SkyRocketwithanchors.png",
                "model": "Sky_Rocket",
                "price": "7282.95537",
                "relProducts": "0,37,9",
                "rotation": 225,
                "title": "Sky Rocket",
                "width": "112.00",
                "xPos": "1091.20",
                "xPos3D": "1147.20",
                "yPos": "617.00",
                "yPos3D": "673.00",
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Custom Park Diamond 60",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023CustomParkDiamond60",
            "text": "Park Size: 100m x 100m",
            "height": 1265,
            "width": 1980,
            "parkDepth": 5,
            "parkIcon": "universalarchway.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Universal",
                "depth": "1.38",
                "folder": "Universal_icecap",
                "height": "53.20",
                "id": "product_209",
                "img": "universal_icecap.png",
                "imgNew": "universal_icecap.png",
                "imgAnchor": "Uni-Ice-Anchor.png",
                "model": "Universal_icecap",
                "price": "2081.04",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Icecap",
                "width": "53.20",
                "xPos": "960.00",
                "xPos3D": "986.60",
                "yPos": "768.40",
                "yPos3D": "795.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 315,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": 929.1,
                "xPos3D": 939.6,
                "yPos": 711.57,
                "yPos3D": 750,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 315,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": "831.70",
                "xPos3D": "842.20",
                "yPos": "611.57",
                "yPos3D": "650.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2360.67",
                "relProducts": ",,",
                "rotation": 270,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": "777.86",
                "xPos3D": "810.20",
                "yPos": "578.88",
                "yPos3D": "594.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 45,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": "824.21",
                "xPos3D": "835.20",
                "yPos": "532.65",
                "yPos3D": "554.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2856.94482",
                "relProducts": "0,51,49",
                "rotation": 135,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": 839.5,
                "xPos3D": 882.2,
                "yPos": 487.65,
                "yPos3D": 509,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 45,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 933.21,
                "xPos3D": 944.2,
                "yPos": 407.72,
                "yPos3D": 450,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 967.25,
                "xPos3D": 994.2,
                "yPos": 375.05,
                "yPos3D": 402,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 45,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "949.14",
                "xPos3D": "963.00",
                "yPos": "361.36",
                "yPos3D": "372.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 315,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "1007.14",
                "xPos3D": "1021.00",
                "yPos": "359.36",
                "yPos3D": "370.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 315,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": 1031.7,
                "xPos3D": 1042.2,
                "yPos": 409.57,
                "yPos3D": 448,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-beam",
                "height": "42.70",
                "id": "product_202",
                "img": "I-BEAM20.png",
                "imgNew": "I-BEAM20.png",
                "imgAnchor": "I-Beam20withanchors.png",
                "model": "I-Beam_20",
                "price": "1170.86303",
                "relProducts": "0,51,49",
                "rotation": 45,
                "title": "I-Beam 20",
                "width": "85.40",
                "xPos": 1055.5,
                "xPos3D": 1098.2,
                "yPos": 485.65,
                "yPos3D": 507,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 135,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 1133.21,
                "xPos3D": 1144.2,
                "yPos": 532.65,
                "yPos3D": 554,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 90,
                "title": "Kickback",
                "width": "64.68",
                "xPos": "1134.86",
                "xPos3D": "1167.20",
                "yPos": "581.88",
                "yPos3D": "597.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 45,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": "1117.21",
                "xPos3D": "1128.20",
                "yPos": "611.72",
                "yPos3D": "654.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Slides",
                "depth": "2.44",
                "folder": "19FreefallExtreme",
                "height": "88.90",
                "id": "product_23",
                "img": "freefallextreme.png",
                "imgNew": "freefallextreme.png",
                "imgAnchor": "Freefallextremewithanchors.png",
                "model": "Freefall_Ext",
                "price": "4888.03437",
                "relProducts": "0,21,26",
                "rotation": 315,
                "title": "Freefall Extreme",
                "width": "38.36",
                "xPos": 1077.02,
                "xPos3D": 1096.2,
                "yPos": 660.55,
                "yPos3D": 705,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 315,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1053.34,
                "xPos3D": 1067.2,
                "yPos": 705.36,
                "yPos3D": 716,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 315,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "1001.34",
                "xPos3D": "1015.20",
                "yPos": "756.36",
                "yPos3D": "767.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 315,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 1020.92,
                "xPos3D": 1042.2,
                "yPos": 723.92,
                "yPos3D": 741,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.78",
                "folder": "02JungleJoe",
                "height": "92.12",
                "id": "product_25",
                "img": "junglejoe.png",
                "imgNew": "junglejoe.png",
                "imgAnchor": "JungleJoewithanchors.png",
                "model": "JJoe2",
                "price": "7738.31758",
                "relProducts": "0,33,31",
                "rotation": 45,
                "title": "Jungle Joe 2",
                "width": "76.58",
                "xPos": "835.91",
                "xPos3D": "874.20",
                "yPos": "668.94",
                "yPos3D": "715.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "parkway20",
                "height": "27.72",
                "id": "product_46",
                "img": "Parkway20.png",
                "imgNew": "Parkway20.png",
                "imgAnchor": "Parkway20withanchors.png",
                "model": "PWay_20",
                "price": "2609.28073",
                "relProducts": "0,11,51",
                "rotation": 270,
                "title": "Parkway 20",
                "width": "85.26",
                "xPos": 942.57,
                "xPos3D": 985.2,
                "yPos": 684.14,
                "yPos3D": 698,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "2.17",
                "folder": "UniversalArchway",
                "height": "53.90",
                "id": "product_179",
                "img": "universalarchway.png",
                "imgNew": "universalarchway.png",
                "imgAnchor": "UniversalArchwaywithanchors.png",
                "model": "Uni_Archway",
                "price": "3950.19",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Universal Archway",
                "width": "53.90",
                "xPos": "959.25",
                "xPos3D": "986.20",
                "yPos": 574.05,
                "yPos3D": 601,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 972.34,
                "xPos3D": 986.2,
                "yPos": 743.36,
                "yPos3D": 754,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 971.34,
                "xPos3D": 985.2,
                "yPos": 631.36,
                "yPos3D": 642,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "49WalkonWater",
                "height": "21.00",
                "id": "product_62",
                "img": "Wal-On-Water.png\r",
                "imgNew": "Wal-On-Water.png\r",
                "imgAnchor": "undefined",
                "model": "Walk_on_Water",
                "price": "1746.7119",
                "relProducts": "0,66,63",
                "rotation": 315,
                "title": "Walk On Water 20",
                "width": "84.00",
                "xPos": 894.2,
                "xPos3D": 936.2,
                "yPos": 640.5,
                "yPos3D": 651,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 135,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": 1023.7,
                "xPos3D": 1034.2,
                "yPos": 608.57,
                "yPos3D": 647,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 974.34,
                "xPos3D": 988.2,
                "yPos": 548.36,
                "yPos3D": 559,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 980.34,
                "xPos3D": 994.2,
                "yPos": 433.36,
                "yPos3D": 444,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "blockade",
                "height": "27.72",
                "id": "product_11",
                "img": "Blockade20.png",
                "imgNew": "Blockade20.png",
                "imgAnchor": "Blockade20withanchors.png",
                "model": "Blockade_20",
                "price": "2848.55403",
                "relProducts": "0,46,51",
                "rotation": 270,
                "title": "Blockade 20",
                "width": "85.26",
                "xPos": 947.57,
                "xPos3D": 990.2,
                "yPos": 488.14,
                "yPos3D": 502,
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Example Park Lincoln UK 150",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023CustomParkLincoln150",
            "text": "Park Size: 100m x 100m",
            "height": 2032,
            "width": 2008,
            "parkDepth": 5,
            "parkIcon": "skyrocket.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "6",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "3.66",
                "folder": "skyrocket",
                "height": "112.00",
                "id": "product_10",
                "img": "skyrocket.png",
                "imgNew": "skyrocket.png",
                "imgAnchor": "SkyRocketwithanchors.png",
                "model": "Sky_Rocket",
                "price": "7282.95537",
                "relProducts": "0,37,9",
                "rotation": 45,
                "title": "Sky Rocket",
                "width": "112.00",
                "xPos": "576.80",
                "xPos3D": "632.80",
                "yPos": "1178.00",
                "yPos3D": "1234.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 45,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 677.81,
                "xPos3D": 688.8,
                "yPos": 1158.65,
                "yPos3D": 1180,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 695.85,
                "xPos3D": 722.8,
                "yPos": 1119.05,
                "yPos3D": 1146,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway20",
                "height": "84.56",
                "id": "product_69",
                "img": "Breezeway20.png\r",
                "imgNew": "Breezeway20.png\r",
                "imgAnchor": "undefined",
                "model": "BWay_20",
                "price": "1592.71841",
                "relProducts": "0,70,65",
                "rotation": 0,
                "title": "Breezeway 20",
                "width": "21.98",
                "xPos": 711.81,
                "xPos3D": 722.8,
                "yPos": 1034.72,
                "yPos3D": 1077,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "parkway20",
                "height": "27.72",
                "id": "product_46",
                "img": "Parkway20.png",
                "imgNew": "Parkway20.png",
                "imgAnchor": "Parkway20withanchors.png",
                "model": "PWay_20",
                "price": "2609.28073",
                "relProducts": "0,11,51",
                "rotation": 270,
                "title": "Parkway 20",
                "width": "85.26",
                "xPos": 680.17,
                "xPos3D": 722.8,
                "yPos": 979.14,
                "yPos3D": 993,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": "708.94",
                "xPos3D": "722.80",
                "yPos": 926.36,
                "yPos3D": 937,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": "695.85",
                "xPos3D": "722.80",
                "yPos": 869.05,
                "yPos3D": 896,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 135,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 676.81,
                "xPos3D": 687.8,
                "yPos": 840.65,
                "yPos3D": 862,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "61Kaos",
                "height": "106.68",
                "id": "product_32",
                "img": "kaos.png",
                "imgNew": "kaos.png",
                "imgAnchor": "Kaoswithanchors.png",
                "model": "Kaos",
                "price": "4590.14862",
                "relProducts": "0,31,82",
                "rotation": 0,
                "title": "Kaos",
                "width": "106.68",
                "xPos": 582.46,
                "xPos3D": 635.8,
                "yPos": 755.66,
                "yPos3D": 809,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 749.16,
                "xPos3D": 759.8,
                "yPos": 884.36,
                "yPos3D": 895,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "monkeyDome",
                "height": "42.56",
                "id": "product_31",
                "img": "MonkeyDome.png",
                "imgNew": "MonkeyDome.png",
                "imgAnchor": "MonkeyDome20withanchors.png",
                "model": "Monkey_Dome",
                "price": "4706.71398",
                "relProducts": "0,49,51",
                "rotation": 0,
                "title": "Monkey Dome",
                "width": "85.40",
                "xPos": "770.10",
                "xPos3D": "812.80",
                "yPos": 874.72,
                "yPos3D": 896,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": "864.81",
                "xPos3D": "875.80",
                "yPos": 874.65,
                "yPos3D": 896,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 896.85,
                "xPos3D": 923.8,
                "yPos": 869.05,
                "yPos3D": 896,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway20",
                "height": "84.56",
                "id": "product_69",
                "img": "Breezeway20.png\r",
                "imgNew": "Breezeway20.png\r",
                "imgAnchor": "undefined",
                "model": "BWay_20",
                "price": "1592.71841",
                "relProducts": "0,70,65",
                "rotation": 45,
                "title": "Breezeway 20",
                "width": "21.98",
                "xPos": "961.81",
                "xPos3D": "972.80",
                "yPos": "805.72",
                "yPos3D": "848.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway20",
                "height": "84.56",
                "id": "product_69",
                "img": "Breezeway20.png\r",
                "imgNew": "Breezeway20.png\r",
                "imgAnchor": "undefined",
                "model": "BWay_20",
                "price": "1592.71841",
                "relProducts": "0,70,65",
                "rotation": 135,
                "title": "Breezeway 20",
                "width": "21.98",
                "xPos": "1064.21",
                "xPos3D": "1075.20",
                "yPos": "803.12",
                "yPos3D": "845.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 1098.25,
                "xPos3D": 1125.2,
                "yPos": 867.05,
                "yPos3D": 894,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1162.21,
                "xPos3D": 1173.2,
                "yPos": 874.65,
                "yPos3D": 896,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "overpass",
                "height": "25.62",
                "id": "product_44",
                "img": "Overpass10.png",
                "imgNew": "Overpass10.png",
                "imgAnchor": "Overpass10withanchors.png",
                "model": "Overpass",
                "price": "1520.62193",
                "relProducts": "0,47,45",
                "rotation": 0,
                "title": "Overpass 10",
                "width": "42.56",
                "xPos": 1194.92,
                "xPos3D": 1216.2,
                "yPos": 883.19,
                "yPos3D": 896,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1248.21,
                "xPos3D": 1259.2,
                "yPos": 875.65,
                "yPos3D": 897,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 1280.25,
                "xPos3D": 1307.2,
                "yPos": 869.05,
                "yPos3D": 896,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 45,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1331.21,
                "xPos3D": 1342.2,
                "yPos": 842.65,
                "yPos3D": 864,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "1",
                "brocap": "4",
                "category": "Stand Alone",
                "depth": "1.5",
                "folder": "50CycloneWheel",
                "height": "33.60",
                "id": "product_41",
                "img": "cyclonewheel.png",
                "imgNew": "cyclonewheel.png",
                "imgAnchor": "CycloneWheelwithanchors.png",
                "model": "Cyclone_Wheel",
                "price": "2239.81123",
                "relProducts": "0,40,38",
                "rotation": 45,
                "title": "Cyclone Wheel Only",
                "width": "33.60",
                "xPos": "1358.40",
                "xPos3D": "1375.20",
                "yPos": "814.60",
                "yPos3D": "831.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Stand Alone",
                "depth": "1.5",
                "folder": "cyclone-enclosure",
                "height": "84.00",
                "id": "product_201",
                "img": "Enclousure.png",
                "imgNew": "Enclousure.png",
                "imgAnchor": "Enclousurewithanchors.png",
                "model": "Cyclone_Enclosure",
                "price": "2211.20967",
                "relProducts": "0,41,11",
                "rotation": 315,
                "title": "Cyclone Enclosure",
                "width": "42.00",
                "xPos": 1352.2,
                "xPos3D": 1373.2,
                "yPos": 793.4,
                "yPos3D": 835.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 1297.56,
                "xPos3D": 1308.2,
                "yPos": 922.76,
                "yPos3D": 933.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "neptune",
                "height": "35.00",
                "id": "product_49",
                "img": "Neptune20.png",
                "imgNew": "Neptune20.png",
                "imgAnchor": "Neptune20withanchors.png",
                "model": "Neptune",
                "price": "2490.59674",
                "relProducts": "0,31,51",
                "rotation": 90,
                "title": "Neptune",
                "width": "85.40",
                "xPos": 1263.5,
                "xPos3D": 1306.2,
                "yPos": 968.9,
                "yPos3D": 986.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway20",
                "height": "84.56",
                "id": "product_69",
                "img": "Breezeway20.png\r",
                "imgNew": "Breezeway20.png\r",
                "imgAnchor": "undefined",
                "model": "BWay_20",
                "price": "1592.71841",
                "relProducts": "0,70,65",
                "rotation": 180,
                "title": "Breezeway 20",
                "width": "21.98",
                "xPos": "1292.21",
                "xPos3D": "1303.20",
                "yPos": "1025.12",
                "yPos3D": "1067.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.78",
                "folder": "02JungleJoe",
                "height": "92.12",
                "id": "product_25",
                "img": "junglejoe.png",
                "imgNew": "junglejoe.png",
                "imgAnchor": "JungleJoewithanchors.png",
                "model": "JJoe2",
                "price": "7738.31758",
                "relProducts": "0,33,31",
                "rotation": 270,
                "title": "Jungle Joe 2",
                "width": "76.58",
                "xPos": 1282.91,
                "xPos3D": 1321.2,
                "yPos": 1103.34,
                "yPos3D": 1149.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Slides",
                "depth": "2.44",
                "folder": "24BastBag",
                "height": "51.24",
                "id": "product_27",
                "img": "blastbag.png",
                "imgNew": "blastbag.png",
                "imgAnchor": "BlastBagwithanchors.png",
                "model": "Blastbag",
                "price": "555.887139",
                "relProducts": "0,89,25",
                "rotation": 180,
                "title": "Blast Bag",
                "width": "23.10",
                "xPos": "1289.65",
                "xPos3D": "1301.20",
                "yPos": "1186.78",
                "yPos3D": "1212.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1241.21,
                "xPos3D": 1252.2,
                "yPos": 1127.05,
                "yPos3D": 1148.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1155.21,
                "xPos3D": 1166.2,
                "yPos": 1124.05,
                "yPos3D": 1145.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 1093.25,
                "xPos3D": 1120.2,
                "yPos": 1117.45,
                "yPos3D": 1144.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "parkway10",
                "height": "27.72",
                "id": "product_47",
                "img": "Parkway10.png",
                "imgNew": "Parkway10.png",
                "imgAnchor": "Parkway1010withanchors.png",
                "model": "PWay_10",
                "price": "1200.63527",
                "relProducts": "0,44,45",
                "rotation": 0,
                "title": "Parkway 10",
                "width": "42.56",
                "xPos": 1188.92,
                "xPos3D": 1210.2,
                "yPos": 1132.54,
                "yPos3D": 1146.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway20",
                "height": "84.56",
                "id": "product_69",
                "img": "Breezeway20.png\r",
                "imgNew": "Breezeway20.png\r",
                "imgAnchor": "undefined",
                "model": "BWay_20",
                "price": "1592.71841",
                "relProducts": "0,70,65",
                "rotation": 45,
                "title": "Breezeway 20",
                "width": "21.98",
                "xPos": "1056.21",
                "xPos3D": "1067.20",
                "yPos": "1150.12",
                "yPos3D": "1192.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway20",
                "height": "84.56",
                "id": "product_69",
                "img": "Breezeway20.png\r",
                "imgNew": "Breezeway20.png\r",
                "imgAnchor": "undefined",
                "model": "BWay_20",
                "price": "1592.71841",
                "relProducts": "0,70,65",
                "rotation": 135,
                "title": "Breezeway 20",
                "width": "21.98",
                "xPos": "964.21",
                "xPos3D": "975.20",
                "yPos": "1151.12",
                "yPos3D": "1193.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Stand Alone",
                "depth": "1.21",
                "folder": "53ActivityTent",
                "height": "50.40",
                "id": "product_61",
                "img": "Event-Tent.png\r",
                "imgNew": "Event-Tent.png\r",
                "imgAnchor": "undefined",
                "model": "Event_Tent",
                "price": "1279.9978",
                "relProducts": "0,60,",
                "rotation": 45,
                "title": "Event Tent (Requires Landing Pad)",
                "width": "50.40",
                "xPos": "998.00",
                "xPos3D": "1023.20",
                "yPos": "1211.20",
                "yPos3D": "1236.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 896.25,
                "xPos3D": 923.2,
                "yPos": 1116.45,
                "yPos3D": 1143.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "6",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "07Splashmat",
                "height": "76.86",
                "id": "product_66",
                "img": "Spalshmat.png",
                "imgNew": "Spalshmat.png",
                "imgAnchor": "undefined",
                "model": "Splashmat",
                "price": "1284.36187",
                "relProducts": "0,65,64",
                "rotation": 270,
                "title": "Splashmat 18",
                "width": "21.00",
                "xPos": "845.70",
                "xPos3D": "856.20",
                "yPos": "1104.97",
                "yPos3D": "1143.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 749.34,
                "xPos3D": 763.2,
                "yPos": 1135.76,
                "yPos3D": 1146.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "12",
                "category": "Slides",
                "depth": "2.78",
                "folder": "08Summit",
                "height": "127.26",
                "id": "product_21",
                "img": "summit.png",
                "imgNew": "summit.png",
                "imgAnchor": "Summitwithanchors.png",
                "model": "SummitExp",
                "price": "8200",
                "relProducts": "0,23,20",
                "rotation": 0,
                "title": "Summit Express",
                "width": "38.36",
                "xPos": "776.02",
                "xPos3D": "795.20",
                "yPos": "1085.77",
                "yPos3D": "1149.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "3",
                "category": "Trampoline & Bouncers",
                "depth": "2.44",
                "folder": "13Supertramp14",
                "height": "59.78",
                "id": "product_72",
                "img": "supertramp14.png",
                "imgNew": "supertramp14.png",
                "imgAnchor": "Supertramp14withanchors.png",
                "model": "STramp_14",
                "price": "1200",
                "relProducts": "0,73,74",
                "rotation": 0,
                "title": "Supertramp 14 w/Swimstep",
                "width": "59.78",
                "xPos": "993.31",
                "xPos3D": "1023.20",
                "yPos": 766.51,
                "yPos3D": 796.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 781.34,
                "xPos3D": 795.2,
                "yPos": 1061.76,
                "yPos3D": 1072.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 831.34,
                "xPos3D": 845.2,
                "yPos": 1010.76,
                "yPos3D": 1021.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 315,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 775.86,
                "xPos3D": 808.2,
                "yPos": 1019.28,
                "yPos3D": 1034.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 315,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 825.86,
                "xPos3D": 858.2,
                "yPos": "973.28",
                "yPos3D": "988.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 881.34,
                "xPos3D": 895.2,
                "yPos": 964.76,
                "yPos3D": 975.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 0,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": "913.21",
                "xPos3D": "924.20",
                "yPos": "922.05",
                "yPos3D": "943.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 0,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 902.92,
                "xPos3D": 924.2,
                "yPos": 965.32,
                "yPos3D": 982.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "parkway20",
                "height": "27.72",
                "id": "product_46",
                "img": "Parkway20.png",
                "imgNew": "Parkway20.png",
                "imgAnchor": "Parkway20withanchors.png",
                "model": "PWay_20",
                "price": "2609.28073",
                "relProducts": "0,11,51",
                "rotation": 0,
                "title": "Parkway 20",
                "width": "85.26",
                "xPos": 981.57,
                "xPos3D": 1024.2,
                "yPos": 963.54,
                "yPos3D": 977.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": "954.21",
                "xPos3D": "965.20",
                "yPos": "957.05",
                "yPos3D": "978.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1075.21,
                "xPos3D": 1086.2,
                "yPos": 957.05,
                "yPos3D": 978.4,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 0,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1113.21,
                "xPos3D": 1124.2,
                "yPos": 922.05,
                "yPos3D": 943.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 0,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 1102.92,
                "xPos3D": 1124.2,
                "yPos": 965.32,
                "yPos3D": 982.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 45,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 1178.86,
                "xPos3D": 1211.2,
                "yPos": "977.28",
                "yPos3D": "992.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 135,
                "title": "Kickback",
                "width": "64.68",
                "xPos": "1178.86",
                "xPos3D": "1211.20",
                "yPos": 1045.28,
                "yPos3D": 1060.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1210.34,
                "xPos3D": 1224.2,
                "yPos": 1015.76,
                "yPos3D": 1026.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1075.21,
                "xPos3D": 1086.2,
                "yPos": "1051.05",
                "yPos3D": "1072.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1155.21,
                "xPos3D": 1166.2,
                "yPos": 959.05,
                "yPos3D": 980.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 1154.21,
                "xPos3D": 1165.2,
                "yPos": 1051.05,
                "yPos3D": 1072.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 90,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 1107.34,
                "xPos3D": 1121.2,
                "yPos": 1091.76,
                "yPos3D": 1102.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 0,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 1102.92,
                "xPos3D": 1124.2,
                "yPos": 1052.32,
                "yPos3D": 1069.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 0,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": "1113.21",
                "xPos3D": "1124.20",
                "yPos": "1003.05",
                "yPos3D": "1024.40",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Core 20ft",
                "depth": "1.66",
                "folder": "blockade",
                "height": "27.72",
                "id": "product_11",
                "img": "Blockade20.png",
                "imgNew": "Blockade20.png",
                "imgAnchor": "Blockade20withanchors.png",
                "model": "Blockade_20",
                "price": "2848.55403",
                "relProducts": "0,46,51",
                "rotation": 0,
                "title": "Blockade 20",
                "width": "85.26",
                "xPos": 983.57,
                "xPos3D": 1026.2,
                "yPos": 1057.54,
                "yPos3D": 1071.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 90,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": 954.21,
                "xPos3D": 965.2,
                "yPos": 1050.05,
                "yPos3D": 1071.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 0,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 902.92,
                "xPos3D": 924.2,
                "yPos": 1050.32,
                "yPos3D": 1067.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 225,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 823.86,
                "xPos3D": 856.2,
                "yPos": 1041.28,
                "yPos3D": 1056.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 878.34,
                "xPos3D": 892.2,
                "yPos": 1058.76,
                "yPos3D": 1069.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 911.34,
                "xPos3D": 925.2,
                "yPos": 1092.76,
                "yPos3D": 1103.4,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "Breezeway10",
                "height": "42.70",
                "id": "product_70",
                "img": "Breezeway10.png",
                "imgNew": "Breezeway10.png",
                "imgAnchor": "undefined",
                "model": "BWay_10",
                "price": "1058.53229",
                "relProducts": "0,69,65",
                "rotation": 0,
                "title": "Breezeway 10",
                "width": "21.98",
                "xPos": "913.21",
                "xPos3D": "924.20",
                "yPos": "1001.05",
                "yPos3D": "1022.40",
                "zPos": 0
            }
        ]
    },
    {
        "parkInfo": {
            "name": "2023 Custom Park Double Ring 100",
            "parkID": "8Jt8KmonKKYHLJ0fwBb0SCuwl3l22023CustomParkDoubleRing100",
            "text": "Park Size: 100m x 100m",
            "height": 1345,
            "width": 1514,
            "parkDepth": 5,
            "parkIcon": "skyrocket.png",
            "location": "United Kingdom",
            "currency": "GBP",
        },
        "parkItems": [
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Jump",
                "depth": "2.43",
                "folder": "Anvil",
                "height": "63.00",
                "id": "product_207",
                "img": "anvil.png",
                "imgNew": "anvil.png",
                "imgAnchor": "Anvil-Anchor.png",
                "model": "Anvil",
                "price": "8575.75229",
                "relProducts": ",,",
                "rotation": 225,
                "title": "Anvil",
                "width": "63.00",
                "xPos": "612.10",
                "xPos3D": "643.60",
                "yPos": "943.50",
                "yPos3D": "975.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 631.61,
                "xPos3D": 642.6,
                "yPos": 899.65,
                "yPos3D": 921,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 20ft",
                "depth": "1.21",
                "folder": "i-hop",
                "height": "42.70",
                "id": "product_203",
                "img": "I-HOP20.png",
                "imgNew": "I-HOP20.png",
                "imgAnchor": "I-Hop20withanchors.png",
                "model": "I-Hop_20",
                "price": "2856.94482",
                "relProducts": "0,51,49",
                "rotation": 90,
                "title": "I-Hop 20",
                "width": "85.40",
                "xPos": 600.9,
                "xPos3D": 643.6,
                "yPos": 834.65,
                "yPos3D": 856,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 0,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 633.61,
                "xPos3D": 644.6,
                "yPos": 770.65,
                "yPos3D": 792,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.54",
                "folder": "Lugeback",
                "height": "30.24",
                "id": "product_175",
                "img": "Lugeback.png",
                "imgNew": "Lugeback.png",
                "imgAnchor": "Lugebackwithanchors.png",
                "model": "Lugeback",
                "price": "2360.67",
                "relProducts": ",,",
                "rotation": 315,
                "title": "Lugeback",
                "width": "64.68",
                "xPos": 625.26,
                "xPos3D": 657.6,
                "yPos": 731.88,
                "yPos3D": 747,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 681.96,
                "xPos3D": 692.6,
                "yPos": 723.36,
                "yPos3D": 734,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "2.17",
                "folder": "UniversalArchway",
                "height": "53.90",
                "id": "product_179",
                "img": "universalarchway.png",
                "imgNew": "universalarchway.png",
                "imgAnchor": "UniversalArchwaywithanchors.png",
                "model": "Uni_Archway",
                "price": "3950.19",
                "relProducts": ",,",
                "rotation": 90,
                "title": "Universal Archway",
                "width": "53.90",
                "xPos": 699.65,
                "xPos3D": 726.6,
                "yPos": 707.05,
                "yPos3D": 734,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 753.96,
                "xPos3D": 764.6,
                "yPos": 724.36,
                "yPos3D": 735,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "5",
                "category": "Corners",
                "depth": "1.62",
                "folder": "Quarterback",
                "height": "38.36",
                "id": "product_176",
                "img": "QuarterBack.png",
                "imgNew": "QuarterBack.png",
                "imgAnchor": "Quarterbackwithanchors.png",
                "model": "Q_Back",
                "price": "3541.25",
                "relProducts": ",,",
                "rotation": 45,
                "title": "Quarterback",
                "width": "64.68",
                "xPos": 765.26,
                "xPos3D": 797.6,
                "yPos": 730.82,
                "yPos3D": 750,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 180,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 801.61,
                "xPos3D": 812.6,
                "yPos": 770.65,
                "yPos3D": 792,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.52",
                "folder": "monkeyDome",
                "height": "42.56",
                "id": "product_31",
                "img": "MonkeyDome.png",
                "imgNew": "MonkeyDome.png",
                "imgAnchor": "MonkeyDome20withanchors.png",
                "model": "Monkey_Dome",
                "price": "4706.71398",
                "relProducts": "0,49,51",
                "rotation": 90,
                "title": "Monkey Dome",
                "width": "85.40",
                "xPos": 767.9,
                "xPos3D": 810.6,
                "yPos": 835.72,
                "yPos3D": 857,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 180,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 798.61,
                "xPos3D": 809.6,
                "yPos": 900.65,
                "yPos3D": 922,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Jump",
                "depth": "2.12",
                "folder": "Hammer",
                "height": "50.40",
                "id": "product_208",
                "img": "hammer.png",
                "imgNew": "hammer.png",
                "imgAnchor": "Hammer-Anchor.png",
                "model": "Hammer",
                "price": "6060.18868",
                "relProducts": ",,",
                "rotation": 135,
                "title": "Hammer",
                "width": "63.00",
                "xPos": 771.1,
                "xPos3D": 802.6,
                "yPos": 947.8,
                "yPos3D": 973,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "674.96",
                "xPos3D": "685.60",
                "yPos": 966.36,
                "yPos3D": 977,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 750.96,
                "xPos3D": 761.6,
                "yPos": 967.36,
                "yPos3D": 978,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.6",
                "folder": "universal-thunder-dome",
                "height": "53.90",
                "id": "product_34",
                "img": "UniversalThunderdome.png",
                "imgNew": "UniversalThunderdome.png",
                "imgAnchor": "UniversalThunderdomewithanchors.png",
                "model": "Uni_TDome",
                "price": "2929.60523",
                "relProducts": "0,35,57",
                "rotation": 0,
                "title": "Universal Thunderdome",
                "width": "53.90",
                "xPos": 696.65,
                "xPos3D": 723.6,
                "yPos": 950.05,
                "yPos3D": 977,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 708.74,
                "xPos3D": 722.6,
                "yPos": 924.36,
                "yPos3D": 935,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "8",
                "category": "Swing & Climb",
                "depth": "1.66",
                "folder": "parkway30",
                "height": "27.72",
                "id": "product_204",
                "img": "Parkway30.png",
                "imgNew": "Parkway30.png",
                "imgAnchor": "Parkway30withanchors.png",
                "model": "PWay_30",
                "price": "3442.59594",
                "relProducts": ",46,47",
                "rotation": 270,
                "title": "Parkway 30",
                "width": "127.96",
                "xPos": 658.62,
                "xPos3D": 722.6,
                "yPos": 842.14,
                "yPos3D": 856,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 270,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 712.74,
                "xPos3D": 726.6,
                "yPos": 765.36,
                "yPos3D": 776,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": "716.61",
                "xPos3D": "727.60",
                "yPos": 621.72,
                "yPos3D": 664,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "0",
                "category": "Universal",
                "depth": "1.21",
                "folder": "universal-lifeguard",
                "height": "53.90",
                "id": "product_205",
                "img": "universallifeguard.png",
                "imgNew": "universallifeguard.png",
                "imgAnchor": "UniversalLifeguardwithanchors.png",
                "model": "Uni_LGaurd",
                "price": "3288.74109",
                "relProducts": "0,34,35",
                "rotation": 0,
                "title": "Universal Lifeguard Station",
                "width": "53.90",
                "xPos": 700.65,
                "xPos3D": 727.6,
                "yPos": 567.05,
                "yPos3D": 594,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 677.96,
                "xPos3D": 688.6,
                "yPos": 583.36,
                "yPos3D": 594,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 225,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 621.26,
                "xPos3D": 653.6,
                "yPos": 565.88,
                "yPos3D": 581,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "49WalkonWater",
                "height": "21.00",
                "id": "product_62",
                "img": "Wal-On-Water.png\r",
                "imgNew": "Wal-On-Water.png\r",
                "imgAnchor": "undefined",
                "model": "Walk_on_Water",
                "price": "1746.7119",
                "relProducts": "0,66,63",
                "rotation": 315,
                "title": "Walk On Water 20",
                "width": "84.00",
                "xPos": 571.6,
                "xPos3D": 613.6,
                "yPos": 610.5,
                "yPos3D": 621,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "10",
                "category": "Slides",
                "depth": "2.78",
                "folder": "02JungleJoe",
                "height": "92.12",
                "id": "product_25",
                "img": "junglejoe.png",
                "imgNew": "junglejoe.png",
                "imgAnchor": "JungleJoewithanchors.png",
                "model": "JJoe2",
                "price": "7738.31758",
                "relProducts": "0,33,31",
                "rotation": 45,
                "title": "Jungle Joe 2",
                "width": "76.58",
                "xPos": 513.31,
                "xPos3D": 551.6,
                "yPos": 636.94,
                "yPos3D": 683,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 630.96,
                "xPos3D": 641.6,
                "yPos": 536.36,
                "yPos3D": 547,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "4",
                "category": "Core 10ft",
                "depth": "1.66",
                "folder": "Subway10",
                "height": "34.16",
                "id": "product_45",
                "img": "Subway10.png",
                "imgNew": "Subway10.png",
                "imgAnchor": "Subway10withanchors.png",
                "model": "Subway10",
                "price": "2360.16928",
                "relProducts": "0,44,12",
                "rotation": 270,
                "title": "Subway 10",
                "width": "42.56",
                "xPos": 619.32,
                "xPos3D": 640.6,
                "yPos": 497.92,
                "yPos3D": 515,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "04Runway10ft",
                "height": "42.70",
                "id": "product_65",
                "img": "Speedway-10.png",
                "imgNew": "Speedway-10.png",
                "imgAnchor": "undefined",
                "model": "SwWay_10",
                "price": "962.175527",
                "relProducts": "0,64,66",
                "rotation": 90,
                "title": "Speedway 10",
                "width": "21.98",
                "xPos": 590.61,
                "xPos3D": 601.6,
                "yPos": 493.65,
                "yPos3D": 515,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "6",
                "brocap": "5",
                "category": "Swing & Climb",
                "depth": "3.66",
                "folder": "skyrocket",
                "height": "112.00",
                "id": "product_10",
                "img": "skyrocket.png",
                "imgNew": "skyrocket.png",
                "imgAnchor": "SkyRocketwithanchors.png",
                "model": "Sky_Rocket",
                "price": "7282.95537",
                "relProducts": "0,37,9",
                "rotation": 90,
                "title": "Sky Rocket",
                "width": "112.00",
                "xPos": "467.60",
                "xPos3D": "523.60",
                "yPos": 459,
                "yPos3D": 515,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "630.96",
                "xPos3D": "641.60",
                "yPos": 472.36,
                "yPos3D": 483,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 315,
                "title": "Bendback",
                "width": "64.68",
                "xPos": "621.26",
                "xPos3D": "653.60",
                "yPos": 432.88,
                "yPos3D": 448,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 135,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 603.61,
                "xPos3D": 614.6,
                "yPos": 364.72,
                "yPos3D": 407,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 677.96,
                "xPos3D": 688.6,
                "yPos": "426.36",
                "yPos3D": "437.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "6",
                "category": "Universal",
                "depth": "1.21",
                "folder": "UniversalConnection",
                "height": "53.90",
                "id": "product_35",
                "img": "Universal.png",
                "imgNew": "Universal.png",
                "imgAnchor": "Universalwithanchors.png",
                "model": "Uni_Connection",
                "price": "1960.49137",
                "relProducts": "0,34,92",
                "rotation": 0,
                "title": "Universal Connection",
                "width": "53.90",
                "xPos": 700.65,
                "xPos3D": 727.6,
                "yPos": "409.05",
                "yPos3D": "436.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 0,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 716.61,
                "xPos3D": 727.6,
                "yPos": 324.72,
                "yPos3D": 367,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "03Runway20ft",
                "height": "84.56",
                "id": "product_64",
                "img": "Speedway-20.png",
                "imgNew": "Speedway-20.png",
                "imgAnchor": "undefined",
                "model": "SWay_20",
                "price": "1428.34753",
                "relProducts": "0,65,66",
                "rotation": 45,
                "title": "Speedway 20",
                "width": "21.98",
                "xPos": 830.61,
                "xPos3D": 841.6,
                "yPos": 363.72,
                "yPos3D": 406,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": "754.96",
                "xPos3D": "765.60",
                "yPos": 424.36,
                "yPos3D": 435,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "bendback",
                "height": "30.24",
                "id": "product_17",
                "img": "bendback.png",
                "imgNew": "bendback.png",
                "imgAnchor": "Bendbackwithanchors.png",
                "model": "Bendback",
                "price": "1579.65599",
                "relProducts": "0,18,63",
                "rotation": 45,
                "title": "Bendback",
                "width": "64.68",
                "xPos": 768.26,
                "xPos3D": 800.6,
                "yPos": 431.88,
                "yPos3D": 447,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 801.96,
                "xPos3D": 812.6,
                "yPos": 470.36,
                "yPos3D": 481,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "5",
                "category": "Core 10ft",
                "depth": "1.21",
                "folder": "barricade",
                "height": "27.72",
                "id": "product_12",
                "img": "Barricade10.png",
                "imgNew": "Barricade10.png",
                "imgAnchor": "Barricade10withanchors.png",
                "model": "Barricade_10",
                "price": "1772.67044",
                "relProducts": "0,11,47",
                "rotation": 90,
                "title": "Barricade 10",
                "width": "42.56",
                "xPos": 791.32,
                "xPos3D": 812.6,
                "yPos": 499.14,
                "yPos3D": 513,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "22SwimstepXL",
                "height": "21.28",
                "id": "product_67",
                "img": "SSXL.png\r",
                "imgNew": "SSXL.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_XL",
                "price": "686.389297",
                "relProducts": "0,68,64",
                "rotation": 0,
                "title": "Swimstep XL",
                "width": "27.72",
                "xPos": 825.74,
                "xPos3D": 839.6,
                "yPos": 502.36,
                "yPos3D": 513,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "8",
                "brocap": "12",
                "category": "Jump",
                "depth": "2.84",
                "folder": "Thor",
                "height": "63.00",
                "id": "product_206",
                "img": "thor.png",
                "imgNew": "thor.png",
                "imgAnchor": "Thor-Anchor.png",
                "model": "Thor",
                "price": "13527.2328",
                "relProducts": ",,",
                "rotation": 0,
                "title": "Thor",
                "width": "126.00",
                "xPos": 853.6,
                "xPos3D": 916.6,
                "yPos": "479.50",
                "yPos3D": "511.00",
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 800.96,
                "xPos3D": 811.6,
                "yPos": 535.36,
                "yPos3D": 546,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 979.96,
                "xPos3D": 990.6,
                "yPos": 502.36,
                "yPos3D": 513,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "4",
                "brocap": "2",
                "category": "Corners",
                "depth": "1.21",
                "folder": "kickback",
                "height": "30.24",
                "id": "product_18",
                "img": "Kickback.png",
                "imgNew": "Kickback.png",
                "imgAnchor": "Kickbackwithanchors.png",
                "model": "Kickback",
                "price": "1699.00778",
                "relProducts": "0,17,63",
                "rotation": 135,
                "title": "Kickback",
                "width": "64.68",
                "xPos": 767.26,
                "xPos3D": 799.6,
                "yPos": 566.88,
                "yPos3D": 582,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "0",
                "brocap": "0",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "23Swimstep",
                "height": "21.28",
                "id": "product_68",
                "img": "SS5x5.png\r",
                "imgNew": "SS5x5.png\r",
                "imgAnchor": "undefined",
                "model": "Swimstep_5x5",
                "price": "636.92625",
                "relProducts": "0,67,65",
                "rotation": 0,
                "title": "Swimstep 5x5",
                "width": "21.28",
                "xPos": 753.96,
                "xPos3D": 764.6,
                "yPos": 583.36,
                "yPos3D": 594,
                "zPos": 0
            },
            {
                "active": false,
                "anchors": "2",
                "brocap": "8",
                "category": "Connectors",
                "depth": "1.21",
                "folder": "49WalkonWater",
                "height": "21.00",
                "id": "product_62",
                "img": "Wal-On-Water.png\r",
                "imgNew": "Wal-On-Water.png\r",
                "imgAnchor": "undefined",
                "model": "Walk_on_Water",
                "price": "1746.7119",
                "relProducts": "0,66,63",
                "rotation": 45,
                "title": "Walk On Water 20",
                "width": "84.00",
                "xPos": 798.6,
                "xPos3D": 840.6,
                "yPos": 610.5,
                "yPos3D": 621,
                "zPos": 0
            },
            {
                "active": true,
                "anchors": "4",
                "brocap": "5",
                "category": "Slides",
                "depth": "2.44",
                "folder": "19FreefallExtreme",
                "height": "88.90",
                "id": "product_23",
                "img": "freefallextreme.png",
                "imgNew": "freefallextreme.png",
                "imgAnchor": "Freefallextremewithanchors.png",
                "model": "Freefall_Ext",
                "price": "4888.03437",
                "relProducts": "0,21,26",
                "rotation": 315,
                "title": "Freefall Extreme",
                "width": "38.36",
                "xPos": 881.42,
                "xPos3D": 900.6,
                "yPos": "638.55",
                "yPos3D": "683.00",
                "zPos": 0
            }
        ]
    }
]

export default preBuiltParks


import React from 'react';
import ProductGroup from '../product-group/product-group.component'
import productData from '../../productData';
import Summary from '../summary/summary.component'
import NextPiece from '../next-piece/next-piece.component'
import TotalPrice from '../total-price/total-price.component'
import './sidebar.styles.scss'
import getUserCountry from "js-user-country";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);

        // getting data - exported from Google Sheets as JSON
        this.state = {
            featureView: true,
            products: productData,
            regionSelected: 'iePrice'
        }
    }

    // open and closes sidebar category dropdown lists
    handleClick(event) {
        if (event.target.id === 'gallery-open') {
            return
        }
        if(event.target.classList.contains('products__group--open')) {
            event.target.classList.remove('products__group--open')
            return
        } else {
            const productGroups = document.querySelectorAll('.products__group')
            productGroups.forEach(productGroup => {
                productGroup.classList.remove('products__group--open')
            })
            event.target.classList.add('products__group--open')
        }
    }

    // if a category has no items in it, it hides itself
    checkProductLists() {
        const productLists = document.querySelectorAll('.products__group ul')
        productLists.forEach((sideBarList) => {
            const catItems = sideBarList.childNodes.length
            catItems === 0 && sideBarList.parentNode.classList.add('display-none')
        })
    }

    getProductByCategory() {
        const euroMode = false
        let activeCats = []
        let productList = []

        // TD: clean this up, too much repeated
        if (euroMode) {
            // if the active cats array has a cat skip it else add it
            this.state.products.map((product) => (
                product.euroCategory === undefined || activeCats.includes(product.euroCategory) || activeCats.push(product.euroCategory)
            ))
            // sort the array, the categories start with a number for Euro 
            // which is removed in product-group.component
            activeCats.sort()
            // a new data model for each unique category to list their products
            activeCats.forEach( (euroCategory, i) => (
                productList.push({category: euroCategory, id: i, items: []})
            ))
            // push each product to the items array of the parent category
            this.state.products.map((product) => (
                product.euroCategory === undefined || productList[activeCats.indexOf(product.euroCategory)].items.push(product)
            ))

        } else {
            // if the active cats array has a cat skip it else add it
            this.state.products.map((product) => (
                activeCats.includes(product.category) || activeCats.push(product.category)
            ))
            // a new data model for each unique category to list their products
            activeCats.forEach( (category, i) => (
                productList.push({category: category, id: i, items: []})
            ))
            // push each product to the items array of the parent category
            this.state.products.map((product) => (
                productList[activeCats.indexOf(product.category)].items.push(product)
            )) 
        }

        return productList;
    }

    componentDidMount() {
        this.getUserRegion()
        this.checkProductLists()

        const localDev = true
        // load all images needed into JS memory to stop drag preview error
        productData.forEach(product => {
            if (product.image && product.model && product.widthM) {
                const img=new Image()
                img.src = `${localDev ? '' : '/aqua-park-builder'}/img/products-new/${product.imageNew}`                
            }
        })
    }

    getUserRegion() {
        // this will gets a more standardized string, no caps & no white space
        const userCountry = getUserCountry().name.toLowerCase().replace(/\s/g, "");

        const date = new Date()
        // returns MM-DD for simple comparison 
        const dateThisYear = date.toISOString().substring(5,10)

        // this MM-DD string is: March 1st (the last day of annual pre-price sale)
        const lastDayOfSale = '01-01'

        // const test = '12-31'

        // console.log("xxx")
        // console.log(dateThisYear)
        // console.log(lastDayOfSale)
        // console.log(test)
        // console.log(test < lastDayOfSale)

        let currencyString = 'EUR';

        // removing any currency classes in case user come from shared park in another region
        // document.body.classList.remove('gbpprePrice', 'gbpPrice', 'ieprePrice', 'iePrice', 'usdPrice', 'denmark', 'france', 'poland')

        // testing for Poland and France
        //document.body.classList.remove('gbpprePrice', 'gbpPrice', 'ieprePrice', 'iePrice', 'usdPrice', 'denmark')


        // Attempting to fix problem for Denmark 13/03/2024

        document.body.classList.remove('gbpprePrice', 'gbpPrice', 'ieprePrice', 'iePrice', 'usdPrice')


        // by default the currency is set to IE Euros
        switch(userCountry.length > 0 && dateThisYear.length > 0) {
            case userCountry === 'unitedkingdom' && dateThisYear < lastDayOfSale :
                this.setState({ regionSelected: 'gbpprePrice' })
                document.body.classList.add('gbpprePrice')
                document.body.dataset.email = 'sales@aquaparks.co.uk'
                currencyString = 'GBP'
                break;
            case userCountry === 'unitedkingdom' :
                this.setState({ regionSelected: 'gbpPrice' })
                document.body.classList.add('gbpPrice')
                document.body.dataset.email = 'sales@aquaparks.co.uk'
                currencyString = 'GBP'
                break;
            case userCountry === 'ireland' && dateThisYear < lastDayOfSale :
                this.setState({ regionSelected: 'ieprePrice' })
                document.body.classList.add('ieprePrice' )
                document.body.dataset.email = 'europe@aquaglide.com'
                break;
            case userCountry === 'canada' :
                this.setState({ regionSelected: 'usdPrice' })
                document.body.classList.add('usdPrice')
                currencyString = 'USD'
                document.body.dataset.email = 'jeffc@aquaglide.com'
                break;
            case userCountry === 'mexico' :
                this.setState({ regionSelected: 'usdPrice' })
                document.body.classList.add('usdPrice')
                currencyString = 'USD'
                document.body.dataset.email = 'jeffc@aquaglide.com'
                break;
            case userCountry === 'unitedstatesofamerica' :
                this.setState({ regionSelected: 'usdPrice' })
                document.body.classList.add('usdPrice')
                currencyString = 'USD'
                document.body.dataset.email = 'jeffc@aquaglide.com'
                break;
            // case userCountry === 'poland':
            //     this.setState({ regionSelected: 'poland' })
            //     document.body.classList.add('poland')
            //     currencyString = 'PLN'
            //     document.body.dataset.email = 'europe@aquaglide.com'
            //     break;

            // Attempting to fix problem for Denmark 13/03/2024

            // case userCountry === 'denmark':
            //     this.setState({ regionSelected: 'denmark' })
            //     document.body.classList.add('denmark')
            //     currencyString = 'DKK'
            //     document.body.dataset.email = 'europe@aquaglide.com'
            //     break;
            case userCountry === 'france':
                this.setState({ regionSelected: 'france' })
                document.body.classList.add('france')
                document.body.dataset.email = 'europe@aquaglide.com'
                break;
            case dateThisYear < lastDayOfSale :
                this.setState({ regionSelected: 'ieprePrice' })
                document.body.classList.add('ieprePrice')
                document.body.dataset.email = 'europe@aquaglide.com'
                break;
            default:
                this.setState({ regionSelected: 'iePrice' })
                document.body.classList.add('iePrice')
                document.body.dataset.email = 'europe@aquaglide.com'
        }
        console.log('you are visiting from ' + getUserCountry().name);
        window.localStorage.setItem('location', getUserCountry().name)
        window.localStorage.setItem('currency', currencyString)
    }

    changeRegion(e) {
        const region = e.target.value
        this.setState({ regionSelected: region })
    }

    toggleSidebar = (e) => {
        if(e.target.id === 'jsToggleSummary') {
            this.setState({ featureView: false })
            document.querySelector('body').classList.add('freezeUi')
        } else {
            this.setState({ featureView: true })
            document.querySelector('body').classList.remove('freezeUi')
        }
    }

    render() {
        const productList = this.getProductByCategory()
        return(
            <aside id='jsSidebar' data-park-depth={this.props.parkDepth} className={`sidebar ${this.state.regionSelected} ${this.props.disabled ? 'disabled' : null}`}>
                <div className="sidebar__inner">

                    <div className="sidebar__toggle">
                        <div className="sidebar__toggle-item">
                            <button onClick={this.toggleSidebar} id="jsToggleFeature" className={`button button--square bold ${this.state.featureView ? '' : 'button--inactive'}`}>Feature</button>
                        </div>

                        <div className="sidebar__toggle-item">
                            <button onClick={this.toggleSidebar} id="jsToggleSummary" className={`button button--square bold ${this.state.featureView ? 'button--inactive' : ''}`}>Summary</button>
                        </div>
                    </div>

                    {this.state.featureView ? 

                        <div className="sidebar__products">
                            <div className='products' onClick={this.handleClick}>
                                {
                                    productList.map(
                                        ({category, euroPrice, id, ...categoryItems}) => (
                                            <ProductGroup key={id} parkDepth={this.props.parkDepth} category={category} {...categoryItems} region={this.state.regionSelected} />
                                        )
                                    )
                                }
                            </div> 
                            <TotalPrice regionSelected={this.state.regionSelected} />
                        </div>                   
                    : 
                        <Summary currentUser={this.props.currentUser} currency={this.state.regionSelected}></Summary>
                    }
                    {
                        // Next Piece Component if it has the data it needs
                        this.props.relatedProductIds && <NextPiece productIDs={this.props.relatedProductIds} productArray={this.state.products} />
                    }
                </div>

                {
                    // this region select was removed but might come back ¯\_(ツ)_/¯
                }
                <div className="region-select">
                    <label htmlFor="region">Select Region:</label>
                    <select name="region" id="region" onChange={this.changeRegion.bind(this)}>
                        <option value="gbpPrice">GBP Price</option>
                        <option value="usdPrice">USD Price</option>
                        <option value="gbpprePrice">GBP Pre Price</option>
                        <option value="iePrice">IE Price</option>
                        <option value="ieprePrice">IE Pre Price</option>
                        <option value="euukPrice">EU UK Price</option>
                        <option value="eunlPrice">EU NL Price</option>
                        <option value="nlPrice">NL Price</option>	
                    </select>
                </div>
            </aside>
        )
    }
}

export default Sidebar;
import React from 'react'
import EditParkItem from '../edit-park-item/edit-park-item.component'

const DropZoneDragItem = (props) => {

    const localDev = true
    let urlString = ''

    if (!localDev) {
        urlString = '/aqua-park-builder'
    }

    // getting the min depth color string for each item
    let depthColor = ''
    const minDepth = Number(props.depth).toFixed(1)

    switch(minDepth > 0) {
        case minDepth < 1.5 :
            depthColor = 'water-depth1'
            break;
        case minDepth < 2 :
            depthColor = 'water-depth2'
            break;
        case minDepth < 2.5 :
            depthColor = 'water-depth3'
            break;
        case minDepth < 3 :
            depthColor = 'water-depth4'
            break;
        case minDepth < 3.5 :
            depthColor = 'water-depth5'
            break;
        case minDepth > 3.6 :
            depthColor = 'water-depth6'
            break;
        default:
            depthColor = ''
    }

    const dragStart = (e) => {
        // sending data for moving a park item
        e.dataTransfer.setData('origin', e.target.getAttribute('data-origin'))
        e.dataTransfer.setData('arrayPos', e.target.getAttribute('data-array-pos'))
        e.dataTransfer.setData('height', props.height)
        e.dataTransfer.setData('width', props.width)
        e.dataTransfer.setData('depth', props.depth)
        createDragPreview(e)

        const target = e.target
        // this hides the park item
        setTimeout(() => {
            target.style.display = 'none'
        }, 5)
    }

    const dragOver = (e) => {
        e.stopPropagation()
    }

    const dragEnd = (e) => {
        e.stopPropagation()
        const target = e.target
        // this hides the park item
        setTimeout(() => {
            target.style.display = 'inline-block'
        }, 5)
    }

    const createDragPreview = (e) => {
        // create the image to go inside the preview
        const previewImg = document.createElement("img")
        previewImg.src = `${urlString}/img/products-new/${props.img}`
        previewImg.style.width = `${props.width}px`
        previewImg.style.height = `${props.height}px`
        previewImg.style.transform = `rotate(${props.rotation}deg)`

        // get the bigger value so previews can handle 45 degree turns
        const height = Number(props.height) + 100
        const width = Number(props.width) + 100
        const biggerValue = height >= width ? height : width
        
        // create the div for the image to attach to
        const previewDiv = document.createElement('div')
        previewDiv.style.position = 'relative'
        previewDiv.style.width = biggerValue + 'px'
        previewDiv.style.height = biggerValue + 'px'
        previewDiv.style.display = 'flex'
        previewDiv.style.justifyContent = 'center'
        previewDiv.style.alignItems = 'center'

        previewDiv.appendChild(previewImg)

        // make a buffer div to stop buggy lines on preview
        const bufferLoadZone = document.createElement('div')
        bufferLoadZone.classList.add('buffer-load-zone')
        bufferLoadZone.appendChild(previewDiv)

        // append it to the body (removed on drop in drop-zone-target)
        document.querySelector('body').appendChild(bufferLoadZone)
        
        // fix drag preview to the center of itself so the curser is always in the middle
        e.dataTransfer.setDragImage(previewDiv, (biggerValue / 2), (biggerValue / 2))
    }

    return(
        <div 
            id={props.id}
            data-array-pos={props.arrayPos}
            className={props.active ? `park-item park-item--active` : `park-item ${depthColor}` }
            draggable='true'
            data-origin='drop-zone'
            data-rotation-style={props.rotation}
            onDragStart={dragStart}
            onDragOver={dragOver}
            onDragEnd={dragEnd}
            style={{
                height: `${props.height}px`,
                left: `${props.xPos}px`,
                top: `${props.yPos}px`,
                width: `${props.width}px`,
                transform: `rotate(${props.rotation}deg)`
            }}
        >
            {
                props.active ? 
                <EditParkItem 
                    rotateClicked={props.rotateClicked} 
                    deleteClicked={props.deleteClicked} 
                    duplicate={props.duplicate} 
                    arrayPos={props.arrayPos} 
                    height={props.height}
                    width={props.width}
                /> 
                : 
                null
            }
            <img 
                src={`${urlString}/img/products-new/${props.img}`}
                alt={props.title}
                style={{
                    height: `${props.height}px`,
                    width: `${props.width}px`
                }}
            />

            {
                props.imgAnchor !== 'undefined' &&  props.imgAnchor !== undefined ?
                    <img 
                        src={`${urlString}/img/products-new/${props.imgAnchor}`}
                        alt={props.title}
                        className="park-item__anchor-overlay"
                        style={{
                            height: `${props.height}px`,
                            width: `${props.width}px`
                        }}
                    />
                : null
            }
        </div>  
    )
}

export default DropZoneDragItem
import React from 'react';
import './intro-overlay.styles.scss'

const IntroOverlay = () => {
    const handleClick = (e) => {
        e.preventDefault()
        document.body.classList.add('navOpen')        
    }

    const euroMode = false
    const localDev = true
    let urlString = ''

    if (!localDev) {
        urlString = '/aqua-park-builder'
    }

    return (
        <div id="jsIntro" className={`intro-overlay ${euroMode ? 'intro-overlay--euro' : ''}`} style={{'backgroundImage': `url(${urlString}/img/${euroMode ? 'intro-euro.jpg' : 'intro.jpg'})`}}>
            <div onClick={handleClick} className="intro-overlay__inner">
                <img className="logo" src={`${urlString}/img/${euroMode ? 'logo-euro.png' : 'logo.png'}`} alt="Aqua Glide Logo" />
                <h1 className="grey-light">Aqua Park Builder</h1>
                <button className='button' onClick={handleClick}>Get Started</button>
            </div>
        </div>
    )
}

export default IntroOverlay
import React from 'react';
import DropZoneDragItem from '../drop-zone-draggable/drop-zone-draggable.component'
import './drop-zone-target.scss'

class DropZoneTarget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'parkItems': props.savedItems ? props.savedItems : []
        }
    }
   
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);

        if (this.state.parkItems.length) {
            this.updateTotalPrice()
            this.updateTotalCapacity()
            this.updateTotalAnchors()
        }
    }

    saveStateFor3D = () => {
        const savedString = JSON.stringify(this.state.parkItems)
        window.localStorage.setItem('grid', savedString)
    }

    drop = (e) => {
        e.preventDefault()
        e.target.classList.remove('drag-over')

        // this removes the element appended for the drag preview
        const body = document.querySelector('body')
        body.removeChild(body.lastChild)

        // if there are park items in the drop zone set them all inactive
        if(this.state.parkItems.length > 0) {
            this.setAllParkItemsInactive()
        }

        // is the dropped item new from sidebar or moving existing park item?
        const origin = e.dataTransfer.getData('origin')
        origin === 'sidebar' ? this.newParkItemDrop(e) : this.moveExistingParkItem(e)
        
        // save the park items object to be rendered in 3D
        this.saveStateFor3D()

        // finally update the total UI in the sidebar
        this.updateTotalPrice()
        this.updateTotalCapacity()
        this.updateTotalAnchors()
    }

    newParkItemDrop = (e) => {        
        // this is a new park item being dropped
        const productId = e.dataTransfer.getData('product_id')
        const dropZone = document.querySelector('#jsDropZone')
        let parkItemsArray = this.state.parkItems
        let parkItemData = {
            'active':       false,
            'anchors':      e.dataTransfer.getData('anchors'),
            'brocap':       e.dataTransfer.getData('brocap'),
            'category':     e.dataTransfer.getData('category'),
            'depth':        e.dataTransfer.getData('depth'),
            'folder':       e.dataTransfer.getData('folder'),
            'height':       e.dataTransfer.getData('height'),
            'id':           productId,
            'img':          e.dataTransfer.getData('imgUrl'),
            'imgNew':       e.dataTransfer.getData('imgNew'),
            'imgAnchor':    e.dataTransfer.getData('imgAnchor'),
            'model':        e.dataTransfer.getData('model'),
            'price':        e.dataTransfer.getData('price'),
            'relProducts':  e.dataTransfer.getData('relatedProducts'),
            'rotation':     Number(e.dataTransfer.getData('rotation')),
            'title':        e.dataTransfer.getData('name'),
            'width':        e.dataTransfer.getData('width'),
            'xPos':         this.getPositionX(e, dropZone),
            'xPos3D':       this.getPositionX3D(e, dropZone),
            'yPos':         this.getPositionY(e, dropZone),
            'yPos3D':       this.getPositionY3D(e, dropZone),
            'zPos':         0,
        }
        parkItemsArray.push(parkItemData)
        this.setState({'parkItems': parkItemsArray})
    }

    moveExistingParkItem = (e) => {
        // this is an existing park item being moved
        let parkItemsArray = this.state.parkItems
        const arrayPos = e.dataTransfer.getData('arrayPos')
        const dropZone = document.querySelector('#jsDropZone')
        // update the 2D and 3D position for the park item
        parkItemsArray[arrayPos].xPos = this.getPositionX(e, dropZone)
        parkItemsArray[arrayPos].yPos = this.getPositionY(e, dropZone)
        parkItemsArray[arrayPos].xPos3D = this.getPositionX3D(e, dropZone)
        parkItemsArray[arrayPos].yPos3D = this.getPositionY3D(e, dropZone)
        // save the state and the drop-zone will render again
        this.setState({'parkItems': parkItemsArray})
    }

    getPositionX = (event, target) => {
        // xPos = distance to screen left minus the left position inside the drop zone
        // minus half the width of the element being dragged to get dead center
        const xPos = (event.clientX - target.getBoundingClientRect().left).toFixed(2)
        const widthOffset = event.dataTransfer.getData('width') / 2
        return (xPos - widthOffset).toFixed(2)
    }

    getPositionY = (event, target) => {
        // same as xPos with top and height values
        const yPos = (event.clientY - target.getBoundingClientRect().top).toFixed(2)
        const heightOffset = event.dataTransfer.getData('height') / 2
        return (yPos - heightOffset).toFixed(2)
    }

    getPositionX3D = (event, target) => {
        // 3D values must be set from the objects center point
        const xPos3D = (event.clientX - target.getBoundingClientRect().left).toFixed(2)
        return xPos3D
    }

    getPositionY3D = (event, target) => {
        // 3D values must be set from the objects center point
        const yPos3D = (event.clientY - target.getBoundingClientRect().top).toFixed(2)
        return yPos3D
    }

    dragOver = (e) => {
        // drag over class stops drop interaction with other placed elements
        e.preventDefault()
        e.target.classList.add('drag-over')
    }

    dragOut = (e) => {
        e.preventDefault()
        e.target.classList.remove('drag-over')
    }

    setAllParkItemsInactive = () => {
        let parkItemsArray = this.state.parkItems
        parkItemsArray.forEach(parkItems => {
            parkItems.active = false
        })
        this.setState({'parkItems': parkItemsArray})
    }

    setParkItemActive = (target) => {
        let parkItemsArray = this.state.parkItems
        // set all the park items in the array to active = false
        parkItemsArray.forEach(parkItems => {
            parkItems.active = false
        })
        // set the park item that was clicked to active
        const arrayPos = target.getAttribute('data-array-pos')
        parkItemsArray[arrayPos].active = true
        
        // getting and setting data need for the 3 related products area
        const relatedProductIds = this.getRelatedProducts(parkItemsArray[arrayPos].relProducts)
        this.props.setRelatedProducts(relatedProductIds)

        // save the array to the state of the component
        this.setState({'parkItems': parkItemsArray})
    }

    // a function that returns an array from the string of data saved
    getRelatedProducts = (relatedProducts) => {
        return relatedProducts.split(',')
    }

    // a reusable function to return a string that looks like money 1234567.89 => 1,234,567.89
    formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
        try {
            decimalCount = Math.abs(decimalCount)
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount
            const negativeSign = amount < 0 ? "-" : ""
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
            let j = (i.length > 3) ? i.length % 3 : 0
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
        } catch (e) {
            console.log('there was an error formatting your money: ' + e)
        }
    }

    updateTotalPrice = () => {
        let totalPrice = 0
        const priceEle = document.querySelector('#js-total-price-tag')

        // loop over all the park items to return a total price
        if (this.state.parkItems.length > 0) {
            this.state.parkItems.map((parkItem) => {
                const itemPrice = Number(parkItem.price.replace(/£/g, "").replace(/,/g, ""))
                totalPrice = totalPrice + itemPrice
                return priceEle.innerHTML = `${this.formatMoney(totalPrice)}`
            })
        } else {
            return priceEle.innerHTML = `${this.formatMoney(totalPrice)}`
        }
    }

    updateTotalCapacity = () => {
        let totalCap = 0
        const capacityEle = document.querySelector('#js-total-cap-tag')

        // loop over all the park items to return a total capacity
        if (this.state.parkItems.length > 0) {
            this.state.parkItems.map((parkItem) => {
                // Connectors will not be counted in the total
                const brocap = parkItem.category === 'Connectors' ? 0 : Number(parkItem.brocap)
                totalCap = totalCap + brocap
                return capacityEle.innerHTML = totalCap
            })
        } else {
            return capacityEle.innerHTML = totalCap
        }
    }

    updateTotalAnchors = () => {
        let totalAnchors = 0
        const capacityEle = document.querySelector('#js-total-anchors-tag')

        // loop over all the park items to return a total anchor points
        if (this.state.parkItems.length > 0) {
            this.state.parkItems.map((parkItem) => {
                // Connectors will not be counted in the total
                const anchors = parkItem.category === 'Connectors' ? 0 : Number(parkItem.anchors)
                totalAnchors = totalAnchors + anchors
                return capacityEle.innerHTML = totalAnchors
            })
        } else {
            return capacityEle.innerHTML = totalAnchors
        }
    }

    handleClick = (e) => {
        if (e.target.classList.contains('rotate-item')) { return }
        
        if(!e.target.classList.contains('park-item')) {
            // the drop zone / water was clicked
            this.setAllParkItemsInactive()
        } else {
            // a park item was clicked
            this.setParkItemActive(e.target)
        }
    }

    rotateClicked = (e) => {
        // get the park items from state
        let parkItemsArray = this.state.parkItems
        const arrayPos = e.target.getAttribute('data-array-pos')

        if (e.target.id === 'jsTurnLeft') {
            // rotate the one that was clicked right
            let rotation = parkItemsArray[arrayPos].rotation - 45
            if(rotation < 0) { rotation = 315 }
            parkItemsArray[arrayPos].rotation = rotation
            this.setState({'parkItems': parkItemsArray})

        } else {
            // rotate the one that was clicked right
            let rotation = parkItemsArray[arrayPos].rotation + 45
            if(rotation >= 360) { rotation = 0 }
            parkItemsArray[arrayPos].rotation = rotation
            this.setState({'parkItems': parkItemsArray})
        }
        
        // save the park items object to be rendered in 3D
        this.saveStateFor3D()
    }

    deleteClicked = (e) => {
        // get the park items from state
        let parkItemsArray = this.state.parkItems
        const arrayPos = e.target.getAttribute('data-array-pos')
        // delete this one item from the park items array
        parkItemsArray.splice(arrayPos, 1)
        this.setState({'parkItems': parkItemsArray})
        // save the park items object to be rendered in 3D
        this.saveStateFor3D()

        // finally update the totals UI in the sidebar
        this.updateTotalPrice()
        this.updateTotalCapacity()
        this.updateTotalAnchors()
    }

    duplicateParkItem = (e) => {
        // get the park items from state
        let parkItemsArray = this.state.parkItems
        const arrayPos = e.target.getAttribute('data-array-pos')

        let duplicateParkItem = {
            'active':       parkItemsArray[arrayPos].active,
            'anchors':      parkItemsArray[arrayPos].anchors,
            'brocap':       parkItemsArray[arrayPos].brocap,
            'category':     parkItemsArray[arrayPos].category,
            'depth':        parkItemsArray[arrayPos].depth,
            'folder':       parkItemsArray[arrayPos].folder,
            'height':       parkItemsArray[arrayPos].height,
            'id':           parkItemsArray[arrayPos].id,
            'img':          parkItemsArray[arrayPos].img,
            'imgNew':       parkItemsArray[arrayPos].imgNew,
            'imgAnchor':   parkItemsArray[arrayPos].imgAnchor,
            'model':        parkItemsArray[arrayPos].model,
            'price':        parkItemsArray[arrayPos].price,
            'relProducts':  parkItemsArray[arrayPos].relProducts,
            'rotation':     parkItemsArray[arrayPos].rotation,
            'title':        parkItemsArray[arrayPos].title,
            'width':        parkItemsArray[arrayPos].width,
            'xPos':         parkItemsArray[arrayPos].xPos,
            'xPos3D':       parkItemsArray[arrayPos].xPos3D,
            'yPos':         parkItemsArray[arrayPos].yPos,
            'yPos3D':       parkItemsArray[arrayPos].yPos3D,
            'zPos':         parkItemsArray[arrayPos].zPos,
        }

        duplicateParkItem.xPos = Number(duplicateParkItem.xPos) + 50
        duplicateParkItem.xPos3D = Number(duplicateParkItem.xPos3D) + 50
        duplicateParkItem.yPos = Number(duplicateParkItem.yPos) + 20
        duplicateParkItem.yPos3D = Number(duplicateParkItem.yPos3D) + 20
        parkItemsArray.push(duplicateParkItem)
        this.setState({'parkItems': parkItemsArray})
        
        // save the park items object to be rendered in 3D
        this.saveStateFor3D()

        // finally update the totals UI in the sidebar
        this.updateTotalPrice()
        this.updateTotalCapacity()
        this.updateTotalAnchors()
    }

    handleKeyPress = (e) => {
        // if it wasn't an arrow key then ignore this
        if (e.which !== 37 && e.which !== 38 && e.which !== 39 && e.which !== 40) {
            return

        } else {
            // an arrow key was pressed, is there an active item?
            e.preventDefault()
            let parkItemsArray = this.state.parkItems

            // get the position of the active park item from the state
            const arrayPos = parkItemsArray.map(function(parkItem) { return parkItem.active; }).indexOf(true)

            // if nothing is active return nothing
            if (arrayPos < 0) {
                return

            } else {
                switch(e.isTrusted) {
                    case e.which === 38:
                        // up arrow pressed, move up
                        parkItemsArray[arrayPos].yPos = Number(parkItemsArray[arrayPos].yPos) - 1
                        parkItemsArray[arrayPos].yPos3D = Number(parkItemsArray[arrayPos].yPos3D) - 1
                        this.setState({'parkItems': parkItemsArray})
                        this.saveStateFor3D()
                        return
                    case e.which === 40:
                        // down arrow pressed, move down
                        parkItemsArray[arrayPos].yPos = Number(parkItemsArray[arrayPos].yPos) + 1
                        parkItemsArray[arrayPos].yPos3D = Number(parkItemsArray[arrayPos].yPos3D) + 1
                        this.setState({'parkItems': parkItemsArray})
                        this.saveStateFor3D()
                        return
                    case e.which === 37:
                        // left arrow pressed, move left
                        parkItemsArray[arrayPos].xPos = Number(parkItemsArray[arrayPos].xPos) - 1
                        parkItemsArray[arrayPos].xPos3D = Number(parkItemsArray[arrayPos].xPos3D) - 1
                        this.setState({'parkItems': parkItemsArray})
                        this.saveStateFor3D()
                        return
                    case e.which === 39:
                        // right arrow pressed, move right
                        parkItemsArray[arrayPos].xPos = Number(parkItemsArray[arrayPos].xPos) + 1
                        parkItemsArray[arrayPos].xPos3D = Number(parkItemsArray[arrayPos].xPos3D) + 1
                        this.setState({'parkItems': parkItemsArray})
                        this.saveStateFor3D()
                        return
                    default:
                        console.log('this can not happen')
                }
            }
        }
    }

    render() {
        return (
            <div 
                id="jsDropZone"
                className="drop-zone__target" 
                onDrop={this.drop}
                onDragOver={this.dragOver}
                onDragLeave={this.dragOut}
                onClick={this.handleClick}
                style={{
                    height: `${this.props.height}px`,
                    width: `${this.props.width}px`
                    }} >
                {
                    // this renders park items added
                    this.state.parkItems.map(
                        (parkItem, i) => (
                            <DropZoneDragItem
                                active={parkItem.active}
                                arrayPos={i}
                                category={parkItem.category}
                                deleteClicked={this.deleteClicked}
                                depth={parkItem.depth}
                                duplicate={this.duplicateParkItem}
                                height={parkItem.height}
                                id={`park-item-${i}`}
                                img={parkItem.img} 
                                imgAnchor={parkItem.imgAnchor} 
                                key={i}
                                rotateClicked={this.rotateClicked}
                                rotation={parkItem.rotation}
                                title={parkItem.title}
                                width={parkItem.width}
                                xPos3D={parkItem.xPos3D}
                                xPos={parkItem.xPos}
                                yPos3D={parkItem.yPos}
                                yPos={parkItem.yPos}
                            />
                        )
                    )
                }
            </div>
        )
    }
}

export default DropZoneTarget;
import React from 'react'
import './saved-build-item.styles.scss'

const SavedBuildItem = (props) => (
    <li onClick={(e) => props.getSelectedParkData(e, props.info, props.items)} className='saved-build-item' >
        <div className='saved-build-item__img'>
            <img src={`img/products-new/${props.info.parkIcon ? props.info.parkIcon : 'Universal.png'}`} alt="icon of first park item"></img>
        </div>

        <div className='saved-build-item__text'>
            <h2>{props.info.name}</h2>
            <p className='time-stamp'>{props.info.timeStamp}</p>
            <p className='park-size-text'>{props.info.text}</p>
            <p id='jsCopyID' className="copyID">Saved Park ID: <span className='no-click'>{props.info.parkID ? props.info.parkID : 'local save'}</span></p>
            <p className="copyID copyID--pre-build">Saved Park ID: <span className='no-click'>Pre Built Park</span></p>
        </div>
    </li>
)

export default SavedBuildItem
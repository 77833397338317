import React from 'react';
import './new-form-build1.styles.scss';

const NewBuildForm1 = (props) => (
    <div id='new-build-section--one' className='new-build-section new-build-section--open'>
        <h2>New Build</h2>
        <label htmlFor='name-your-park'>
            <span className='ukShow'>Name your park</span>
            <span className='euroShow'>Name your project</span>
            <input type='text' placeholder='My Project' id='name-your-park' onKeyUp={props.getParkName} />
        </label>
        <p>1 of 5</p>
        <button className="button nextBuildForm" id='two'>Next</button>
    </div>
)

export default NewBuildForm1;
import React from 'react'
import './edit-width-height.styles.scss'

const EditParkWidthHeight = ({ update, close, width, height }) => (
    <div id='jsEditPopup' className='edit-width-height'>
        <button onClick={close} className='button button--close'>x</button>

        <form id='edit-width-height'>
            <div>
                <label htmlFor='jsEdit-width'>Width</label>
                <input type='tel' id='jsEdit-width' placeholder={Number(width) / 14 }></input>
            </div>
            <div>
                <label htmlFor='jsEdit-height'>Height</label>
                <input type='tel' id='jsEdit-height' placeholder={Number(height) / 14 }></input>
            </div>
        </form>

        <button onClick={update} className='button button--update'>Update</button>

    </div>
)

export default EditParkWidthHeight
import React from 'react'
import DropZoneTarget from '../drop-zone-target/drop-zone-target.component'
import WaterDepth from '../water-depth/water-depth.component'
import './drop-zone.styles.scss'

const DropZone = (props) => {

    const width = props.gridState.parkWidth
    const height = props.gridState.parkHeight
    const setRelatedProducts = props.setRelatedProducts
    const savedItems = props.gridState.savedItems ? props.gridState.savedItems : null

    const parkSizeObj = {
        'width': (width / 14).toFixed(0) + 'm',
        'height': (height / 14).toFixed(0) + 'm',
    }
    const parkSize = JSON.stringify(parkSizeObj)
    window.localStorage.setItem('parkSize', parkSize)

    return(
        <section id="jsPdfImage" className="drop-zone">
            <DropZoneTarget 
                width={width} 
                height={height} 
                setRelatedProducts={setRelatedProducts} 
                savedItems={savedItems}
            />
            <WaterDepth />
        </section>
    )
}

export default DropZone
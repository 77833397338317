import React from 'react';
import NewBuildForm1 from './new-build-form1/new-build-form1.component'
import NewBuildForm2 from './new-build-form2/new-build-form2.component'
import NewBuildForm3 from './new-build-form3/new-build-form3.component'
import NewBuildForm4 from './new-build-form4/new-build-form4.component'
import NewBuildForm5 from './new-build-form5/new-build-form5.component'
import './new-build-forms.styles.scss';

class NewBuildForms extends React.Component {

    constructor() {
        super();

        this.state = {
            parkName: 'New Park',
            parkWidth: 200,
            parkHeight: 300,
            parkDepth: 5,
            userEmail: '',
            demoMode: false,
            setupReady: false
        }
    }

    handleClick(e) {
        if(e.target.classList.contains('nextBuildForm')) {
            const target = e.target.id
            const formList = document.querySelectorAll('.new-build-section')
            formList.forEach(form => form.classList.remove('new-build-section--open'))
            document.querySelector(`#new-build-section--${target}`).classList.add('new-build-section--open')
        } else {
            return
        } 
    }

    getParkName = e => {
        window.localStorage.setItem('parkName', e.target.value)
        this.setState(
            { parkName: e.target.value }
        )
    }

    getParkWidth = e => {
        this.setState(
            { parkWidth: (Number(e.target.value) * 14).toFixed(2) }
        )
    }

    getParkHeight = e => {
        this.setState(
            { parkHeight: (Number(e.target.value) * 14).toFixed(2) }
        )
    }

    getParkDepth = e => {
        this.setState(
            { parkDepth: Number(e.target.value) }
        )
        window.localStorage.setItem('parkDepth', this.state.parkDepth)
    }

    getUserEmail = e => {
        this.setState(
            { userEmail: e.target.value }
        )
    }

    demoStart = (e) => {
        if (e.target.value === 'Yes') {
            this.setState({ demoMode: true })
        } else if (e.target.value === 'No') {
            this.setState({ demoMode: false })
        }
    }

    launchBuild = () => {
        this.state.demoMode && document.body.classList.add('help--open')
        this.props.setReady(this.state);
    }

    render() {
        return (
            <div className='new-build' onClick={this.handleClick}>
                <NewBuildForm1 
                    parkName={this.state.parkName} 
                    getParkName={this.getParkName}
                />
                <NewBuildForm2 
                    parkWidth={this.state.parkWidth} 
                    getParkWidth={this.getParkWidth} 
                    parkHeight={this.state.parkHeight} 
                    getParkHeight={this.getParkHeight}
                />
                <NewBuildForm3 
                    parkDepth={this.state.parkDepth} 
                    getParkDepth={this.getParkDepth}
                />
                <NewBuildForm4 
                    userEmail={this.state.userEmail}
                    getUserEmail={this.getUserEmail}
                />
                <NewBuildForm5 
                    demoStart={this.demoStart}
                    launchBuild={this.launchBuild}
                />
            </div>
        )
    }
}

export default NewBuildForms;
import React from 'react'
import './help.styles.scss'

const Help = () => {
    const localDev = true
    let urlString = ''

    if (!localDev) {
        urlString = '/aqua-park-builder'
    }

    const clickHandle = (e) => {
        e.preventDefault()
        const id = e.target.id
        
        switch(true) {
            case id === 'one' :
                document.querySelector('#oneWrap').classList.add('hide')
                break;
            case id === 'two' :
                document.querySelector('#twoWrap').classList.add('hide')
                break;
            case id === 'three' :
                document.querySelector('#threeWrap').classList.add('hide')
                break;
            case id === 'four' :
                document.querySelector('#fourWrap').classList.add('hide')
                break;
            case id === 'five' :
                document.querySelector('#fiveWrap').classList.add('hide')
                break;
            case id === 'six' :
                document.querySelector('#sixWrap').classList.add('hide')
                break;
            case id === 'seven' :
                document.querySelector('#sevenWrap').classList.add('hide')
                break;
            case id === 'end' :
                document.body.classList.remove('help--open')
                setTimeout(function() {
                    document.querySelectorAll('.help__item').forEach(item => {item.classList.remove('hide')})
                }, 500)
                break;
            default:
                console.log('a button was clicked that has no been accounted for');
            }
    }

    const closeHelp = () => {
        document.body.classList.remove('help--open')
    }

    return(
        <div className='help'>
            <button onClick={closeHelp} className="button exit-help">
                <img src="/img/icons/close.svg" alt="close menu overlay" />
            </button>
            <div id='oneWrap' className='help__item'>

                <h2 className='ukShow'>Welcome to Aqua Park Builder</h2>
                <h2 className='euroShow'>Welcome to the Park Builder</h2>

                <img src={`${urlString}/img/help/1-intro.jpg`} alt="help lesson"></img>
                <p>Select a product category to get started</p>
                <button id="one" onClick={clickHandle} className="button">Next</button>
            </div>

            <div id='twoWrap' className='help__item'>
                <h2>Drag in park items</h2>
                <img src={`${urlString}/img/help/2-drag.jpg`} alt="help lesson"></img>
                <p>You can drag in park items from the sidebar and drop them into your park</p>
                <button id="two" onClick={clickHandle} className="button">Next</button>
            </div>

            <div id='threeWrap' className='help__item'>
                <h2>Edit a park item</h2>
                <img src={`${urlString}/img/help/3-active.jpg`} alt="help lesson"></img>
                <p>If you click on a park item you can rotate it or delete it from your park</p>
                <button id="three" onClick={clickHandle} className="button">Next</button>
            </div>

            <div id='fourWrap' className='help__item'>
                <h2>But don't forget the <span className="blue">golden</span> rule</h2>
                <img src={`${urlString}/img/help/4-connect.jpg`} alt="help lesson"></img>
                <p>For your park to work in real life you need to follow this pattern:</p>
                <p className="bold">Item &gt; Connector &gt; Item &gt; Connector</p>
                <p>Stand alone park pieces are solo, they don't connect to other pieces</p>
                <button id="four" onClick={clickHandle} className="button">Next</button>
            </div>

            <div id='fiveWrap' className='help__item'>
                <h2>Build it and they will come</h2>
                <img src={`${urlString}/img/help/5-build.jpg`} alt="help lesson"></img>
                <p className='ukShow'>Drag in as many items as you like, make the Aqua Park of your dreams</p>
                <p className='euroShow'>Drag in as many items as you like, make the park of your dreams</p>
                <button id="five" onClick={clickHandle} className="button">Next</button>
            </div>

            <div id='sixWrap' className='help__item'>
                <h2>Need some inspiration?</h2>
                <img src={`${urlString}/img/help/6-next.jpg`} alt="help lesson"></img>
                <p>Open the bottom tab and click an item to see some related items</p>
                <p>Coming soon will be a library of pre-built parks</p>
                <button id="six" onClick={clickHandle} className="button">Next</button>
            </div>

            <div id='sevenWrap' className='help__item'>
                <h2>Save it for later</h2>
                <img src={`${urlString}/img/help/7-save.jpg`} alt="help lesson"></img>
                <p>Press the save button to save your park</p>
                <p className="bold">You can see your saved parks in Menu &gt; Saved Parks</p>
                <p>Please remember each park needs a <strong>unique name</strong></p>
                <button id="seven" onClick={clickHandle} className="button">Next</button>
            </div>

            <div id='endWrap' className='help__item'>
                <h2>Share the build</h2>
                <img src={`${urlString}/img/help/8-share.jpg`} alt="help lesson"></img>
                <p>The share button will export your park code, ready to send</p>
                <p>To build a park from shared code simply paste it into the box in: <strong>Menu &gt; Shared Park</strong></p>
                <button id="end" onClick={clickHandle} className="button">Got it!</button>
            </div>
        </div>
    )
}

export default Help
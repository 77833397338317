import React from 'react'
import './total-features.styles.scss'

const TotalFeatures = () => {
    return (
        <div className="total-features">
            <span id="js-total-cap-tag" className="icon icon--person">0</span>
            <span id="js-total-anchors-tag" className="icon icon--anchor">0</span>
        </div>
    )
}
export default TotalFeatures
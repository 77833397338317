import React from 'react'
import SummaryProduct from '../summary-product/summary-product.component'
import { cloudShare } from '../../firebase/firebase.utils'
import './summary.styles.scss'

const Summary = ({ currentUser }) => {

    // getting the data saved in local storage and creating vars needed
    const sentDataString = localStorage.getItem('grid');
    const sentData = sentDataString ? JSON.parse(sentDataString) : false
    let itemsGrouped = {}
    let groupedData = []
    sentData && sentData.forEach(item => {
        if (itemsGrouped.hasOwnProperty(item.id)) {
            itemsGrouped[item.id] = itemsGrouped[item.id] + 1
        } else {
            itemsGrouped[item.id] = 1
            groupedData.push(item)
        }
    })

    let capacity = 0
    sentData && sentData.forEach(item => {
        item.brocap ?
            capacity = capacity + Number(item.brocap)
        :
            capacity = capacity + 0
    });

    let anchors = 0
    sentData && sentData.forEach(item => {
        item.anchors ?
            anchors = anchors + Number(item.anchors)
        :
            anchors = anchors + 0
    });

    let totalPrice = 0
    sentData && sentData.forEach(item => {
        item.price ?
            totalPrice = totalPrice + Number(item.price)
        :
            totalPrice = totalPrice + 0
    });

    const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
        try {
            decimalCount = Math.abs(decimalCount)
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount
            const negativeSign = amount < 0 ? "-" : ""
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
            let j = (i.length > 3) ? i.length % 3 : 0
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
        } catch (e) {
            console.log('there was an error formatting your money: ' + e)
        }
    }

    const sharePark = (e) => {
        // TD: refactor sharePark also used in side-tools component, should be a utility

        if (!currentUser) {
            e.target.id === 'jsToggleFeature' ? 
            alert('Contact a park representative and share your park ID with them to take a look, but first you need to sign into your Google account')
            :
            alert('You can upload your build to a shared space and send the ID code to a friend, colleague or park representative, but first you need to sign into your Google account')
            return
        }

        // check if there is anything currently to save
        let currentBuild = localStorage.getItem('grid')
        
        if (currentBuild && currentBuild.length > 0) {
            currentBuild = JSON.parse(currentBuild)

            // get the park info
            const parkInfo = {
                'name': localStorage.getItem('parkName'),
                'text': document.querySelector('#jsParkSize').innerHTML,
                'height': document.querySelector('#jsDropZone').offsetHeight,
                'width': document.querySelector('#jsDropZone').offsetWidth,
                'parkDepth': document.querySelector('#jsSidebar').dataset.parkDepth,
                'location': window.localStorage.getItem('location'),
                'currency': window.localStorage.getItem('currency'),
            }

            // save the build info with the park info
            const savedParkItem = {
                'parkInfo': parkInfo,
                'parkItems': currentBuild
            }

            cloudShare(currentUser, savedParkItem.parkInfo.name, JSON.stringify(savedParkItem), e.target.id)
            
        } else {
            alert('Add some items to your park first')
        }
    }
    
    return (
        <div className="summary">
            <div className="summary__totals">
                <ul>
                    <li>
                        <span className="summary__type">Features</span>
                        <span className="summary__amount">{sentData && sentData.length}</span>
                    </li>
                    <li>
                        <span className="summary__type">Capacity</span>
                        <span className="summary__amount">{sentData && capacity}</span>
                    </li>
                    <li>
                        <span className="summary__type">Anchors</span>
                        <span className="summary__amount">{sentData && anchors}</span>
                    </li>
                    <li>
                        <span className="summary__type blue">Total Cost</span>
                        <span className="summary__amount">{sentData && formatMoney(totalPrice)}</span>
                    </li>
                </ul>
            </div>

            <div className="sidebar__toggle">
                <div className="sidebar__toggle-item">
                    <button onClick={sharePark} id="jsToggleFeature" className={`button button--square`}>Email Quote</button>
                </div>

                <div className="sidebar__toggle-item">
                    <button disabled id="jsToggleSummary" className={`button button--square`}>View PDF</button>
                </div>
            </div>

            <div className="summary__products">
                {
                    groupedData && 
                    groupedData.map(
                        (product, i) => (<SummaryProduct key={i} data={product} amount={itemsGrouped[product.id]} />)
                    )
                }
            </div>
        </div>
    )
}
export default Summary
import React from 'react';
import './new-form-build3.styles.scss';

const NewBuildForm3 = (props) => (
    <div id='new-build-section--three' className='new-build-section'>
        <h2>New Build</h2>
        <label htmlFor='water-depth'>
            <span className='ukShow'>Do you know the depth of water<br />In your park?</span>
            <span className='euroShow'>Do you know the depth of water<br />for your project?</span>
            <input type='number' placeholder='5m' id='water-depth' onKeyUp={props.getParkDepth}/>
        </label>
        <p>3 of 5</p>
        <button className='button nextBuildForm' id='four'>Next</button>
    </div>
)

export default NewBuildForm3;
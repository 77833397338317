import React from 'react';

const Launch3d = () => {

    const localDev = true

    const launch = () => {
        if (localDev) {
            window.open(`/render3d/`, '_blank')
        } else {
            window.open(`/aqua-park-builder/render3d/`, '_blank')
        }
    }

    return(
        <button className='menuButton' onClick={launch}>
            View 3D
            <img src="/img/icons/three.png" alt="launch 3D render icon" />
        </button>
    )
}

export default Launch3d;
import firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/auth'

const euroMode = false

// config copied from firebase API
const config = {
    apiKey: "AIzaSyA0Kw3ZieG10zrreyBCXknSBV5b9j1i3Ro",
    authDomain: "aqua-park-builder.firebaseapp.com",
    databaseURL: "https://aqua-park-builder.firebaseio.com",
    projectId: "aqua-park-builder",
    storageBucket: "aqua-park-builder.appspot.com",
    messagingSenderId: "952938008076",
    appId: "1:952938008076:web:477a844e18b009b0f6a829"
}

// app.js will call this function when it is mounted, it returns this user
// or if this user is new it also creates the user in the database
// then returns the same userRef
export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return
    
    // get this user who signed in with Google 
    const userRef = firestore.doc(`users/${userAuth.uid}`)
    const snapShot = await userRef.get()

    // if they do not exist in the firestore lets make them a user
    if (!snapShot.exists) {
        const { displayName, email } = userAuth
        const createdAt = new Date()

        try {
            // set is the create method to add this user object
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData
            })

        } catch (error) {
            console.log('Error creating the user in firebase: ' + error)
        }
    }

    // return userRef so it can be used in app.js
    return userRef
}

// cloud save will make a new firestore doc for this user's park
export const cloudSaveBuild = async (userAuthID, parkName, parkDataString) => {
    if (!userAuthID) return

    // get this user who signed in with Google 
    const cloudSavedParks = firestore.doc(`users/${userAuthID}/savedParks/${parkName}`)
    
    await cloudSavedParks.get()

    try {
        // set is the create method to add this user object
        await cloudSavedParks.set({ savedPark : parkDataString })
    } catch (error) {
        alert(`Error creating the ${parkName} in firebase: `)
        console.log(error)
    }   
}

// allows users to upload to a shared firestore to share builds via ID
export const cloudShare = async (userAuthID, parkName, parkDataString, sendEmail) => {
    if (!userAuthID) return

    // get the shared park from firebase
    const currentParkID = `${userAuthID}${parkName.replace(/ /g,'')}`
    const sharedPark = firestore.doc(`sharedPark/${currentParkID}`)
    await sharedPark.get()

    try {
        // set is the create method to add new or over write this park object
        await sharedPark.set({ [currentParkID] : parkDataString })
        
        if (sendEmail === 'jsSend' || sendEmail === 'jsToggleFeature') {
            // this mails the share code
            const mailSubject = `New Aquaglide Park Design | ${parkName}`
            const mailMessage = `Hello Aquaglide team, \n I have created a new Park Design, my share code is ${currentParkID} \n Please can you make contact to give me more information on what is possible, and why Aquaglide is the market leader. \n Thankyou.`
            document.location = `mailto:${document.body.dataset.email}?subject=${mailSubject}&body=${mailMessage}`
        } else {
            alert(`Share this park ID: ${currentParkID}`)
            window.localStorage.setItem('shareID', currentParkID)
        }

    } catch (error) {
        alert(`Error creating the ${parkName} in firebase`)
        console.log(error)
    }
}

// allows users to get a shared park from the firestore
export const getSavedPark = async (userID, requestedParkID, parkData) => {
    if (!userID) return

    // check to see if the requested park exists
    const parkCheck = firestore.doc(`sharedPark/${requestedParkID}`)
    const parkSnapShot = await parkCheck.get()   

    if (!parkSnapShot.exists) {
        alert(`Sorry we can't find park: ${requestedParkID} could you check the ID and try again please?`)
        return false
    } else {
        const parkSnapShotData = parkSnapShot.data()
        parkData = parkSnapShotData[Object.keys(parkSnapShotData)[0]]

        return parkData
    }
}

// get collection of logged in users saved parks from firestore
export const getCloudSavedParks = async (userID, cloudParkCollection) => {
    if (!userID) return    

    // check to see if the user has the collection
    const parkCollectionCheck = firestore.collection(`users/${userID}/savedParks`)
    const cloudSavedParksSnapShot = await parkCollectionCheck.get()

    if (cloudSavedParksSnapShot.size <= 0) {
        return cloudParkCollection = false
    } else {
        cloudParkCollection = []
        cloudSavedParksSnapShot.docs.forEach(doc => {
            const parkSnapShotData = doc.data()
            cloudParkCollection.push(JSON.parse(parkSnapShotData[Object.keys(parkSnapShotData)[0]]))
        })
    }
    return cloudParkCollection
}


// get collection of lpre built parks saved on firebase
export const getPreBuiltParks = async (userID, cloudParkCollection) => {
    if (!userID) return    

    // check to see if the collection is there
    const parkCollectionCheck = firestore.doc(`prebuilds/aqua-park-pre-builds`)
    const cloudSavedParksSnapShot = await parkCollectionCheck.get()

    if (cloudSavedParksSnapShot.exists <= 0) {
        return cloudParkCollection = false
    } else {
        // get the object data and use its length to loop over it
        const parkSnapShotData = cloudSavedParksSnapShot.data()
        const size = Object.keys(parkSnapShotData).length
        cloudParkCollection = []
        for (let i = 0; i < size; i++) {
            cloudParkCollection.push(JSON.parse(parkSnapShotData[Object.keys(parkSnapShotData)[i]]))
        }        
        
    }
    return cloudParkCollection
}


// innit firebase with config
firebase.initializeApp(config)

// exporting features of firebase to be used in app
export const auth = firebase.auth()
export const firestore = firebase.firestore()

// exporting the sign in with Google feature for app
const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })
export const signInWithGoogle = () => auth.signInWithPopup(provider)

export default firebase
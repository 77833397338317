import React from 'react';
import './new-form-build2.styles.scss';

const NewBuildForm2 = (props) => (
    <div id='new-build-section--two' className='new-build-section'>
        <h2>New Build</h2>
        <label htmlFor='park-size-width'>
            <span className='ukShow'>How big is your park?</span>
            <span className='euroShow'>How big is your project?</span>
            <input type='number' placeholder='meters' id='park-size-width' onKeyUp={props.getParkWidth} />
            <span>x</span>
            <input type='number' placeholder='meters' id='park-size-breadth' onKeyUp={props.getParkHeight} />
        </label>
        <p>2 of 5</p>
        <button className='button nextBuildForm' id='three'>Next</button>
    </div>
)

export default NewBuildForm2;
import React from 'react'
import './image-preview-overlay.styles.scss'

const ImagePreviewOverlay = () => {
    const closeImagePreviewOverlay = (e) => {
        if (e.target.parentNode.id !== 'jsGalleryButtons' && e.target.parentNode.id !== 'jsGalleryMain') {
            document.body.classList.remove('open-image-preview-overlay')
            document.querySelector('#jsGalleryButtons').innerHTML = ''
        }
    }

    return (
        <div onClick={closeImagePreviewOverlay} id="jsPreviewImg" className="gallery image-preview-overlay">
            {/* these dives are used for the slider gallery which was removed but could be back */}
            <div id="jsGalleryMain" className="display-none gallery__main"></div>
            <div id="jsGalleryButtons" className="display-none gallery__buttons"></div>

            <div className="gallery__flex">
                <div id="jsGalleryImage" className="gallery__image" style={{backgroundImage: `url('/img/preview/Arena30-2.jpg')`}}></div>
                <div className="gallery__details">
                    <div className="gallery__close"><img src="/img/icons/close.svg" alt="close icon to remove overlay" /></div>
                    <div className="gallery__image-mini">
                        <img id="jsGalleryImageMini" src="/img/products-new/arena30.png" alt="mini preview of product" />
                    </div>
                    <p id="jsGalleryTitle" className="gallery__title bold caps"></p>
                    <ul id="jsGalleryFeatures" className="gallery__features"></ul>
                </div>
            </div>
        </div>
    )
}

export default ImagePreviewOverlay
import React from 'react';
import './new-form-build5.styles.scss';

const NewBuildForm5 = (props) => (
    <div id='new-build-section--five' className='new-build-section'>
        <h2>New Build</h2>
        <p>Would you like to complete a tutorial before you start?</p>

        <div className="inputs-flex">
            <div className="inputs-flex__yes">
                <input onClick={props.demoStart} type="radio" id="demo-true" name="demo" value="Yes" />
                <label onClick={props.demoStart} disabled htmlFor="demo-true">Yes</label>
            </div>
            <div className="inputs-flex__no">
                <input onClick={props.demoStart} type="radio" id="demo-false" name="demo" value="No" />
                <label onClick={props.demoStart} htmlFor="demo-false">No</label>
            </div>
        </div>

        <p>5 of 5</p>
        <button className="button" id="formComplete" onClick={props.launchBuild}>Start</button>
    </div>
)

export default NewBuildForm5;
import React from 'react'
import { auth } from '../../firebase/firebase.utils'
import { signInWithGoogle, cloudSaveBuild, cloudShare } from '../../firebase/firebase.utils'
import './side-tools.styles.scss'

const SideTools = ({ currentUser }) => {

    const toolClick = (e) => {
        // TD get zooms working with drag and drop 
        // button is currently hidden with CSS
        e.preventDefault()
        const gridEle = document.getElementById('jsDropZone')
        let width = gridEle.style.width
        let height = gridEle.style.height
        console.log(`width = ${width}`);
        console.log(`height = ${height}`);
    }

    const getDate = () => {
        const date = new Date()
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const localDateString = date.toLocaleDateString("en-US", options)
        return localDateString
    }

    const savePark = () => {
        if (!currentUser) {
            alert('To save your parks, please sign in with your Google account.')
            return
        }

        // check if there is anything currently to save
        let currentBuild = localStorage.getItem('grid')
        
        if (currentBuild && currentBuild.length > 0) {
            currentBuild = JSON.parse(currentBuild)

            // check if there is any locally saved parks, if not make the new array
            let localSaved = JSON.parse(localStorage.getItem('localSaved'))
            localSaved ? localStorage.getItem('localSaved') : localSaved = []

            // get the park info
            const parkInfo = {
                'name': localStorage.getItem('parkName'),
                'parkID': currentUser ? `${currentUser}${localStorage.getItem('parkName').replace(/ /g,'')}` : 'local save',
                'text': document.querySelector('#jsParkSize').innerHTML,
                'height': Number(document.querySelector('#jsDropZone').offsetHeight),
                'width': Number(document.querySelector('#jsDropZone').offsetWidth),
                'parkDepth': Number(document.querySelector('#jsSidebar').dataset.parkDepth),
                'timeStamp': `last updated: ${getDate()}`,
                'parkIcon': currentBuild[0].img,
                'location': window.localStorage.getItem('location'),
                'currency': window.localStorage.getItem('currency'),
            }
            

            // save the build info with the park info
            const savedParkItem = {
                'parkInfo': parkInfo,
                'parkItems': currentBuild
            }

            // if logged into Google use cloud save
            if (currentUser) {
                cloudSaveBuild(currentUser, savedParkItem.parkInfo.name, JSON.stringify(savedParkItem))
            } else {
                // if not logged in then use local storage

                // if this park is already saved (by name) remove it to save the new version
                localSaved.forEach((item, i) => {
                    if (item.parkInfo.name === parkInfo.name) {
                        localSaved.splice(i, 1); 
                    }
                })

                // push the new item, string it up and save it
                localSaved.push(savedParkItem)
                localSaved = JSON.stringify(localSaved)
                localStorage.setItem('localSaved', localSaved)
            }
        } else {
            alert('Add some items to your park first')
        }
    }

    const sharePark = (e) => {
        // TD: refactor share and save, too much shared code

        if (!currentUser) {
            e.target.id === 'jsSend' ? 
            alert('Contact a park representative and share your park ID with them to take a look, but first you need to sign into your Google account')
            :
            alert('You can upload your build to a shared space and send the ID code to a friend, colleague or park representative, but first you need to sign into your Google account')
            return
        }

        // check if there is anything currently to save
        let currentBuild = localStorage.getItem('grid')
        
        if (currentBuild && currentBuild.length > 0) {
            currentBuild = JSON.parse(currentBuild)

            // get the park info
            const parkInfo = {
                'name': localStorage.getItem('parkName'),
                'text': document.querySelector('#jsParkSize').innerHTML,
                'height': document.querySelector('#jsDropZone').offsetHeight,
                'width': document.querySelector('#jsDropZone').offsetWidth,
                'parkDepth': document.querySelector('#jsSidebar').dataset.parkDepth,
                'location': window.localStorage.getItem('location'),
                'currency': window.localStorage.getItem('currency'),
            }

            // save the build info with the park info
            const savedParkItem = {
                'parkInfo': parkInfo,
                'parkItems': currentBuild
            }

            cloudShare(currentUser, savedParkItem.parkInfo.name, JSON.stringify(savedParkItem), e.target.id)
            
        } else {
            alert('Add some items to your park first')
        }
    }

    const toggleTools = (e) => {
        e.preventDefault()
        e.target.parentNode.classList.toggle('side-tools--open')
    }

    return (
        <aside className="side-tools side-tools--open">
            <div className='toggle' onClick={toggleTools}>...</div>
            <button 
                id='jsZoomIn'
                className="button side-tools__button hide"
                onClick={toolClick}
            >
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search-plus" className="svg-inline--fa fa-search-plus fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z"></path></svg>
            </button>

            <button 
                id='jsZoomOut'
                className="button side-tools__button hide"
                onClick={toolClick}
            >
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search-minus" className="svg-inline--fa fa-search-minus fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 192v32c0 6.6-5.4 12-12 12H124c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z"></path></svg>
            </button>

            <button 
                id='jsSave'
                className="button side-tools__button"
                onClick={savePark}
            >
                <div className="toolTip" style={{top: '-20px'}}>
                <p>
                    {
                    currentUser ?
                    `Save your park to your Google cloud, each park needs a unique name`
                    :
                    `To save your park, please sign in with your Google account`
                    }
                </p>
                    
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.818 26.355"><g transform="translate(-0.5)"><path className="a" d="M21.7,0H.5V26.355H26.318V4.618ZM19.325,1.076v7.53H6.417V1.076ZM5.341,25.28V15.06h15.6V25.28Zm19.9,0H22.015v-11.3H4.265v11.3H1.576V1.076H5.341V9.682H20.4V1.076h.853l3.988,3.988Z"/><path className="a" d="M13.038,32.076H16.8A.538.538,0,1,0,16.8,31H13.038a.538.538,0,1,0,0,1.076Z" transform="translate(-5.546 -14.326)"/><path className="a" d="M18.417,35H13.038a.538.538,0,0,0,0,1.076h5.379a.538.538,0,0,0,0-1.076Z" transform="translate(-5.546 -16.175)"/><path className="a" d="M25.656,35.162a.545.545,0,0,0,0,.764.553.553,0,0,0,.382.156.539.539,0,1,0-.382-.92Z" transform="translate(-11.553 -16.18)"/><path className="a" d="M30.727,4H27.5V9.379h3.227ZM29.651,8.3H28.576V5.076h1.076Z" transform="translate(-12.478 -1.849)"/></g></svg>
            </button>

            <button 
                id='jsShare'
                className="button side-tools__button"
                onClick={sharePark}
            >
                <div className="toolTip" style={{top: '-30px'}}>
                    <p>
                        {
                        currentUser ?
                        `You can upload your park to a shared space and send the ID code to a park representative`
                        :
                        `You can share your build online, but please sign into your Google account first.`
                        }
                    </p>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.5 33.415"><g transform="translate(-13.8)"><path className="a" d="M39.319,21.467A5.98,5.98,0,0,0,34.587,23.8l-9.179-5.127a5.994,5.994,0,0,0,.333-1.97,5.862,5.862,0,0,0-.34-1.977L34.573,9.6a5.967,5.967,0,1,0-1.235-3.629,5.833,5.833,0,0,0,.34,1.977l-9.166,5.12a5.968,5.968,0,1,0,.014,7.251L33.7,25.45a5.971,5.971,0,1,0,5.62-3.983Zm0-19.587a4.1,4.1,0,1,1-4.1,4.1A4.1,4.1,0,0,1,39.319,1.88ZM19.781,20.8a4.1,4.1,0,1,1,4.1-4.1A4.1,4.1,0,0,1,19.781,20.8ZM39.319,31.535a4.1,4.1,0,1,1,4.1-4.1A4.1,4.1,0,0,1,39.319,31.535Z"/></g></svg>
            </button>

            <button 
                id="jsSend"
                className="button side-tools__button"
                onClick={sharePark}
            >
            <div className="toolTip" style={{top: '-30px'}}>
                    <p>
                        {
                        currentUser ?
                        `You can share your contact email and build data with a park representative via email`
                        :
                        `Sign in to your Google account to share your build with a park representative via email`
                        }
                    </p>
                </div>
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" className="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>            
            </button>

            <button 
                id='googleAuth'
                className={`button side-tools__button ${currentUser ? 'loggedIn' : 'loggedOut'}`}
                onClick={currentUser ? () => auth.signOut()  : signInWithGoogle}
            >
                <div className="toolTip">
                    <p>
                        {
                        currentUser ?
                        `Sign out of your Google account`
                        :
                        `Sign into your Google account`
                        }
                    </p>
                </div>
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" className="svg-inline--fa fa-google fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
            </button>

        </aside>
    )

}

export default SideTools
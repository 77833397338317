import React from 'react'
import { cloudShare } from '../../firebase/firebase.utils'
import './total-price.styles.scss'

const TotalPrice = ({ currentUser }) => {

    const sharePark = (e) => {
        // TD: refactor sharePark also used in side-tools component, should be a utility

        if (!currentUser) {
            e.target.id === 'jsToggleFeature' ? 
            alert('Contact a park representative and share your park ID with them to take a look, but first you need to sign into your Google account')
            :
            alert('You can upload your build to a shared space and send the ID code to a friend, colleague or park representative, but first you need to sign into your Google account')
            return
        }

        // check if there is anything currently to save
        let currentBuild = localStorage.getItem('grid')
        
        if (currentBuild && currentBuild.length > 0) {
            currentBuild = JSON.parse(currentBuild)

            // get the park info
            const parkInfo = {
                'name': localStorage.getItem('parkName'),
                'text': document.querySelector('#jsParkSize').innerHTML,
                'height': document.querySelector('#jsDropZone').offsetHeight,
                'width': document.querySelector('#jsDropZone').offsetWidth,
                'parkDepth': document.querySelector('#jsSidebar').dataset.parkDepth,
                'location': window.localStorage.getItem('location'),
                'currency': window.localStorage.getItem('currency'),
            }

            // save the build info with the park info
            const savedParkItem = {
                'parkInfo': parkInfo,
                'parkItems': currentBuild
            }

            cloudShare(currentUser, savedParkItem.parkInfo.name, JSON.stringify(savedParkItem), e.target.id)
            
        } else {
            alert('Add some items to your park first')
        }
    }

    return (
        <div className="total-price">
            <h3 className="price-currency">Sub Total <span id="js-total-price-tag">0.00</span></h3>
            <button id="jsSend" onClick={sharePark} className="button">
                Submit Quote
            </button>
        </div>
    )
}
export default TotalPrice
import React from 'react'
import Header from '../../components/header/header.component'
import DropZone from '../../components/drop-zone/drop-zone.component'
import Sidebar from '../../components/sidebar/sidebar.component'
import EditParkWidthHeight from '../../components/edit-width-height/edit-width-height.component'

class AutoBuildPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            setupReady: true,
            parkName: 'Aqua Park Builder',
            parkWidth: 1960,
            parkHeight: 1960,
            parkDepth: 10,
            relatedIds: []
        }

        window.localStorage.setItem('grid', [])
        window.localStorage.setItem('parkName', 'Aqua Park Builder')
        window.localStorage.setItem('parkDepth', this.state.parkDepth)
    }

    setRelatedProducts = (relatedProductIds) => {
        this.setState({relatedIds: relatedProductIds})
    }

    setNewParkName = (newName) => {
        this.setState({parkName: newName})
    }

    updateParkWidthHeightState = (e) => {
        e.preventDefault()

        // hide the popup
        document.querySelector('#jsEditPopup').classList.remove('edit-width-height--open')

        // get the updated values
        let newWidth = Number(document.querySelector('#jsEdit-width').value)
        let newHeight = Number(document.querySelector('#jsEdit-height').value)

        // if nothing is entered then take the measurement from the state
        newWidth === 0 ? newWidth = this.state.parkWidth : newWidth = newWidth  * 14
        newHeight === 0 ? newHeight = this.state.parkHeight : newHeight = newHeight * 14
        
        // set the state
        this.setState({ parkWidth: newWidth })
        this.setState({ parkHeight: newHeight })
    }

    openEditWidthHeightPopup = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.add('edit-width-height--open')
        }
    }

    closeEditWidthHeightPopup = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.remove('edit-width-height--open')
        }
    }

    render() {
        return (
            <div className='build-page page-flex'>
                <main className='main'>
                    <Header 
                        currentUser={this.props.currentUser} 
                        title={this.state.parkName} 
                        setNewParkName={this.setNewParkName}
                    />
                    <DropZone gridState={this.state} setRelatedProducts={this.setRelatedProducts}/>
                    
                    <p onClick={this.openEditWidthHeightPopup} id="jsParkSize" className="park-size">Park Size: { Number(this.state.parkWidth) / 14 }m x { Number(this.state.parkHeight) / 14}m</p>
                    <EditParkWidthHeight 
                        update={this.updateParkWidthHeightState} 
                        close={this.closeEditWidthHeightPopup} 
                        width={this.state.parkWidth}
                        height={this.state.parkHeight}
                    />
                </main>
                <Sidebar disabled={false} currentUser={this.props.currentUser} parkDepth={this.state.parkDepth} relatedProductIds={this.state.relatedIds}/>
            </div>
            )
        }
}

export default AutoBuildPage;
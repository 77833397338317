import React from 'react';
import './product-drag-item.scss';
import './gallery.css'

const ProductListItem = ({   
    id, name, imgUrl, imgAnchor, imgNew, folder, model, rotation, price, brocap, 
    anchors, length, width, deep, depth, relatedProducts, 
    previewImages, category, type, distance, webLink }) => {

    const localDev = true
    let urlString = ''

    if (!localDev) {
        urlString = '/aqua-park-builder'
    }

    const dragStart = (e) => {
        // make a preview for the dragged item
        createDragPreview(e)

        // transferring the data needed
        e.dataTransfer.setData('anchors', anchors)
        e.dataTransfer.setData('brocap', brocap)
        e.dataTransfer.setData('category', category)
        e.dataTransfer.setData('depth', depth)
        e.dataTransfer.setData('folder', folder)
        e.dataTransfer.setData('height', length)
        e.dataTransfer.setData('imgUrl', imgUrl)
        e.dataTransfer.setData('imgAnchor', imgAnchor)
        e.dataTransfer.setData('imgNew', imgNew)
        e.dataTransfer.setData('model', model)
        e.dataTransfer.setData('name', name)
        e.dataTransfer.setData('origin', e.target.getAttribute('data-origin'))
        e.dataTransfer.setData('price', price)
        e.dataTransfer.setData('product_id', e.target.id)
        e.dataTransfer.setData('relatedProducts', relatedProducts)
        e.dataTransfer.setData('rotation', rotation)
        e.dataTransfer.setData('width', width)
    }

    const dragOver = (e) => {
        e.stopPropagation()
    }

    const dragEnd = (e) => {
        e.stopPropagation()
    }

    const createDragPreview = (e) => {
        // making a preview image of the drag item
        
        const previewImg = document.createElement("img")
        previewImg.src = `${urlString}/img/products-new/${imgUrl}`

        previewImg.style.width = `${width}px`
        previewImg.style.height = `${length}px`

        // a container div for the preview item
        const previewDiv = document.createElement('div')
        previewDiv.style.width = `${width}px`
        previewDiv.style.height = `${length}px`
        previewDiv.appendChild(previewImg)

        // make a buffer div to stop buggy lines on preview
        const bufferLoadZone = document.createElement('div')
        bufferLoadZone.classList.add('buffer-load-zone')
        bufferLoadZone.appendChild(previewDiv)

        document.querySelector('body').appendChild(bufferLoadZone)
        e.dataTransfer.setDragImage(previewDiv, (width / 2), (length / 2))        
    }

    const openProductOverview = (e) => {
        // making and array of images and removing white space
        const imgUrls = e.target.getAttribute('data-img').replace(/\s/g, "").split(',')
        const galleryImages = []

        // making the object needed for my gallery plugin
        imgUrls.forEach(img => {
            const imgObj = {
                url: `${urlString}/img/preview/${img}`
            }
            galleryImages.push(imgObj)
        })

        // gallery image (just takes first value of images array)
        const galleryImage = document.querySelector('#jsGalleryImage')
        galleryImage.style.backgroundImage = `url(${galleryImages[0].url})`

        // mini preview of the product image
        const galleryMiniPic = document.querySelector('#jsGalleryImageMini')
        galleryMiniPic.src = `${urlString}/img/products-new/${e.target.getAttribute('data-pic')}`

        // get the gallery title
        const galleryTitle = document.querySelector('#jsGalleryTitle')
        galleryTitle.textContent = e.target.getAttribute('data-title')

        // if the gallery has any features append them as list items
        const galleryFeatureList = document.querySelector('#jsGalleryFeatures')
        galleryFeatureList.innerHTML = ''

        if (distance) {
            const listItem = document.createElement('li')
            listItem.textContent = `From land: ${e.target.getAttribute('data-distance')}m`
            galleryFeatureList.append(listItem)
        }
        if (type) {
            const listItem = document.createElement('li')
            listItem.textContent = `Min Depth: ${e.target.getAttribute('data-depth')}`
            galleryFeatureList.append(listItem)
        }
        if (webLink) {
            const listItem = document.createElement('li')
            listItem.innerHTML = `web: <a href="${e.target.getAttribute('data-link')}" target="_blank" rel="nofollow noopener">${e.target.getAttribute('data-title')}</a>`
            galleryFeatureList.append(listItem)
        }

        // show the gallery
        document.body.classList.add('open-image-preview-overlay')
    }



    return (
        <li 
            id={`product_${id}`}
            className={`product-list-item drag ${previewImages ? 'has-preview' : 'no-preview'}`}
            onDragStart={dragStart}
            onDragEnd={dragEnd}
            onDragOver={dragOver}
            draggable='true'
            data-origin='sidebar'
            data-folder={folder}
            data-model={model}
            data-rotation={rotation}
            data-price={price}
            data-deep={deep}
        >
            <img src={`${urlString}/img/products-new/${imgUrl}`} alt={name} />
            <p className="bold">{name}</p>
            <p className="min-depth-warning">min depth is {depth}m</p>
            
            { previewImages ? 
                <button 
                    className="button button--mini bold serif italic button--greyDark"
                    data-distance={distance} 
                    data-img={previewImages} 
                    data-title={name} 
                    data-depth={depth} 
                    data-link={webLink}
                    data-pic={imgUrl}
                    onClick={openProductOverview}
                    id="gallery-open"
                >i</button> 
                : null 
            }

            <div className="product-list-item__detail">
                <p className="icon icon--money">
                    <span>{price}</span>
                </p>
                <p className="icon icon--person">
                    <span>{brocap} person(s)</span>
                </p>
            </div>
        </li>
    )
}

export default ProductListItem;
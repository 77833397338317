import React from 'react';
import './water-depth.styles.scss'

const WaterDepth = () => {

    return(
        <div className="water-depth">
            <div className="water-depth__item water-depth__1">
                <span className="water-depth__text">
                    1.0m &gt; 1.4m
                </span>
                <span className="water-depth__color"></span>
            </div>

            <div className="water-depth__item water-depth__2">
                <span className="water-depth__text">
                    1.5m &gt; 1.9m
                </span>
                <span className="water-depth__color"></span>
            </div>

            <div className="water-depth__item water-depth__3">
                <span className="water-depth__text">
                    2.0m &gt; 2.4m
                </span>
                <span className="water-depth__color"></span>
            </div>

            <div className="water-depth__item water-depth__4">
                <span className="water-depth__text">
                    2.5m &gt; 2.9m
                </span>
                <span className="water-depth__color"></span>
            </div>

            <div className="water-depth__item water-depth__5">
                <span className="water-depth__text">
                    3.0m &gt; 3.4m
                </span>
                <span className="water-depth__color"></span>
            </div>

            <div className="water-depth__item water-depth__6">
                <span className="water-depth__text">
                    3.5m &gt; 4.0m
                </span>
                <span className="water-depth__color"></span>
            </div>
        </div>
    )
}

export default WaterDepth;
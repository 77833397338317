import React from 'react'
import Launch3d from '../launch3d/launch3d.component'
import ShareBuild from '../share-build/share-build.component'
import ParkSettings from '../park-settings/park-settings.component'
import ToggleSwitch from '../toggle-switch/toggle-switch.component'
import TotalPrice from '../total-price/total-price.component'
import TotalFeatures from '../total-features/total-features.component'
import buildPdf from '../../common/buildPdf'
import './header.styles.scss'

const Header = (props) => {

    const toggleClasses = () => {
        document.querySelector('#jsTitleH1').classList.toggle('hide')
        document.querySelector('#jsEditTitle').classList.toggle('hide')
    }

    const updateTitle = () => {
        const newParkName = document.querySelector('#jsEditInput').value
        localStorage.setItem('parkName', newParkName)
        props.setNewParkName(newParkName)
        toggleClasses()
    }

    const toggleNav = (e) => {
        document.body.classList.toggle('navOpen')
        if (e.target.id === 'open-help') {
            document.body.classList.add('help--open')
        } else if(e.target.id === 'jsMenu') {
            document.body.classList.remove('help--open')
        }
    }

    const openHelp = () => {
        document.body.classList.add('help--open')
    }

    return (
        <header className="header">
            <div className="header__white">
                <div className="header__buttons header__flex">

                    <button 
                        onClick={toggleNav}
                        className='menuButton' 
                        id='jsMenu'
                        alt="open and close navigation menu">
                        Menu
                        <img src="/img/icons/burger.svg" alt="menu icon" />
                    </button>

                    <button 
                        onClick={openHelp}
                        className='menuButton' 
                        id='open-help'
                        alt="open helper info">
                        Help
                        <img src="/img/icons/question.svg" alt="help menu icon" />
                    </button>

                    <ParkSettings />

                    <Launch3d />
                    <ShareBuild currentUser={props.currentUser} />
                </div>

                <div className="header__toggles header__flex">
                    <ToggleSwitch text="Anchors" toggleClass="toggle-anchors" />

                    <ToggleSwitch text="Depth" toggleClass="toggle-depth" />
                </div>

                <div className="header__features header__flex">
                    <TotalFeatures />
                </div>
            </div> {/* end header white */}

            <div className="header__blue">
                <TotalPrice currentUser={props.currentUser}></TotalPrice>

                <button className="button button--pdf" onClick={buildPdf}>
                    <img src="/img/icons/pdf.svg" alt="download your park quote as PDF" />
                    Download PDF
                </button>
            </div>

            <div className="title">
                <h1 onClick={toggleClasses} id="jsTitleH1" className="title__text">
                    <span className="blue">Park Name: </span>
                    {props.title}
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" className="title__icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg>
                </h1>
                
                <div id="jsEditTitle" className="title__edit hide">
                    <input id="jsEditInput" type="text" placeholder='Unique Park Name' pattern="[A-Za-z0-9]" />
                    <button onClick={updateTitle} className="button button--mini">
                        <svg className="title__icon--button" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                    </button>
                    <button onClick={toggleClasses} className="button button--mini button--grey">
                        <svg className="title__icon--button" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#ffffff" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                    </button>
                </div>
            </div>
        </header>
    )
}

export default Header
import React from 'react'
import Header from '../../components/header/header.component'
import SavedBuildItem from '../../components/saved-build-item/saved-build-item.compent'
import DropZone from '../../components/drop-zone/drop-zone.component'
import Sidebar from '../../components/sidebar/sidebar.component'
import EditParkWidthHeight from '../../components/edit-width-height/edit-width-height.component'
import { getCloudSavedParks } from '../../firebase/firebase.utils'
import './savedBuild.styles.scss'

class SavedBuildPage extends React.Component {
    
    constructor(props) {
        super()
        const localSaved = JSON.parse(localStorage.getItem('localSaved'))

        this.state = {
            localSaved: localSaved,
            cloudSaved: null,
            setupReady: false,
            relatedIds: [],
            gridState: {}
        }
    }

    getSelectedParkData = (e, info, items) => {
        if (e.target.id === 'jsCopyID') {
            const parkID = e.target.children[0].innerText
            
            if (parkID !== 'local save') {
                var text = parkID
                navigator.clipboard.writeText(text).then(function() {
                    alert('You have copied this park ID: ' + parkID);
                });
            }
        }
        
        const gridState = {
            'parkDepth' : Number(info.parkDepth),
            'parkHeight' : info.height,
            'parkWidth' : info.width,
            'parkName' : info.name,
            'parkSizeText' : info.text,
            'savedItems' : items
        }

        this.setState({'gridState': gridState})
        this.setState({setupReady: true})

        window.localStorage.setItem('grid', JSON.stringify(items))
        window.localStorage.setItem('parkName', info.name)
        window.localStorage.setItem('parkDepth', info.parkDepth)
    }

    setRelatedProducts = (relatedProductIds) => {
        this.setState({relatedIds: relatedProductIds})
    }

    backToSaveList = () => {
        // close any possibly opened sidebar product groups
        const productSideBarGroups = document.querySelectorAll('.products__group')        
        productSideBarGroups.forEach(item => {
            item.classList.remove('products__group--open')
        })
        // reset the state of the page so user goes back to saved list
        this.setState({'setupReady': false})
    }

    setNewParkName = (newName) => {
        this.setState({
            gridState: {
                  ...this.state.gridState,
                  parkName: newName
            }
        })
    }

    updateParkWidthHeightState = (e) => {
        e.preventDefault()

        // hide the popup
        document.querySelector('#jsEditPopup').classList.remove('edit-width-height--open')

        // get the updated values
        let newWidth = Number(document.querySelector('#jsEdit-width').value)
        let newHeight = Number(document.querySelector('#jsEdit-height').value)

        // if nothing is entered then take the measurement from the state
        newWidth === 0 ? newWidth = this.state.parkWidth : newWidth = newWidth  * 14
        newHeight === 0 ? newHeight = this.state.parkHeight : newHeight = newHeight * 14
        
        // set the state
        this.setState({
            gridState: {
                  ...this.state.gridState,
                  parkWidth: newWidth,
                  parkHeight: newHeight
            }
        })
    }

    openEditWidthHeightPopup = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.add('edit-width-height--open')
        }
    }

    closeEditWidthHeightPopup = () => {
        const popup = document.querySelector('#jsEditPopup')
        if (!popup) {
            return
        } else {
            popup.classList.remove('edit-width-height--open')
        }
    }

    getCloudParks = async () => {
        // early return to stop data loop
        if (this.state.cloudSaved) {
            return
        } else {
            const cloudSavedParks = await getCloudSavedParks(this.props.currentUser, this.state.cloudSaved)
            this.setState({cloudSaved: cloudSavedParks})
        }
    }

    toggleNav = (e) => {
        document.body.classList.toggle('navOpen')
    }
    
    render() {
        if(!this.state.setupReady) {
            return (
                <div className='saved-builds-list build-page page-flex'>
                    <main className='main'>
                        <button 
                            onClick={this.toggleNav}
                            className='menuButton wayOut' 
                            id='jsMenu'
                            alt="open and close navigation menu">
                            Menu
                            <img src="/img/icons/burger.svg" alt="menu icon" />
                        </button>
                        <div className="saved-builds">
                            <h1 className='offlineTitle'>Local Saved Aqua Parks</h1>
                            <h1 className='onlineTitle'>Cloud Saved Aqua Parks</h1>

                            <ol>
                                {
                                    this.props.currentUser ?
                                    (
                                        this.getCloudParks()
                                        &&
                                        this.state.cloudSaved && this.state.cloudSaved.length > 0 && this.state.cloudSaved.map((park, i) => (
                                            <SavedBuildItem getSelectedParkData={this.getSelectedParkData} key={i} info={park.parkInfo} items={park.parkItems} />
                                        ))
                                    )
                                    :
                                    <li className='log-in-for-parks'>Log in to get cloud saved parks</li>
                                }
                                {
                                    this.state.localSaved && this.state.localSaved.length > 0 && this.state.localSaved.map((park, i) => (
                                        <SavedBuildItem getSelectedParkData={this.getSelectedParkData} key={i} info={park.parkInfo} items={park.parkItems} />
                                    ))
                                }
                            </ol>
                        </div>
                    </main>
                    <Sidebar disabled={true} />
                </div>
            )
        } else {
            return (
                <div className='build-page page-flex'>
                    <main className='main'>

                        <Header currentUser={this.props.currentUser} title={this.state.gridState.parkName} setNewParkName={this.setNewParkName}/>
                        <button onClick={this.backToSaveList} className='button button--grey button--savedBuilds'>&lt; Back to saved park list</button>
                        
                        <DropZone gridState={this.state.gridState} setRelatedProducts={this.setRelatedProducts} />
                        <p onClick={this.openEditWidthHeightPopup} id="jsParkSize" className="park-size">Park Size: { Number(this.state.gridState.parkWidth) / 14 }m x { Number(this.state.gridState.parkHeight) / 14}m</p>
                        <EditParkWidthHeight 
                            update={this.updateParkWidthHeightState} 
                            close={this.closeEditWidthHeightPopup} 
                            width={this.state.gridState.parkWidth}
                            height={this.state.gridState.parkHeight}
                        />
                    </main>
                    <Sidebar disabled={false} parkDepth={this.state.gridState.parkDepth} relatedProductIds={this.state.relatedIds} />
                </div>
            )
        }
    }
}

export default SavedBuildPage;
import React from 'react'
import ProductListItem from '../product-drag-item/product-drag-item.component'
import './next-piece.styles.scss'

const NextPiece = (props) => {
    
    const toggleOpen = (e) => {
        e.preventDefault()
        e.target.parentNode.classList.toggle('next-piece--open')
    }

    const products = props.productArray
    let productIds = props.productIDs

    if (productIds.length === 0) {
        productIds = ['9', '10', '20']
    }
    
    return (
        <div className='next-piece'>
            <div className='toggle' onClick={toggleOpen}>
                ...
            </div>
            <ul>
                {
                    // map the related products if they exist and have the minimum needed data and assets
                    productIds.map((productId) => products[productId] && products[productId].image && products[productId].model && products[productId].widthM && (
                        <ProductListItem 
                            id={products[productId].id} 
                            key={productId} 
                            name={products[productId].product}
                            imgUrl={products[productId].imageNew}
                            folder={products[productId].folder}
                            model={products[productId].model}
                            width={(products[productId].widthM * 14).toFixed(2)}
                            length={(products[productId].lengthM * 14).toFixed(2)}
                            rotation={products[productId].rotation ? products[productId].rotation : 0}
                            price={products[productId].gbpPrice}
                            relatedProducts={[products[productId].stRelatedId, products[productId].ndRelatedId, products[productId].rdRelatedId]}
                        />
                    ))
                }
            </ul>
        </div>
    )
}

export default NextPiece
import React from 'react';
import ProductListItem from '../product-drag-item/product-drag-item.component';
import './product-group.styles.scss';

const ProductGroup = ({category, items, parkDepth, region}) =>  {
    const filteredItems = items
    .filter((item) => item.imageNew)
    .filter((item) => item.model)
    .filter((item) => item[region])
    .filter((item) => item.widthM)
    .filter((item) => item.category)
     
    //console.log("xxx", filteredItems)
    return (
        <div className={`products__group cat-${category} items-${filteredItems.length}`}>
            <h2 className="bold">{category}</h2>
            <ul className="products__list">
                {
                    // return a list item if it has an image, 
                    // 3D model, width and category
                    filteredItems.map((item) => (
                        <ProductListItem 
                            anchors={item.anchorPoints}
                            brocap={item.brochureCapacity}
                            category={item.category} 
                            deep={item.minDepthM < parkDepth ? true : false}
                            depth={item.minDepthM}
                            distance={item.safetyDistanceFromLandM}
                            folder={item.folder}
                            id={item.id}
                            imgAnchor={item.imageWithAnchors}
                            imgNew={item.imageNew}
                            imgUrl={item.imageNew}
                            key={item.id} 
                            length={(item.lengthM * 14).toFixed(2)}
                            model={item.model}
                            name={item.product}
                            previewImages={item.previewImages ? item.previewImages : null}
                            price={item[region]}
                            relatedProducts={[item.stRelatedId, item.ndRelatedId, item.rdRelatedId]}
                            rotation={0}
                            type={item.connectorPieceOrAlone}
                            webLink={item.websiteLink}
                            width={(item.widthM * 14).toFixed(2)}
                        />
                    ))
                }
            </ul>
        </div>
    )
}

export default ProductGroup;